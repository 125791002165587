import { Media } from 'constants';
import styled from 'styled-components';

const WrapperContent = styled.div`
  position: relative;
  display: ${({ display }) => display || 'flex'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '0'};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ justifyContent }) => justifyContent && 'justify-content: center'};
  ${({ justifyContentEnd }) => justifyContentEnd && 'justify-content: end'};
  ${({ alignItems }) => alignItems && 'align-items: center'};
  ${({ placeContent }) => placeContent && `place-content: ${placeContent}`};
  ${({ marginBotTop }) =>
    marginBotTop && `margin-bottom: ${marginBotTop}; margin-top: ${marginBotTop}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ width }) => width && `width: ${width}`};
  .Mui-error {
  }
  .multi-select {
    width: 250px
  }
  .rs-picker-toggle-textbox {
    color: black !important;
  }
  .rs-picker-toggle-value{
    color: black !important;
  }
  .rs-picker-toggle.rs-btn{
    padding-bottom: 9px !important;
    padding-top: 9px !important;
  }
  .rs-picker-toggle:hover {
    border-color: #cccccc !important;
  }
  .rs-picker-toggle-clean {
    top: 10px !important;
  }
`;
const WrapperForm = styled.div`
  margin-top: 30px;
  max-width: ${({ maxWidth }) => maxWidth || '800px'};
  margin-left: auto;
  margin-right: auto;
  ${Media.greaterThan(1109)} {
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  }
  .MuiFormHelperText-contained {
    margin-left: 0;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
  }
`;
const Content = styled.p`
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  margin-top: ${({ marginTop }) => marginTop || '5px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '2rem'};
  min-width: ${({ minWidth }) => minWidth || '200px'};
  ${({ width }) => width && `width: ${width} !important`};
  margin-left: ${({ mgl }) => mgl || '0'};
  ${({ removeMargin }) => removeMargin && `margin: 0 !important`};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  ${({ textStart }) => textStart && 'text-align: start'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({pdrl}) => pdrl && `padding-right: ${pdrl}; padding-left: ${pdrl}`};
  ${({ fsize }) => fsize && `font-size: ${fsize}`};
  ${({ overText }) =>
    overText &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}`};
`;
const Title = styled.h3`
  margin-bottom: ${({ marginBottom }) => marginBottom || '3rem'};
  font-weight: 600;
  text-align: center;
`;
const WrapperButtonForm = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%'};
  margin-left: ${({ mgl }) => mgl || 'auto'};
  margin-right: ${({ mgr }) => mgr || 'auto'};
  justify-content: ${({ justifyContent }) => justifyContent || 'end'};
  ${({ gap }) => gap && `gap: ${gap}`};
  ${({ wrap }) => wrap && `flex-wrap: wrap`};
  ${({ maxWidth }) => maxWidth && `maxWidth: ${maxWidth}`};
`;
const WrapperTitleContent = styled.div`
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  height: 35px;
  ${({ mtop }) => mtop && `margin-top: ${mtop}`};
  margin-bottom: 15px;
  border-radius: 6px;
  color: #fff;
  padding-left: 15px;
  padding-top: 2px;
`;
const WrapperShowTable = styled.div`
  padding: 10px 30px;
`;
export {
  WrapperContent,
  Content,
  Title,
  WrapperButtonForm,
  WrapperTitleContent,
  WrapperForm,
  WrapperShowTable,
};
