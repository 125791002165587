import { PATH_URL } from 'constants/routes/settingUrl';
import { PackagePayment } from 'pages/SettingSite/packagePayment';
import { SettingCategoryContact } from 'pages/SettingSite/settingCategoryContact';
import { SettingCategoryReport } from 'pages/SettingSite/settingCategoryReport';
import { SettingEffectStamp } from 'pages/SettingSite/settingEffectStamp';
import { SettingSiteOverall } from 'pages/SettingSite/settingSiteOverall';
import { SettingPayments } from 'pages/SettingSite/settingPayments';
import { Navigate } from 'react-router-dom';

export const listRouteSettingSite = [
  {
    path: `${PATH_URL.SETTING_SITE}/setting-category-contact`,
    element: <SettingCategoryContact />,
    title: 'お問い合わせカテゴリー設定画面',
    currentPath: `/${PATH_URL.SETTING_SITE}/setting-category-contact`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.SETTING_SITE}/setting-category-report`,
    element: <SettingCategoryReport />,
    title: '通報のカテゴリー設定画面',
    currentPath: `/${PATH_URL.SETTING_SITE}/setting-category-report`,
    backPath: `/${PATH_URL.REVENUE}`,
  },

  {
    path: `${PATH_URL.SETTING_SITE}/setting-effect`,
    element: <SettingEffectStamp />,
    title: 'エフェクト・スタンプ設定画面',
    currentPath: `/${PATH_URL.SETTING_SITE}/setting-effect`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.SETTING_SITE}/setting-point`,
    element: <PackagePayment />,
    title: 'ポイント設定画面',
    currentPath: `/${PATH_URL.SETTING_SITE}/setting-point`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.SETTING_SITE}/point-create`,
    element: <div />,
    title: 'ポイント 新規登録画面',
    currentPath: `/${PATH_URL.SETTING_SITE}/point-create`,
    backPath: `/${PATH_URL.SETTING_SITE}/setting-point`,
  },
  {
    path: `${PATH_URL.SETTING_SITE}/point-edit/:id`,
    element: <div />,
    title: 'ポイント 編集画面',
    currentPath: `/${PATH_URL.SETTING_SITE}/point-edit`,
    backPath: `/${PATH_URL.SETTING_SITE}/setting-point`,
  },
  {
    path: `${PATH_URL.SETTING_SITE}/general-setting`,
    element: <SettingSiteOverall />,
    title: 'サイト全般設定画面',
    currentPath: `/${PATH_URL.SETTING_SITE}/general-setting`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.SETTING_SITE}`,
    currentPath: `/${PATH_URL.SETTING_SITE}/general-setting`,
    element: <Navigate to='general-setting' replace />,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.SETTING_SITE}/setting-payment`,
    title: 'お支払い方法',
    currentPath: `/${PATH_URL.SETTING_SITE}/setting-payment`,
    element: <SettingPayments />,
    backPath: `/${PATH_URL.REVENUE}`,
  },
];
