import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import { DeleteInputDate, InputTextDate, MessError, WrapperDate } from './styled';
import { ja } from 'date-fns/locale';
import { ICON_CLEAR } from 'constants';
import { useEffect } from 'react';
import { formatDateOnly } from 'constants/FilterDate';
export const FormInputDate = ({
  name,
  control,
  label,
  clearData,
  styleInput,
  defaultValue,
  propsErr,
  ...props
}) => {
  const sttInput = document.getElementById('DisabledInput');
  useEffect(() => {
    if (sttInput) {
      sttInput.readOnly = true;
    }
  }, [sttInput]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
        const showMessDate = {
          birthday: formState?.errors?.birthday?.message,
          start_time: formState?.errors?.start_time?.message,
          end_time: formState?.errors?.end_time?.message,
        };
        return (
          <WrapperDate>
            <LocalizationProvider locale={ja} dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat='yyyy/MM/dd'
                label={label}
                error={!!error}
                onChange={onChange}
                value={value ? value : defaultValue ? defaultValue : null}
                maxDate={new Date()}
                closeOnSelect
                allowSameDateSelection
                disableMaskedInput
                renderInput={(params) => (
                  <InputTextDate
                    id='DisabledInput'
                    variant='outlined'
                    {...params}
                    {...styleInput}
                  />
                )}
                {...props}
              />
              {clearData && value && (
                <DeleteInputDate onClick={() => clearData(name, '')}>
                  {ICON_CLEAR}
                </DeleteInputDate>
              )}
            </LocalizationProvider>
            {error && <MessError {...propsErr}>{showMessDate[name]}</MessError>}
          </WrapperDate>
        );
      }}
    />
  );
};
