import { Text, TooltipHover } from 'components/Text';
import { textToStatusPoint } from 'constants';
import { textToStatusReport } from 'constants';
import {
  textToStatusRoleType,
  textToStatusNotification,
  textToTransaction,
  textToPaymentMethod,
  textToStatusAdminCS,
  textToStatusPayment,
  textToStatus,
  textToGender,
  // textToStatusUser,s
} from 'constants';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import { textToRevenue } from 'constants';
import { textToDisPlayPosition } from 'constants';
import { getEnv } from 'configs/env';
import CryptoJS from 'crypto-js';
import { typePost } from 'constants';
import { statusPost } from 'constants';

const maxLengthString = (str, max) => (str.length > max ? str.slice(0, max) : str);
const removePercent = (str) => str?.replace(/%/g, '');
// regex only number and one dot (.)

const formatNumberPhone = (phone) => {
  let newPhone = phone?.toString().replace(/[^0-9]/g, '');

  if (newPhone?.length === 10) {
    return `${newPhone.slice(0, 2)}-${newPhone.slice(2, 6)}-${newPhone.slice(6, 10)}`;
  }
  if (newPhone?.length === 11) {
    return `${newPhone.slice(0, 3)}-${newPhone.slice(3, 7)}-${newPhone.slice(7, 11)}`;
  }
  if (newPhone?.length === 12) {
    return `${newPhone.slice(0, 4)}-${newPhone.slice(4, 8)}-${newPhone.slice(8, 12)}`;
  }
  if (newPhone?.length > 12) {
    newPhone = newPhone.slice(0, -1);
    return `${newPhone.slice(0, 4)}-${newPhone.slice(4, 8)}-${newPhone.slice(8, 12)}`;
  }
  return newPhone;
};
// format UTC
const formatUTC = (dt) => moment.utc(dt).format('YYYY-MM-DD HH:mm:ss');

const convertToCurrentTime = (dayConvert) => {
  const moment = require('moment-timezone');
  const currentZone = moment.tz.guess();
  const day = moment.utc(dayConvert);
  return dayConvert ? day.tz(currentZone) : '';
};
const formatShowDate = (date, type) => {
  const d = moment(new Date(date));
  const year = d.format('YYYY');
  const month = d.format('MM');
  const day = d.format('DD');
  const hour = d.format('HH');
  const minute = d.format('mm');
  const second = d.format('ss');
  if (year === 'Invalid date' || month === 'Invalid date' || day === 'Invalid date') {
    return '';
  }
  switch (type) {
    case 1:
      return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    case 2:
      return `${year}/${month}/${day}`;
    case 3:
      return `${year}/${month}`;
    case 4:
      return `${year}-${month}-${day}`;
    case 5:
      return `${year}/${month}/${day} ${hour}:${minute}`;
    case 6:
      return `${year}${month}${day}${hour}${minute}${second}`;
    default:
      return '';
  }
};
const renderFormatDate = (date, type) => {
  switch (type) {
    case 1:
      return formatShowDate(convertToCurrentTime(date), 1);
    case 2:
      return formatShowDate(convertToCurrentTime(date), 2);
    case 3:
      return formatShowDate(convertToCurrentTime(date), 3);
    case 4:
      return formatShowDate(convertToCurrentTime(date), 4);
    case 5:
      return formatShowDate(convertToCurrentTime(date), 5);
    case 6:
      return formatShowDate(convertToCurrentTime(date), 6);
    default:
      return '';
  }
};

const enterToSearch = (e, callback) => {
  if (e.key === 'Enter') {
    callback();
  }
};
const convertWrapComma = (data = '') => data.split(',').join('\n');
const removeDash = (str) => str?.replace(/-/g, '');
const convertToSingleLine = (str) => str?.replace(/(\r\n|\n|\r)/gm, ' ');
const checkTypeCell = (type, content) => {
  switch (type) {
    case 'text':
      return content || '-';
    case 'date':
      return renderFormatDate(content, 2);
    case 'date_time':
      return renderFormatDate(content, 1);
    case 'date_month':
      return renderFormatDate(content, 3);
    case 'date_api':
      return renderFormatDate(content, 4);
    case 'date_hour':
      return renderFormatDate(content, 5);
    case 'name_csv':
      return `${content}_${renderFormatDate(new Date(), 6)}`;
    case 'percent':
      return `${content} %`;
    case 'total_sales':
      return content === null
        ? '-'
        : `${Number(content.price)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 円`;
    case 'price_sales':
      return content === null
        ? '-'
        : `${Number(content.price_sales)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 円`;
    case 'price_subscription':
      return content === null
        ? '-'
        : `${Number(content.price_subscription)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 円`;
    case 'price_livestream':
      return content === null
        ? '-'
        : `${Number(content.price_livestream)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 円`;
    case 'price_message':
      return content === null
        ? '-'
        : `${Number(content.price_message)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 円`;
    case 'type_role':
      return textToStatusRoleType[content];
    case 'gender':
      return textToGender[content];
    case 'status':
      return textToStatus[content];
    case 'status_payment':
      return textToStatusPayment[content];
    case 'status_notification':
      return textToStatusNotification[content];
    case 'status_payment_method':
      return textToPaymentMethod[content];
    case 'status_report':
      return textToStatusReport[content] || '-';
    case 'status_revenue':
      return textToRevenue[content];
    case 'phone':
      return formatNumberPhone(content);
    case 'amount':
      return content === null
        ? '-'
        : `${Number(content)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 円`;
    case 'discount':
      return content === null
        ? '-'
        : `${Number(content)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`;
    case 'amount_pt':
      return isNumber(content)
        ? `${content
            ?.toFixed(0)
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}pt`
        : '0pt';
    case 'company':
      return content || 'Sharegram';
    case 'level':
      return content === null ? '第１次代理店' : '第２次代理店';
    case 'level_creator':
      return content === null ? '第1次代理店' : '第2次代理店';
    case 'level_agency':
      return content === 1 ? '第1次代理店' : '第2次代理店';
    case 'decimal':
      return content?.toFixed(4);
    case 'name_subscription':
      return content || 'なし';
    case 'transaction':
      return textToTransaction[content];
    case 'fan_name':
      return content === 1 ? 'アフィリエイター' : 'ユーザー';
    case 'status_point':
      return textToStatusPoint[content];
    case 'buy_counter':
      return content === 0 ? '未販売' : '販売中';
    case 'status_admin_cs':
      return textToStatusAdminCS[content];
    case 'display_position':
      return textToDisPlayPosition[content];
    case 'one_line':
      return convertToSingleLine(content);
    case 'type_post':
      return typePost[content];
    case 'status_post':
      return statusPost[content] || '-';
    case 'agency_full_name':
      return content === null ? 'Sharegram' : content;
    case 'point_bonus':
      return content === 0 ? '-' : content;
    default:
      return content;
  }
};
const cellToTooltip = (params, type = 'text', min = 200, isWrapContent = false, ...rest) => ({
  field: params,
  minWidth: min,
  flex: 1,
  sortable: false,
  renderCell: (data) => (
    <TooltipHover
      title={
        isWrapContent
          ? convertWrapComma(checkTypeCell(type, data.row[params]) || '')
          : (checkTypeCell(type, data.row[params])?.length > 100
              ? checkTypeCell(type, data.row[params]).substring(0, 100) + '...'
              : checkTypeCell(type, data.row[params])) || ''
      }>
      <Text maxWidth={min}>{checkTypeCell(type, data.row[params])}</Text>
    </TooltipHover>
  ),
  ...rest,
});
const cellToTooltipTwoLevel = (params1, params2, type = 'text', min = 200, ...rest) => ({
  field: params1 + params2,
  minWidth: min,
  flex: 1,
  sortable: false,
  renderCell: (data) => (
    <TooltipHover title={checkTypeCell(type, data.row[params1]?.[params2]) || ''}>
      <Text maxWidth={min}>{checkTypeCell(type, data.row[params1]?.[params2])}</Text>
    </TooltipHover>
  ),
  ...rest,
});
const cellToTooltipHideCond = (
  params1,
  cond = false,
  text = '-',
  type = 'text',
  min = 200,
  ...rest
) => ({
  field: params1,
  minWidth: min,
  flex: 1,
  sortable: false,
  renderCell: (data) =>
    cond(data?.row) ? (
      text
    ) : (
      <TooltipHover title={checkTypeCell(type, data.row[params1]) || ''}>
        <Text maxWidth={min}>{checkTypeCell(type, data.row[params1])}</Text>
      </TooltipHover>
    ),
  ...rest,
});

const decryptPath = function (pathEncrypted) {
  if (!pathEncrypted) return;
  const key = getEnv('REACT_APP_KEY_DECODE_IMAGE');
  // Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
  // So let's base64 decode the string to get them.
  try {
    pathEncrypted = JSON.parse(atob(pathEncrypted));
    // Decrypt the value, providing the IV.
    const decrypted = CryptoJS.AES.decrypt(
      pathEncrypted.value,
      CryptoJS.enc.Base64.parse(key),
      {
        iv: CryptoJS.enc.Base64.parse(pathEncrypted.iv),
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return pathEncrypted;
  }
};

const convertDateStringToObject = (dateString = '', type = 'to') => {
  if (!isEmpty(dateString)) {
    const [year, month, day] = dateString?.split('-');
    if (type === 'from') {
      return {
        yearStart: +year,
        monthStart: +month,
        dayStart: +day,
      };
    } else
      return {
        yearEnd: +year,
        monthEnd: +month,
        dayEnd: +day,
      };
  }
  return {};
};

const convertToDate = (year, month, day) => {
  if (day) {
    return `${year}-${month}-${day}`;
  } else if (month) {
    return `${year}-${month}`;
  } else if (year) {
    return `${year}`;
  }
  return '';
};

export {
  renderFormatDate,
  formatNumberPhone,
  enterToSearch,
  removeDash,
  cellToTooltip,
  maxLengthString,
  checkTypeCell,
  removePercent,
  cellToTooltipTwoLevel,
  cellToTooltipHideCond,
  formatUTC,
  decryptPath,
  convertDateStringToObject,
  convertToDate,
};
