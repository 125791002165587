/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  error: null,
  pagination: {},
  listCreatorsFavorite: [],
  listCreatorsFavoriteType2: [],
  listNewCreators: [],
  listNewCreatorsType2: [],
  listRankingCreators: [],
  listRankingCreatorsType2: [],
  listRecommends: [],
  listRecommendsType2: [],
  ListPickups: [],
  listFavoriteGenres: [],
  listBanners: [],
  listBannersAdmin: [],
  dataCheckBox: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_CREATOR_FAVORITE_REQUEST:
    case TYPES.GET_LIST_PICKUP_REQUEST:
    case TYPES.GET_LIST_RANKING_CREATOR_REQUEST:
    case TYPES.GET_LIST_RANKING_CREATOR_TYPE2_REQUEST:
    case TYPES.GET_LIST_CREATOR_FAVORITE_TYPE2_REQUEST:
    case TYPES.GET_LIST_NEW_CREATOR_REQUEST:
    case TYPES.GET_LIST_NEW_CREATOR_TYPE2_REQUEST:
    case TYPES.GET_LIST_RECOMMENDS_REQUEST:
    case TYPES.GET_LIST_RECOMMENDS_TYPE2_REQUEST:
    case TYPES.DELETE_CREATOR_TYPE2_REQUEST:
    case TYPES.PUT_CREATOR_FAVORITE_REQUEST:
    case TYPES.POST_CREATOR_FAVORITE_SAVED_REQUEST:
    case TYPES.ADD_CREATOR_REQUEST:
    case TYPES.CHANGE_TYPE_LIST_FAVORITE_GENRES_REQUEST:
    case TYPES.GET_LIST_BANNERS_REQUEST:
    case TYPES.CREATE_BANNER_ADMIN_REQUEST:
    case TYPES.DELETE_BANNER_ADMIN_REQUEST:
    case TYPES.GET_LIST_CHECK_BOX_TYPE_REQUEST:
      return {
        ...state,
        status: action.status,
        error: null,
        pagination: {},
      };

    case TYPES.GET_LIST_CREATOR_FAVORITE_SUCCESS:
      return {
        ...state,
        status: action.status,
        listCreatorsFavorite: action?.data.data,
      };
    case TYPES.GET_LIST_CREATOR_FAVORITE_TYPE2_SUCCESS:
      return {
        ...state,
        status: action.status,
        listCreatorsFavoriteType2: action.data.data,
      };
    case TYPES.GET_LIST_NEW_CREATOR_SUCCESS:
      return {
        ...state,
        status: action.status,
        listNewCreators: action.data.data,
      };
    case TYPES.GET_LIST_NEW_CREATOR_TYPE2_SUCCESS:
      return {
        ...state,
        status: action.status,
        listNewCreatorsType2: action.data.data,
      };
    case TYPES.GET_LIST_PICKUP_SUCCESS:
      return {
        ...state,
        status: action.status,
        ListPickups: action?.data.data,
      };
    case TYPES.GET_LIST_RANKING_CREATOR_SUCCESS:
      return {
        ...state,
        status: action.status,
        listRankingCreators: action?.data.data,
      };
    case TYPES.GET_LIST_RANKING_CREATOR_TYPE2_SUCCESS:
      return {
        ...state,
        status: action.status,
        listRankingCreatorsType2: action?.data.data,
      };
    case TYPES.GET_LIST_RECOMMENDS_SUCCESS:
      return {
        ...state,
        status: action.status,
        listRecommends: action.data.data,
      };
    case TYPES.GET_LIST_RECOMMENDS_TYPE2_SUCCESS:
      return {
        ...state,
        status: action.status,
        listRecommendsType2: action.data.data,
      };

    case TYPES.DELETE_CREATOR_TYPE2_SUCCESS:
    case TYPES.PUT_CREATOR_FAVORITE_SUCCESS:
    case TYPES.POST_CREATOR_FAVORITE_SAVED_SUCCESS:
    case TYPES.ADD_CREATOR_SUCCESS:
    case TYPES.DELETE_BANNER_ADMIN_SUCCESS:
      return {
        ...state,
        status: action.status,
      };
    case TYPES.CHANGE_TYPE_LIST_FAVORITE_GENRES_SUCCESS:
      return {
        ...state,
        status: action.status,
        listFavoriteGenres: action.data.data,
      };
    case TYPES.GET_LIST_BANNERS_SUCCESS:
      return {
        ...state,
        status: action.status,
        listBanners: action.data.data,
      };

    case TYPES.CREATE_BANNER_ADMIN_SUCCESS:
      return {
        ...state,
        status: action.status,
      };
    case TYPES.GET_LIST_CHECK_BOX_TYPE_SUCCESS:
      return {
        ...state,
        status: action.status,
        dataCheckBox: action.data.data,
      };
    case TYPES.CREATE_POINT_FAILED:
    case TYPES.GET_LIST_CREATOR_FAVORITE_FAILED:
    case TYPES.GET_LIST_CREATOR_FAVORITE_TYPE2_FAILED:
    case TYPES.GET_LIST_NEW_CREATOR_FAILED:
    case TYPES.GET_LIST_NEW_CREATOR_TYPE2_FAILED:
    case TYPES.GET_LIST_PICKUP_FAILED:
    case TYPES.GET_LIST_RANKING_CREATOR_FAILED:
    case TYPES.GET_LIST_RANKING_CREATOR_TYPE2_FAILED:
    case TYPES.GET_LIST_RECOMMENDS_FAILED:
    case TYPES.GET_LIST_RECOMMENDS_TYPE2_FAILED:
    case TYPES.DELETE_CREATOR_TYPE2_FAILED:
    case TYPES.PUT_CREATOR_FAVORITE_FAILED:
    case TYPES.POST_CREATOR_FAVORITE_SAVED_FAILED:
    case TYPES.ADD_CREATOR_FAILED:
    case TYPES.CHANGE_TYPE_LIST_FAVORITE_GENRES_FAILED:
    case TYPES.GET_LIST_BANNERS_FAILED:
    case TYPES.CREATE_BANNER_ADMIN_FAILED:
    case TYPES.DELETE_BANNER_ADMIN_FAILED:
    case TYPES.GET_LIST_CHECK_BOX_TYPE_FAILED:
      return {
        ...state,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};
