import { createContext } from 'react';
import PropTypes from 'prop-types';
const EmailContext = createContext();
// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COUNT':
      return { ...state, count: action.payload };
    default:
      return state;
  }
};
const initialState = {
  count: 0,
};
const EmailReducer = { reducer, initialState };
// PropTypes
const EmailPropTypes = {
  typesMain: {},
  defaultMain: {},
};
export { EmailContext, EmailReducer, EmailPropTypes };
