export const VOUCHER = {
  GET_LIST_VOUCHER: 'GET_LIST_VOUCHER',
  GET_LIST_VOUCHER_REQUEST: 'GET_LIST_VOUCHER_REQUEST',
  GET_LIST_VOUCHER_SUCCESS: 'GET_LIST_VOUCHER_SUCCESS',
  GET_LIST_VOUCHER_FAILED: 'GET_LIST_VOUCHER_FAILED',

  CREATE_VOUCHER_REQUEST: 'CREATE_VOUCHER_REQUEST',
  CREATE_VOUCHER_SUCCESS: 'CREATE_VOUCHER_SUCCESS',
  CREATE_VOUCHER_FAILED: 'CREATE_VOUCHER_FAILED',

  DELETE_VOUCHER_REQUEST: 'DELETE_VOUCHER_REQUEST',
  DELETE_VOUCHER_SUCCESS: 'DELETE_VOUCHER_SUCCESS',
  DELETE_VOUCHER_FAILED: 'DELETE_VOUCHER_FAILED',

  EDIT_VOUCHER_REQUEST: 'EDIT_VOUCHER_REQUEST',
  EDIT_VOUCHER_SUCCESS: 'EDIT_VOUCHER_SUCCESS',
  EDIT_VOUCHER_FAILED: 'EDIT_VOUCHER_FAILED',
};
