const paramsCreate = {
  email: '',
  gender: 0,
  full_name: '',
  birthday: '',
  name_romaji: '',
};
const paramsEdit = {
  email: '',
  gender: '',
  full_name: '',
  birthday: '',
  name_romaji: '',
  status: '',
};
export { paramsCreate, paramsEdit };
