export const AUTH = {
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    PRE_SIGN_IN: 'PRE_SIGN_IN',
    SEND_EMAIL_RESET_PASSWORD: 'SEND_EMAIL_RESET_PASSWORD',
    CHECK_TOKEN_RESET_PASSWORD: 'CHECK_TOKEN_RESET_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    GET_PROFILE_AUTH: 'GET_PROFILE_AUTH',
    GET_PERMISSION: 'GET_PERMISSION',
  };
  