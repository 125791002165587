/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  listFansAccount: [],
  detailFan: {},
  pagination: {},
  listSubscription: [],
  listContent: [],
  listPoint: [],
  listDonate: [],
  listPaymentHistory: [],
  listPointHistory: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DELETE_FAN_ACCOUNT_REQUEST:
    case TYPES.DELETE_CONTRACT_ACCOUNT_REQUEST:
    case TYPES.UPDATE_FAN_ACCOUNT_REQUEST:
    case TYPES.GET_LIST_FANS_REQUEST:
    case TYPES.GET_FAN_DETAIL_REQUEST:
      return {
        ...state,
        detailFan: {},
        pagination: {},
      };
    case TYPES.GET_FAN_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        listSubscription: [],
        pagination: {},
      };
    case TYPES.GET_FAN_CONTENT_REQUEST:
      return {
        ...state,
        listContent: [],
        pagination: {},
      };
    case TYPES.GET_FAN_POINT_REQUEST:
      return {
        ...state,
        listPoint: [],
        pagination: {},
      };
    case TYPES.GET_FAN_DONATE_REQUEST:
      return {
        ...state,
        listDonate: [],
        pagination: {},
      };
    case TYPES.GET_FAN_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        listPaymentHistory: [],
        pagination: {},
      };
    case TYPES.GET_FAN_POINT_HISTORY_REQUEST:
      return {
        ...state,
        listPointHistory: [],
        pagination: {},
      };
    case TYPES.DELETE_FAN_ACCOUNT_SUCCESS:
    case TYPES.DELETE_CONTRACT_ACCOUNT_SUCCESS:
    case TYPES.UPDATE_FAN_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case TYPES.GET_LIST_FANS_SUCCESS:
      return {
        ...state,
        listFansAccount: action.data.res.data,
        pagination: action.data.res.pagination,
      };
    case TYPES.GET_FAN_DETAIL_SUCCESS:
      return {
        ...state,
        detailFan: action.data.data,
      };
    case TYPES.GET_FAN_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        listSubscription: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_FAN_CONTENT_SUCCESS:
      return {
        ...state,
        listContent: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_FAN_POINT_SUCCESS:
      return {
        ...state,
        listPoint: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_FAN_DONATE_SUCCESS:
      return {
        ...state,
        listDonate: action.data.data,

        pagination: action.data.pagination,
      };
    case TYPES.GET_FAN_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        listPaymentHistory: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_FAN_POINT_HISTORY_SUCCESS:
      return {
        ...state,
        listPointHistory: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.DELETE_FAN_ACCOUNT_FAILED:
    case TYPES.DELETE_CONTRACT_ACCOUNT_FAILED:
    case TYPES.UPDATE_FAN_ACCOUNT_FAILED:
    case TYPES.GET_LIST_FANS_FAILED:
    case TYPES.GET_FAN_DETAIL_FAILED:
    case TYPES.GET_FAN_SUBSCRIPTION_FAILED:
    case TYPES.GET_FAN_CONTENT_FAILED:
    case TYPES.GET_FAN_POINT_FAILED:
    case TYPES.GET_FAN_DONATE_FAILED:
    case TYPES.GET_FAN_PAYMENT_HISTORY_FAILED:
    case TYPES.GET_FAN_POINT_HISTORY_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
