import { listRouterRejectAuth, listRouterAcceptAuth } from 'routes';
import { Route, Navigate } from 'react-router-dom';
import { useRequireAuth } from 'hooks';
import { WrapperAuth } from 'components/layouts/admin';
import { useSelector } from 'react-redux';
import { PATH_URL } from 'constants/routes/settingUrl';
export const useTemplateRouter = () => {
  const { ProtectedRoutes, isAuth } = useRequireAuth();
  const { checkPermission, permissions, profile } = useSelector((state) => state.auth);
  const notFound = (
    <Route
      path='*'
      element={
        <Navigate
          replace
          to={
            isAuth
              ? `/${profile.user_type === 6 ? 'users-management/account' : PATH_URL.REVENUE}`
              : '/sign-in'
          }
        />
      }></Route>
  );
  const routeAdmin = (
    <Route
      path='/'
      element={
        <WrapperAuth>
          <ProtectedRoutes />
        </WrapperAuth>
      }>
      {notFound}
      {listRouterAcceptAuth(permissions).map((route) => (
        <Route
          key={route.path}
          {...route}
          element={
            (profile?.user_type === 6 ? checkPermission : profile?.id) ? (
              route.element
            ) : (
              <div>...</div>
            )
          }
        />
      ))}
    </Route>
  );
  const routeGhost = (
    <Route path='/'>
      {listRouterRejectAuth.map((route) => (
        <Route key={route.path} {...route} />
      ))}
      {notFound}
    </Route>
  );

  return { routeAdmin, routeGhost, notFound };
};
