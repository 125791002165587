import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { lazyRoute } from 'effect';

import { PATH_URL } from 'constants/routes/settingUrl';

const PostPage = lazy(() =>
  lazyRoute(() =>
    import('pages/post').then((module) => ({
      default: module.PostPage,
    }))
  )
);
export const listRoutePostPage = [
  {
    path: `${PATH_URL.POST}/manage`,
    element: <PostPage />,
    title: '投稿管理',
    currentPath: `/${PATH_URL.POST}/manage`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.POST}`,
    element: <Navigate to='manage' replace />,
    currentPath: `/${PATH_URL.POST}/manage`,
  },
];
