
import { Text, TooltipHover } from 'components/Text';
import { textToStatus } from 'constants';
import { ButtonCCF } from 'styled';
import { cellToTooltip, checkTypeCell } from 'utils/utils';

const columns = (onClick) => [
  { headerName: '氏名', ...cellToTooltip('full_name') },
  { headerName: '氏名（ローマ字）', ...cellToTooltip('name_romaji') },
  { headerName: '生年月日', ...cellToTooltip('birthday', 'date') },
  { headerName: '性別', ...cellToTooltip('gender', 'gender') },
  { headerName: 'メールアドレス', ...cellToTooltip('email') },
  // { headerName: 'ステータス', ...cellToTooltip('status', 'status') },
  {
    headerName: '',
    field: 'status',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: (data) => (
      <TooltipHover
        title={
          data.row['first_login'] === 0 && data.row['status'] === 1
            ? '未ログイン'
            : textToStatus[data.row['status']]
        }>
        <Text maxWidth={200}>
          {data.row['first_login'] === 0 && data.row['status'] === 1
            ? '未ログイン'
            : textToStatus[data.row['status']]}
        </Text>
      </TooltipHover>
    ),
  },
  {
    field: 'action',
    headerName: 'アクション',
    minWidth: 100,
    sortable: false,
    renderCell: (cellValues) => (
      <ButtonCCF
        onClick={() => onClick(cellValues?.id)}
        variant='contained'
        size='small'
        bgtheme='primary'>
        編集
      </ButtonCCF>
    ),
  },
];
const columnsHistorySale = [
  { headerName: '購入日時', ...cellToTooltip('created_at', 'date_time') },
  { headerName: 'アフィリエイターの氏名', ...cellToTooltip('name_affiliate') },
  { headerName: 'ファンの氏名', ...cellToTooltip('name_fan') },
  { headerName: '購入ポイント数', ...cellToTooltip('point', 'amount_pt') },
  { headerName: 'アフィリエイターの利益', ...cellToTooltip('profit', 'amount_pt') },
];
const columnsHistoryPayment = [
  { headerName: '支払い依頼日時', ...cellToTooltip('created_at', 'date_time') },
  {
    field: 'name_affiliate',
    headerName: 'アフィリエイターの氏名',
    minWidth: 250,
    sortable: false,
    renderCell: (params) => (
      <TooltipHover title={checkTypeCell('text', params.row.users?.full_name) || ''}>
        <Text maxWidth={250}>{checkTypeCell('text', params.row.users?.full_name)}</Text>
      </TooltipHover>
    ),
  },
  { headerName: '支払い金額', ...cellToTooltip('amount', 'amount') },
  { headerName: '支払い状況', ...cellToTooltip('status', 'status_payment') },
  { headerName: 'ペンディング・支払いの日付', ...cellToTooltip('change_status_date', 'date') },
];
const columnListBanner = [
  { headerName: '氏名', ...cellToTooltip('name_affiliate') },
  { headerName: '使用可能なバナー件数', ...cellToTooltip('number_banner') },
  { headerName: '紹介URL', ...cellToTooltip('URL', 'text', 350) },
  { headerName: 'クリック数', ...cellToTooltip('click_count') },
  { headerName: '成果件数', ...cellToTooltip('number_contract') },
  { headerName: '金額', ...cellToTooltip('point_affiliate', 'amount_pt') },
];
export { columns, columnsHistorySale, columnsHistoryPayment, columnListBanner };
