/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  listSubscription: [],
  pagination: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        listSubscription: [],
        pagination: {},
      };
    case TYPES.GET_LIST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        listSubscription: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_SUBSCRIPTION_FAILED:
      return state;

    default:
      return state;
  }
};
