import { commonAPI, configParamsGETJson, settingSiteAPI } from 'apis';
import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';

const getListCountry = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.COUNTRY_LIST_REQUEST));
    callApi(
      commonAPI.getListCountry,
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.COUNTRY_LIST_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.COUNTRY_LIST_FAILED, { error: error.errors }));
      },
      null
    );
  };
};
const getListAgencies = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.AGENT_LIST_REQUEST));
    callApi(
      commonAPI.getListAgency(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.AGENT_LIST_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.AGENT_LIST_FAILED, { error: error.errors }));
      },
      null
    );
  };
};
const getListAffliate = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.AFFILIATOR_LIST_REQUEST));
    callApi(
      commonAPI.getListAffiliate(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.AFFILIATOR_LIST_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.AFFILIATOR_LIST_FAILED, { error: error.errors }));
      },
      null
    );
  };
};
const getListCreator = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATOR_LIST_REQUEST));
    callApi(
      commonAPI.getListCreator(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.CREATOR_LIST_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_LIST_FAILED, { error: error.errors }));
      },
      null
    );
  };
};
const getListFan = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.FAN_LIST_REQUEST));
    callApi(
      commonAPI.getListFan(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.FAN_LIST_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_LIST_FAILED, { error: error.errors }));
      },
      null
    );
  };
};
const getListFavoriteGenres = () => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_FAVORITE_GENRES_REQUEST));
    callApi(
      settingSiteAPI.getListTag,
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_FAVORITE_GENRES_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_FAVORITE_GENRES_FAILED, { error: error.errors }));
      },
      null
    );
  };
};
const getListReportCategory = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.REPORT_CATEGORY_LIST_REQUEST));
    callApi(
      commonAPI.getListReportCategory(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.REPORT_CATEGORY_LIST_SUCCESS, response));
      }
      ,
      (error) => {
        dispatch(updateStatus(TYPES.REPORT_CATEGORY_LIST_FAILED, { error: error.errors }));
      }
      ,
      null
    );
  };
}

export {
  getListCountry,
  getListAgencies,
  getListAffliate,
  getListCreator,
  getListFan,
  getListFavoriteGenres,
  getListReportCategory
};
