import styled, { keyframes, css } from 'styled-components';
const animation = keyframes`
  0%,
  100% {
    transform: scale(0) rotate(0);
  }
  40%,
  80% {
    transform: scale(1) rotate(-90deg);
  }
`;
const AreaLoading = styled.div`
  position: fixed;
  z-index: 9999999;
  width: calc(100% - ${({ space }) => space || '18.285rem'});
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const WrapperLoading = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transform: rotate(45deg);
`;
const ItemLoading = styled.span`
  --c: gray;
  flex-shrink: 0;
  width: 30%;
  height: 30%;
  background-color: var(--c);
  position: relative;
  transform: scale(0);
  animation: ${animation} 2s linear infinite;
  animation-delay: var(--d);
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: rotate(-45deg);
  }
  &:nth-child(7) {
    --c: #f15a5a;
    --d: 0s;
  }
  &:nth-child(4),
  &:nth-child(8) {
    --c: #f0c419;
    --d: 0.2s;
  }
  &:nth-child(1),
  &:nth-child(5),
  &:nth-child(9) {
    --c: #4eba6f;
    --d: 0.4s;
  }
  &:nth-child(2),
  &:nth-child(6) {
    --c: #2d95bf;
    --d: 0.6s;
  }
  &:nth-child(3) {
    --c: #2d95bf;
    --d: 0.6s;
  }
`;
const animation2 = keyframes`
 0% {
    transform: rotateX(-360deg);
  }
  70% {
    transform: rotateX(0);
  }
`;

const renderTextEffect = () => {
  let style = '';
  for (let i = 1; i < 7; i++) {
    style += `
    &:nth-child(${i + 1}) {
      animation-delay: ${i * 0.1}s;
    }
  `;
  }
  return css`
    ${style}
  `;
};
const TextLoading = styled.div`
  perspective: 1000px;
  span {
    transform-origin: 50% 50% -25px;
    transform-style: preserve-3d;
    animation: ${animation2} 1.6s infinite;
    ${renderTextEffect()}
  }
`;
export { WrapperLoading, ItemLoading, AreaLoading, TextLoading };
