import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import { colorText, defaultColor, hoverColor } from 'styled/variables/color';
import PropTypes from 'prop-types';
const ButtonCCF = styled(Button)`
  ${({ bgtheme }) =>
    bgtheme &&
    css`
      background-color: ${defaultColor[bgtheme]} !important;
      color: ${colorText[bgtheme]} !important;
      &.Mui-disabled {
        background-color: ${defaultColor['gray']} !important;
      }
      &:hover {
        background-color: ${hoverColor[bgtheme]} !important;
      }
    `}
`;
ButtonCCF.propTypes = {
  bgtheme: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'error',
    'warning',
    'info',
    'light',
    'dark',
    'white',
    'black',
    'gray',
  ]),
};
ButtonCCF.defaultProps = {
  bgtheme: 'light',
};

export { ButtonCCF };
