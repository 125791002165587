/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  error: null,
  ListCSAdmin: [],
  DataCreateCSAdmin: [],
  data: {},
  total: 0,
  pagination: {},
  detailCSAdmin: {},
  permissionCSAdmin: [],
  listOperationAdmin: [],
  detailOperationAdmin: {},
  listAccountLogManagement: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DELETE_CS_ADMIN_REQUEST:
    case TYPES.DELETE_OPERATION_ADMIN_REQUEST:
    case TYPES.GET_LIST_CS_ADMIN_REQUEST:
    case TYPES.GET_LIST_OPERATION_ADMIN_REQUEST:
    case TYPES.GET_LIST_ACCOUNT_LOG_MANAGEMENT_REQUEST:
    case TYPES.UPDATE_CS_ADMIN_REQUEST:
    case TYPES.UPDATE_OPERATION_ADMIN_REQUEST:
    case TYPES.CREATE_CS_ADMIN_REQUEST:
    case TYPES.CREATE_OPERATION_ADMIN_REQUEST:
    case TYPES.GET_DETAIL_CS_ADMIN_REQUEST:
    case TYPES.GET_PERMISSIONS_CS_ADMIN_REQUEST:
    case TYPES.GET_DETAIL_OPERATION_ADMIN_REQUEST:
      return {
        ...state,
        status: action.type,
        pagination: {},
        detailCSAdmin: {},
        detailOperationAdmin: {},
      };
    case TYPES.GET_LIST_CS_ADMIN_SUCCESS:
      return {
        ...state,
        ListCSAdmin: action.data.data,
        total: action.data.pagination.total,
        pagination: action.data.pagination,
      };
    case TYPES.GET_PERMISSIONS_CS_ADMIN_SUCCESS:
      return {
        ...state,
        permissionCSAdmin: action.data || [],
      };
    case TYPES.GET_LIST_OPERATION_ADMIN_SUCCESS:
      return {
        ...state,
        listOperationAdmin: action.data.data,
        total: action.data.pagination.total,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_ACCOUNT_LOG_MANAGEMENT_SUCCESS:
      return {
        ...state,
        listAccountLogManagement: action.data.data,
        total: action.data.pagination.total,
        pagination: action.data.pagination,
      };
    case TYPES.GET_DETAIL_CS_ADMIN_SUCCESS:
      return {
        ...state,
        detailCSAdmin: action.data.res,
      };
    case TYPES.GET_DETAIL_OPERATION_ADMIN_SUCCESS:
      return {
        ...state,
        detailOperationAdmin: action.data.res,
      };
    case TYPES.DELETE_CS_ADMIN_SUCCESS:
    case TYPES.DELETE_OPERATION_ADMIN_SUCCESS:
    case TYPES.CREATE_CS_ADMIN_SUCCESS:
    case TYPES.CREATE_OPERATION_ADMIN_SUCCESS:
    case TYPES.UPDATE_CS_ADMIN_SUCCESS:
    case TYPES.UPDATE_OPERATION_ADMIN_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.GET_LIST_CS_ADMIN_FAILED:
    case TYPES.CREATE_CS_ADMIN_FAILED:
    case TYPES.CREATE_OPERATION_ADMIN_FAILED:
    case TYPES.UPDATE_CS_ADMIN_FAILED:
    case TYPES.UPDATE_OPERATION_ADMIN_FAILED:
    case TYPES.DELETE_CS_ADMIN_FAILED:
    case TYPES.DELETE_OPERATION_ADMIN_FAILED:
    case TYPES.GET_LIST_OPERATION_ADMIN_FAILED:
    case TYPES.GET_LIST_ACCOUNT_LOG_MANAGEMENT_FAILED:
      return {
        ...state,
        status: false,
        error: action.error,
      };
    default:
      return state;
  }
};
