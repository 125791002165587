import { AlertDialog } from 'components/Dialog';
import { FormInputRadio, FormInputText } from 'components/FromFeature/form-components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TabsParent from '../tabParent';
import { FormSettingSite, TitleSettingSite } from './styled';
import { FormInputDateTime } from 'components/FromFeature/form-components/FormInputDateTime';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCreateSettingSite } from './schema';
import { formatUTC, removePercent } from 'utils/utils';
import moment from 'moment';
import { getListSettingSite, putCreatorSettingSite } from 'store/actions/settingsite.action';
import { parseISO } from 'date-fns';
import { useState, useContext } from 'react';
import { LanguageContext } from '../../../Context/translation.context';

const paramsEdit = {
  radio_limit: '0',
  date_from_top: '',
  date_to_top: '',
  date_from_bottom: '',
  date_to_bottom: '',
  commission_sales: '',
  commission_subscription: '',
  commission_message: '',
  commission_livestream: '',
  status: '',
};

export const SettingSiteOverall = () => {
  const { listSettingSite } = useSelector((state) => state.settingsite);
  const dispatch = useDispatch();
  const [showMessageMaintain, setShowMessageMaitain] = useState(false);
  const [showMessageLimit, setShowMessageLimit] = useState(false);
  const { tSettingsite } = useContext(LanguageContext);

  const methods = useForm({
    mode: 'all',
    defaultValues: paramsEdit,
    resolver: yupResolver(schemaCreateSettingSite),
  });
  const { handleSubmit, control, setValue, watch } = methods;

  useEffect(() => {
    dispatch(getListSettingSite());
  }, []);

  const options = [
    {
      value: '0',
      label: tSettingsite('settingSiteOverall.indefinitePeriod'),
    },
    {
      value: '1',
      label: tSettingsite('settingSiteOverall.timeDesignation'),
    },
  ];

  useEffect(() => {
    if (listSettingSite.length > 0) {
      listSettingSite.forEach((item) => {
        for (let key in paramsEdit) {
          switch (key) {
            case 'radio_limit':
              if (item.key === '1') {
                setValue(key, item['value']);
              }
              break;
            case 'date_from_top':
              if (item.key === '1') {
                setValue(key, item['date_from'] ? moment.utc(item['date_from']) : null);
              }
              break;
            case 'date_to_top':
              if (item.key === '1') {
                setValue(key, item['date_to'] ? moment.utc(item['date_to']) : null);
              }
              break;
            case 'date_from_bottom':
              if (item.key === '2') {
                setValue(key, item['date_from'] ? moment.utc(item['date_from']) : null);
              }
              break;
            case 'date_to_bottom':
              if (item.key === '2') {
                setValue(key, item['date_to'] ? moment.utc(item['date_to']) : null);
              }
              break;
            case 'commission_sales':
              if (item.key === '7') {
                if (item.value !== null) {
                  setValue(key, `${item['value']}%`);
                }
              }
              break;
            case 'commission_subscription':
              if (item.key === '8') {
                if (item.value !== null) {
                  setValue(key, `${item['value']}%`);
                }
              }
              break;
            case 'commission_livestream':
              if (item.key === '9') {
                if (item.value !== null) {
                  setValue(key, `${item['value']}%`);
                }
              }
              break;
            case 'commission_message':
              if (item.key === '10') {
                if (item.value !== null) {
                  setValue(key, `${item['value']}%`);
                }
              }
              break;
            case 'status':
              if (item.key === '2') {
                setValue(key, item['status']);
              }
              break;

            default:
              break;
          }
        }
      });
    }
  }, [listSettingSite, paramsEdit]);

  const valueLimitRadio = watch('radio_limit');
  const valueStatus = watch('status');

  const handleSubmitSettingLimit = (data) => {
    if (data.radio_limit === '1') {
      const paramPutSettingSite = {
        key: 1,
        value: data.radio_limit,
        date_from: formatUTC(data.date_from_top),
        date_to: formatUTC(data.date_to_top),
      };

      if (formatUTC(data.date_from_top) <= formatUTC(new Date())) {
        setShowMessageLimit(true);
        return;
      } else {
        setShowMessageLimit(false);
        setShowMessageMaitain(false);
      }
      dispatch(putCreatorSettingSite({ type: 1 }, paramPutSettingSite, getListSettingSite()));
    } else {
      const paramPutSettingSite = {
        key: 1,
        value: data.radio_limit,
        date_from: null,
        date_to: null,
      };
      setShowMessageLimit(false);
      setShowMessageMaitain(false);
      dispatch(putCreatorSettingSite({ type: 1 }, paramPutSettingSite, getListSettingSite()));
    }
  };

  const handleSubmitStop = (data) => {
    const paramPutSettingSite = {
      key: 2,
      value: 1,
      date_from: formatUTC(data.date_from_bottom),
      date_to: formatUTC(data.date_to_bottom),
      status: 1,
    };

    if (formatUTC(data.date_from_bottom) <= formatUTC(new Date())) {
      setShowMessageMaitain(true);
      return;
    } else {
      setShowMessageMaitain(false);
      setShowMessageLimit(false);
    }
    dispatch(
      putCreatorSettingSite({ type: 2 }, paramPutSettingSite, getListSettingSite(), 'STOP')
    );
  };

  const handleSubmitStart = (data) => {
    const paramPutSettingSite = {
      key: 2,
      value: 0,
      date_from: null,
      date_to: null,
      status: 0,
    };
    dispatch(
      putCreatorSettingSite({ type: 2 }, paramPutSettingSite, getListSettingSite(), 'START')
    );
  };

  const handleSubmitPercent = (data) => {
    const paramPutSettingSiteOperator = [
      {
        key: 7,
        value: removePercent(data.commission_sales),
        date_from: null,
        date_to: null,
      },
      {
        key: 8,
        value: removePercent(data.commission_subscription),
        date_from: null,
        date_to: null,
      },
      {
        key: 9,
        value: removePercent(data.commission_livestream),
        date_from: null,
        date_to: null,
      },
      {
        key: 10,
        value: removePercent(data.commission_message),
        date_from: null,
        date_to: null,
      },
    ];

    const value = {
      ids: paramPutSettingSiteOperator,
    };

    dispatch(putCreatorSettingSite({ type: 3 }, value, getListSettingSite()));
  };

  return (
    <FormSettingSite>
      <TabsParent />
      <TitleSettingSite>
        <span>{tSettingsite('settingSiteOverall.pointSalesLimit')}</span>
      </TitleSettingSite>

      <div className='form-time-limit'>
        <div className='time-limit'>
          <div className='title'>
            <span>{tSettingsite('settingSiteOverall.timeLimit')}</span>
          </div>
          <div className='radio-limit'>
            <FormInputRadio options={options} control={control} name='radio_limit' />
          </div>
          <div className='form-date-time-limit'>
            <div className='form-date-time'>
              <FormInputDateTime
                disabled={valueLimitRadio === '1' ? false : true}
                control={control}
                name='date_from_top'
                disablePast
                minDateTime={new Date()}
                maxDateTime={
                  watch('date_to_top')
                    ? parseISO(
                        moment.utc(watch('date_to_top')).local().format('YYYY-MM-DD HH:mm')
                      )
                    : null
                }
              />
              <FormInputDateTime
                control={control}
                disabled={valueLimitRadio === '1' ? false : true}
                name='date_to_top'
                disablePast
                minDateTime={
                  watch('date_from_top')
                    ? parseISO(
                        moment.utc(watch('date_from_top')).local().format('YYYY-MM-DD HH:mm')
                      )
                    : new Date()
                }
                maxDateTime={null}
              />
            </div>
            {showMessageLimit ? (
              <div style={{ color: 'red' }}>
                {tSettingsite('settingSiteOverall.selectionPeriod')}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='btn-action-setting'>
          <AlertDialog
            title={tSettingsite('setting')}
            content={tSettingsite('wantToSetIt')}
            updateStatus={tSettingsite('setting')}
            propsButton={{ bgtheme: 'secondary' }}
            handleAccpet={() =>
              handleSubmitSettingLimit({
                date_from_top: watch('date_from_top'),
                date_to_top: watch('date_to_top'),
                radio_limit: watch('radio_limit'),
              })
            }
          />
        </div>
      </div>

      <TitleSettingSite>
        <span>{tSettingsite('settingSiteOverall.allSiteOutages')}</span>
      </TitleSettingSite>

      <div className='form-time-stop-start'>
        <div className='form-date-time-stop-start'>
          <FormInputDateTime
            control={control}
            name='date_from_bottom'
            disablePast
            minDateTime={new Date()}
            maxDateTime={
              watch('date_to_bottom')
                ? parseISO(
                    moment.utc(watch('date_to_bottom')).local().format('YYYY-MM-DD HH:mm')
                  )
                : null
            }
          />
          <FormInputDateTime
            control={control}
            name='date_to_bottom'
            disablePast
            minDateTime={
              watch('date_from_bottom')
                ? parseISO(
                    moment.utc(watch('date_from_bottom')).local().format('YYYY-MM-DD HH:mm')
                  )
                : new Date()
            }
            maxDateTime={null}
          />
        </div>
        {showMessageMaintain ? (
          <div style={{ color: 'red' }}>
            {tSettingsite('settingSiteOverall.selectionPeriod')}
          </div>
        ) : (
          ''
        )}
        <div className='btn-stop-start'>
          <AlertDialog
            title={tSettingsite('settingSiteOverall.allSiteOutages')}
            content={tSettingsite('settingSiteOverall.wantToCompletelyStop')}
            updateStatus='停止'
            propsButton={{ bgtheme: 'secondary', disabled: valueStatus === 1 ? true : false }}
            handleAccpet={() =>
              handleSubmitStop({
                date_from_bottom: watch('date_from_bottom'),
                date_to_bottom: watch('date_to_bottom'),
              })
            }
          />
          <AlertDialog
            title={tSettingsite('settingSiteOverall.outageRecovery')}
            updateStatus={tSettingsite('settingSiteOverall.recovery')}
            content={tSettingsite('settingSiteOverall.wantRecover')}
            propsButton={{ bgtheme: 'secondary', disabled: valueStatus === 1 ? false : true }}
            handleAccpet={() => handleSubmitStart()}
          />
        </div>
      </div>

      <TitleSettingSite>
        <span>{tSettingsite('settingSiteOverall.multiplyRateSetting')}</span>
      </TitleSettingSite>

      <div className='form-setting-system-usage'>
        <div className='point'>
          <div className='title'>
            <span>{tSettingsite('settingSiteOverall.salesContent')}</span>
          </div>
          <FormInputText
            className='input input-setting-site'
            control={control}
            name='commission_sales'
          />

          <div className='title'>
            <span>{tSettingsite('settingSiteOverall.subscription')}</span>
          </div>
          <FormInputText
            className='input input-setting-site'
            control={control}
            name='commission_subscription'
          />
        </div>

        <div className='point'>
          <div className='title'>
            <span>{tSettingsite('settingSiteOverall.donate_message')}</span>
          </div>
          <FormInputText
            className='input input-setting-site'
            control={control}
            name='commission_message'
          />

          <div className='title'>
            <span>{tSettingsite('settingSiteOverall.donate_livestream')}</span>
          </div>
          <FormInputText
            className='input input-setting-site'
            control={control}
            name='commission_livestream'
          />
        </div>
        {/* <div className='point'>
          <div className='title'>
            <span>{tSettingsite('settingSiteOverall.affiliateFee')}</span>
          </div>
          <FormInputText className='input' control={control} name='value_affiliate' />
        </div> */}
        <div className='btn-action-setting'>
          <AlertDialog
            title={tSettingsite('setting')}
            content={tSettingsite('wantToSetIt')}
            updateStatus={tSettingsite('setting')}
            propsButton={{ bgtheme: 'secondary' }}
            handleAccpet={handleSubmit(handleSubmitPercent)}
          />
        </div>
      </div>
    </FormSettingSite>
  );
};
