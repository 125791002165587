import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import jp from './jp';
const resources = {
  jp,
};
i18n.use(initReactI18next).init({
  resources,
  lng: 'jp',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
