import { yupGender, yupCountryName, yupAccountID } from 'constants';
import {
  yupBirthday,
  yupPhone,
  yupLastName,
  yupFirstName,
  yupAccountname,
  yupFirstNameKana,
  yupLastNameKana,
} from 'constants';
import * as yup from 'yup';
const schemaEdit = yup.object().shape({
  ...yupFirstName(),
  ...yupLastName(),
  ...yupFirstNameKana(),
  ...yupLastNameKana(),
  ...yupPhone(),
  ...yupBirthday(),
  ...yupGender(),
  ...yupCountryName(),
  ...yupAccountname(),
  ...yupAccountID(),
});
export { schemaEdit };
