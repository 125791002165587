/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  error: null,
  data: {},
  listCreator: [],
  detailCreator: {},
  detailContract: {},
  pagination: {},
  listSaleHistory: [],
  listPaymentHistory: [],
  listPostHistory: [],
  listLivestream: [],
  listRecordLivestream: [],
  listContract: [],
  detailSubscription: {},
  totalProfit: '---',
  limitSetting: '---',
  statusApprove: '',
};
const clearState = {
  status: null,
  data: {},
  listCreator: [],
  pagination: {},
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CREATOR_LIST_REQUEST:
      return {
        ...state,
        ...clearState,
        status: action.type,
      };
    case TYPES.GET_CREATOR_DETAIL_REQUEST:
      return {
        ...state,
        detailCreator: {},
      };
    case TYPES.GET_CONTRACT_DETAIL_REQUEST:
      return {
        ...state,
        detailContract: {},
      };
    case TYPES.GET_SUBSCRIPTION_CREATOR_REQUEST:
      return {
        ...state,
        detailSubscription: {},
      };
    case TYPES.UPDATE_DETAIL_CREATOR_REQUEST:
    case TYPES.UPDATE_DETAIL_CONTRACT_REQUEST:
    case TYPES.DELETE_CREATOR_REQUEST:
    case TYPES.DELETE_SUBSCRIPTION_CREATOR_REQUEST:
    case TYPES.UPDATE_SETTING_LIMIT_CREATOR_REQUEST:
    case TYPES.DELETE_POST_CREATOR_REQUEST:
    case TYPES.DELETE_RECORD_LIVESTREAM_REQUEST:
    case TYPES.CREATOR_STOP_LIVE_REQUEST:
    case TYPES.ADMIN_LOGIN_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.APPROVE_CREATOR_REQUEST:
      return {
        ...state,
        statusApprove: '',
      };
    case TYPES.GET_PAYMENT_HISTORY_CREATOR_REQUEST:
    case TYPES.GET_POST_HISTORY_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
        pagination: {},
        listPaymentHistory: [],
        listPostHistory: [],
      };
    case TYPES.GET_SALE_HISTORY_CREATOR_REQUEST:
      return {
        ...state,
        listSaleHistory: [],
        status: action.type,
        pagination: {},
        totalProfit: '---',
      };
    case TYPES.GET_LIST_CONTRACT_REQUEST:
      return {
        ...state,
        listContract: [],
        status: action.type,
        pagination: {},
      };
    case TYPES.GET_SETTING_LIMIT_CREATOR_REQUEST:
      return {
        ...state,
        limitSetting: '---',
        error: null,
      };
    case TYPES.GET_LIST_LIVESTREAM_CREATOR_REQUEST:
      return {
        ...state,
        listLivestream: [],
        pagination: {},
      };
    case TYPES.GET_LIST_RECORD_LIVESTREAM_REQUEST:
      return {
        ...state,
        listRecordLivestream: [],
        pagination: {},
      };

    case TYPES.GET_SETTING_LIMIT_CREATOR_SUCCESS:
      return {
        ...state,
        limitSetting: action?.data?.data?.min_price,
        error: null,
      };
    case TYPES.GET_SALE_HISTORY_CREATOR_SUCCESS:
      return {
        ...state,
        status: action.type,
        listSaleHistory: action.data.data,
        pagination: action.data.pagination,
        totalProfit: action.data.total_profit,
      };
    case TYPES.GET_LIST_CONTRACT_SUCCESS:
      return {
        ...state,
        status: action.type,
        listContract: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_PAYMENT_HISTORY_CREATOR_SUCCESS:
      return {
        ...state,
        status: action.type,
        listPaymentHistory: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_POST_HISTORY_CREATOR_SUCCESS:
      return {
        ...state,
        status: action.type,
        listPostHistory: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_CREATOR_LIST_SUCCESS:
      return {
        ...state,
        listCreators: action.data.res.data,
        pagination: action.data.res.pagination,
      };
    case TYPES.GET_CREATOR_DETAIL_SUCCESS:
      return {
        ...state,
        detailCreator: action.data.res,
      };
    case TYPES.GET_CONTRACT_DETAIL_SUCCESS:
      return {
        ...state,
        detailContract: action.data.res,
      };
    case TYPES.APPROVE_CREATOR_SUCCESS:
      return {
        ...state,
        statusApprove: action.data.data.status,
      };
    case TYPES.UPDATE_DETAIL_CREATOR_SUCCESS:
    case TYPES.UPDATE_DETAIL_CONTRACT_SUCCESS:
    case TYPES.DELETE_CREATOR_SUCCESS:
    case TYPES.DELETE_RECORD_LIVESTREAM_SUCCESS:
    case TYPES.UPDATE_SETTING_LIMIT_CREATOR_SUCCESS:
    case TYPES.CREATOR_STOP_LIVE_SUCCESS:
    case TYPES.ADMIN_LOGIN_CREATOR_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.GET_LIST_LIVESTREAM_CREATOR_SUCCESS:
      return {
        ...state,
        listLivestream: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_RECORD_LIVESTREAM_SUCCESS:
      return {
        ...state,
        listRecordLivestream: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.DELETE_SUBSCRIPTION_CREATOR_SUCCESS:
      const newData = state.detailCreator?.creator_subscriptions?.filter(
        (item) => item.id !== action.data
      );
      return {
        ...state,
        detailCreator: { ...state.detailCreator, creator_subscriptions: newData },
      };
    case TYPES.DELETE_POST_CREATOR_SUCCESS:
      const newDataPost = state.detailCreator?.creator_contents?.filter(
        (item) => item.id !== action.data
      );
      return {
        ...state,
        detailCreator: { ...state.detailCreator, creator_contents: newDataPost },
      };
    case TYPES.GET_SUBSCRIPTION_CREATOR_SUCCESS:
      return {
        ...state,
        detailSubscription: action.data.data,
      };
    case TYPES.GET_CREATOR_DETAIL_FAILED:
    case TYPES.GET_CONTRACT_DETAIL_FAILED:
    case TYPES.GET_CREATOR_LIST_FAILED:
    case TYPES.UPDATE_DETAIL_CREATOR_FAILED:
    case TYPES.UPDATE_DETAIL_CONTRACT_FAILED:
    case TYPES.APPROVE_CREATOR_FAILED:
    case TYPES.DELETE_CREATOR_FAILED:
    case TYPES.GET_SALE_HISTORY_CREATOR_FAILED:
    case TYPES.GET_LIST_CONTRACT_FAILED:
    case TYPES.GET_PAYMENT_HISTORY_CREATOR_FAILED:
    case TYPES.GET_POST_HISTORY_CREATOR_FAILED:
    case TYPES.GET_SETTING_LIMIT_CREATOR_FAILED:
    case TYPES.UPDATE_SETTING_LIMIT_CREATOR_FAILED:
    case TYPES.DELETE_POST_CREATOR_FAILED:
    case TYPES.DELETE_SUBSCRIPTION_CREATOR_FAILED:
    case TYPES.DELETE_RECORD_LIVESTREAM_FAILED:
    case TYPES.GET_LIST_LIVESTREAM_CREATOR_FAILED:
    case TYPES.GET_LIST_RECORD_LIVESTREAM_FAILED:
    case TYPES.CREATOR_STOP_LIVE_FAILED:
    case TYPES.ADMIN_LOGIN_CREATOR_FAILED:
    case TYPES.GET_SUBSCRIPTION_CREATOR_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error.message,
      };
    case TYPES.CREATE_TOKEN_AGORA_GUEST_REQUEST:
      return { ...state };
    case TYPES.CREATE_TOKEN_AGORA_GUEST_SUCCESS:
      return { ...state };
    case TYPES.CREATE_TOKEN_AGORA_GUEST_FAILED:
      return { ...state };
    default:
      return state;
  }
};
