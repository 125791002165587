import SelectInput from 'components/SelectInput';
import { listMonth, listYear } from 'constants/FilterDate';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { convertDateStringToObject } from 'utils/utils';
import _ from 'lodash';
import { Span, WrapperFilter } from './styled';
function DateSelectGroup({
  notMulti = false,
  handleSelect,
  memoryDate = ['year', 'month', 'day'],
  isTypeFromTo = false,
  isTypeFromDate = false,
  hideDay = false,
  resetDate = 0,
  modeReset = false,
  initDateFrom = {},
  initDateTo = {},
}) {
  const [filterDateFrom, setFilterDateFrom] = React.useState({});
  const [filterDateTo, setFilterDateTo] = React.useState({});
  const [dateTo, setDateTo] = React.useState([{ value: '', label: '全て' }]);
  const [dateEnd, setDateEnd] = React.useState([{ value: '', label: '全て' }]);
  const [loading, setLoading] = React.useState(false);
  const [month, setMonth] = React.useState(
    listMonth.map((item) => ({
      value: item,
      label: `${item} 月`,
    }))
  );
  const [year, setYear] = React.useState(
    listYear.map((item) => ({
      value: item,
      label: `${item} 年`,
    }))
  );
  const refYearFrom = useRef();
  const refYearTo = useRef();
  const refMonthFrom = useRef();
  const refMonthTo = useRef();
  const refDayFrom = useRef();
  const refDayTo = useRef();

  useEffect(() => {
    if (!_.isEmpty(initDateFrom)) {
      setFilterDateFrom(convertDateStringToObject(initDateFrom, 'from'));
    }
  }, [initDateFrom]);

  useEffect(() => {
    if (!_.isEmpty(initDateTo)) {
      setFilterDateTo(convertDateStringToObject(initDateTo, 'to'));
    }
  }, [initDateTo]);

  useEffect(() => {
    if (modeReset) {
      refYearFrom?.current?.selectOption('');
      refYearTo?.current?.selectOption('');
      refMonthFrom?.current?.selectOption('');
      refMonthTo?.current?.selectOption('');
      refDayFrom?.current?.selectOption('');
      refDayTo?.current?.selectOption('');
    }
  }, [resetDate]);
  const handleDateTo = (key, value) => setFilterDateTo((prev) => ({ ...prev, [key]: value }));
  const handleDateFrom = (key, value) =>
    setFilterDateFrom((prev) => ({ ...prev, [key]: value }));

  const selectDate = (month, year, array) => {
    if (array.length > 1) {
      array = [{ value: '', label: '全て' }];
    }
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        for (let index = 0; index < 31; index++) {
          array.push({
            value: 31 - index,
            label: `${31 - index} 日`,
          });
        }
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        for (let index = 0; index < 30; index++) {
          array.push({
            value: 30 - index,
            label: `${30 - index} 日`,
          });
        }
        break;
      case 2:
        if (year % 4 === 0) {
          for (let index = 0; index < 29; index++) {
            array.push({
              value: 29 - index,
              label: `${29 - index} 日`,
            });
          }
        } else
          for (let index = 0; index < 28; index++) {
            array.push({
              value: 28 - index,
              label: `${28 - index} 日`,
            });
          }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const { yearStart, monthStart, dayStart } = filterDateFrom;
    const { yearEnd, monthEnd, dayEnd } = filterDateTo;
    !notMulti &&
      handleSelect(
        isTypeFromTo ? 'date_from' : isTypeFromDate ? 'from_date' : 'start_day',
        `${yearStart || ''}${
          (yearStart &&
            monthStart &&
            (monthStart > 9 ? `-${monthStart}` : `-0${monthStart}`)) ||
          ''
        }${
          (yearStart &&
            monthStart &&
            dayStart &&
            (dayStart > 9 ? `-${dayStart}` : `-0${dayStart}`)) ||
          ''
        }`
      );
    !notMulti &&
      handleSelect(
        isTypeFromTo ? 'date_to' : isTypeFromDate ? 'to_date' : 'end_day',
        `${yearEnd || ''}${
          (yearEnd && monthEnd && (monthEnd > 9 ? `-${monthEnd}` : `-0${monthEnd}`)) || ''
        }${
          (yearEnd && monthEnd && dayEnd && (dayEnd > 9 ? `-${dayEnd}` : `-0${dayEnd}`)) || ''
        }`
      );
    if (notMulti) {
      handleSelect(memoryDate[0], yearStart || '');
      handleSelect(
        memoryDate[1],
        (yearStart && monthStart && (monthStart > 9 ? monthStart : `0${monthStart}`)) || ''
      );
      handleSelect(
        memoryDate[2],
        (yearStart && monthStart && dayStart && (dayStart > 9 ? dayStart : `0${dayStart}`)) ||
          ''
      );
    }
    yearStart && monthStart && selectDate(monthStart, yearStart, dateTo);
    yearEnd && monthEnd && selectDate(monthEnd, yearEnd, dateEnd);
  }, [filterDateFrom, filterDateTo]);

  const optionsYearFrom = useMemo(() => {
    return [
      { value: '', label: '全て' },
      ...year.filter((item) =>
        filterDateTo.yearEnd ? item.value <= filterDateTo.yearEnd : item
      ),
    ];
  }, [filterDateTo.yearEnd, year]);

  const optionsYearTo = useMemo(() => {
    return [
      { value: '', label: '全て' },
      ...year.filter((item) =>
        filterDateFrom.yearStart ? item.value >= filterDateFrom.yearStart : item
      ),
    ];
  }, [filterDateFrom.yearStart, year]);

  const optionsMonthFrom = useMemo(() => {
    return [
      { value: '', label: '全て' },
      ...month.filter((item) => {
        if (filterDateTo.monthEnd && filterDateFrom.yearStart === filterDateTo.yearEnd) {
          return item.value <= filterDateTo.monthEnd;
        } else {
          return item;
        }
      }),
    ];
  }, [filterDateFrom.yearStart, filterDateTo.monthEnd, filterDateTo.yearEnd, month]);

  const optionsMonthTo = useMemo(() => {
    return [
      { value: '', label: '全て' },
      ...month.filter((item) => {
        if (filterDateFrom.monthStart && filterDateTo.yearEnd === filterDateFrom.yearStart) {
          return item.value >= filterDateFrom.monthStart;
        } else {
          return item;
        }
      }),
    ];
  }, [filterDateFrom.monthStart, filterDateFrom.yearStart, filterDateTo.yearEnd, month]);

  return (
    <WrapperFilter>
      <WrapperFilter>
        <SelectInput
          isSearchable={false}
          placeholder='年'
          ref={refYearFrom}
          value={optionsYearFrom?.filter(
            (item) => +item?.value === +filterDateFrom?.yearStart
          )}
          onChange={(e) => {
            handleDateFrom('yearStart', e.value);
            setDateTo([{ value: '', label: '全て' }]);
            handleDateFrom('dayStart', '');
          }}
          options={optionsYearFrom}
        />
        <SelectInput
          isSearchable={false}
          placeholder='月'
          ref={refMonthFrom}
          onChange={(e) => {
            handleDateFrom('monthStart', e.value);
            setDateTo([{ value: '', label: '全て' }]);
            handleDateFrom('dayStart', '');
          }}
          value={optionsMonthFrom?.filter(
            (item) => +item?.value === +filterDateFrom?.monthStart
          )}
          options={optionsMonthFrom}
          isDisabled={!filterDateFrom.yearStart}
        />
        {!hideDay && (
          <SelectInput
            isSearchable={false}
            onChange={(e) => handleDateFrom('dayStart', e.value)}
            placeholder='日'
            ref={refDayFrom}
            value={dateTo.filter(
              (item) =>
                +item.value ===
                +(
                  filterDateFrom.yearStart &&
                  filterDateFrom.monthStart &&
                  filterDateFrom.dayStart
                )
            )}
            options={dateTo.filter((item) => {
              if (
                filterDateTo.dayEnd &&
                filterDateFrom.yearStart === filterDateTo.yearEnd &&
                filterDateFrom.monthStart === filterDateTo.monthEnd
              ) {
                return item.value <= filterDateTo.dayEnd;
              } else {
                return item;
              }
            })}
            isDisabled={!filterDateFrom.yearStart || !filterDateFrom.monthStart}
          />
        )}
      </WrapperFilter>
      {!notMulti && (
        <>
          <Span>-</Span>
          <WrapperFilter>
            <SelectInput
              isSearchable={false}
              placeholder='年'
              ref={refYearTo}
              onChange={(e) => {
                handleDateTo('yearEnd', e.value);
                setDateEnd([{ value: '', label: '全て' }]);
                handleDateTo('dayEnd', '');
              }}
              value={optionsYearTo?.filter((item) => +item?.value === +filterDateTo.yearEnd)}
              options={optionsYearTo}
            />
            <SelectInput
              isSearchable={false}
              placeholder='月'
              ref={refMonthTo}
              onChange={(e) => {
                handleDateTo('monthEnd', e.value);
                setDateEnd([{ value: '', label: '全て' }]);
                handleDateTo('dayEnd', '');
              }}
              value={optionsMonthTo?.filter((item) => +item?.value === +filterDateTo.monthEnd)}
              options={optionsMonthTo}
              isDisabled={!filterDateTo.yearEnd}
            />
            {!hideDay && (
              <SelectInput
                isSearchable={false}
                placeholder='日'
                ref={refDayTo}
                options={dateEnd.filter((item) => {
                  if (
                    filterDateFrom.dayStart &&
                    filterDateFrom.yearStart === filterDateTo.yearEnd &&
                    filterDateFrom.monthStart === filterDateTo.monthEnd
                  ) {
                    return item.value >= filterDateFrom.dayStart;
                  } else {
                    return item;
                  }
                })}
                value={dateEnd.filter(
                  (item) =>
                    item.value ===
                    (filterDateTo.yearEnd && filterDateTo.monthEnd && filterDateTo.dayEnd)
                )}
                onChange={(e) => handleDateTo('dayEnd', e.value)}
                isDisabled={!filterDateTo.yearEnd || !filterDateTo.monthEnd}
              />
            )}
          </WrapperFilter>
        </>
      )}
    </WrapperFilter>
  );
}
export { DateSelectGroup };
export default memo(DateSelectGroup);
