/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  pagination: {},
  listAgents: [],
  listCreators: [],
  listSalesHistory: [],
  listPaymentHistory: [],
  detailAgent: {},
  totalProfit: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_AGENT_LIST_REQUEST:
    case TYPES.GET_AGENT_LIST_CREATOR_REQUEST:
    case TYPES.GET_AGENT_DETAIL_REQUEST:
    case TYPES.GET_AGENT_SALES_HISTORY_REQUEST:
    case TYPES.GET_AGENT_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        status: action.type,
        detailAgent: {},
        listCreators: [],
        listSalesHistory: [],
        listPaymentHistory: [],
        pagination: {},
        totalProfit: {},
        loadingAgency: true,
      };
    case TYPES.CREATE_AGENT_REQUEST:
    case TYPES.UPDATE_AGENT_REQUEST:
    case TYPES.DELETE_AGENT_REQUEST:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.GET_AGENT_LIST_SUCCESS:
      return {
        ...state,
        listAgents: action.data.res.data,
        pagination: action.data.res.pagination,
      };
    case TYPES.GET_AGENT_LIST_CREATOR_SUCCESS:
      return {
        ...state,
        listCreators: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_AGENT_SALES_HISTORY_SUCCESS:
      return {
        ...state,
        listSalesHistory: action.data.data,
        pagination: action.data.pagination,
        totalProfit: action.data.total_profit,
      }
    case TYPES.GET_AGENT_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        listPaymentHistory: action.data.data,
        pagination: action.data.pagination,
      }
    case TYPES.GET_AGENT_DETAIL_SUCCESS:
      return {
        ...state,
        detailAgent: action.data.res.data,
      };
    case TYPES.CREATE_AGENT_SUCCESS:
    case TYPES.UPDATE_AGENT_SUCCESS:
    case TYPES.DELETE_AGENT_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.GET_AGENT_LIST_FAILED:
    case TYPES.CREATE_AGENT_FAILED:
    case TYPES.GET_AGENT_LIST_CREATOR_FAILED:
    case TYPES.GET_AGENT_DETAIL_FAILED:
    case TYPES.UPDATE_AGENT_FAILED:
    case TYPES.DELETE_AGENT_FAILED:
    case TYPES.GET_AGENT_SALES_HISTORY_FAILED:
    case TYPES.GET_AGENT_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        status: false,
      };
    default:
      return state;
  }
};
