import { API_URL, API_URL_V2 } from 'constants';
import { stringify } from 'qs';
const contactAPI = {
  postMessage: `${API_URL}/msg_group`,
  getGroupMessage: (...props) => `${API_URL_V2}/message-groups?${stringify(...props)}`,
  getMessOfGroup: (group_id, ...props) =>
    `${API_URL_V2}/message-groups/${group_id}/messages?${stringify(...props)}`,
  deleteGroupMessage: (group_id) => `${API_URL_V2}/message-groups/${group_id}`,
  editMessage: (mess_id) => `${API_URL}/message/${mess_id}`,
  deleteMessage: (mess_id) => `${API_URL}/message/${mess_id}`,
  agencyAddMessage: `${API_URL}/agency/msg_group`,
  postMultipleMessage: `${API_URL_V2}/messages`,
  getAgencyLv2FromAgency: agency_id => `${API_URL_V2}/agencies/${agency_id}/agencies`,
  postMultipleMessageAgency: `${API_URL_V2}/agencies/send-messages`,
};
export { contactAPI };
