import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteAccountAgent,
  getDetailAgent,
  postCreateAgent,
  putUpdateAgent,
} from 'store/actions/agent.action';
import { ButtonSubmit } from '../../../components/FromFeature/form-element/styledElement';
import {
  Content,
  WrapperContent,
  WrapperForm,
  WrapperButtonForm,
} from '../../../components/FromFeature/form-element/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCreate, schemaEdit, schemaEdit1 } from './schema';
import { paramsCreate, paramsEdit, paramsEdit1 } from './dataForm';
import { AlertDialog } from 'components/Dialog';
import { getRenderInput } from 'constants';
import { textToStatus } from 'constants';
import { renderFormatDate, formatNumberPhone } from 'utils/utils';
import { checkParams } from 'constants';
import { PATH_URL } from 'constants/routes/settingUrl';
import { LanguageContext } from 'Context/translation.context';

export const FormEdit = ({ isEdit = false }) => {
  const { detailAgent } = useSelector((state) => state.agent);
  const { tAgency } = useContext(LanguageContext);
  const location = useLocation();
  const methods = useForm({
    mode: 'all',
    defaultValues:
      isEdit && detailAgent?.parent_id
        ? paramsEdit1
        : isEdit && !detailAgent?.parent_id
        ? paramsEdit
        : paramsCreate,
    resolver: yupResolver(
      isEdit && detailAgent?.parent_id
        ? schemaEdit1
        : isEdit && !detailAgent?.parent_id
        ? schemaEdit
        : schemaCreate
    ),
  });
  const { handleSubmit, control, setValue, watch } = methods;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirectBack = (url) => {
    if (location?.state?.prevPath) {
      return navigate(`/${`${PATH_URL.AGENCY}/manage-agency`}${location?.state?.prevPath}`);
    }
    return navigate(`/${PATH_URL.AGENCY}/manage-agency`);
  };

  const [checkAgency, setCheckAgency] = useState('')
  useEffect(() => {
    isEdit &&
      Object.keys(detailAgent).length === 0 &&
      dispatch(
        getDetailAgent(
          id,
          () => {},
          () => handleRedirectBack()
        )
      );
  }, []);

  useEffect(() => {
    if (isEdit && Object.keys(detailAgent).length > 0) {
      setCheckAgency(detailAgent?.parent_id);
      for (let key in paramsEdit) {
        setValue(
          key,
          key === 'phone' ? formatNumberPhone(detailAgent[key]) : detailAgent[key] || ''
        );
        key === 'status' && setValue(key, textToStatus[detailAgent[key]]);
        key === 'created_at' && setValue(key, renderFormatDate(detailAgent[key], 2));
        (key === 'commission_sales' ||
          key === 'commission_subscription' ||
          key === 'commission_livestream' ||
          key === 'commission_message') &&
          setValue(key, `${detailAgent[key]}%`);
      }
    }
  }, [detailAgent]);

  const returnHome = () => () => handleRedirectBack();
  const onSubmit = (data) => {
    dispatch(postCreateAgent(checkParams(data), returnHome(), paramsCreate));
  };
  const updateAccount = (data) => {
    dispatch(putUpdateAgent(checkParams(data), id, returnHome(), paramsEdit));
  };
  const deleteAccount = (account) => {
    dispatch(deleteAccountAgent(account, returnHome()));
  };
  const renderForm = [
    {
      content: tAgency('form.company_name'),
      label: tAgency('form.company_name'),
      name: 'company_name',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.company_address'),
      label: tAgency('form.company_address'),
      name: 'company_address',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.phone'),
      label: '0XX-XXXX-XXXX・0X-XXXX-XXXX・0120-XXXX-XXXX',
      name: 'phone',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.full_name'),
      label: tAgency('form.full_name'),
      name: 'full_name',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.name_romaji'),
      label: tAgency('form.name_romaji'),
      name: 'name_romaji',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.created_at'),
      label: tAgency('form.created_at'),
      name: 'created_at',
      control,
      type: isEdit ? '' : 'text',
      show: isEdit,
      props: {
        disabled: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('created_at')}</p>,
    },
    {
      content: tAgency('form.email'),
      label: tAgency('form.email'),
      name: 'email',
      control,
      type: isEdit ? '' : 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('email')}</p>,
    },
    {
      content: tAgency('form.department'),
      label: tAgency('form.department'),
      name: 'department',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.position'),
      label: tAgency('form.position'),
      name: 'position',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.status'),
      label: tAgency('form.status'),
      name: 'status',
      control,
      type: isEdit ? '' : 'text',
      show: isEdit,
      props: {
        disabled: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('status')}</p>,
    },
    {
      content: tAgency('form.parent_company_name'),
      label: tAgency('form.parent_company_name'),
      name: 'parent_company_name',
      control,
      type: isEdit ? '' : 'text',
      show: isEdit,
      props: {
        disabled: true,
        width: '480px',
      },
      render: (
        <p style={{ marginTop: 5 }}>
          {watch('parent_company_name') ? watch('parent_company_name') : '-'}
        </p>
      ),
    },
    {
      content: tAgency('form.commission_sales'),
      label: tAgency('form.commission_sales'),
      name: 'commission_sales',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.commission_subscription'),
      label: tAgency('form.commission_subscription'),
      name: 'commission_subscription',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.commission_livestream'),
      label: tAgency('form.commission_livestream'),
      name: 'commission_livestream',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.commission_message'),
      label: tAgency('form.commission_message'),
      name: 'commission_message',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
  ];

  const renderFormAgency = [
    {
      content: tAgency('form.company_name'),
      label: tAgency('form.company_name'),
      name: 'company_name',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.company_address'),
      label: tAgency('form.company_address'),
      name: 'company_address',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.phone'),
      label: '0XX-XXXX-XXXX・0X-XXXX-XXXX・0120-XXXX-XXXX',
      name: 'phone',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.full_name'),
      label: tAgency('form.full_name'),
      name: 'full_name',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.name_romaji'),
      label: tAgency('form.name_romaji'),
      name: 'name_romaji',
      control,
      type: 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.created_at'),
      label: tAgency('form.created_at'),
      name: 'created_at',
      control,
      type: isEdit ? '' : 'text',
      show: isEdit,
      props: {
        disabled: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('created_at')}</p>,
    },
    {
      content: tAgency('form.email'),
      label: tAgency('form.email'),
      name: 'email',
      control,
      type: isEdit ? '' : 'text',
      props: {
        isErrForm: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('email')}</p>,
    },
    {
      content: tAgency('form.status'),
      label: tAgency('form.status'),
      name: 'status',
      control,
      type: isEdit ? '' : 'text',
      show: isEdit,
      props: {
        disabled: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('status')}</p>,
    },
    {
      content: tAgency('form.parent_company_name'),
      label: tAgency('form.parent_company_name'),
      name: 'parent_company_name',
      control,
      type: isEdit ? '' : 'text',
      show: isEdit,
      props: {
        disabled: true,
        width: '480px',
      },
      render: (
        <p style={{ marginTop: 5 }}>
          {watch('parent_company_name') ? watch('parent_company_name') : '-'}
        </p>
      ),
    },
    {
      content: tAgency('form.commission_sales'),
      label: tAgency('form.commission_sales'),
      name: 'commission_sales',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.commission_subscription'),
      label: tAgency('form.commission_subscription'),
      name: 'commission_subscription',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.commission_livestream'),
      label: tAgency('form.commission_livestream'),
      name: 'commission_livestream',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
    {
      content: tAgency('form.commission_message'),
      label: tAgency('form.commission_message'),
      name: 'commission_message',
      control,
      type: 'text',
      isCommission: true,
      props: {
        isErrForm: true,
        width: '480px',
      },
    },
  ];

  return (
    <WrapperForm paddingRight='130px'>
      {checkAgency ? (
        <>
          {renderFormAgency.map((item, index) => {
            let { content, show = true, type } = item;
            return (
              <WrapperContent key={index}>
                {show && <Content>{content}</Content>}
                {type === 'text' ? <div>{getRenderInput(item)}</div> : getRenderInput(item)}
              </WrapperContent>
            );
          })}
        </>
      ) : (
        <>
          {renderForm.map((item, index) => {
            let { content, show = true, type } = item;
            return (
              <WrapperContent key={index}>
                {show && <Content>{content}</Content>}
                {type === 'text' ? <div>{getRenderInput(item)}</div> : getRenderInput(item)}
              </WrapperContent>
            );
          })}
        </>
      )}

      <WrapperButtonForm width='610px'>
        <ButtonSubmit
          onClick={() => {
            handleRedirectBack();
          }}
          variant='outlined'>
          {tAgency('form.btn_cancel')}
        </ButtonSubmit>

        {isEdit && (
          <>
            <AlertDialog
              handleAccpet={handleSubmit(updateAccount)}
              title={tAgency('form.title_update')}
              content={tAgency('form.content_update')}
              updateStatus={tAgency('form.title_update_new')}
            />
            <AlertDialog
              handleAccpet={() => {
                isEdit ? deleteAccount(id) : console.log('delete user');
              }}
              addDelete
              title={tAgency('form.title_delete')}
              content={`${watch('full_name')}${tAgency('form.areYouSureYouWantToDeleteThe')}`}
            />
          </>
        )}
        {!isEdit && (
          <AlertDialog
            handleAccpet={handleSubmit(onSubmit)}
            updateStatus={tAgency('form.register')}
            title={tAgency('form.title_create')}
            content={tAgency('form.content_create')}
          />
        )}
      </WrapperButtonForm>
    </WrapperForm>
  );
};

export default FormEdit;
