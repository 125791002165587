/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from 'store/actions';

const initialState = {
  listCountry: [],
  listAgency: [],
  loadingAgency: false,
  listAffiliate: [],
  listCreator: [],
  loadingAffiliate: false,
  listFan: [],
  listfavoritegenres: [],
  listReportCategory: [],
  loadingReportCategory: false,
  loadingListUser: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.COUNTRY_LIST_REQUEST:
      return {
        ...state,
        listCountry: [],
      };
    case TYPES.REPORT_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        listReportCategory: [],
        loadingReportCategory: true,
      };
    case TYPES.AGENT_LIST_REQUEST:
      return {
        ...state,
        listAgency: [],
        loadingAgency: true,
      };
    case TYPES.AFFILIATOR_LIST_REQUEST:
      return {
        ...state,
        listAffiliate: [],
        loadingAffiliate: true,
      };
    case TYPES.CREATOR_LIST_REQUEST:
      return {
        ...state,
        listCreator: [],
        loadingListUser: true,
      };
    case TYPES.FAN_LIST_REQUEST:
      return {
        ...state,
        listFan: [],
      };
    case TYPES.GET_LIST_FAVORITE_GENRES_REQUEST:
      return {
        ...state,
        listfavoritegenres: [],
      };
    case TYPES.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        listCountry: action.data,
      };
    case TYPES.AGENT_LIST_SUCCESS:
      return {
        ...state,
        listAgency: action.data.data,
        loadingAgency: false,
      };
    case TYPES.AFFILIATOR_LIST_SUCCESS:
      return {
        ...state,
        listAffiliate: action.data.data,
        loadingAffiliate: false,
      };
    case TYPES.CREATOR_LIST_SUCCESS:
      return {
        ...state,
        listCreator: action.data.data,
        loadingListUser: false,
      };
    case TYPES.FAN_LIST_SUCCESS:
      return {
        ...state,
        listFan: action.data.data,
      };
    case TYPES.GET_LIST_FAVORITE_GENRES_SUCCESS:
      return {
        ...state,
        listfavoritegenres: action?.data.data,
      };
    case TYPES.REPORT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        listReportCategory: action.data,
        loadingReportCategory: false,
      };
    case TYPES.AFFILIATOR_LIST_FAILED:
      return {
        ...state,
        loadingAffiliate: false,
      };
    case TYPES.AGENT_LIST_FAILED:
    case TYPES.COUNTRY_LIST_FAILED:
    case TYPES.CREATOR_LIST_FAILED:
    case TYPES.FAN_LIST_FAILED:
    case TYPES.GET_LIST_FAVORITE_GENRES_FAILED:
      return {
        ...state,
      };
    case TYPES.REPORT_CATEGORY_LIST_FAILED:
      return {
        ...state,
        loadingReportCategory: false,
      };
    default:
      return state;
  }
};
