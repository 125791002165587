import TabMenu from 'components/TabMenu';
import { LanguageContext } from 'Context/translation.context';
import { useContext } from 'react';

export const TabsReports = () => {
  const { tReport } = useContext(LanguageContext);
  const navItems = [
    {
      id: 'report-fan',
      path: `/reports-management/manage-reports-users`,
      text: tReport('reportList'),
    },
  ];

  return <TabMenu tabs={navItems} />;
};
