import { Pagination, Skeleton, Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { WrapperListCard } from 'pages/Creator/components/styled';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles({
  ul: {
    '& .MuiPagination-ul': {
      justifyContent: 'end !important',
    },
  },
});
export const WrapperShowListCard = ({
  action = () => () => {},
  query = {},
  countSearch = 0,
  count = 1,
  listCard = [],
  itemCard,
  firstSearch = true,
  limit,
  resetPage = 0,
  gmin = '350px',
}) => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const { pagination } = useSelector((state) => state.creator);
  const { current_page, total_pages, count: countStore } = pagination;

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pauseSearch, setPauseSearch] = useState(false);

  useEffect(() => {
    if (current_page === total_pages && countStore === 1) {
      isLoggedIn && !pauseSearch && dispatch(action({ page: page - 1, limit: limit, ...query }));
      setPage(page - 1);
    } else {
      isLoggedIn && !pauseSearch && dispatch(action({ page, limit: limit, ...query }));
    }
    // scroll smoothly to top
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (countSearch > 0) {
      isLoggedIn && dispatch(action({ page: 1, limit: limit, ...query }));
      setPage(1);
      setPauseSearch(true);
      // scroll to top
    }
  }, [countSearch]);
  useEffect(() => {
    resetPage > 0 && setPage(1);
  }, [resetPage]);
  const listLoading = () =>
    Array.from({ length: 4 }, (_, i) => (
      <Stack key={i} spacing={1}>
        <Skeleton variant='rectangular' height={275} />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </Stack>
    ));
  const showListCard = () => listCard.map((item, index) => itemCard(item, index));
  return (
    <>
      <WrapperListCard marginTopBottom='20px' gmin={gmin}>
        {loading ? listLoading() : <>{showListCard()}</>}
      </WrapperListCard>
      {!loading && listCard.length === 0 && (
        <Stack height='475px' alignItems='center' justifyContent='center'>
          検索結果がありません
        </Stack>
      )}
      <Pagination
        className={classes.ul}
        page={page}
        count={count}
        boundaryCount={3}
        onChange={(e, p) => {
          setPage(p);
          setPauseSearch(false);
        }}
      />
    </>
  );
};
