import { Checkbox } from '@mui/material';
import { AlertDialog } from 'components/Dialog';
import { FormInputText } from 'components/FromFeature/form-components';
import { TitleItem } from 'pages/SettingDisplaySite/styled';
import { useEffect, useState,useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TabsChildren from '../tabChildren';
import TabsParent from '../tabParent';
import {
  FormCreateCategoryReport,
  FormSettingCategoryReport,
  TitleCategoryReport,
} from './styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCreateCategoryReport } from './schema';
import {
  deleteSettingCategoryReport,
  getListSettingCategoryReport,
  postCreateSettingCategoryReport,
  putCreatorSettingCategoryReport,
} from 'store/actions/settingsite.action';
import { LanguageContext } from '../../../Context/translation.context';

const paramsCategoryContact = {
  name: '',
  name_en: '',
  active_flag: 0,
};

export const SettingCategoryReport = () => {
  const { listSettingCategoryReport } = useSelector((state) => state.settingsite);
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'all',
    defaultValues: paramsCategoryContact,
    resolver: yupResolver(schemaCreateCategoryReport),
  });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = methods;

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(listSettingCategoryReport);
  }, [listSettingCategoryReport]);

  useEffect(() => {
    dispatch(getListSettingCategoryReport());
  }, []);

  const handleChangeFormChecked = (e) => {
    setValue('active_flag', e.target.checked ? 1 : 0);
  };

  const { tSettingsite } = useContext(LanguageContext);

  const formCreateCategoryReport = (
    <FormCreateCategoryReport>
      <div className='form-create-report'>
        <label className='label-name'>{tSettingsite("settingCategoryReport.categoryName")}</label>
        <FormInputText control={control} name='name' />
      </div>
      <div className='form-create-report'>
        <label className='label-name'>カテゴリー名（英語）</label>
        <FormInputText control={control} name='name_en' />
      </div>
      <div className='form-create-report'>
        <label className='label-active'>{tSettingsite("displayFlag")}</label>
        <Checkbox control={control} name='active_flag' onChange={handleChangeFormChecked} />
      </div>
    </FormCreateCategoryReport>
  );

  const handleCreateCategoryReport = (data) => {
    dispatch(postCreateSettingCategoryReport(data, getListSettingCategoryReport()));
    reset();
  };

  const handleButtonCancel = () => {
    clearErrors();
    reset();
  };

  const handleDeleteCategoryReport = (id) => {
    dispatch(deleteSettingCategoryReport(id, getListSettingCategoryReport()));
  };

  const onChangeCheckTable = (id, index, e) => {
    const idx = data.find((item) => item.id === id);

    if (idx) {
      setData((prev) => {
        const list = [...prev];
        list[index].active_flag = e.target.checked ? 1 : 0;
        return list;
      });
    }
  };

  const handleSubmitSettingReport = () => {
    const paramSubmit = [];

    data.forEach((item) => {
      paramSubmit.push({ id: item.id, active_flag: item.active_flag });
    });

    const paramPutSettingReport = {
      ids: paramSubmit,
    };

    dispatch(
      putCreatorSettingCategoryReport(paramPutSettingReport, getListSettingCategoryReport())
    );
  };
  const valueName = watch('name');

  return (
    <FormSettingCategoryReport>
      <TabsParent />
      <TabsChildren />
      <TitleCategoryReport>
        <span>{tSettingsite("settingCategoryReport.reportCategory")}</span>
      </TitleCategoryReport>

      <div className='table-category'>
        <div className='thead-table'>
          <div className='thead'>
            <div className='row row-stt'>{tSettingsite('displayRank')}</div>
            <div className='row row-name'>{tSettingsite('category')}</div>
            <div className='row row-name'>{tSettingsite('category')}（英語）</div>
            <div className='row row-checkbox'>{tSettingsite('displayFlag')}</div>
            <div className='row row-action'></div>
          </div>
        </div>
        <div className='tbody-table'>
          {data?.length
            ? data.map((item, index) => {
                return (
                  <div key={index} className='tbody'>
                    <div className='row row-stt'>{index + 1}</div>
                    <div className='row row-name'>{item.name}</div>
                    <div className='row row-name'>{item?.name_en || ''}</div>
                    <div className='row row-checkbox'>
                      <input
                        type='checkbox'
                        name='active_flag'
                        id='active_flag'
                        checked={!!item?.active_flag}
                        onChange={(e) => onChangeCheckTable(item.id, index, e)}
                      />
                    </div>
                    <div className='row row-action'>
                      <TitleItem borderRight minWidth='100px'>
                        <AlertDialog
                          addDelete
                          title={tSettingsite('delete')}
                          content={tSettingsite('deleteCategory')}
                          handleAccpet={() => handleDeleteCategoryReport(item.id)}
                          propsButton={{
                            min: '60px',
                            height: '30px',
                            startIcon: <DeleteIcon />,
                            variant: 'text',
                          }}
                        />
                      </TitleItem>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>

      <div className='form-action'>
        <AlertDialog
          contentElement={formCreateCategoryReport}
          title={tSettingsite("settingCategoryReport.addReport")}
          updateStatus={tSettingsite("addLine")}
          handleAccpet={handleSubmit(handleCreateCategoryReport)}
          handleCancel={handleButtonCancel}
          buttonConfimrNobutton={tSettingsite("toAdd")}
          buttonCacnel={tSettingsite("cancel")}
          styleDialog={{ width: '500px' }}
          propsButtonNo={{
            disabled: !valueName.length || Object.keys(errors).length ? true : false,
          }}
        />

        <AlertDialog
          title={tSettingsite("setting")}
          content={tSettingsite("wantToSetIt")}
          updateStatus={tSettingsite("setting")}
          propsButton={{ bgtheme: 'secondary' }}
          handleAccpet={handleSubmitSettingReport}
        />
      </div>
    </FormSettingCategoryReport>
  );
};
