import AgoraRTC from 'agora-rtc-sdk-ng';
import { linkS3 } from 'apis/s3';
import { AlertDialog } from 'components/Dialog';
import { ButtonSubmit, Content, WrapperButton } from 'components/FromFeature/form-components';
import { MediaPlayer } from 'components/MediaAgora/MediaPlayer';
import { TooltipHover } from 'components/Text';
import { NO_IMAGE_LIVESTREAM } from 'constants';
import { LanguageContext } from 'Context/translation.context';
import { useAgora } from 'hooks/useAgora';
import { updateData } from 'hooks/useRealTimeDB';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createTokenAgoraGuest, updateStopLivestream } from 'store/actions/creator.action';
import { decryptPath, renderFormatDate } from 'utils/utils';
import { CardInfo, ContentCard, ImageCard } from './styled';

const RenderTitle = ({ title, content, showTooltip }) => (
  <WrapperButton overText display='block'>
    <Content removeMargin minWidth='140px'>
      {title}
    </Content>
    {showTooltip ? (
      <TooltipHover title={content || ''}>
        <Content overText fontWeight='500' removeMargin minWidth='100px'>
          {content}
        </Content>
      </TooltipHover>
    ) : (
      <Content overText fontWeight='500' removeMargin minWidth='100px'>
        {content}
      </Content>
    )}
  </WrapperButton>
);
const client = AgoraRTC.createClient({ codec: 'vp8', mode: 'live' });
client.setClientRole('audience', 1);

export const CardDetail = ({ data, pushSearch, setKeyLive, keyLive }) => {
  const { leave, join, remoteUsers } = useAgora(client);
  const {
    account_name,
    account_id,
    img_background,
    period_time_1,
    period_time_2,
    realtime,
    live_key,
    id,
  } = data;

  const findChannel = useMemo(() => {
    const checkList = remoteUsers.filter((item) => item?.hasAudio || item?.hasVideo);
    const result = checkList.pop();
    const obj = {
      ViewLive: (
        <MediaPlayer
          videoTrack={result?.videoTrack}
          audioTrack={result?.audioTrack}
          // videoTrack={remoteAgora[0]?.videoTrack}
          // audioTrack={remoteAgora[0]?.audioTrack}
        />
      ),
      Result: result,
    };
    return obj;
  }, [remoteUsers]);

  const dispatch = useDispatch();
  const handleJoinLive = (keyLive) => {
    dispatch(
      createTokenAgoraGuest({
        name: live_key,
        action: (data) => join(keyLive, data),
      })
    );
  };
  const handleStopLive = () => {
    dispatch(
      updateStopLivestream(id, () => {
        updateData(`/lives/${live_key}/config`, { status: 5 });
        pushSearch();
      })
    );
  };
  // const [pauseLive, setPauseLive] = useState(false);
  // const handleUserPublished = (user, media) => {
  //   // media && client.subscribe(user, media);
  //   if (user?.hasVideo) {
  //     setPauseLive(false);
  //   } else {
  //     setPauseLive(true);
  //   }
  // };
  // const handleUserLeft = (user, reason) => {
  //   if (reason === 'Quit') {
  //     setPauseLive(true);
  //   }
  //   if (reason === 'ServerTimeOut') {
  //     window.location.reload();
  //   }
  // };
  // const handleUserJoined = (user, media) => {
  //   if (user?.uid === findChannel.Result?.uid) {
  //     if (user?.hasVideo) {
  //       setPauseLive(false);
  //     } else {
  //       setPauseLive(true);
  //     }
  //   }
  // };
  // const handleUserUnPublished = (user, media) => {
  //   if (user?.uid === findChannel.Result?.uid) {
  //     if (user?.hasVideo) {
  //       setPauseLive(false);
  //     } else {
  //       setPauseLive(true);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   // setPauseLive(true);
  //   client.on('user-joined', handleUserJoined);
  //   client.on('user-unpublished', handleUserUnPublished);
  //   client.on('user-published', handleUserPublished);
  //   client.on('user-left', handleUserLeft);
  //   return () => {
  //     client.off('user-published', handleUserPublished);
  //     client.off('user-unpublished', handleUserUnPublished);
  //     client.off('user-joined', handleUserJoined);
  //     client.off('user-left', handleUserLeft);
  //   };
  // }, [client, findChannel.Result]);
  const stylePause = {
    position: 'absolute',
    top: '45%',
    color: '#fff',
    background: 'rgba(40, 39, 39, 0.6)',
    borderRadius: '12px',
    width: '204px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: '400',
  };
  const { tCreator } = useContext(LanguageContext);
  return (
    <CardInfo>
      <ContentCard>
        <WrapperButton>
          <RenderTitle
            title={tCreator('card_content.creator_name')}
            content={account_name}
            showTooltip
          />
          <RenderTitle
            title={tCreator('card_content.creator_ID')}
            content={account_id}
            showTooltip
          />
        </WrapperButton>
        <WrapperButton>
          <RenderTitle
            title={tCreator('card_content.live_distribution_start_date_and_time')}
            content={renderFormatDate(period_time_1, 1)}
          />
          <RenderTitle
            title={tCreator('card_content.end_date_and_time_of_live_distribution')}
            content={renderFormatDate(period_time_2, 1)}
          />
        </WrapperButton>
        <WrapperButton>
          <RenderTitle
            title={tCreator('card_content.time_left')}
            content={`${realtime?.minutes_left}分`}
          />
          <RenderTitle
            title={tCreator('card_content.status')}
            content={tCreator('card_content.live_streaming')}
          />
        </WrapperButton>
      </ContentCard>
      <ContentCard bg='#000' bora='20px' >
        {keyLive[live_key] ? (
          // <MediaPlayer
          //   // videoTrack={remoteUsers[0]?.videoTrack}
          //   // audioTrack={remoteUsers[0]?.audioTrack}
          //   videoTrack={remoteUsers[remoteUsers.length - 1]?.videoTrack}
          //   audioTrack={remoteUsers[remoteUsers.length - 1]?.audioTrack}
          // />
          findChannel.ViewLive
        ) : (
          <ImageCard
            height='400px'
            loading='lazy'
            src={`${linkS3}${decryptPath(img_background)}`}
            alt=''
            onError={(e) => (e.target.src = NO_IMAGE_LIVESTREAM)}
          />
        )}
      </ContentCard>
      <WrapperButton gap='0'>
        <AlertDialog
          updateStatus={tCreator('card_content.status')}
          title={tCreator('card_content.status')}
          content={`${tCreator('card_content.very')}${account_name}${tCreator(
            'card_content.are_you_sure_you_want_to_force_stop_the_live_stream_of'
          )}`}
          handleAccpet={handleStopLive}
        />
        <ButtonSubmit
          variant='contained'
          bgtheme={keyLive[live_key] ? 'error' : 'primary'}
          onClick={() => {
            (async () => {
              if (client.channelName !== live_key) {
                await leave();
                setKeyLive({ [live_key]: true });
                await handleJoinLive(live_key);
              } else {
                leave();
                setKeyLive({ [live_key]: false });
              }
            })();
            // console.warn(live_key)
            // join(live_key);
          }}>
          {keyLive[live_key]
            ? tCreator('card_content.leave_the_live_room')
            : tCreator('card_content.enter_the_live_room')}
        </ButtonSubmit>
      </WrapperButton>
    </CardInfo>
  );
};
