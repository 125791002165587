import WrapperTable from 'components/Table';
import TabsAccount from 'pages/account/tabs';
import React, { useContext, useState } from 'react';
import {
  getDetailAdminCS,
  getDetailPermissionsAS,
  getListCSAdmin,
} from 'store/actions/admin.action';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewButton } from 'pages/account/styled';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  WrapperButton,
  ButtonSubmit,
} from 'components/FromFeature/form-element/styledElement';
import { Content, FormInputText, WrapperContent } from 'components/FromFeature/form-components';
import { PATH_URL } from 'constants/routes/settingUrl';
import { cellToTooltip, enterToSearch } from 'utils/utils';
import { LanguageContext } from 'Context/translation.context';
import { useGetParamsPage } from 'hooks/useGetParamsPage';

export const AccountCS = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const defaultValues = {
    keyword: searchParams.get('keyword') || '',
  };
  const { tAdmincs } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm({ mode: 'all', defaultValues: defaultValues });
  const { handleSubmit, control } = methods;
  const [countSearch, setCountSearch] = useState(0);
  const [query, setQuery] = useState({
    ...defaultValues,
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  });

  const columns = (goToEdit, goToUpdateRole) => [
    { headerName: tAdmincs('table_admin.full_name'), ...cellToTooltip('full_name') },
    { headerName: tAdmincs('table_admin.name_romaji'), ...cellToTooltip('name_romaji') },
    { headerName: tAdmincs('table_admin.birthday'), ...cellToTooltip('birthday', 'date') },
    { headerName: tAdmincs('table_admin.gender'), ...cellToTooltip('gender', 'gender') },
    { headerName: tAdmincs('table_admin.email'), ...cellToTooltip('email') },
    { headerName: tAdmincs('table_admin.status'), ...cellToTooltip('status', 'status') },
    {
      field: 'action',
      headerName: tAdmincs('table_admin.action'),
      minWidth: 200,
      renderCell: (data) => {
        return (
          <>
            <ButtonSubmit
              onClick={() => goToEdit(data.id)}
              variant='contained'
              bgtheme='primary'
              height='30px'
              min='60px'>
              {tAdmincs('table_admin.edit')}
            </ButtonSubmit>

            <ButtonSubmit
              onClick={() => goToUpdateRole(data.id)}
              variant='contained'
              bgtheme='primary'
              height='30px'
              min='80px'>
              {tAdmincs('table_admin.update')}
            </ButtonSubmit>
          </>
        );
      },
    },
  ];

  const handleGetDetail = (id) => {
    dispatch(
      getDetailAdminCS(
        id,
        () => navigate(`/${PATH_URL.OPERATION_ACCOUNT}/cs-account/edit/${id}`, {
          state: {
            prevPath: location?.search,
          },
        }),
        () => {}
      )
    );
  };

  const handleGetPermission = (id) => {
    dispatch(
      getDetailPermissionsAS(
        id,
        () => navigate(`/${PATH_URL.OPERATION_ACCOUNT}/cs-account/permissions/${id}`, {
          state: {
            prevPath: location?.search,
          },
        }),
        () => {}
      )
    );
  };

  const { pagination, ListCSAdmin } = useSelector((state) => state.admin);
  const { dataParams, pushStateParams } = useGetParamsPage();
  const handleSearch = (data) => {
    // setQuery(data);
    pushStateParams({
      ...data,
      page: 1,
    });
    setCountSearch(countSearch + 1);
  };

  const handleNavigate = (page) => {
    pushStateParams({
      ...dataParams,
      page: page || 1,
    });
  };

  return (
    <>
      <TabsAccount />
      <WrapperButton justifyContent wrap='true'>
        <AddNewButton
          onClick={() => navigate(`/${PATH_URL.OPERATION_ACCOUNT}/cs-account/create`, {
            state: {
              prevPath: location?.search,
            },
          })}
          variant='outlined'
          bgtheme='primary'
          size='large'>
          {tAdmincs('admin.create')}
        </AddNewButton>
        <WrapperButton width='450px'>
          <FormInputText
            name='keyword'
            isSearch
            control={control}
            label={tAdmincs('cs_account.input')}
            inputProps={{
              onKeyDown: (e) => {
                enterToSearch(e, handleSubmit(handleSearch));
              },
            }}
          />
          <ButtonSubmit
            onClick={handleSubmit(handleSearch)}
            variant='contained'
            bgtheme='primary'>
            {tAdmincs('admin.search')}
          </ButtonSubmit>
        </WrapperButton>
      </WrapperButton>
      <WrapperContent justifyContentEnd marginBotTop={'10px'} paddingRight={'10px'}>
        <Content marginTop={'0'} marginBottom={'0'} textAlign={'end'}>
          検索結果：
        </Content>
        <div>{pagination?.total}件</div>
      </WrapperContent>
      <WrapperTable
        columns={columns(
          (id) => handleGetDetail(id),
          (id) => {
            handleGetPermission(id);
          }
        )}
        page={pagination?.current_page}
        getRowId={(row) => row.id}
        total={pagination?.total}
        countSearch={countSearch}
        query={query}
        listRows={ListCSAdmin}
        action={getListCSAdmin}
        handleNavigate={handleNavigate}
      />
    </>
  );
};
