import { PATH_URL } from 'constants/routes/settingUrl';
import { AffiliatorPage, FormEdit, Setting } from 'pages/affiliator';
import { Navigate } from 'react-router-dom';

export const listRouteAffiliatorPage = [
  {
    path: 'affiliate-management/manage-affiliate',
    element: <AffiliatorPage isMain />,
    title: 'アフィリエイターアカウント管理画面',
    currentPath: '/affiliate-management/manage-affiliate',
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: 'affiliate-management/manage-affiliate/edit/:id',
    element: <FormEdit />,
    title: 'アフィリエイターアカウント編集画面',
    currentPath: '/affiliate-management/manage-affiliate/edit',
    backPath: '/affiliate-management/manage-affiliate',
  },
  {
    path: 'affiliate-management/sale-history',
    element: <AffiliatorPage isSaleHistory />,
    title: 'アフィリエイターアカウント管理画面',
    currentPath: '/affiliate-management/sale-history',
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: 'affiliate-management/payment-history',
    element: <AffiliatorPage isPaymentHistory />,
    title: 'アフィリエイターアカウント管理画面',
    currentPath: '/affiliate-management/payment-history',
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: 'affiliate-management/banner',
    element: <AffiliatorPage isBanner />,
    title: 'アフィリエイターアカウント管理画面',
    currentPath: '/affiliate-management/banner',
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: 'affiliate-management/setting',
    element: <Setting />,
    title: 'アフィリエイターアカウント管理画面',
    currentPath: '/affiliate-management/setting',
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `affiliate-management`,
    currentPath: `/affiliate-management/manage-affiliate`,
    element: <Navigate to='manage-affiliate' replace />,
  },
];
