import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller } from 'react-hook-form';
import { DeleteInputDate, InputTextDate, MessError, WrapperDate } from './styled';
import { ja } from 'date-fns/locale';
import { ICON_CLEAR } from 'constants';
import { useEffect } from 'react';
export const FormInputDateTime = ({ name, control, disabled, label, clearData, propsErr, ...props }) => {
  const sttInput = document.getElementById('DisabledInput');
  useEffect(() => {
    if (sttInput) {
      sttInput.readOnly = true;
    }
  }, [sttInput]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
        const showMessDate = {
          birthday: formState?.errors?.birthday?.message,
          start_time: formState?.errors?.start_time?.message,
          end_time: formState?.errors?.end_time?.message,
        };
        return (
          <WrapperDate>
            <LocalizationProvider locale={ja} dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat='yyyy/MM/dd HH:mm'
                error={!!error}
                onChange={onChange}
                value={value || null}
                maxDateTime={new Date()}
                closeOnSelect
                disableMaskedInput
                disabled={disabled}
                renderInput={(params) => (
                  <InputTextDate id='DisabledInput' variant='outlined' {...params} />
                )}
                {...props}
              />
              {clearData && value && (
                <DeleteInputDate onClick={() => clearData(name, '')}>
                  {ICON_CLEAR}
                </DeleteInputDate>
              )}
            </LocalizationProvider>
            <MessError {...propsErr}>{error && showMessDate[name]}</MessError>
          </WrapperDate>
        );
      }}
    />
  );
};
