import { API_URL, API_URL_GLOBAL, API_URL_GLOBAL_V2, API_URL_V2 } from 'constants';
import { stringify } from 'qs';

const settingSiteAPI = {
  CREATE_POINT: `${API_URL_V2}/packages`,
  DELETE_POINT: (id) => `${API_URL_V2}/packages/${id}`,
  getListPackagePayment: (...params) =>
    `${API_URL_GLOBAL_V2}/payments/packages?${stringify(...params)}`,
  getListSettingSystem: () =>
    `${API_URL_GLOBAL_V2}/setting/systems/6`,
  deletePackagePayment: (id) => `${API_URL_V2}/packages/${id}`,
  createPackagePayment: `${API_URL_V2}/packages`,
  putSettingPackagePayment: `${API_URL_V2}/packages`,
  getListPackagePaymentPoint: (...params) =>
    `${API_URL_V2}/settings/points?${stringify(...params)}`,
  getListSettingSite: (...params) => `${API_URL_V2}/settings?${stringify(...params)}`,
  getListSettingPayment: (...params) => `${API_URL_V2}/settings/payment-gateway?${stringify(...params)}`,
  putSettingSite: (...params) => `${API_URL_V2}/settings?${stringify(...params)}`,
  createPackagePaymentPoint: `${API_URL_V2}/settings/points`,
  getListFavoriteCreators: (...params) =>
    `${API_URL_GLOBAL}/priorities/favorite-creators?${stringify(...params)}`,
  getListFavoriteCreatorsType2: (...params) =>
    `${API_URL_GLOBAL}/priorities/favorite-creators?${stringify(...params)}`,
  getListNewCreators: (...params) =>
    `${API_URL_GLOBAL}/priorities/new-creators?${stringify(...params)}`,
  getListNewCreatorsType2: (...params) =>
    `${API_URL_GLOBAL}/priorities/new-creators?${stringify(...params)}`,
  getListPickupCreators: (...params) =>
    `${API_URL_GLOBAL}/priorities/list-pickups?${stringify(...params)}`,
  getListRankingCreators: (...params) =>
    `${API_URL_GLOBAL}/priorities/ranking-creators?${stringify(...params)}`,
  getListRankingCreatorsType2: (...params) =>
    `${API_URL_GLOBAL}/priorities/ranking-creators?${stringify(...params)}`,
  getListRecommends: (...params) =>
    `${API_URL_GLOBAL_V2}/priorities/suggest-users?${stringify(...params)}`,
  getListRecommendsType2: (...params) =>
    `${API_URL_GLOBAL_V2}/priorities/suggest-users?${stringify(...params)}`,
  deleteCreatorType2: (priority_id) => `${API_URL_GLOBAL_V2}/priorities/user/${priority_id}`,
  putCreatorFavorite: (id) => `${API_URL_GLOBAL_V2}/priorities/user/${id}`,
  postCreatorFavoriteSaved: () => `${API_URL_GLOBAL_V2}/priorities/save`,
  addCreator: `${API_URL_GLOBAL_V2}/priorities/user`,
  getListTag: `${API_URL_GLOBAL_V2}/tags`,
  getListChangeTypeListFavoriteGenres: (tag) =>
    `${API_URL_GLOBAL_V2}/priorities/suggest-users?type=3&${stringify(tag)}`,
  getListCheckBoxType: (type) => `${API_URL_GLOBAL_V2}/priorities/public-flag?${stringify(type)}`,
  // A005-6
  getListBanners: `${API_URL_GLOBAL}/priorities/list-banners`,
  createBannerAdmin: `${API_URL_GLOBAL}/priorities/banner`,
  updateBannerAdmin: `${API_URL_GLOBAL}/priorities/banners/`,
  deleteBannerAdmin: (id) => `${API_URL_GLOBAL}/admin/banner/${id}`,

  // list-setting-category-contact
  getListSettingCategoryContact: (...params) =>
    `${API_URL_GLOBAL_V2}/feedback-categories?${stringify(...params)}`,
  createSettingCategoryContact: `${API_URL_V2}/feedback/categories`,
  deleteSettingCategoryContact: (id) => `${API_URL_V2}/feedback/categories/${id}`,
  putSettingCategoryContact: `${API_URL_V2}/feedback/categories`,

  // list-setting-category-report
  getListSettingCategoryReport: (...params) =>
    `${API_URL_GLOBAL_V2}/report-categories?${stringify(...params)}`,
  createSettingCategoryReport: `${API_URL_V2}/report/categories`,
  deleteSettingCategoryReport: (id) => `${API_URL_V2}/report/categories/${id}`,
  putSettingCategoryReport: `${API_URL_V2}/report/categories`,

  //list-setting-effect
  getListSettingEffect: (...params) =>
    `${API_URL_GLOBAL_V2}/donate/categories?${stringify(...params)}`,
  createSettingEffect: `${API_URL_V2}/donate/categories`,
  deleteSettingEffect: (id) => `${API_URL_V2}/donate/categories/${id}`,
  putSettingEffect: `${API_URL_V2}/donate/categories`,
  createPayment: `${API_URL_V2}/settings/payment-gateway`,
  getListSettingMessageDonate: (...params) =>
    `${API_URL_GLOBAL_V2}/donation-message-categories?${stringify(...params)}`,
  createSettingMessageDonate: `${API_URL_V2}/message-donate/categories`,
  deleteSettingMessageDonate: (id) => `${API_URL_V2}/message-donate/categories/${id}`,
  putSettingMessageDonate: `${API_URL_V2}/message-donate/categories`,
  // Creator donate
  getEffectCreator: (...params) => `${API_URL_GLOBAL_V2}/user-donate/categories?${stringify(...params)}`,
  updateEffectCreator: `${API_URL_V2}/user-donate/categories`,
  deleteEffectCreator: id => `${API_URL_V2}/user-donate/categories/${id}`,
  postEffectCreator: `${API_URL_V2}/user-donate/categories`
};
export { settingSiteAPI };
