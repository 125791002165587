import { Media } from 'constants';
import styled from 'styled-components';
import { TextField } from '@mui/material';

const WrapperFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ m }) => m};
  align-items: center;
  width: ${({ width }) => width};
`;
const Input = styled(TextField)`
  flex-grow: 1;
  padding-right: 10rem !important;
  label {
    line-height: 1;
  }
  input {
    padding: 0.635rem;
  }
`;
const Span = styled.span`
  margin-right: 10px;
  ${Media.lessThan(Media.SIZE.LG)} {
    display: none;
  }
`;
export { WrapperFilter, Input, Span };
