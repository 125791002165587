import { combineReducers } from 'redux';
import auth from './auth.reducer';
import creator from './creator.reducer';
import fan from './fan.reducer';
import affiliator from './affiliate.reducer';
import agent from './agent.reducer';
import admin from './admin.reducer';
import settingsite from './settingsite.reducer';
import settingpage from './settingpage.reducer';
import tag from './tag.reducer';
import loading from './loading.reducer';
import report from './report.reducer';
import banner from './banner.reducer';
import common from './common.reducer';
import contact from './contact.reducer';
import notification from './notification.reducer';
import point from './point.reducer';
import payment from './payment.reducer';
import revenue from './revenue.reducer';
import email from './email.reducer';
import post from './post.reducer';
import subscription from './subscription.reducer';
import campaign from './campaign.reducer';
import voucher from './voucher.reducer';
import chart from './chart.reducer';
import error from './error.reducer';
const appReducer = combineReducers({
  auth,
  creator,
  affiliator,
  fan,
  agent,
  admin,
  settingsite,
  settingpage,
  tag,
  loading,
  report,
  banner,
  common,
  contact,
  notification,
  point,
  payment,
  email,
  revenue,
  post,
  subscription,
  campaign,
  voucher,
  chart,
  error,
});
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
