export const CREATOR = {
  GET_CREATOR_DETAIL: 'GET_CREATOR_DETAIL',
  GET_CREATOR: 'GET_CREATOR',
  GET_CREATOR_LIST: 'GET_CREATOR_LIST',
  UPDATE_DETAIL_CREATOR: 'UPDATE_DETAIL_CREATOR',
  DELETE_CREATOR: 'DELETE_CREATOR',
  APPROVE_CREATOR: 'APPROVE_CREATOR',
  GET_SALE_HISTORY_CREATOR: 'GET_SALE_HISTORY_CREATOR',
  GET_PAYMENT_HISTORY_CREATOR: 'GET_PAYMENT_HISTORY_CREATOR',
  GET_POST_HISTORY_CREATOR: 'GET_POST_HISTORY_CREATOR',
  GET_SETTING_LIMIT_CREATOR: 'GET_SETTING_LIMIT_CREATOR',
  UPDATE_SETTING_LIMIT_CREATOR: 'UPDATE_SETTING_LIMIT_CREATOR',
  DELETE_POST_CREATOR: 'DELETE_POST_CREATOR',
  GET_LIST_LIVESTREAM_CREATOR: 'GET_LIST_LIVESTREAM_CREATOR',
  DELETE_SUBSCRIPTION_CREATOR: 'DELETE_SUBSCRIPTION_CREATOR',
  CREATOR_STOP_LIVE: 'CREATOR_STOP_LIVE',
  ADMIN_LOGIN_CREATOR: 'ADMIN_LOGIN_CREATOR',
  GET_SUBSCRIPTION_CREATOR: 'GET_SUBSCRIPTION_CREATOR',
  CREATE_TOKEN_AGORA_GUEST: 'CREATE_TOKEN_AGORA_GUEST',
  GET_LIST_RECORD_LIVESTREAM: 'GET_LIST_RECORD_LIVESTREAM',
  DELETE_RECORD_LIVESTREAM: 'DELETE_RECORD_LIVESTREAM',
  GET_LIST_CONTRACT: 'GET_LIST_CONTRACT',
  GET_CONTRACT_DETAIL: 'GET_CONTRACT_DETAIL',
  UPDATE_DETAIL_CONTRACT: 'UPDATE_DETAIL_CONTRACT',
  DELETE_CONTRACT_ACCOUNT: 'DELETE_CONTRACT_ACCOUNT',
};
