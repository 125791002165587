
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from 'firebase/database';

// const firebaseConfig = {
//   apiKey: "AIzaSyBjIJHZ-BzurxtFHxWzOPUPRyvZc2PURYg",
//   authDomain: "fansite-fe88b.firebaseapp.com",
//   databaseURL: "https://fansite-fe88b-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "fansite-fe88b",
//   storageBucket: "fansite-fe88b.appspot.com",
//   messagingSenderId: "819724178981",
//   appId: "1:819724178981:web:0e06bde2beeb36e41491cc"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCOfrU2gWyHuEE8w3LF3ScC_Ci_h3PYBZg",
//   authDomain: "fansite-message.firebaseapp.com",
//   databaseURL: "https://fansite-message-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "fansite-message",
//   storageBucket: "fansite-message.appspot.com",
//   messagingSenderId: "957265527119",
//   appId: "1:957265527119:web:2005a524a0385c07de453a"
// };


//! CONNECT CUA KHACH 

const firebaseConfig = {
  apiKey: "AIzaSyCgil80Pm3mKrMxzZCOcBFvcbbWo_LmMjk",
  authDomain: "cocofans-de52a.firebaseapp.com",
  databaseURL: "https://cocofans-de52a-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cocofans-de52a",
  storageBucket: "cocofans-de52a.appspot.com",
  messagingSenderId: "892796778350",
  appId: "1:892796778350:web:fd8d44dc7108d2bcde737f",
  measurementId: "G-X9PWR08KK3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const DBRealTime = getDatabase(app);


