import { ICON_EMAIL } from 'constants';
import { ICON_POST } from 'constants';
import { ICON_MESSAGE } from 'constants';
import {
  ICON_INFO,
  ICON_MANAGE,
  ICON_PAID,
  ICON_SALES_MANAMENT,
  ICON_SETTINGS,
  ICON_OPERATION,
  ICON_AGENCY,
  ICON_AFFILICATE,
  ICON_AWARD,
  ICON_ACCOUT_MANEMENT,
  ICON_REPORT,
  ICON_VOUCHER,
} from 'constants';
import { ICON_SUBSCRIPTION } from 'constants/icons';
import { PATH_URL } from 'constants/routes/settingUrl';
const permissionsSidebar = (oldArray) => {
  const newArray = [];
  oldArray.forEach((item) => {
    if (item.toggleList.length > 0) {
      newArray.push({
        title: item.title,
        toggleList: item.toggleList.filter((item) => item.permissions === 1),
        link: item.link,
        icon: item.icon,
      });
    } else {
      if (item.permissions === 0) {
      } else {
        newArray.push({
          title: item.title,
          permissions: item.permissions,
          toggleList: [],
          link: item.link,
          icon: item.icon,
        });
      }
    }
  });

  return newArray.filter((item) => {
    if (item.permissions === undefined && item.toggleList.length === 0) {
      return false;
    } else {
      return true;
    }
  });
};
const menuSidebarX = (array = []) => {
  const convertArr = () => {
    let newObj = {};
    array.map((item) => (newObj = { ...newObj, ...item }));
    return newObj;
  };
  const {
    A004 = 1,
    A005 = 1,
    A006 = 1,
    A008 = 1,
    A015 = 1,
    A012 = 1,
    A010 = 1,
    A013 = 1,
    A014 = 1,
    A009 = 1,
    A017 = 1,
    A018 = 1,
    A019 = 1,
    A020 = 1,
    A021 = 1,
    A022 = 1,
    A023 = 1,
    A024 = 1,
  } = convertArr();
  return [
    {
      title: 'サイト設定',
      icon: ICON_SETTINGS,
      link: '#',
      toggleList: [
        {
          title: 'サイト全般設定',
          icon: ICON_SETTINGS,
          link: PATH_URL.SETTING_SITE,
          permissions: A004,
        },
        {
          title: 'サイト内表示設定',
          icon: ICON_SETTINGS,
          link: PATH_URL.SETTING_PAGE,
          permissions: A005,
        },
      ],
    },
    {
      title: '売上管理',
      link: '#',
      icon: ICON_SALES_MANAMENT,
      toggleList: [
        {
          title: '月別売上一覧',
          icon: ICON_SETTINGS,
          link: PATH_URL.REVENUE,
          permissions: A006,
        },
        {
          title: '詳細売上',
          icon: ICON_AFFILICATE,
          link: PATH_URL.DETAIL_SALE,
          toggleList: [],
          permissions: A024,
        },

      ],
    },
    {
      title: '支払い管理',
      icon: ICON_PAID,
      link: 'payment-management',
      toggleList: [],
      permissions: A008,
    },
    {
      title: 'クリエイター管理',
      link: PATH_URL.CREATOR,
      icon: ICON_ACCOUT_MANEMENT,
      permissions: A015,
      toggleList: [],
      // toggleList: [
      //   {
      //     title: 'ユーザーアカウント管理',
      //     icon: ICON_MANAGE,
      //     link: PATH_URL.CREATOR,
      //     permissions: A015,
      //   },
      // ],
    },
    {
      title: '運営アカウント管理',
      icon: ICON_OPERATION,
      link: '#',
      toggleList: [
        {
          title: '運営アカウント管理',
          icon: ICON_MANAGE,
          link: PATH_URL.OPERATION_ACCOUNT,
          permissions: A012,
        },
        {
          title: 'インフォメーション',
          icon: ICON_INFO,
          link: PATH_URL.NOTIFICATION,
          permissions: A010,
        },
      ],
    },
    {
      title: '代理店アカウント管理',
      icon: ICON_AGENCY,
      link: '#',
      toggleList: [
        {
          title: '代理店アカウント管理',
          icon: ICON_MANAGE,
          link: PATH_URL.AGENCY,
          permissions: A013,
        },
      ],
    },
    // {
    //   title: 'アフィリエイター管理',
    //   icon: ICON_AFFILICATE,
    //   link: '#',
    //   toggleList: [
    //     {
    //       title: 'アフィリエイターアカウント管理',
    //       icon: ICON_MANAGE,
    //       link: 'affiliate-management',
    //       permissions: A014,
    //     },
    //     {
    //       title: 'バナー作成',
    //       icon: ICON_MANAGE,
    //       link: 'banners-management',
    //       permissions: A009,
    //     },
    //   ],
    // },
    {
      title: '通報管理',
      icon: ICON_REPORT,
      link: PATH_URL.REPORT,
      toggleList: [],
      permissions: A017,
    },
    {
      title: 'サブスク・ポイント購入履歴',
      icon: ICON_AWARD,
      link: '#',
      toggleList: [
        // {
        //   title: 'サブスク購入履歴',
        //   link: PATH_URL.SUBSCRIPTION,
        //   permissions: A022,
        // },
        {
          title: 'ポイント購入履歴',
          link: PATH_URL.POINT,
          permissions: A018,
        },
      ],
    },
    {
      title: 'お問い合わせ',
      icon: ICON_MESSAGE,
      link: PATH_URL.CONTACT,
      toggleList: [],
      permissions: A019,
    },
    {
      title: 'メールテンプレート管理',
      icon: ICON_EMAIL,
      link: PATH_URL.EMAIL,
      toggleList: [],
      permissions: A020,
    },
    {
      title: '投稿管理',
      icon: ICON_POST,
      link: PATH_URL.POST,
      toggleList: [],
      permissions: A021,
    },
    // {
    //   title: 'クーポン管理',
    //   icon: ICON_VOUCHER,
    //   link: PATH_URL.VOUCHER,
    //   toggleList: [],
    //   permissions: A023,
    // },
  ];
};

const menuSidebarY = (array = []) => {
  const convertArr = () => {
    let newObj = {};
    array.map((item) => (newObj = { ...newObj, ...item }));
    return newObj;
  };
  const {
    A004 = 1,
    A005 = 1,
    A006 = 1,
    A008 = 1,
    A015 = 1,
    A012 = 1,
    A010 = 1,
    A013 = 1,
    A014 = 1,
    A009 = 1,
    A017 = 0,
    A018 = 0,
    A019 = 0,
    A020 = 0,
    A021 = 1,
    A022 = 1,
    A023 = 1,
    A024 = 0,
  } = convertArr();
  return [
    {
      title: 'サイト設定',
      icon: ICON_SETTINGS,
      link: '#',
      toggleList: [
        {
          title: 'サイト全般設定',
          icon: ICON_SETTINGS,
          link: PATH_URL.SETTING_SITE,
          permissions: A004,
        },
        {
          title: 'サイト内表示設定',
          icon: ICON_SETTINGS,
          link: PATH_URL.SETTING_PAGE,
          permissions: A005,
        },
      ],
    },
    {
      title: '売上管理',
      link: '#',
      icon: ICON_SALES_MANAMENT,
      toggleList: [
        {
          title: '月別売上一覧',
          icon: ICON_SETTINGS,
          link: PATH_URL.REVENUE,
          permissions: A006,
        },
        {
          title: '詳細売上',
          icon: ICON_AFFILICATE,
          link: PATH_URL.DETAIL_SALE,
          toggleList: [],
          permissions: A024,
        },

      ],
    },
    {
      title: '支払い管理',
      icon: ICON_PAID,
      link: 'payment-management',
      toggleList: [],
      permissions: A008,
    },
    {
      title: 'クリエイター管理',
      link: PATH_URL.CREATOR,
      icon: ICON_ACCOUT_MANEMENT,
      permissions: A015,
      toggleList: [],
    },
    {
      title: '運営アカウント管理',
      icon: ICON_OPERATION,
      link: '#',
      toggleList: [
        {
          title: '運営アカウント管理',
          icon: ICON_MANAGE,
          link: PATH_URL.OPERATION_ACCOUNT,
          permissions: A012,
        },
        {
          title: 'インフォメーション',
          icon: ICON_INFO,
          link: PATH_URL.NOTIFICATION,
          permissions: A010,
        },
      ],
    },
    {
      title: '代理店アカウント管理',
      icon: ICON_AGENCY,
      link: '#',
      toggleList: [
        {
          title: '代理店アカウント管理',
          icon: ICON_MANAGE,
          link: PATH_URL.AGENCY,
          permissions: A013,
        },
      ],
    },
    // {
    //   title: 'アフィリエイター管理',
    //   icon: ICON_AFFILICATE,
    //   link: '#',
    //   toggleList: [
    //     {
    //       title: 'アフィリエイターアカウント管理',
    //       icon: ICON_MANAGE,
    //       link: 'affiliate-management',
    //       permissions: A014,
    //     },
    //     {
    //       title: 'バナー作成',
    //       icon: ICON_MANAGE,
    //       link: 'banners-management',
    //       permissions: A009,
    //     },
    //   ],
    // },
    {
      title: '通報管理',
      icon: ICON_REPORT,
      link: PATH_URL.REPORT,
      toggleList: [],
      permissions: A017,
    },
    {
      title: 'サブスク・ポイント購入履歴',
      icon: ICON_AWARD,
      link: '#',
      toggleList: [
        {
          title: 'サブスク購入履歴',
          link: PATH_URL.SUBSCRIPTION,
          permissions: A022,
        },
        {
          title: 'ポイント購入履歴',
          link: PATH_URL.POINT,
          permissions: A018,
        },
      ],
    },
    {
      title: 'お問い合わせ',
      icon: ICON_MESSAGE,
      link: PATH_URL.CONTACT,
      toggleList: [],
      permissions: A019,
    },
    {
      title: 'メールテンプレート管理',
      icon: ICON_EMAIL,
      link: PATH_URL.EMAIL,
      toggleList: [],
      permissions: A020,
    },
    {
      title: '投稿管理',
      icon: ICON_POST,
      link: PATH_URL.POST,
      toggleList: [],
      permissions: A021,
    },
    // {
    //   title: 'クーポン管理',
    //   icon: ICON_VOUCHER,
    //   link: PATH_URL.VOUCHER,
    //   toggleList: [],
    //   permissions: A023,
    // },
  ];
};

const menuSidebar = (array) => permissionsSidebar(menuSidebarX(array));
const menuSidebarCSAdmin = (array) => permissionsSidebar(menuSidebarY(array));

export { menuSidebar, menuSidebarCSAdmin };
