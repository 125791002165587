import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import { lazy } from 'react';
const DetailSalePage = lazy(() =>
  lazyRoute(() =>
    import('pages/DetailSale').then((module) => ({
      default: module.DetailSalePage,
    }))
  )
);
export const listRouteDetailSalePage = [
  {
    path: PATH_URL.DETAIL_SALE,
    element: <DetailSalePage />,
    title: '詳細売上',
    currentPath:`/${PATH_URL.DETAIL_SALE}`,
    backPath: `/${PATH_URL.REVENUE}`,

  }
];
