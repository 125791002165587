import { Tooltip } from '@mui/material';
import { ButtonCCF } from 'styled';
import styled from 'styled-components';
const ButtonSubmit = styled(ButtonCCF)``;
const WrapperButton = styled.div`
  text-align: center;
  margin: 10px 0px 20px 0px;
`;

const WrapperShowImgDetail = styled.div`
  display: flex;
  width: 120px;
  gap: 20px;
  justify-content: space-between;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
`;
const ImgShowDetail = styled.img`
  display: block;
  width: 100%;
  border: 1px solid #000;
  cursor: pointer;
`;

const WrapperImageUser = styled.div`
  max-width: 100px;
  margin: 10px 0;

  .image-item {
    height: 45px;
    margin-bottom: 5px;

    .enable {
      height: 45px;
      width: 45px;
      position: absolute;
      background-color: #1a1a1a91;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .image {
      height: 45px;
      width: 45px;
      cursor: pointer;
      object-fit: cover;
    }
  }
`;

const ToolTipName = styled(Tooltip)`
  &.MuiTooltip-tooltip {
    max-width: fit-content !important;
  }
`;

export {
  ButtonSubmit,
  WrapperButton,
  WrapperShowImgDetail,
  ImgShowDetail,
  WrapperImageUser,
  ToolTipName,
};
