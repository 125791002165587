/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  error: null,
  listPackagePayment: [],
  listCampaignPoint: [],
  listSettingSystem: {},
  listSettingCategoryContact: [],
  listSettingCategoryReport: [],
  listSettingEffect: [],
  listSettingMessageDonate: [],
  listSettingPackagePaymentPoint: {},
  listSettingSite: [],
  listEffectCreator: [],
  listSettingPayment: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_PACKAGE_PAYMENT_REQUEST:
    case TYPES.GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS_REQUEST:
    case TYPES.GET_LIST_SETTING_SYSTEM_REQUEST:
    case TYPES.DELETE_PACKAGE_PAYMENT_REQUEST:
    case TYPES.CREATE_PACKAGE_PAYMENT_REQUEST:
    case TYPES.PUT_SETTING_PACKAGE_PAYMENT_REQUEST:
    case TYPES.GET_LIST_PACKAGE_PAYMENT_POINT_REQUEST:
    case TYPES.CREATE_PACKAGE_PAYMENT_POINT_REQUEST:
    case TYPES.GET_LIST_SETTING_CATEGORY_CONTACT_REQUEST:
    case TYPES.CREATE_SETTING_CATEGORY_CONTACT_REQUEST:
    case TYPES.DELETE_SETTING_CATEGORY_CONTACT_REQUEST:
    case TYPES.PUT_SETTING_CATEGORY_CONTACT_REQUEST:
    case TYPES.GET_LIST_SETTING_CATEGORY_REPORT_REQUEST:
    case TYPES.CREATE_SETTING_CATEGORY_REPORT_REQUEST:
    case TYPES.DELETE_SETTING_CATEGORY_REPORT_REQUEST:
    case TYPES.PUT_SETTING_CATEGORY_REPORT_REQUEST:
    case TYPES.GET_LIST_SETTING_EFFECT_REQUEST:
    case TYPES.CREATE_SETTING_EFFECT_REQUEST:
    case TYPES.DELETE_SETTING_EFFECT_REQUEST:
    case TYPES.PUT_SETTING_EFFECT_REQUEST:
    case TYPES.GET_LIST_SETTING_MESSAGE_DONATE_REQUEST:
    case TYPES.CREATE_SETTING_MESSAGE_DONATE_REQUEST:
    case TYPES.DELETE_SETTING_MESSAGE_DONATE_REQUEST:
    case TYPES.PUT_SETTING_MESSAGE_DONATE_REQUEST:
    case TYPES.GET_LIST_SETTING_SITE_REQUEST:
    case TYPES.GET_LIST_SETTING_PAYMENT_REQUEST:
    case TYPES.PUT_SETTING_SITE_REQUEST:
    case TYPES.POST_EFFECT_CREATOR_REQUEST:
      return {
        ...state,
        status: action.status,
        error: null,
      };

    case TYPES.GET_LIST_PACKAGE_PAYMENT_SUCCESS:
      return {
        ...state,
        status: action.status,
        listPackagePayment: action.data,
      };
    case TYPES.GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS_SUCCESS:
      return {
        ...state,
        status: action.status,
        listCampaignPoint: action.data,
      };
    case TYPES.GET_LIST_SETTING_SYSTEM_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingSystem: action.data.data,
      };
    case TYPES.GET_LIST_SETTING_SITE_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingSite: action.data.data,
      };
    case TYPES.GET_LIST_SETTING_PAYMENT_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingPayment: action.data.data,
      };
    case TYPES.GET_LIST_PACKAGE_PAYMENT_POINT_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingPackagePaymentPoint: action.data.data,
      };
    case TYPES.GET_LIST_SETTING_CATEGORY_CONTACT_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingCategoryContact: action.data.data,
      };
    case TYPES.GET_LIST_SETTING_CATEGORY_REPORT_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingCategoryReport: action.data.data,
      };
    case TYPES.GET_LIST_SETTING_EFFECT_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingEffect: action.data.data,
      };
    case TYPES.GET_LIST_SETTING_MESSAGE_DONATE_SUCCESS:
      return {
        ...state,
        status: action.status,
        listSettingMessageDonate: action.data,
      };

    case TYPES.PUT_SETTING_PACKAGE_PAYMENT_SUCCESS:
    case TYPES.DELETE_PACKAGE_PAYMENT_SUCCESS:
    case TYPES.CREATE_PACKAGE_PAYMENT_SUCCESS:
    case TYPES.CREATE_PACKAGE_PAYMENT_POINT_SUCCESS:
    case TYPES.CREATE_SETTING_CATEGORY_CONTACT_SUCCESS:
    case TYPES.DELETE_SETTING_CATEGORY_CONTACT_SUCCESS:
    case TYPES.CREATE_SETTING_CATEGORY_REPORT_SUCCESS:
    case TYPES.DELETE_SETTING_CATEGORY_REPORT_SUCCESS:
    case TYPES.CREATE_SETTING_EFFECT_SUCCESS:
    case TYPES.DELETE_SETTING_EFFECT_SUCCESS:
    case TYPES.PUT_SETTING_CATEGORY_CONTACT_SUCCESS:
    case TYPES.PUT_SETTING_CATEGORY_REPORT_SUCCESS:
    case TYPES.PUT_SETTING_EFFECT_SUCCESS:
    case TYPES.PUT_SETTING_SITE_SUCCESS:
    case TYPES.CREATE_SETTING_MESSAGE_DONATE_SUCCESS:
    case TYPES.DELETE_SETTING_MESSAGE_DONATE_SUCCESS:
    case TYPES.PUT_SETTING_MESSAGE_DONATE_SUCCESS:
    case TYPES.PUT_EFFECT_CREATOR_REQUEST:
    case TYPES.PUT_EFFECT_CREATOR_SUCCESS:
    case TYPES.DELETE_EFFECT_CREATOR_REQUEST:
    case TYPES.DELETE_EFFECT_CREATOR_SUCCESS:
    case TYPES.POST_EFFECT_CREATOR_SUCCESS:
      return {
        ...state,
        status: action.status,
      };

    case TYPES.PUT_SETTING_PACKAGE_PAYMENT_FAILED:
    case TYPES.GET_LIST_PACKAGE_PAYMENT_POINT_FAILED:
    case TYPES.GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS_FAILED:
    case TYPES.GET_LIST_SETTING_SYSTEM_FAILED:
    case TYPES.CREATE_PACKAGE_PAYMENT_POINT_FAILED:
    case TYPES.GET_LIST_PACKAGE_PAYMENT_FAILED:
    case TYPES.DELETE_PACKAGE_PAYMENT_FAILED:
    case TYPES.CREATE_PACKAGE_PAYMENT_FAILED:
    case TYPES.GET_LIST_SETTING_CATEGORY_CONTACT_FAILED:
    case TYPES.CREATE_SETTING_CATEGORY_CONTACT_FAILED:
    case TYPES.DELETE_SETTING_CATEGORY_CONTACT_FAILED:
    case TYPES.GET_LIST_SETTING_CATEGORY_REPORT_FAILED:
    case TYPES.CREATE_SETTING_CATEGORY_REPORT_FAILED:
    case TYPES.DELETE_SETTING_CATEGORY_REPORT_FAILED:
    case TYPES.GET_LIST_SETTING_EFFECT_FAILED:
    case TYPES.CREATE_SETTING_EFFECT_FAILED:
    case TYPES.DELETE_SETTING_EFFECT_FAILED:
    case TYPES.GET_LIST_SETTING_MESSAGE_DONATE_FAILED:
    case TYPES.CREATE_SETTING_MESSAGE_DONATE_FAILED:
    case TYPES.DELETE_SETTING_MESSAGE_DONATE_FAILED:
    case TYPES.PUT_SETTING_MESSAGE_DONATE_FAILED:
    case TYPES.PUT_SETTING_CATEGORY_CONTACT_FAILED:
    case TYPES.PUT_SETTING_CATEGORY_REPORT_FAILED:
    case TYPES.PUT_SETTING_EFFECT_FAILED:
    case TYPES.GET_LIST_SETTING_SITE_FAILED:
    case TYPES.GET_LIST_SETTING_PAYMENT_FAILED:
    case TYPES.PUT_SETTING_SITE_FAILED:
    case TYPES.GET_EFFECT_CREATOR_FAILED:
    case TYPES.PUT_EFFECT_CREATOR_FAILED:
    case TYPES.DELETE_EFFECT_CREATOR_FAILED:
    case TYPES.POST_EFFECT_CREATOR_FAILED:
      return {
        ...state,
        status: action.status,
        error: action.error,
      };
    case TYPES.GET_EFFECT_CREATOR_REQUEST:
      return {
        ...state,
        listEffectCreator: [],
      };
    case TYPES.GET_EFFECT_CREATOR_SUCCESS:
      return {
        ...state,
        listEffectCreator: action.data,
      };

    default:
      return state;
  }
};
