import { messageError } from 'constants/errorMessage';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getKey } from 'utils/request';

const useErrorForm = () => {
  const [errorApi, setErrorApi] = useState(false);
  const [listErrorApi, setListErrorApi] = useState([]);
  const { error } = useSelector((state) => state.error);
  const handleErrApi = useCallback(() => {
    if (Array.isArray(error?.message) && error?.message.length > 0) {
      const findKey = error?.message.map((item) => {
        const splitKey = item.split('_');
        return {
          key: getKey(splitKey),
          mess: messageError[item],
        };
      });
      setListErrorApi(findKey);
      setErrorApi(true);
    } else {
      setListErrorApi([]);
      setErrorApi(false);
    }
  }, [error]);

  useEffect(() => {
    handleErrApi();
  }, [error]);

  const watchError = (name) => {
    if (errorApi) {
      const findKey = listErrorApi?.find((item) => item.key === name);
      if (findKey) {
        return findKey.mess;
      }
    }
    return '';
  };
  const handleChangeError = useCallback(
    (name) => {
      const newFilter = listErrorApi?.filter((item) => item.key !== name);
      setListErrorApi(newFilter);
    },
    [listErrorApi]
  );
  const lengthListErr = listErrorApi.length;
  return { errorApi, watchError, handleChangeError, lengthListErr };
};

export { useErrorForm };
