import React, { useEffect, useState } from 'react';
import { Controller, useController } from 'react-hook-form';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { WrapperDropdownForm } from './styleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'hooks';
import {
  getListAffliate,
  getListAgencies,
  getListCountry,
  getListCreator,
  getListFan,
  getListReportCategory,
} from 'store/actions/common.action';
import { MessError } from './styled';
import { getListVoucher } from 'store/actions/voucher.action';
import { getListCampaign } from 'store/actions/campaign.action';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

export const FormInputAsyncSelect = ({
  name,
  control,
  propsForm,
  vl = 'user_id',
  lb = 'account_name',
  defaultSelect,
  getLabel = () => {},
  getURL = () => {},
  type = '',
  hiddenTooltip = false,
  haveOptAll = false,
  errAll = true,
  bannerAllOption = false,
  getMulti = null,
  placeholder = '---',
  selectAllLevel = false,
  disabled,
  isLoadingAgency,
  permissionAgency = true,
  onClickWapper,
  isMulti,
  valueTMP,
  labelTMP,
  isPageVoucher,
  idsCam,
  enableFiltering = true,
  ...props
}) => {
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();
  const debounce = useDebounce(keyword, 600);
  const defaultValueSearch = {
    page: 1,
    maxPage: 2,
    keyword: '',
  };
  const {
    listCountry,
    listAgency,
    listAffiliate,
    listCreator,
    listFan,
    listfavoritegenres,
    listReportCategory,
    loadingReportCategory,
    loadingAffiliate,
    loadingListUser,
    loadingAgency,
  } = useSelector((state) => state.common);
  const { listAgencyLv2FromAgency } = useSelector((state) => state.contact);
  const { listVoucher, loadingVoucher, pagination } = useSelector((state) => state.voucher);
  const { listCampaign, loadingCampaign } = useSelector((state) => state.campaign);
  const { profile } = useSelector((state) => state.auth);
  useEffect(() => {
    if (debounce !== null) {
      setParamsSearch({
        ...defaultValueSearch,
        keyword: debounce,
      });
    }
  }, [debounce]);

  const [optionsUser, setOptionsUser] = useState([]);
  const [paramsSearch, setParamsSearch] = useState(defaultValueSearch);

  const [optionsAgency, setOptionsAgency] = useState([]);
  const [optionsVoucher, setOptionsVoucher] = useState([]);
  const [optionsCampaign, setOptionsCampaign] = useState([]);
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles

    let bg = 'transparent';
    if (isFocused) bg = '#eee';
    if (isActive) bg = '#B2D4FF';
    const style = {
      alignItems: 'center',
      backgroundColor: bg,
      color: 'inherit',
      display: 'flex',
      gap: '5px',
    };

    // prop assignment

    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}>
        {isMulti && <input type='checkbox' checked={isSelected} onChange={() => {}} />}

        {children}
      </components.Option>
    );
  };

  // const ValueContainer = ({ children, getValue, ...props }) => {
  //   let maxToShow = 2;
  //   var length = getValue().length;
  //   let displayChips = React.Children.toArray(children).slice(0, maxToShow);
  //   let shouldBadgeShow = length > maxToShow;
  //   let displayLength = length - maxToShow;

  //   return (
  //     <components.ValueContainer {...props}>
  //       {!props.selectProps.inputValue && displayChips}
  //       <div className='root'>{shouldBadgeShow && `+ ${displayLength}`}</div>
  //     </components.ValueContainer>
  //   );
  // };

  useEffect(() => {
    if (type === 'user') {
      if (listCreator?.length === 15) {
        setParamsSearch((prev) => ({
          ...prev,
          maxPage: prev.maxPage + 1,
        }));
      }
      if (paramsSearch.page !== 1 && optionsUser?.length > 0) {
        setOptionsUser(uniqWith(optionsUser?.concat(listCreator), isEqual));
      } else {
        setOptionsUser(listCreator || []);
      }
    }
  }, [listCreator]);

  useEffect(() => {
    if (type === 'user') {
      dispatch(
        getListCreator({
          page: paramsSearch.page,
          limit: 15,
          keyword: paramsSearch.keyword,
        })
      );
    }
  }, [paramsSearch.page, paramsSearch.keyword]);

  useEffect(() => {
    const optionSelectNameCampaign =
      listCampaign?.length > 0 &&
      listCampaign.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));

    if (type === 'campaign') {
      if (optionSelectNameCampaign?.length === 15) {
        setParamsSearch((prev) => ({
          ...prev,
          maxPage: prev.maxPage + 1,
        }));
      }
      if (paramsSearch.page !== 1 && optionsCampaign?.length > 0) {
        setOptionsCampaign(optionsCampaign?.concat(optionSelectNameCampaign));
      } else {
        setOptionsCampaign(optionSelectNameCampaign || []);
      }
    }
  }, [listCampaign]);

  useEffect(() => {
    if (type === 'campaign') {
      dispatch(
        getListCampaign(true, {
          page: paramsSearch.page,
          limit: 15,
          keyword: paramsSearch.keyword,
        })
      );
    }
  }, [paramsSearch.page, paramsSearch.keyword]);

  useEffect(() => {
    const optionSelectNameVoucher =
      listVoucher?.length > 0 &&
      listVoucher.map((item) => ({
        value: item?.voucher_id,
        label: item?.voucher_name,
      }));

    if (type === 'voucher') {
      if (optionSelectNameVoucher?.length === 15) {
        setParamsSearch((prev) => ({
          ...prev,
          maxPage: prev.maxPage + 1,
        }));
      }

      if (
        paramsSearch.page !== 1 &&
        optionsVoucher?.length > 0 &&
        pagination?.current_page !== 1
      ) {
        setOptionsVoucher(uniqWith(optionsVoucher?.concat(optionSelectNameVoucher), isEqual));
      } else {
        setOptionsVoucher(uniqWith(optionSelectNameVoucher, isEqual) || []);
      }
    }
  }, [listVoucher]);

  useEffect(() => {
    if (type === 'voucher') {
      dispatch(
        getListVoucher(true, {
          page: paramsSearch.page,
          limit: 15,
          campaign_id: idsCam && idsCam,
          keyword: paramsSearch.keyword,
        })
      );
    }
  }, [paramsSearch.page, paramsSearch.keyword]);

  useEffect(() => {
    if (type === 'agency') {
      if (listAgency?.length === 15) {
        setParamsSearch((prev) => ({
          ...prev,
          maxPage: prev.maxPage + 1,
        }));
      }
      if (paramsSearch.page !== 1 && optionsAgency?.length > 0) {
        setOptionsAgency(uniqWith(optionsAgency?.concat(listAgency), isEqual));
      } else {
        setOptionsAgency(listAgency || []);
      }
    }
  }, [listAgency]);

  useEffect(() => {
    if (type === 'agency') {
      if (selectAllLevel) {
        if (permissionAgency) {
          dispatch(
            getListAgencies({
              page: paramsSearch.page,
              limit: 15,
              keyword: paramsSearch.keyword,
            })
          );
        }
      } else {
        dispatch(
          getListAgencies({
            page: paramsSearch.page,
            limit: 15,
            level: 1,
            keyword: paramsSearch.keyword,
          })
        );
      }
    }
  }, [paramsSearch.page, paramsSearch.keyword]);

  useEffect(() => {
    if (type === 'country' && listCountry?.length === 0) {
      dispatch(getListCountry());
    }

    if (type === 'affiliate' && listAffiliate?.length === 0) {
      dispatch(
        getListAffliate({
          active: 1,
        })
      );
    }

    if (type === 'fan' && listFan?.length === 0) {
      dispatch(getListFan());
    }

    if (type === 'report_category' && listReportCategory?.length === 0 && profile.user_type !== 6) {
      dispatch(
        getListReportCategory({
          active_flag: 1,
        })
      );
    }
  }, []);
  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });
  const handleChange = (val, action) => {
    if (action?.action === 'clear') {
      onChange('');
    } else {
      onChange(val);
    }
  };
  const renderSelect = (list) =>
    list?.map((item) => ({
      value: item[vl],
      label: item[lb],
      ...(type === 'affiliate' && {
        banner_url: item.banner_url,
      }),
    }));
  const loadingList = {
    country: {
      loading: listCountry?.length === 0,
      list: listCountry,
    },
    agency: {
      loading: isLoadingAgency ?? loadingAgency,
      list: optionsAgency,
    },
    affiliate: {
      loading: loadingAffiliate,
      list: listAffiliate,
    },
    user: {
      loading: loadingListUser,
      list: optionsUser,
    },
    fan: {
      loading: listFan?.length === 0,
      list: listFan,
    },
    agencyLv2: {
      loading: false,
      list: listAgencyLv2FromAgency,
    },
    favoriteGenres: {
      loading: false,
      list: listfavoritegenres,
    },
    report_category: {
      loading: loadingReportCategory,
      list: listReportCategory.data,
    },
    campaign: {
      loading: loadingCampaign,
      list: optionsCampaign,
    },
    voucher: {
      loading: loadingVoucher,
      list: optionsVoucher,
    },
  };
  const listRender = renderSelect(loadingList[type]?.list || [])?.filter(
    (item) => item.label || item.value
  );

  return (
    <WrapperDropdownForm mbot='20px' {...propsForm} onClick={onClickWapper}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const campaignValue = { label: labelTMP, value: valueTMP };
          const listRenderOptions = campaignValue
            ? [campaignValue, ...listRender]
            : listRender;
          const listRenderNew = listRender?.find((c) => {
            return c.value === value || (type === 'country' && c.label === value);
          });
          return (
            <>
              <Select
                isDisabled={disabled}
                filterOption={(option, input) => {
                  if (enableFiltering) {
                    return (
                      option?.value?.toString()?.includes(input) ||
                      option?.label?.includes(input)
                    );
                  }
                  return true;
                }}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 998 }),
                  control: (base) => ({
                    ...base,
                    ...(haveOptAll && listRender?.length < 1 && { display: 'none' }),
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    overflowY: `${isMulti ? 'auto' : 'visible'}`,
                    maxHeight: `${isMulti ? '80px' : 'none'}`,
                  }),
                }}
                className='select-custom'
                variant='outlined'
                isMulti={isMulti ? true : false}
                inputValue={keyword}
                onInputChange={(value, action) => {
                  if (action.action === 'input-change' || action.action === 'set-value') {
                    setKeyword(value);
                  }
                }}
                onChange={(val, action) => {
                  handleChange(val?.value, action);
                  getLabel(val?.label);
                  getURL(val?.banner_url);
                  getMulti && getMulti(val);
                }}
                value={
                  labelTMP && valueTMP
                    ? listRenderOptions?.find(
                        (c) => c.value === value || (type === 'country' && c.label === value)
                      )
                    : type === 'voucher' && optionsVoucher?.length === 0
                    ? []
                    : listRenderNew
                }
                options={[
                  ...(haveOptAll && listRender?.length > 0
                    ? [{ value: 'all', label: '全て' }]
                    : []),
                  ...(bannerAllOption && listRender?.length > 0
                    ? [{ value: 0, label: '全て' }]
                    : []),
                  ...(labelTMP && valueTMP ? listRenderOptions : listRender),
                ]}
                components={
                  isMulti
                    ? {
                        Option: InputOption,
                        //ValueContainer,
                      }
                    : {}
                }
                closeMenuOnSelect={isMulti ? false : true}
                hideSelectedOptions={isMulti ? false : true}
                blurInputOnSelect={isMulti ? false : true}
                noOptionsMessage={({ inputValue }) => '検索結果がありません'}
                placeholder={placeholder}
                isClearable={true}
                isLoading={loadingList[type].loading || false}
                onMenuScrollToBottom={() => {
                  setParamsSearch((prev) => {
                    if (prev.maxPage === prev.page) {
                      return prev;
                    }
                    return {
                      ...prev,
                      page: prev.page + 1,
                    };
                  });
                }}
                {...props}
              />
              {error && (
                <MessError bottom='0' marginTop='5px'>
                  {error?.message}
                </MessError>
              )}
              {!hiddenTooltip && type !== 'agency' && value !== 0 && (
                <div className='hide-custom'>
                  {listRender.find((item) => item.value === value)?.label}
                </div>
              )}
            </>
          );
        }}
        control={control}
        name={name}
      />
      {haveOptAll && errAll && listRender?.length < 1 && (
        <MessError marginTop='15px'>
          上記一次代理店に所属している二次代理店の担当者名はありません。
        </MessError>
      )}
    </WrapperDropdownForm>
  );
};
FormInputAsyncSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  propsForm: PropTypes.object,
};
FormInputAsyncSelect.defaultProps = {
  option: [],
};
