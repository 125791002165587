import React, { useEffect } from 'react';
import { useTemplateRouter } from 'routes';
import {
  useCheckURLParams,
  useRequireAuth,
  useBroadcastChannel,
  useLocationRouter,
} from 'hooks';
import { MediaWrapper } from 'constants';
import ErrorBoundary from 'components/ErrorBoundary';
import { Routes, useNavigate } from 'react-router-dom';
import { LoadingGlobal } from 'effect';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissionAuth, getProfileAuth } from 'store/actions/auth.action';
import { PATH_URL } from 'constants/routes/settingUrl';

const App = () => {

  const { routeAdmin, routeGhost } = useTemplateRouter();
  const { getPathName } = useLocationRouter();
  const { isAuth } = useRequireAuth();
  const { profile } = useSelector((state) => state.auth);
  const { checkUrlAuth, checkUrlNoAuth } = useCheckURLParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localToken = useBroadcastChannel('Local_token', (ev) => {
    if ((checkUrlNoAuth && ev.data) || (checkUrlAuth && !ev.data)) {
      window.location.reload();
    }
  });
  localToken(window.localStorage.getItem('USER_ACCESS_TOKEN'));
  useEffect(() => {
    if (
      (checkUrlAuth && Object.entries(profile).length === 0) ||
      window.localStorage.getItem('USER_ACCESS_TOKEN')
    ) {
      dispatch(
        getProfileAuth(
          (data) => {
            getPathName === '/' && navigate(data.data.user_type === 6 ? PATH_URL.REPORT : PATH_URL.REVENUE);
            data.data.user_type === 6
              ? dispatch(
                  getPermissionAuth(
                    data.data.id,
                    () => getPathName === '/' && navigate(PATH_URL.REPORT),
                    () => navigate(`/${PATH_URL.REPORT}`)
                  )
                )
              : (() => {})();
          },
          () => navigate('/sign-in')
        ),
        () => navigate('/sign-in')
      );
    }
  }, []);
  return (
    <ErrorBoundary>
      <MediaWrapper>
        <Routes>
          {!isAuth && routeGhost}
          {isAuth && routeAdmin}
        </Routes>
        {/* <LoadingCheckProfile /> */}
        <LoadingGlobal />
      </MediaWrapper>
    </ErrorBoundary>
  );
};

export default App;
