import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormInputText } from 'components/FromFeature/form-components';
import { AlertDialog } from 'components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import TabsParent from '../tabParent';
import { TitleItem } from 'pages/SettingDisplaySite/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FormCreatSettingPackage,
  FormPoint,
  FormSettingPackagePayment,
  TitleSettingPackagePayment,
} from './styled';
import { Checkbox } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCreateSettingPackage } from './schema';
import {
  deletePackagePayment,
  getListPackagePayment,
  getListPackagePaymentCampaignPoints,
  getListPackagePaymentPoint,
  getListSettingSystem,
  postCreatePackagePayment,
  postCreatePackagePaymentPoint,
  putCreatorSettingPackagePayment,
} from 'store/actions/settingsite.action';
import { LanguageContext } from 'Context/translation.context';

const paramsPackagePayment = {
  value: '',
  price: '',
  active_flag: 0,
};

export const PackagePayment = () => {
  const methods = useForm({
    mode: 'all',
    defaultValues: paramsPackagePayment,
    resolver: yupResolver(schemaCreateSettingPackage),
  });
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;
  const {
    listPackagePayment,
    listSettingPackagePaymentPoint,
    listCampaignPoint,
    listSettingSystem,
  } = useSelector((state) => state.settingsite);
  const { tSettingsite } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [dataPoint, setDataPoint] = useState({});
  const [dataCampaignPoint, setDataCampaignPoint] = useState([]);
  const [dataSettingSystem, setDataSettingSystem] = useState({});

  useEffect(() => {
    setData(listPackagePayment);
  }, [listPackagePayment]);

  useEffect(() => {
    setDataCampaignPoint(listCampaignPoint);
  }, [listCampaignPoint]);

  useEffect(() => {
    setDataSettingSystem(listSettingSystem);
  }, [listSettingSystem]);

  useEffect(() => {
    setDataPoint(
      listSettingPackagePaymentPoint?.value
        ? JSON.parse(listSettingPackagePaymentPoint?.value)
        : ''
    );
  }, [listSettingPackagePaymentPoint]);

  const handleDeletePackage = (id) => {
    dispatch(deletePackagePayment(id, getListPackagePayment({ type: 1 })));
  };

  const handleDeleteCampaignPoints = (id) => {
    dispatch(deletePackagePayment(id, getListPackagePaymentCampaignPoints({ type: 2 })));
  };

  const onChangeCheckTable = (id, index, e) => {
    const idx = data.find((item) => item.id === id);

    if (idx) {
      setData((prev) => {
        const list = [...prev];
        list[index].active_flag = e.target.checked ? 1 : 0;
        return list;
      });
    }
  };

  const onChangeCheckTableCampaignPoint = (id, index, e) => {
    const idx = dataCampaignPoint.find((item) => item.id === id);

    if (idx) {
      setDataCampaignPoint((prev) => {
        const list = [...prev];
        list[index].active_flag = e.target.checked ? 1 : 0;
        return list;
      });
    }
  };

  useEffect(() => {
    dispatch(getListPackagePayment({ type: 1 }));
    dispatch(getListPackagePaymentCampaignPoints({ type: 2 }));
    dispatch(getListPackagePaymentPoint());
    dispatch(getListSettingSystem());
  }, []);

  const handleCreatePackage = (data) => {
    dispatch(
      postCreatePackagePayment({ ...data, type: 1 }, getListPackagePayment({ type: 1 }))
    );
    reset();
  };

  const handleCreateCampaignPoint = (data) => {
    dispatch(
      postCreatePackagePayment(
        { ...data, type: 2 },
        getListPackagePaymentCampaignPoints({ type: 2 })
      )
    );
    reset();
  };

  const handleButtonCancel = () => {
    clearErrors();
    reset();
  };

  const handleChangeFormChecked = (e) => {
    setValue('active_flag', e.target.checked ? 1 : 0);
  };

  const handleChangeFormCheckedCampaign = (e) => {
    setValue('active_flag', e.target.checked ? 1 : 0);
  };

  const formCreatePackage = (
    <FormCreatSettingPackage>
      <div className='form-create-setting-package'>
        <div className='label-title'>{tSettingsite('package_payment.form_1')}</div>
        <FormInputText className='input' control={control} type='number' name='value' />
      </div>
      <div className='form-create-setting-package'>
        <div className='label-title'>{tSettingsite('package_payment.form_2')}</div>
        <FormInputText className='input' control={control} type='number' name='price' />
      </div>
      <div className='form-create-setting-package'>
        <div className='label-title-active'>{tSettingsite('package_payment.form_3')}</div>
        <Checkbox
          className='input-checkbox'
          control={control}
          name='active_flag'
          onChange={handleChangeFormChecked}
        />
      </div>
    </FormCreatSettingPackage>
  );

  const formCreatePackageCampaign = (
    <FormCreatSettingPackage>
      <div className='form-create-setting-package'>
        <div className='label-title'>{tSettingsite('package_payment.form_1')}</div>
        <FormInputText className='input' control={control} type='number' name='value' />
      </div>
      <div className='form-create-setting-package'>
        <div className='label-title'>{tSettingsite('package_payment.form_2')}</div>
        <FormInputText className='input' control={control} type='number' name='price' />
      </div>
      <div className='form-create-setting-package'>
        <div className='label-title-active'>{tSettingsite('package_payment.form_3')}</div>
        <Checkbox
          className='input-checkbox'
          control={control}
          name='active_flag'
          onChange={handleChangeFormCheckedCampaign}
        />
      </div>
    </FormCreatSettingPackage>
  );

  const handleChangeFormPoint = (e) => {
    if (e.target.name) {
      setDataPoint((prev) => {
        const list = { ...prev };
        if (e.target.value !== '') {
          list[e.target.name] = e.target.value;
        } else {
          list[e.target.name] = undefined;
        }
        return list;
      });
    }
  };

  const handleCreatePackagePoint = () => {
    dispatch(postCreatePackagePaymentPoint(dataPoint, getListPackagePaymentPoint()));
  };

  const handleSubmitSettingPackage = () => {
    const paramSubmit = [];
    const paramSubmitCampaign = [];

    data.forEach((item) => {
      paramSubmit.push({ id: item.id, active_flag: item.active_flag });
    });

    dataCampaignPoint.forEach((item) => {
      paramSubmitCampaign.push({ id: item.id, active_flag: item.active_flag });
    });

    const paramPutSettingPackage = {
      ids: dataSettingSystem.value === '1' ? paramSubmit : paramSubmitCampaign,
      type: dataSettingSystem.value,
    };

    dispatch(
      putCreatorSettingPackagePayment(
        paramPutSettingPackage,
        dataSettingSystem.value === '1'
          ? getListPackagePayment({ type: 1 })
          : getListPackagePaymentCampaignPoints({ type: 2 }),
        getListSettingSystem()
      )
    );
  };

  const handleChangeRadio = (e) => {
    if (e.target.name === 'normal') {
      setDataSettingSystem((prev) => ({ ...prev, value: '1' }));
    } else {
      setDataSettingSystem((prev) => ({ ...prev, value: '2' }));
    }
  };

  const dataValue = watch('value');
  const dataPrice = watch('price');

  return (
    <FormSettingPackagePayment>
      <TabsParent />
      <TitleSettingPackagePayment>
        <span>{tSettingsite("package_payment.arbitraryPointSetting")}</span>
      </TitleSettingPackagePayment>

      <FormPoint>
        <div className='form-point-limit'>
          <div className='title'>
            <span>{tSettingsite('package_payment.upperAndLowerLimit')}</span>
          </div>
          <div className='input-1'>
            <input
              type='number'
              name='min_point'
              value={dataPoint?.min_point || ''}
              onChange={handleChangeFormPoint}
            />
          </div>
          <div className='input-2'>
            <input
              type='number'
              name='max_point'
              value={dataPoint?.max_point || ''}
              onChange={handleChangeFormPoint}
            />
          </div>
        </div>
        <div className='form-point'>
          <div className='title'>
            <span>{tSettingsite("package_payment.pointUnitPrice")}</span>
          </div>
          <div className='input'>
            <input
              type='number'
              name='point_to_jp'
              value={dataPoint?.point_to_jp || ''}
              onChange={handleChangeFormPoint}
            />
          </div>
        </div>
        <div className='form-point'>
          <div className='title'>
            <span>{tSettingsite("package_payment.inPoints")}</span>
          </div>
          <div className='input'>
            <input
              type='number'
              name='point_unit'
              value={dataPoint?.point_unit || ''}
              onChange={handleChangeFormPoint}
            />
          </div>
        </div>
        <div className='form-point'>
          <div className='title'>
            <span>{tSettingsite('package_payment.taxRate')}</span>
          </div>
          <div className='input'>
            <input
              type='number'
              name='tax'
              value={dataPoint?.tax || ''}
              onChange={handleChangeFormPoint}
            />
          </div>
        </div>
        <div className='form-point-button'>
          <AlertDialog
            title={tSettingsite("package_payment.setting")}
            content={tSettingsite('package_payment.wantToSetIt')}
            updateStatus={tSettingsite("package_payment.setting")}
            propsButton={{ bgtheme: 'secondary' }}
            handleAccpet={handleCreatePackagePoint}
          />
        </div>
      </FormPoint>

      <TitleSettingPackagePayment>
        <span>{tSettingsite("package_payment.fixedPointSetting")}</span>
      </TitleSettingPackagePayment>
      <div className='radio-form'>
        <div className='radio'>
          <input
            type='radio'
            checked={dataSettingSystem?.value === '1' ? true : false}
            onChange={handleChangeRadio}
            name='normal'
          />
          {tSettingsite("package_payment.normalPoint")}
        </div>
        <div className='radio'>
          <input
            type='radio'
            checked={dataSettingSystem?.value === '2' ? true : false}
            onChange={handleChangeRadio}
            name='campaign'
          />
            {tSettingsite("package_payment.campaignPoints")}
        </div>
      </div>

      <div className='group-table'>
        <div className='table-setting-point'>
          <div className='thead-table'>
            <div className='thead'>
              <div className='row row-stt'>{tSettingsite("package_payment.displayRank")}</div>
              <div className='row row-value'>{tSettingsite("package_payment.points")}</div>
              <div className='row row-checkbox'>{tSettingsite("package_payment.displayFlag")}</div>
              <div className='row row-price'>{tSettingsite("package_payment.amount")}</div>
              <div className='row row-action'></div>
            </div>
          </div>
          <div className='tbody-table'>
            {data?.length
              ? data.map((item, index) => {
                  return (
                    <div key={index} className='tbody'>
                      <div className='row row-stt'>{index + 1}</div>
                      <div className='row row-value'>{item.value.toLocaleString('en-US')}</div>
                      <div className='row row-checkbox'>
                        <input
                          type='checkbox'
                          name='active_flag'
                          id='active_flag'
                          checked={!!item?.active_flag}
                          onChange={(e) => onChangeCheckTable(item.id, index, e)}
                        />
                      </div>
                      <div className='row row-price'>{item.price.toLocaleString('en-US')}</div>
                      <div className='row row-action'>
                        <TitleItem borderRight minWidth='100px'>
                          <AlertDialog
                            addDelete
                            title={tSettingsite(tSettingsite('package_payment.delete'))}
                            content={tSettingsite('package_payment.wantToRemove')}
                            handleAccpet={() => handleDeletePackage(item.id)}
                            propsButton={{
                              min: '60px',
                              height: '30px',
                              startIcon: <DeleteIcon />,
                              variant: 'text',
                            }}
                          />
                        </TitleItem>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </div>

        <div className='table-setting-campaign-point'>
          <div className='thead-table'>
            <div className='thead'>
              <div className='row row-stt'>{tSettingsite('package_payment.displayRank')}</div>
              <div className='row row-value'>{tSettingsite('package_payment.points')}</div>
              <div className='row row-checkbox'>{tSettingsite('package_payment.displayFlag')}</div>
              <div className='row row-price'>{tSettingsite('package_payment.delete')}</div>
              <div className='row row-action'></div>
            </div>
          </div>
          <div className='tbody-table'>
            {dataCampaignPoint?.length
              ? dataCampaignPoint.map((item, index) => {
                  return (
                    <div key={index} className='tbody'>
                      <div className='row row-stt'>{index + 1}</div>
                      <div className='row row-value'>{item.value.toLocaleString('en-US')}</div>
                      <div className='row row-checkbox'>
                        <input
                          type='checkbox'
                          name='active_flag'
                          id='active_flag'
                          checked={!!item?.active_flag}
                          onChange={(e) => onChangeCheckTableCampaignPoint(item.id, index, e)}
                        />
                      </div>
                      <div className='row row-price'>{item.price.toLocaleString('en-US')}</div>
                      <div className='row row-action'>
                        <TitleItem borderRight minWidth='100px'>
                          <AlertDialog
                            addDelete
                            title={tSettingsite('package_payment.delete')}
                            content={tSettingsite('package_payment.wantToRemove')}
                            handleAccpet={() => handleDeleteCampaignPoints(item.id)}
                            propsButton={{
                              min: '60px',
                              height: '30px',
                              startIcon: <DeleteIcon />,
                              variant: 'text',
                            }}
                          />
                        </TitleItem>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </div>
      </div>

      <div className='form-action'>
        <AlertDialog
          contentElement={formCreatePackage}
          title={tSettingsite('package_payment.addFixedPoint')}
          updateStatus={tSettingsite('package_payment.addLine')}
          handleAccpet={handleSubmit(handleCreatePackage)}
          handleCancel={handleButtonCancel}
          buttonConfimrNobutton={tSettingsite('package_payment.toAdd')}
          buttonCacnel={tSettingsite("package_payment.cancel")}
          propsButtonNo={{
            disabled: !dataValue.length || !dataPrice.length ? true : false,
          }}
        />

        <AlertDialog
          contentElement={formCreatePackageCampaign}
          title={tSettingsite('package_payment.addFixedPoint')}
          updateStatus={tSettingsite('package_payment.addLine')}
          handleAccpet={handleSubmit(handleCreateCampaignPoint)}
          handleCancel={handleButtonCancel}
          buttonConfimrNobutton={tSettingsite('package_payment.toAdd')}
          buttonCacnel={tSettingsite("package_payment.cancel")}
          propsButtonNo={{
            disabled:
              !dataValue.length || !dataPrice.length || Object.keys(errors).length
                ? true
                : false,
          }}
        />

        <AlertDialog
          title={tSettingsite('package_payment.setting')}
          content={tSettingsite('package_payment.wantToSetIt')}
          updateStatus={tSettingsite('package_payment.setting')}
          propsButton={{ bgtheme: 'secondary' }}
          handleAccpet={handleSubmitSettingPackage}
        />
      </div>
    </FormSettingPackagePayment>
  );
};
