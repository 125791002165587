import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import FormEdit from 'pages/agent/EditForm';
import { SettingAgentPage } from 'pages/agent/settingAgentPage';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AgentPage = lazy(() =>
  lazyRoute(() =>
    import('pages/agent').then((module) => ({
      default: module.AgentPage,
    }))
  )
);

export const listRouteAgentPage = [
  {
    path: `${PATH_URL.AGENCY}/edit/:id`,
    element: <FormEdit isEdit />,
    title: '代理店アカウント編集画面',
    currentPath: `/${PATH_URL.AGENCY}/edit`,
    backPath: `/${PATH_URL.AGENCY}/manage-agency`,
  },
  {
    path: `${PATH_URL.AGENCY}/create`,
    element: <FormEdit />,
    title: `代理店アカウント新規登録画面`,
    currentPath: `/${PATH_URL.AGENCY}/create`,
    backPath: `/${PATH_URL.AGENCY}/manage-agency`,
  },
  {
    path: `${PATH_URL.AGENCY}/manage-agency`,
    element: <AgentPage haveAdd />,
    title: '代理店管理画面',
    currentPath: `/${PATH_URL.AGENCY}/manage-agency`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.AGENCY}/manage-agency-user`,
    element: <AgentPage isCreatorAgent />,
    title: '代理店ト管理画面',
    currentPath: `/${PATH_URL.AGENCY}/manage-agency-user`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.AGENCY}/sale-history`,
    element: <AgentPage isSaleHistory />,
    title: '代理店管理画面',
    currentPath: `/${PATH_URL.AGENCY}/sale-history`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.AGENCY}/payment-history`,
    element: <AgentPage isPaymentHistory />,
    title: '代理店管理画面',
    currentPath: `/${PATH_URL.AGENCY}/payment-history`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.AGENCY}/setting`,
    element: <SettingAgentPage />,
    title: 'クリエイターアカウント管理画面',
    currentPath: `/${PATH_URL.AGENCY}/setting`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.AGENCY}`,
    title: 'アカウント情報閲覧',
    backPath: `${PATH_URL.AGENCY}`,
    currentPath: `/${PATH_URL.AGENCY}/manage-agency`,
    element: <Navigate to='manage-agency' replace />,
  },
];
