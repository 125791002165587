import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  configParamsGETJson,
  agentAPI,
  configParamsPUTJson,
  configParamsPOSTJsonDelete,
  configParamsPOSTJson,
} from 'apis';
const getListAgent = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_AGENT_LIST_REQUEST));
    callApi(
      agentAPI.GET_LIST(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_AGENT_LIST_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_AGENT_LIST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListAgentCreator = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_AGENT_LIST_CREATOR_REQUEST));
    callApi(
      agentAPI.GET_LIST_AGENCY(props?.[0]?.agency_id || 'all', ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_AGENT_LIST_CREATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_AGENT_LIST_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListAgentSales = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_AGENT_SALES_HISTORY_REQUEST));
    callApi(
      agentAPI.agencySaleHistory(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_AGENT_SALES_HISTORY_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_AGENT_SALES_HISTORY_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListAgentPayment = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_AGENT_PAYMENT_HISTORY_REQUEST));
    callApi(
      agentAPI.agencyPaymentHistory(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_AGENT_PAYMENT_HISTORY_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_AGENT_PAYMENT_HISTORY_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const getDetailAgent = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_AGENT_DETAIL_REQUEST));
    callApi(
      agentAPI.GET_DETAIL(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_AGENT_DETAIL_SUCCESS, {
            res: response,
          })
        );
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_AGENT_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};
const postCreateAgent = (data, navigate, errParams) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_AGENT_REQUEST, {
        create: data,
      })
    );
    callApi(
      agentAPI.POST_CREATE_AGENT,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_AGENT_SUCCESS, {
              res: response,
            })
          );
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_AGENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATE_AGENCY',
      null,
      () => {},
      errParams
    );
  };
};
const putUpdateAgent = (data, id, navigate, errParams) => {
  const params = { ...data, created_at: undefined };
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_AGENT_REQUEST));
    callApi(
      agentAPI.UPDATE_AGENT(id),
      configParamsPUTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_AGENT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_AGENT_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_AGENCY', null, () => {}, errParams
    );
  };
};
const deleteAccountAgent = (id, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_AGENT_REQUEST));
    callApi(
      agentAPI.DELETE_AGENT(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_AGENT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_AGENT_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_AGENCY'
    );
  };
};
export {
  getListAgent,
  getListAgentCreator,
  getDetailAgent,
  postCreateAgent,
  putUpdateAgent,
  deleteAccountAgent,
  getListAgentSales,
  getListAgentPayment,
};
