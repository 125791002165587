import { WrapperButton } from 'components/FromFeature/form-components';
import { ICON_ADD_PHOTO } from 'constants';
import { UPLOAD } from 'constants';
import { ButtonUpload, ContentDrag, ImageDrag, ImageShow, WrapperDragUpload } from './styled';

export const UIDragUpload = ({
  imageList,
  onImageUpload,
  isDragging,
  dragProps,
  styleButton,
  onImageUpdate,
  onImageRemove,
  haveInput = true,
  haveDrag = true,
  onlyView = false,
  editMode = false,
  srcOriginal = '',
  contentLeft,
}) => {
  return (
    <>
      {haveInput && (
        <WrapperButton margin='0'>
          {contentLeft}
          <ButtonUpload
            variant='outlined'
            endIcon={ICON_ADD_PHOTO}
            onClick={onImageUpload}
            style={styleButton}
            >
            画像のアップロード
          </ButtonUpload>
          <div>10MB以下、jpg / gif ファイルのアップロードができます。</div>
        </WrapperButton>
      )}
      {haveDrag && (
        <WrapperDragUpload onClick={onImageUpload} isdrag={isDragging ? 1 : 0} {...dragProps}>
          {imageList.length > 0 ? (
            <ImageShow src={imageList[0].data_url} alt='' />
          ) : (
            <>
              <ContentDrag>Drag and drop your files here</ContentDrag>
              <ImageDrag src={UPLOAD} alt='' />
            </>
          )}
        </WrapperDragUpload>
      )}
      {editMode && (
        <WrapperDragUpload
          onClick={onImageUpload}
          isdrag={isDragging ? 1 : 0}
          {...dragProps}
          disabled={onlyView}>
          {imageList.length > 0 ? (
            <ImageShow src={imageList[0].data_url} alt='' />
          ) : (
            <ImageShow src={srcOriginal} alt='' />
          )}
        </WrapperDragUpload>
      )}
    </>
  );
};
