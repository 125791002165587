import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useGetParamsPage = (valueParams) => {
  const location = useLocation();
  const navigate = useNavigate();

  const dataParams = useMemo(() => {
    return _.fromPairs(
      location?.search
        ?.substring(1)
        ?.split('&')
        .map((s) => s.split('='))
    );
  }, [location?.search]);

  const pushStateParams = useCallback(
    (params) => {
      const dataParams = params || valueParams;
      if (!_.isEmpty(dataParams)) {
        const newParams = _.omitBy(dataParams, _.isNil);
        const url = new URLSearchParams(newParams).toString();
        navigate(`${location.pathname}?${url}`);
      }
    },
    [location.pathname, navigate, valueParams]
  );

  return { dataParams, pushStateParams };
};
