// import { translateSchema as t } from 'Context/translation.context';
import moment from 'moment';
import * as yup from 'yup';
import { regexAcceptJapanese, regexEmailCreate, regexJapanese, regexPhone, regexRomanji, regexRomanjiAgency } from './regex';

const yupEmail = () => ({
  email: yup
    .string()
    .trim()
    .required('メールアドレスを入力してください。')
    .matches(regexEmailCreate, 'メールアドレスを有効な形式で入力してください。'),
});
const yupEmailCreate = () => ({
  email: yup
    .string()
    .trim()
    .required('メールアドレスを入力してください。')
    .matches(regexEmailCreate, 'メールアドレスを有効な形式で入力してください。'),
});
const yupEmailSignin = () => ({
  email: yup
    .string()
    .required('メールアドレスを入力してください。')
    .email('メールアドレスを有効な形式で入力してください。')
    .max(255, 'メールアドレスを255文字まで入力してください。'),
});
const yupCompanyName = () => ({
  company_name: yup
    .string()
    .trim()
    .required('会社名を入力してください。')
    .max(50, '会社名を50文字まで入力してください。'),
});
const yupBirthday = () => ({
  birthday: yup
    .date()
    .required('生年月日を入力してください。')
    .typeError('生年月日を入力してください。')
    .test(
      'birthday',
      '年齢が18歳未満の方はご利用できません。',
      (value) => moment().diff(moment(value), 'years') >= 18
    ),
});
const yupPhone = () => ({
  phone: yup
    .string()
    .transform((currentValue) => currentValue.replace(/-/g, ''))
    .trim()
    // .required('電話番号を入力してください。')
    //.matches(regexPhone, '電話番号を有効な形式で入力してください。'),
    .test('phone', '電話番号を有効な形式で入力してください。', (phone) => {
      if (!phone) {
        return true;
      } else {
        return regexPhone.test(phone);
      }
    })
});

const yupPhoneAgent = () => ({
  phone: yup
    .string()
    .transform((currentValue) => currentValue.replace(/-/g, ''))
    .trim()
    .required('電話番号を入力してください。')
    .matches(regexPhone, '電話番号を有効な形式で入力してください。')
    .test('phone', '電話番号を有効な形式で入力してください。', (phone) => {
      if (!phone) {
        return true;
      } else {
        return regexPhone.test(phone);
      }
    })
});

const yupJapaneseName = () => ({
  name_japanese: yup
    .string()
    .trim()
    .required('氏名（日本語）を入力してください')
    .matches(regexJapanese, '日本語で入力してください。')
    .max(50, '氏名（日本語）を50文字まで入力してください。'),
});
const yupRomajiName = (
  req = '氏名（ローマ字）を入力してください。',
  mat = 'ローマ字で入力してください。',
  max = '氏名（ローマ字）を50文字まで入力してください。'
) => ({
  name_romaji: yup.string().trim().required(req).matches(regexRomanji, mat).max(50, max),
});
const yupFirstName = () => ({
  first_name: yup
    .string()
    .trim()
    .required('名は入力してください。')
    .max(20, '名は２０文字以内で入力してください。'),
});

const yupRomajiNameAgency = (
  req = '氏名（ローマ字）を入力してください。',
  mat = '有効形式な担当者名（ローマ字）入力してください。',
  max = '氏名（ローマ字）を50文字まで入力してください。'
) => ({
  name_romaji: yup.string().trim().required(req).matches(regexRomanjiAgency, mat).max(50, max),
});

const yupLastName = () => ({
  last_name: yup
    .string()
    .trim()
    .required('性は入力してください。')
    .max(20, '性は２０文字以内で入力してください。'),
});
const yupLastNameKana = () => ({
  last_name_kana: yup
    .string()
    .trim()
    .test('last_name_kana', 'セイはカタカナで入力してください。', (last_name_kana) => {
      if (!last_name_kana) {
        return true;
      } else {
        return /^[ァ-ンー－　ｧ-ﾝｰ－]+$/gu.test(last_name_kana);
      }
    })
    .max(20, 'セイは２０文字以内で入力してください。'),
});
const yupFirstNameKana = () => ({
  first_name_kana: yup
    .string()
    .trim()
    .test('first_name_kana', 'メイはカタカナで入力してください。', (first_name_kana) => {
      if (!first_name_kana) {
        return true;
      } else {
        return /^[ァ-ンー－　ｧ-ﾝｰ－]+$/gu.test(first_name_kana);
      }
    })
    .max(20, 'メイは２０文字以内で入力してください。'),
});
const yupCommissionSales = () => ({
  commission_sales: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_sales', '販売コンテンツの利用料は0%より入力してください。', (value) => value >= 1)
    .test('commission_sales', '販売コンテンツの利用料は99%以下入力してください。', (value) => value <= 99)
    .required('販売コンテンツの利用料を入力してください。'),
});
const yupCommissionSubscription = () => ({
  commission_subscription: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_subscription', 'サブスクリプションの利用料は1%より入力してください。', (value) => value >= 1)
    .test('commission_subscription', 'サブスクリプションの利用料は99%以下入力してください。', (value) => value <= 99)
    .required('サブスクリプションの利用料を入力してください。'),
});
const yupCommissionLivestream = () => ({
  commission_livestream: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_livestream', 'ライブ投げ銭の利用料は1%より入力してください。', (value) => value >= 1)
    .test('commission_livestream', 'ライブ投げ銭の利用料は99%以下入力してください。', (value) => value <= 99)
    .required('ライブ投げ銭の利用料を入力してください。'),
});
const yupCommissionMessage = () => ({
  commission_message: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_message', 'メッセージ投げ銭の利用料は1%より入力してください。', (value) => value >= 1)
    .test('commission_message', 'メッセージ投げ銭の利用料は99%以下入力してください。', (value) => value <= 99)
    .required('メッセージ投げ銭の利用料を入力してください。'),
});
const yupFullName = (
  required = '担当者名を入力してください。',
  max = '担当者名を50文字まで入力してください。'
) => ({
  full_name: yup
    .string()
    .trim()
    .required(required)
    .matches(regexJapanese, '日本語で入力してください。')
    .max(50, max),
});

const yupFullNameAgency = (
  required = '担当者名を入力してください。',
  max = '担当者名を50文字まで入力してください。'
) => ({
  full_name: yup
    .string()
    .trim()
    .required(required)
    .matches(regexAcceptJapanese, '有効形式な担当者名を入力してください。')
    .max(50, max),
});

const yupGender = () => ({
  gender: yup.string().required('性別を入力してください。'),
});

const yupMinPrice = () => ({
  min_price: yup
    .number('下限金額を数字のみで入力してください。')
    .required('下限金額を入力してください。')
    .typeError('下限金額を入力してください。'),
});
const yupCompanyAddress = () => ({
  company_address: yup
    .string()
    .trim()
    .max(100, '会社住所を100文字まで入力してください。')
    .required('会社住所を入力してください。'),
});
const yupDepartment = () => ({
  department: yup
    .string()
    .trim()
    .max(50, '所属部署を50文字まで入力してください。')
    .required('所属部署を入力してください。'),
});
const yupPassword = (
  req = '新しいパスワードを入力してください。',
  min = 'パスワードを8文字以上入力してください。'
) => ({
  password: yup.string().trim().required(req).min(8, min),
});
const yupRePassword = () => ({
  re_password: yup
    .string()
    .trim()
    .required('新しいパスワード（再入力）を入力してください。')
    .min(8, 'パスワードを8文字以上入力してください。')
    .oneOf(
      [yup.ref('password')],
      '新しいパスワード（再入力）と新しいパスワードが一致しません。'
    ),
});
const yupFaCode = () => ({
  fa_code: yup
    .string()
    .required('6桁のコードを入力してください。')
    .length(6, '6桁のコードが間違っています。再度正しい6桁のコードを入力してください。'),
});
const yupCountryName = () => ({
  country_id: yup.string().required('この項目は必須です。'),
});
const yupAccountID = () => ({
  account_id: yup
    .string()
    .trim()
    .max(30, 'アカウントIDを30文字まで入力してください。')
    .required('アカウントIDを入力してください。'),
});
const yupUsername = () => ({
  username: yup
    .string()
    .trim()
    .max(30, 'アカウント名を30文字まで入力してください。')
    .required('アカウント名を入力してください。'),
});
const yupAccountname = () => ({
  account_name: yup
    .string()
    .trim()
    .max(30, 'アカウント名を30文字まで入力してください。')
    .required('アカウント名を入力してください。'),
});
const yupSubject = () => ({
  subject: yup.string().required('件名を入力してください。'),
});
const yupContent = (req = 'メールの内容を入力してください。') => ({
  content: yup.string().required(req),
});
const yupStartTime = (
  type = '掲載期間を入力してください。',
  req = '掲載期間を入力してください。'
) => ({
  start_time: yup.date().typeError(type).required(req),
});
const yupEndTime = () => ({
  end_time: yup
    .date()
    .typeError('掲載期間を入力してください。')
    .required('掲載期間を入力してください。'),
});
const yupTitle = () => ({
  title: yup.string().trim().required('お知らせタイトルを入力してください。'),
});
const yupValue = () => ({
  value: yup
    .string()
    .trim()
    .required('ポイント数を入力してください。')
    .test('value', 'このポイント数は0より入力してください。', (value) => value > 0),
});
const yupPrice = () => ({
  price: yup
    .string()
    .trim()
    .required('ポイント数を入力してください。')
    .test('price', 'このポイント数は0より入力してください。', (value) => value > 0),
});
const yupName = () => ({
  name: yup
    .string()
    .trim()
    .required('カテゴリー名を入力してください。')
    .max(50, 'このカテゴリー名は50文字以内で入力してください。'),
});
const yupNameEng = () => ({
  name_en: yup
    .string()
    .trim()
    .required('カテゴリー名（英語）を入力してください。')
    .max(50, 'このカテゴリー名（英語）は50文字以内で入力してください。'),
});
const yupNameEffect = () => ({
  name: yup
    .string()
    .trim()
    .required('エフェクトスタンプ名を入力してください。')
    .max(30, 'このエフェクトスタンプ名は30文字以内で入力してください。'),
});
const yupPoint = () => ({
  point: yup
    .string()
    .trim()
    .required('ポイント数を入力してください。')
    .test('point', 'このポイント数は0より入力してください。', (value) => value > 0),
});
const yupValueOperator = () => ({
  commission_sales: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_sales', '販売コンテンツの利用料は1%より入力してください。', (value) => value >= 1)
    .test(
      'commission_sales',
      '販売コンテンツの利用料は99%以下入力してください。',
      (value) => value <= 99
    )
    .required('販売コンテンツの利用料を入力してください。'),
    commission_subscription: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_subscription', 'サブスクリプションの利用料は1%より入力してください。', (value) => value >= 1)
    .test(
      'commission_subscription',
      'サブスクリプションの利用料は99%以下入力してください。',
      (value) => value <= 99
    )
    .required('サブスクリプションの利用料を入力してください。'),
    commission_message: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_message', 'メッセージ投げ銭の利用料は1%より入力してください。', (value) => value >= 1)
    .test(
      'commission_message',
      'メッセージ投げ銭の利用料は99%以下入力してください。',
      (value) => value <= 99
    )
    .required('メッセージ投げ銭の利用料を入力してください。'),
    commission_livestream: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test('commission_livestream', 'ライブ投げ銭の利用料は1%より入力してください。', (value) => value >= 1)
    .test(
      'commission_livestream',
      'ライブ投げ銭の利用料は99%以下入力してください。',
      (value) => value <= 99
    )
    .required('ライブ投げ銭の利用料を入力してください。'),
});
const yupValueAffiliate = () => ({
  value_affiliate: yup
    .string()
    .transform((value) => value?.toString()?.replace(/%/g, ''))
    .test(
      'value_affiliate',
      'アフィリエイター費用は0%より入力してください。',
      (value) => value >= 0
    )
    .test(
      'value_affiliate',
      'アフィリエイター費用は100%以下入力してください。',
      (value) => value <= 100
    )
    .required('アフィリエイター費用を入力してください。'),
});
const yupTag = () => ({
  tag: yup.string().required('Tagを入力してください。').trim().max(255),
});
export {
  yupEmail,
  yupEmailCreate,
  yupCompanyName,
  yupBirthday,
  yupPhone,
  yupJapaneseName,
  yupRomajiName,
  yupCommissionLivestream,
  yupCommissionMessage,
  yupCommissionSales,
  yupCommissionSubscription,
  yupFullName,
  yupGender,
  yupMinPrice,
  yupCompanyAddress,
  yupDepartment,
  yupPassword,
  yupRePassword,
  yupEmailSignin,
  yupFaCode,
  yupCountryName,
  yupAccountID,
  yupUsername,
  yupAccountname,
  yupSubject,
  yupContent,
  yupStartTime,
  yupEndTime,
  yupTitle,
  yupValue,
  yupPrice,
  yupName,
  yupNameEffect,
  yupPoint,
  yupValueOperator,
  yupValueAffiliate,
  yupTag,
  yupNameEng,
  yupFirstName,
  yupLastName,
  yupFirstNameKana,
  yupLastNameKana,
  yupPhoneAgent,
  yupFullNameAgency,
  yupRomajiNameAgency
};
