import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

const Wrapper = styled.div`
  /* height: calc(1170px - ${({ countHeight }) => countHeight * 52 || 0}px); */
  /* height: calc(100vh - 250px); */
  height: 650px;
  width: 100%;
  text-align: center;

  .table-sales-content {
    .MuiDataGrid-row {
      min-height: 52px !important;

      .MuiDataGrid-cell {
        min-height: 52px !important;
      }
    }
  }
`;
const TableGrid = styled(DataGrid)`
  .MuiCircularProgress-root {
    display: none;
  }
  ${({ dblock }) =>
    dblock &&
    `
 .MuiDataGrid-columnSeparator{
   display: none !important;
 }
 .MuiDataGrid-columnHeader{
   padding: 0 !important;
 }
 .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderTitleContainerContent {
   display: block !important;
  }
 .MuiDataGrid-cell{
    padding: 0 !important;
   &:nth-child(1){
    border-right: 1px solid #e0e0e0 !important;
   }
 }

 `}
`;
export { Wrapper, TableGrid };
