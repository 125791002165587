const statusToTextButton = {
    1: {
      text: '停止',
      title: 'アカウント停止',
      confirm: 'アカウントを停止してもよろしいですか？',
      payload: 2,
    },
    2: {
      text: '停止解除',
      title: 'アカウント停止解除',
      confirm: 'アカウントの停止を解除してもよろしいですか？',
      payload: 1,
    },
    3: {
      text: '拒否',
      title: 'アカウント拒否',
      confirm: 'アカウントを拒否してもよろしいですか？',
      payload: 4,
    },
    4: {
      text: '承認',
      title: 'アカウント承認',
      confirm: 'アカウントを承認してもよろしいですか？',
      payload: 1,
    },
    7: {
      text: '停止',
      title: 'アカウント停止',
      confirm: 'アカウントを停止してもよろしいですか？',
      payload: 2,
    },
  };

  export {
    statusToTextButton,
  }