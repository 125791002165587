import { ERROR_IMAGE } from 'constants';
import styled from 'styled-components';
const WrapperHeaderTable = styled.div`
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  ${({ borderRight }) => borderRight && 'border-right: 1px solid #e0e0e0 !important;'}
  ${({ borderLeft }) => borderLeft && 'border-left: 1px solid #e0e0e0 !important;'}
`;
const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
const MainTitle = styled.div`
  line-height: 40px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`;
const TitleItem = styled.div`
  display: flex;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
  text-align: left;
  justify-content: ${({ newJistifyContent }) => newJistifyContent || 'center'};
  align-items: center;
  line-height: 40px;
  ${({ borderRight }) => borderRight && 'border-right: 1px solid #e0e0e0 !important;'}
  ${({ borderLeft }) => borderLeft && 'border-left: 1px solid #e0e0e0 !important;'}
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ColumnContent = styled(TitleItem)`
  flex-direction: column;
  * {
    line-height: 20px;
  }
`;

const WrapperDropdown = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const AvatarChatImg = styled.img`
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
`;

const AvatarDefault = (props) => {
  return (
    <AvatarChatImg
      {...props}
      onError={(e) => {
        e.target.src = ERROR_IMAGE;
      }}
    />
  );
};

const titleNameCreatorDropbox = styled.p`
  margin-bottom: 10px;
`;

const WrapperSelectBox = styled.div`
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
`;

export {
  WrapperDropdown,
  WrapperTitle,
  WrapperHeaderTable,
  MainTitle,
  TitleItem,
  ColumnContent,
  WrapperButton,
  AvatarDefault,
  titleNameCreatorDropbox,
  WrapperSelectBox,
};
