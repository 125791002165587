import {
  CREATOR,
  AUTH,
  AGENT,
  AFFILIATOR,
  ADMIN,
  SETTINGSITE,
  SETTINGPAGE,
  TAG,
  FAN,
  REPORT,
  BANNER,
  COMMON,
  CONTACT,
  NOTIFICATION,
  POINT,
  REVENUE,
  PAYMENT,
  EMAIL,
  POST,
  SUBSCRIPTION,
  CAMPAIGN,
  VOUCHER,
  CHART
} from 'store/constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  'LIST_ERROR',
  ...Object.values(AUTH),
  ...Object.values(CREATOR),
  ...Object.values(AGENT),
  ...Object.values(AFFILIATOR),
  ...Object.values(ADMIN),
  ...Object.values(SETTINGSITE),
  ...Object.values(SETTINGPAGE),
  ...Object.values(TAG),
  ...Object.values(FAN),
  ...Object.values(REPORT),
  ...Object.values(BANNER),
  ...Object.values(COMMON),
  ...Object.values(CONTACT),
  ...Object.values(NOTIFICATION),
  ...Object.values(POINT),
  ...Object.values(PAYMENT),
  ...Object.values(REVENUE),
  ...Object.values(EMAIL),
  ...Object.values(POST),
  ...Object.values(SUBSCRIPTION),
  ...Object.values(CAMPAIGN),
  ...Object.values(VOUCHER),
  ...Object.values(CHART),
];
