import {
  yupFullNameAgency,
  yupPhoneAgent,
  yupEmail,
  yupCompanyName,
  yupRomajiNameAgency,
  yupCompanyAddress,
  yupDepartment,
  yupEmailCreate,
  yupCommissionSales,
  yupCommissionSubscription,
  yupCommissionLivestream,
  yupCommissionMessage,
} from 'constants';
import * as yup from 'yup';
const schemaCreate = yup.object().shape({
  ...yupCompanyName(),
  ...yupCompanyAddress(),
  ...yupPhoneAgent(),
  ...yupRomajiNameAgency(),
  ...yupFullNameAgency(),
  ...yupEmailCreate(),
  ...yupDepartment(),
  position: yup.string().trim().max(255, '役職（任意項目）を255文字まで入力してください。'),
  ...yupCommissionSales(),
  ...yupCommissionSubscription(),
  ...yupCommissionLivestream(),
  ...yupCommissionMessage(),
});
const schemaEdit = yup.object().shape({
  ...yupCompanyName(),
  ...yupCompanyAddress(),
  ...yupPhoneAgent(),
  ...yupRomajiNameAgency(),
  ...yupFullNameAgency(),
  ...yupEmail(),
  ...yupDepartment(),
  position: yup.string().trim().max(255, '役職（任意項目）を255文字まで入力してください。'),
  ...yupCommissionSales(),
  ...yupCommissionSubscription(),
  ...yupCommissionLivestream(),
  ...yupCommissionMessage(),
});

const schemaEdit1 = yup.object().shape({
  ...yupCompanyName(),
  ...yupCompanyAddress(),
  ...yupPhoneAgent(),
  ...yupRomajiNameAgency(),
  ...yupFullNameAgency(),
  ...yupEmail(),
  position: yup.string().trim().max(255, '役職（任意項目）を255文字まで入力してください。'),
  ...yupCommissionSales(),
  ...yupCommissionSubscription(),
  ...yupCommissionLivestream(),
  ...yupCommissionMessage(),
});

export { schemaCreate, schemaEdit, schemaEdit1 };
