import AgoraRTC from 'agora-rtc-sdk-ng';
import {
  ButtonSubmit,
  Content,
  FormInputText,
  WrapperButton,
} from 'components/FromFeature/form-components';
import { MediaPlayer } from 'components/MediaAgora/MediaPlayer';
import { AGORA_APP_ID } from 'constants';
import { useAgora } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ContentCard } from './Creator/components/styled';
const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });
const TestObs = () => {
  const { leave, join, remoteUsers, joinState } = useAgora(client);
  const methods = useForm({
    defaultValues: {
      channelName: '',
      uid: '',
    },
  });
  const { handleSubmit, control, watch } = methods;
  const { channelName, uid } = watch();
  const navigate = useNavigate();
  const loginSecret = useCallback(() => {
    const pass = prompt('Nhập password đúng thì dô hóng, hông thì thôi!!');
    if (pass === '1234') {
      return true;
    }
    navigate('/payment-management/creator');
    return false;
  }, []);
  useEffect(() => {
    loginSecret();
  }, []);
  return (
    <WrapperButton display='block' mawidth='700px'>
      <Content marginBottom='5px'>Key super vip: {AGORA_APP_ID}</Content>
      <b>Set up livestream:</b> <br />
      Step 1: OBS =&gt; Tools =&gt; Agora RTC Tool <br />
      Step 2: In Agora RTC Tool =&gt; Settings =&gt; General =&gt; Paste Key to Agora APPID{' '}
      <br />
      <b>Set up join live:</b> <br />
      Type channel name =&gt; Join <br />
      <Content marginBottom='5px'>Channel Name</Content>
      <WrapperButton>
        <FormInputText control={control} name='channelName' disabled={joinState} />
      </WrapperButton>
      {/* <Content marginBottom='5px'>UID</Content>
      <WrapperButton>
        <FormInputText control={control} name='uid' />
      </WrapperButton> */}
      {joinState ? (
        <ButtonSubmit bgtheme='error' onClick={() => leave()}>
          Leave
        </ButtonSubmit>
      ) : (
        <ButtonSubmit bgtheme='primary' onClick={() => join(channelName)}>
          Join
        </ButtonSubmit>
      )}
      <ContentCard mgt='10px'>
        {joinState && remoteUsers.length === 0 && 'Channel Unavailable'}
        <MediaPlayer
          videoTrack={remoteUsers[0]?.videoTrack}
          audioTrack={remoteUsers[0]?.audioTrack}
        />
      </ContentCard>
    </WrapperButton>
  );
};
export { TestObs };
