import { Tooltip } from '@mui/material';
import styled from 'styled-components';
import { defaultColor } from 'styled/variables/color';

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
`;
const TooltipHover = styled(Tooltip)``;
const TextShow = styled.div`
  overflow-wrap: anywhere;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ wrapText = 'pre-wrap' }) => wrapText && `white-space: ${wrapText};`}
  ${({ mwidth }) => mwidth && `min-width: ${mwidth};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ pding }) => pding && `padding: ${pding};`}
`;
const WrapperShow = styled.div`
  display: ${({ display }) => display || 'flex'};
  margin-top: ${({ mtop }) => mtop || '0px'};
  margin-bottom: ${({ mbot }) => mbot || '0px'};
  gap: ${({ gap }) => gap || '0px'};
  ${({ flexFl }) => flexFl && `flex-flow: ${flexFl};`}
  ${({ flexWr }) => flexWr && `flex-wrap: ${flexWr};`}
  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ mwidth }) => mwidth && `max-width: ${mwidth};`}
`;
const TextLink = styled.a`
  text-decoration: ${({ tdecor }) => tdecor || 'none'};
  cursor: pointer;
  color: ${defaultColor['primary']};
`;
export { Text, TooltipHover, TextShow, WrapperShow, TextLink };
