/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  listEmailTemplate: [],
  detailEmailTemplate: {},
  pagination: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_TEMPLATE_EMAIL_REQUEST:
      return {
        ...state,
        listEmailTemplate: [],
        pagination: {},
      };
    case TYPES.GET_TEMPLATE_EMAIL_SUCCESS:
      return {
        ...state,
        listEmailTemplate: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_TEMPLATE_EMAIL_DETAIL_REQUEST:
      return {
        ...state,
        detailEmailTemplate: {},
      };
    case TYPES.GET_TEMPLATE_EMAIL_DETAIL_SUCCESS:
      return {
        ...state,
        detailEmailTemplate: action.data.data,
      };
    case TYPES.UPDATE_TEMPLATE_EMAIL_REQUEST:
      return state;
    case TYPES.UPDATE_TEMPLATE_EMAIL_SUCCESS:
      return {
        ...state,
      };
    case TYPES.UPDATE_TEMPLATE_EMAIL_FAILED:
    case TYPES.GET_TEMPLATE_EMAIL_FAILED:
    case TYPES.GET_TEMPLATE_EMAIL_DETAIL_FAILED:
      return state;

    default:
      return state;
  }
};
