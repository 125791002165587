import { useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import { ButtonSubmit, TextForm, FormSignIn, WrapperInput, IconShowPassword } from './styled';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { preLogin } from 'store/actions/auth.action';
import { yupResolver } from '@hookform/resolvers/yup';
import { Content, FormInputText } from 'components/FromFeature/form-components';
import { schemaLogin } from './schema';
import { ICON_SHOW_PASSWORD, ICON_HIDE_PASSWORD, BACKGROUND_LOGIN } from 'constants';
import { AlertDialog } from 'components/Dialog';
import { LanguageContext } from 'Context/translation.context';

export const LoginPage = () => {
  const { tAuth } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmLogin, setConfirmLogin] = useState(false);
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
      token_del_flag: '1'
    },
    resolver: yupResolver(schemaLogin),
  });
  const { handleSubmit, control, watch } = methods;
  const onSubmit = (data) => {
    dispatch(preLogin(data, () => navigate('/verification-email/vrfemail2fa')));
  };
  const { logging, error } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    error?.message === 'SOMEONE_IS_LOGGED_IN_WITH_THIS_ACCOUNT_DO_YOU_WANT_TO_LOGIN?' &&
      setConfirmLogin(true);
  }, [error]);

  return (
    <FormSignIn
      onSubmit={handleSubmit(onSubmit)}
      bgi={BACKGROUND_LOGIN}
      xs={11}
      sm={9}
      md={5}
      lg={4}
      xl={3}
      title={tAuth('signIn.signIn')}
      item>
      <WrapperInput>
        <Content marginBottom='0.4rem'>{tAuth('signIn.emailAddress')}</Content>
        <FormInputText
          name='email'
          control={control}
          autoComplete='email'
          label={tAuth('signIn.pleaseEnterYourEmailAddress')}
        />
      </WrapperInput>
      <WrapperInput>
        <Content marginBottom='0.4rem'>{tAuth('signIn.password')}</Content>
        <FormInputText
          name='password'
          autoComplete='new-password'
          type={showPassword ? 'text' : 'password'}
          control={control}
          label={tAuth('signIn.pleaseEnterYourPassword')}
        />
        <IconShowPassword onClick={() => setShowPassword(!showPassword)}>
          {!showPassword ? ICON_HIDE_PASSWORD : ICON_SHOW_PASSWORD}
        </IconShowPassword>
      </WrapperInput>
      <ButtonSubmit disabled={logging} type='submit' bgtheme='primary' size='medium' fullWidth>
        {tAuth('signIn.signIn')}
      </ButtonSubmit>
      <AlertDialog
        content={tAuth('signIn.thisAccountIsLoggedIntoAnotherBrowser')}
        noButton
        close={confirmLogin}
        handleAccpet={() => {
          setConfirmLogin(false);
          const params = {
            token_del_flag: '1',
            email: watch('email'),
            password: watch('password'),
          };
          onSubmit(params);
        }}
        handleCancel={() => {
          setConfirmLogin(false);
        }}
      />

      <TextForm>
        {tAuth('signIn.forgotYourPassword')}
        <Link to='/forgot-password'>{tAuth('here')}</Link>
      </TextForm>
    </FormSignIn>
  );
};

export default LoginPage;
