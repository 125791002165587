import { Checkbox } from '@mui/material';
import { AlertDialog } from 'components/Dialog';
import { FormInputText } from 'components/FromFeature/form-components';
import { TitleItem } from 'pages/SettingDisplaySite/styled';
import { useEffect, useState,useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TabsChildren from '../tabChildren';
import TabsParent from '../tabParent';
import {
  FormCreateCategoryContact,
  FormSettingCategoryContact,
  TitleCategoryContact,
} from './styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCreateCategoryContact } from './schema';
import {
  deleteSettingCategoryContact,
  getListSettingCategoryContact,
  postCreateSettingCategoryContact,
  putCreatorSettingCategoryContact,
} from 'store/actions/settingsite.action';
import { LanguageContext } from '../../../Context/translation.context';

const paramsCategoryContact = {
  name: '',
  name_en: '',
  active_flag: 0,
};

export const SettingCategoryContact = () => {
  const { listSettingCategoryContact } = useSelector((state) => state.settingsite);
  const dispatch = useDispatch();

  const { tSettingsite } = useContext(LanguageContext);

  const methods = useForm({
    mode: 'all',
    defaultValues: paramsCategoryContact,
    resolver: yupResolver(schemaCreateCategoryContact),
  });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(listSettingCategoryContact);
  }, [listSettingCategoryContact]);

  useEffect(() => {
    dispatch(getListSettingCategoryContact());
  }, []);

  const handleChangeFormChecked = (e) => {
    setValue('active_flag', e.target.checked ? 1 : 0);
  };

  const formCreateCategoryContact = (
    <FormCreateCategoryContact>
      <div className='form-create-contact'>
        <label className='label-name'>{tSettingsite("settingCategoryContact.categoryName")}</label>
        <FormInputText control={control} name='name' />
      </div>
      <div className='form-create-contact'>
        <label className='label-name'>カテゴリー名（英語）</label>
        <FormInputText control={control} name='name_en' />
      </div>
      <div className='form-create-contact'>
        <label className='label-active'>{tSettingsite("displayFlag")}</label>
        <Checkbox control={control} name='active_flag' onChange={handleChangeFormChecked} />
      </div>
    </FormCreateCategoryContact>
  );

  const handleCreateCategoryContact = (data) => {
    dispatch(postCreateSettingCategoryContact(data, getListSettingCategoryContact()));
    reset();
  };

  const handleButtonCancel = () => {
    clearErrors();
    reset();
  };

  const handleDeleteCategoryContact = (id) => {
    dispatch(deleteSettingCategoryContact(id, getListSettingCategoryContact()));
  };

  const onChangeCheckTable = (id, index, e) => {
    const idx = data.find((item) => item.id === id);

    if (idx) {
      setData((prev) => {
        const list = [...prev];
        list[index].active_flag = e.target.checked ? 1 : 0;
        return list;
      });
    }
  };

  const handleSubmitSettingContact = () => {
    const paramSubmit = [];

    data.forEach((item) => {
      paramSubmit.push({ id: item.id, active_flag: item.active_flag });
    });

    const paramPutSettingContact = {
      ids: paramSubmit,
    };

    dispatch(
      putCreatorSettingCategoryContact(paramPutSettingContact, getListSettingCategoryContact())
    );
  };

  const valueName = watch('name');

  return (
    <FormSettingCategoryContact>
      <TabsParent />
      <TabsChildren />
      <TitleCategoryContact>
        <span>{tSettingsite("settingCategoryContact.inquiryCategoryList")}</span>
      </TitleCategoryContact>

      <div className='table-category'>
        <div className='thead-table'>
          <div className='thead'>
            <div className='row row-stt'>{tSettingsite("settingCategoryContact.displayRank")}</div>
            <div className='row row-name'>{tSettingsite("settingCategoryContact.category")}</div>
            <div className='row row-name'>{tSettingsite("settingCategoryContact.category")}（英語）</div>
            <div className='row row-checkbox'>{tSettingsite("settingCategoryContact.displayFlag")}</div>
            <div className='row row-action'></div>
          </div>
        </div>
        <div className='tbody-table'>
          {data?.length
            ? data.map((item, index) => {
                return (
                  <div key={index} className='tbody'>
                    <div className='row row-stt'>{index + 1}</div>
                    <div className='row row-name'>{item.name}</div>
                    <div className='row row-name'>{item?.name_en || ''}</div>
                    <div className='row row-checkbox'>
                      <input
                        type='checkbox'
                        name='active_flag'
                        id='active_flag'
                        checked={!!item?.active_flag}
                        onChange={(e) => onChangeCheckTable(item.id, index, e)}
                      />
                    </div>
                    <div className='row row-action'>
                      <TitleItem borderRight minWidth='100px'>
                        <AlertDialog
                          addDelete
                          title={tSettingsite('delete')}
                          content={tSettingsite('settingCategoryContact.deleteCategory')}
                          handleAccpet={() => handleDeleteCategoryContact(item.id)}
                          propsButton={{
                            min: '60px',
                            height: '30px',
                            startIcon: <DeleteIcon />,
                            variant: 'text',
                          }}
                        />
                      </TitleItem>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>

      <div className='form-action'>
        <AlertDialog
          contentElement={formCreateCategoryContact}
          title={tSettingsite("settingCategoryContact.addInquiryCategory")}
          updateStatus={tSettingsite("addLine")}
          handleAccpet={handleSubmit(handleCreateCategoryContact)}
          handleCancel={handleButtonCancel}
          buttonConfimrNobutton={tSettingsite("toAdd")}
          buttonCacnel={tSettingsite("cancel")}
          styleDialog={{ width: '500px' }}
          propsButtonNo={{
            disabled: !valueName.length || Object.keys(errors).length ? true : false,
          }}
        />

        <AlertDialog
          title={tSettingsite("setting")}
          content={tSettingsite("wantToSetIt")}
          propsButton={{ bgtheme: 'secondary' }}
          updateStatus={tSettingsite("setting")}
          handleAccpet={handleSubmitSettingContact}
        />
      </div>
    </FormSettingCategoryContact>
  );
};
