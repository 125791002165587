import { useForm } from 'react-hook-form';
import React, { useState, createRef, useEffect, useContext } from 'react';
import { Input, ButtonSubmit, FormSignIn } from './styled';
import { useNavigate, useParams } from 'react-router-dom';
import { BACKGROUND_LOGIN } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Content,
  FormInputText,
  WrapperContent,
} from 'components/FromFeature/form-components';
import { getPermissionAuth, getProfileAuth, verifyLogin } from 'store/actions/auth.action';
import { PATH_URL } from 'constants/routes/settingUrl';
import { yupFaCode } from 'constants';
import { LanguageContext } from 'Context/translation.context';
export const VerificationEmail = () => {
  const { tAuth } = useContext(LanguageContext)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      fa_code: '',
    },
    resolver: yupResolver(
      yup.object().shape({
      ...yupFaCode() ,
      })
    ),
  });
  const { email } = useSelector((state) => state.auth);
  const { handleSubmit, control, setValue } = methods;
  useEffect(() => {
    email && setValue('email', email);
    !email && navigate('/sign-in');
  }, []);
  const onSubmit = (data) => {
    dispatch(
      verifyLogin(data, () =>
        dispatch(
          getProfileAuth(
            (data) => {
              data.data.user_type === 6
                ? dispatch(
                    getPermissionAuth(
                      data.data.id,
                      () => navigate(`/${PATH_URL.REPORT}`),
                      () => navigate(`/${PATH_URL.REPORT}`)
                    )
                  )
                : navigate(`/${PATH_URL.REVENUE}`);
            },
            () => navigate('/sign-in')
          ),
          () => navigate('/sign-in')
        )
      )
    );
  };
  return (
    <FormSignIn
      onSubmit={handleSubmit(onSubmit)}
      bgi={BACKGROUND_LOGIN}
      xs={11}
      sm={9}
      md={5}
      lg={4}
      xl={3}
      title={tAuth('verificationEmail.emailAuthenticationScreen')}
      item>
      <p style={{marginBottom: '8px'}}>{tAuth('verificationEmail.pleaseEnterTheVerificationCodeSentToYourRegisteredEmailAddress')}</p>
      <WrapperContent>
        <Content marginBottom='1.5rem' paddingTop='4px' minWidth='85px'>
        {tAuth('verificationEmail.confirmationCode')}
        </Content>
        <FormInputText type='number' name='fa_code' control={control} />
      </WrapperContent>
      <ButtonSubmit
        type='submit'
        variant='contained'
        bgtheme='primary'
        size='medium'
        fullWidth>
        {tAuth('verificationEmail.signIn')}
      </ButtonSubmit>
    </FormSignIn>
  );
};

export default VerificationEmail;
