import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';

const pointAPI = {
  getRequestPoints: (...data) => `${API_URL_V2}/request-points?${stringify(...data)}`,
  putApprovalPoint: (...data) => `${API_URL_V2}/approval-points?${stringify(...data)}`,
  postRefundPoint: (...data) => `${API_URL_V2}/payments/refund?${stringify(...data)}`,
  getDetailRequestPoint: (id) => `${API_URL_V2}/request-points/${id}`,
};

export { pointAPI };
