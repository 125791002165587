import { ButtonSubmit, WrapperButton } from 'components/FromFeature/form-components';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FeatureImage, ImageShow, ImageWraper, WrapperViewImage } from './styled';
import { ADD_IMAGE } from 'constants';

export const UIListImage = ({
  imageList,
  onImageUpload,
  onImageUpdate,
  onImageRemove,
  onImageRemoveAll,
  privateMessage = '',
  privateWrapper = '',
}) => {
  return (
    <>
      <WrapperViewImage width={privateWrapper}>
        {imageList.map((item, index) => {
          return (
            <ImageWraper key={index} width={privateMessage}>
              <FeatureImage pos='left' onClick={() => onImageUpdate(index)}>
                <DriveFileRenameOutlineIcon />
              </FeatureImage>
              <FeatureImage pos='right' onClick={() => onImageRemove(index)}>
                <DeleteForeverIcon />
              </FeatureImage>
              <ImageShow src={item?.data_url} alt='' />
            </ImageWraper>
          );
        })}
        {imageList.length < 10 && (
          <ImageWraper pointer onClick={onImageUpload} width={privateMessage}>
            <ImageShow src={ADD_IMAGE} alt='add img' />
          </ImageWraper>
        )}
      </WrapperViewImage>
      <WrapperButton justify='end'>
        <ButtonSubmit
          bgtheme='primary'
          variant='contained'
          height='30px'
          min='60px'
          onClick={onImageRemoveAll}
          startIcon={<ClearIcon />}>
          キャンセル
        </ButtonSubmit>
      </WrapperButton>
    </>
  );
};
