/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  listRequestPoints: [],
  detailRequestPoint: {},
  pagination: {},
  not_granted: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_REQUEST_POINT_REQUEST:
      return {
        ...state,
        listRequestPoints: [],
        pagination: {},
        not_granted: 0,
      };
    case TYPES.GET_DETAIL_REQUEST_POINT_REQUEST:
      return {
        ...state,
        detailRequestPoint: {},
      };
    case TYPES.PUT_APPROVE_REQUEST_POINT_REQUEST:
    case TYPES.POST_APPROVE_REFUND_POINT_REQUEST:
      return {
        ...state,
        detailRequestPoint: {},
      };
    case TYPES.GET_LIST_REQUEST_POINT_SUCCESS:
      return {
        ...state,
        listRequestPoints: action.data.data,
        pagination: action.data.pagination,
        not_granted: action.data.not_granted,
      };
    case TYPES.GET_DETAIL_REQUEST_POINT_SUCCESS:
      return {
        ...state,
        detailRequestPoint: action.data.data,
      };
    case TYPES.PUT_APPROVE_REQUEST_POINT_SUCCESS:
      return {
        ...state,
        detailRequestPoint: action.data.data,
      };
    case TYPES.POST_APPROVE_REFUND_POINT_SUCCESS:
      return {
        ...state,
        detailRequestPoint: action.data.data,
      };
    case TYPES.GET_LIST_REQUEST_POINT_FAILED:
    case TYPES.GET_DETAIL_REQUEST_POINT_FAILED:
    case TYPES.PUT_APPROVE_REQUEST_POINT_FAILED:
    case TYPES.POST_APPROVE_REFUND_POINT_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
