import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

const MediaWrapper = ({ children }) => <ThemeProvider theme={theme} children={children} />;

const SIZE = {
  XXL: 1441, // DESKTOP L
  XL: 1200, // DESKTOP M
  XL_1: 1044, // DESKTOP CUSTOM-1
  LG: 992, // TABLET L
  MD: 768, // TABLET M
  SM: 576, // Mobile L
  XS: 423, // Mobile M
};

class Media {
  static SIZE = SIZE;

  static greaterThan(windowSize) {
    return `@media only screen and (min-width: ${windowSize}px)`;
  }

  static lessThan(windowSize) {
    return `@media only screen and (max-width: ${windowSize - 1}px)`;
  }
}
const VARIABLES = {
  WIDTH_SIDEBAR: '278px',
};
export { Media, MediaWrapper, VARIABLES };

// ${Media.lessThan(Media.SIZE.MD)} {
//   .class-1,
//   .class-2 {
//     .class-1--1 {
//       flex: 0 0 50%;
//     }
//   }
// }
