import { createContext } from 'react';
import PropTypes from 'prop-types';
const FanContext = createContext();
// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_QUERY':
      return { ...state, query: action.payload };
    case 'SET_COUNT':
      return { ...state, count: action.payload };
    default:
      return state;
  }
};
const initialState = {
  query: {},
  count: 0,
};
const FanReducer = { reducer, initialState };
// PropTypes
const FanPropTypes = {
  typesMain: {
    isManage: PropTypes.bool,
    isHistoryPayment: PropTypes.bool,
    isPointHistory: PropTypes.bool,
  },
  defaultMain: {
    isManage: false,
    isHistoryPayment: false,
    isPointHistory: false,
  },
};
export { FanContext, FanReducer , FanPropTypes};
