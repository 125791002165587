/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  error: null,
  dataList: [],
  dataSaleHistory: [],
  dataPaymentHistory: [],
  detailAccount: {},
  pagination: {},
  listBanner: [],
  limitSetting: '---',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_AFFILIATOR_REQUEST:
      return {
        ...state,
        dataList: [],
        pagination: {},
      };
    case TYPES.GET_LIST_HISTORY_SALE_AFFILIATOR_REQUEST:
      return {
        ...state,
        dataSaleHistory: [],
        pagination: {},
      };
    case TYPES.GET_LIST_HISTORY_PAYMENT_AFFILIATOR_REQUEST:
      return {
        ...state,
        dataPaymentHistory: [],
        pagination: {},
      };
    case TYPES.GET_DETAIL_AFFILIATOR_REQUEST:
      return {
        ...state,
        detailAccount: {},
      };
    case TYPES.GET_LIST_BANNER_AFFILIATOR_REQUEST:
      return {
        ...state,
        listBanner: [],
        pagination: {},
      };
    case TYPES.GET_SETTING_LIMIT_AFFILIATOR_REQUEST:
      return {
        ...state,
        limitSetting: '---',
        error: null,
      };
    case TYPES.UPDATE_SETTING_LIMIT_AFFILIATOR_REQUEST:
    case TYPES.CHANGE_STATUS_AFFILIATOR_REQUEST:
      return {
        ...state,
      };
    case TYPES.GET_DETAIL_AFFILIATOR_SUCCESS:
      return {
        ...state,
        detailAccount: action.data.data,
      };
    case TYPES.GET_LIST_AFFILIATOR_SUCCESS:
      return {
        ...state,
        dataList: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_HISTORY_SALE_AFFILIATOR_SUCCESS:
      return {
        ...state,
        dataSaleHistory: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_HISTORY_PAYMENT_AFFILIATOR_SUCCESS:
      return {
        ...state,
        dataPaymentHistory: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_BANNER_AFFILIATOR_SUCCESS:
      return {
        ...state,
        listBanner: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_SETTING_LIMIT_AFFILIATOR_SUCCESS:
    case TYPES.UPDATE_SETTING_LIMIT_AFFILIATOR_SUCCESS:
      return {
        ...state,
        error: null,
        limitSetting: action.data.data[0]?.min_price,
      };

    case TYPES.CHANGE_STATUS_AFFILIATOR_SUCCESS:
      return {
        ...state,
      };

    case TYPES.GET_LIST_AFFILIATOR_FAILED:
    case TYPES.GET_LIST_HISTORY_SALE_AFFILIATOR_FAILED:
    case TYPES.GET_LIST_HISTORY_PAYMENT_AFFILIATOR_FAILED:
    case TYPES.GET_DETAIL_AFFILIATOR_FAILED:
    case TYPES.GET_LIST_BANNER_AFFILIATOR_FAILED:
    case TYPES.GET_SETTING_LIMIT_AFFILIATOR_FAILED:
    case TYPES.UPDATE_SETTING_LIMIT_AFFILIATOR_FAILED:
    case TYPES.CHANGE_STATUS_AFFILIATOR_FAILED:
      return {
        ...state,
        error: action.data.error.message,
      };
    default:
      return state;
  }
};
