import { updateStatus } from 'constants';
import { callApi, logSuccess } from '../../utils/request';
import { TYPES } from './index';
import {
  affiliatorAPI,
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
} from 'apis';
import { findMess } from 'constants';

const getListAffiliator = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.getListAffiliator(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_AFFILIATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_AFFILIATOR_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListBannerAffiliator = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_BANNER_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.getListBannerAffiliator(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_BANNER_AFFILIATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_BANNER_AFFILIATOR_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const getListHistorySaleAffiliator = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_HISTORY_SALE_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.getListHistorySaleAffiliator(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_HISTORY_SALE_AFFILIATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_HISTORY_SALE_AFFILIATOR_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const getListHistoryPaymentAffiliator = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_HISTORY_PAYMENT_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.getListHistoryPaymentAffiliator(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_HISTORY_PAYMENT_AFFILIATOR_SUCCESS, response)
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_HISTORY_PAYMENT_AFFILIATOR_FAILED, {
            error: error.errors,
          })
        );
      },
      dispatch
    );
  };
};
const getDetailAffiliator = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.getDetailAffiliator(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_DETAIL_AFFILIATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_AFFILIATOR_FAILED, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};
const updateDetailAffiliator = (data, id, navigate, errParams) => {
  const params = { ...data, created_at: undefined };
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_DETAIL_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.updateDetailAffiliator(id),
      configParamsPUTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_DETAIL_AFFILIATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_DETAIL_AFFILIATOR_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_AFFILIATOR',
      null,
      () => {},
      errParams
    );
  };
};
const deleteAccountAffiliator = (id, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.deleteAffiliator(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_AFFILIATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_AFFILIATOR_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_AFFILIATOR'
    );
  };
};
const affiliatorGetSettingLimit = () => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_SETTING_LIMIT_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.settingPaymentAffiliator,
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_SETTING_LIMIT_AFFILIATOR_SUCCESS, response));
      },
      (error) => {
        console.log(error.errors);
        dispatch(
          updateStatus(TYPES.GET_SETTING_LIMIT_AFFILIATOR_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const affiliatorChangeSettingLimit = (data, callback) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_SETTING_LIMIT_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.settingPaymentAffiliator,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_SETTING_LIMIT_AFFILIATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.UPDATE_SETTING_LIMIT_AFFILIATOR_FAILED, { error: error.errors })
        );
      },
      dispatch,
      'UPDATE_SETTING_LIMIT_AFFILIATOR',
      null,
      callback
    );
  };
};
const approveAffiliatorAccount = (id, func) => {
  return async (dispatch, s) => {
    const statusUser = s().affiliator.detailAccount?.status;
    dispatch(updateStatus(TYPES.CHANGE_STATUS_AFFILIATOR_REQUEST));
    callApi(
      affiliatorAPI.updateStatusAffiliator(id),
      configParamsPUTJson(s, null),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.CHANGE_STATUS_AFFILIATOR_SUCCESS, response));
        logSuccess(findMess(statusUser, response.data.status));
        await func();
      },
      (error) => {
        dispatch(updateStatus(TYPES.CHANGE_STATUS_AFFILIATOR_FAILED, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};
export {
  getListAffiliator,
  getDetailAffiliator,
  updateDetailAffiliator,
  deleteAccountAffiliator,
  getListHistorySaleAffiliator,
  getListHistoryPaymentAffiliator,
  getListBannerAffiliator,
  affiliatorGetSettingLimit,
  affiliatorChangeSettingLimit,
  approveAffiliatorAccount,
};
