import { css } from 'styled-components';
import { defaultColor } from './variables/color';
const ResetMUI = css`
  .MuiInputBase-input.Mui-disabled {
    font-weight: 500;
    color: #6b6b6b;
  }
  .MuiTab-root {
    max-width: 500px !important;
  }

  .MuiTooltip-popper {
    line-height: 25px;
  }

  .MuiTooltip-tooltip {
    font-size: 14px !important;
    white-space: pre-line;
  }

  .MuiFormHelperText-root.Mui-error {
    font-size: 13px;
    margin-left: 0;
  }

  .MuiIconButton-root:hover {
    border-radius: 0;
    margin: 0;
  }
  .MuiPaginationItem-root {
    border-radius: 3px !important;
    height: 32px !important;
    width: 32px !important;
    display: flex;
    align-items: center;
    &.Mui-selected {
      background-color: ${defaultColor['primary']} !important;
      color: ${defaultColor['white']} !important;
    }
  }
  .MuiDialogContent-root{
    overflow-x: hidden;
  }
`;
export { ResetMUI };
