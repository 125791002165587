import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';

export const FormInputRadio = ({ name, control, label, options = [] , ...props}) => {
  const generateRadioOptions = () => {
    return options.map((singleOption, index) => (
      <FormControlLabel
        key={index}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <RadioGroup
            style={{
              flexDirection: 'row',
            }}
            value={value}
            onChange={e => onChange(e)}>
            {generateRadioOptions()}
          </RadioGroup>
        )}
        {...props}
      />
    </FormControl>
  );
};
