import { FormInputText } from 'components/FromFeature/form-components';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Content, WrapperContent } from '../../components/FromFeature/form-element/styled';
import { TabsAgent } from './settings/tabs';
import { ButtonSubmit, WrapperButton, WrapperCard, WrapperTitleSetting } from '../../components/FromFeature/form-element/styledElement';
import { LanguageContext } from 'Context/translation.context';

const SettingAgentPage = () => {
  const { tAgency, tAdmincs } = useContext(LanguageContext)
  const defaultValues = {
    keyword: '',
  };
  const methods = useForm({ mode: 'all' , defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, watch } = methods;
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <TabsAgent />
      <WrapperCard>
        <WrapperTitleSetting>{tAgency('settingAgency.withdrawalLimitTagTitle')}</WrapperTitleSetting>
        <WrapperContent justifyContent paddingLeft='20px'>
          <Content minWidth='55px'>{tAdmincs('affiliator.lowerLimit')}</Content>
          <FormInputText isSearch width='200px' name='keyword' control={control} label={tAgency('form.company_name')} />
          <Content mgl='5px' minWidth='50px'>
            {tAdmincs('affiliator.circle')}
          </Content>
        </WrapperContent>
        <WrapperContent justifyContent paddingLeft='20px'>
          <Content minWidth='55px'>{tAgency('settingAgency.upperLimit')}</Content>
          <FormInputText isSearch width='200px' name='keyword' control={control} label={tAgency('form.company_name')}  />
          <Content mgl='5px' minWidth='50px'>
            {tAdmincs('affiliator.circle')}
          </Content>
        </WrapperContent>
        <WrapperButton justifyContent>
          <ButtonSubmit onClick={handleSubmit(onSubmit)} variant='contained' bgtheme='primary'>
            {tAdmincs('affiliator.setting')}
          </ButtonSubmit>
        </WrapperButton>
      </WrapperCard>
    </>
  );
};
export { SettingAgentPage };
export default SettingAgentPage;
