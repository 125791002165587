const paramsDetail = {
  birthday: '',
  email: '',
  full_name: '',
  gender: '',
  name_romaji: '',
  status: '',
};
const paramsEdit = {
  email: '',
  full_name: '',
  birthday: '',
  gender: '',
  name_romaji: '',
};
export { paramsEdit, paramsDetail };
