import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import { FormEdit } from 'pages/ReportPage';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
const PointPage = lazy(() =>
  lazyRoute(() =>
    import('pages/ReportPage').then((module) => ({
      default: module.ReportsPage,
    }))
  )
);
export const listRouteReportPage = [
  {
    path: `${PATH_URL.REPORT}/manage-reports-users`,
    element: <PointPage isCreator />,
    title: '通報管理画面',
    currentPath: `/${PATH_URL.REPORT}/manage-reports-users`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  // {
  //   path: `${PATH_URL.REPORT}/manage-reports-fan`,
  //   element: <PointPage isFan />,
  //   title: '通報管理画面',
  //   currentPath: `/${PATH_URL.REPORT}/manage-reports-fan`,
  //   backPath: `/${PATH_URL.REVENUE}`,
  // },
  {
    path: `${PATH_URL.REPORT}/manage-reports-users/detail/:id`,
    element: <FormEdit isCreator />,
    title: 'ユーザーの通報の詳細画面',
    currentPath: `/${PATH_URL.REPORT}/manage-reports-users/detail`,
    backPath: `/${PATH_URL.REPORT}/manage-reports-users`,
  },
  // {
  //   path: `${PATH_URL.REPORT}/manage-reports-fan/detail/:id`,
  //   element: <FormEdit isFan />,
  //   title: 'クリエイター→ファンの通報の詳細画面',
  //   currentPath: `/${PATH_URL.REPORT}/manage-reports-fan/detail`,
  //   backPath: `/${PATH_URL.REPORT}/manage-reports-fan`,
  // },
  {
    path: `${PATH_URL.REPORT}`,
    element: <Navigate to='manage-reports-users' replace />,
    currentPath: `/${PATH_URL.REPORT}/manage-reports-users`,
  },
];
