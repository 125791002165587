import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  bannerAPI,
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
} from 'apis';

const getListBanner = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_BANNER_REQUEST));
    callApi(
      bannerAPI.getListBanner(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_BANNER_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_BANNER_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};

const getDetailBanner = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_BANNER_REQUEST));
    callApi(
      bannerAPI.getDetailBanner(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_DETAIL_BANNER_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_BANNER_FAILED, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};
const updateDetailBanner = (data, id, navigate) => {
  const params = { ...data, created_at: undefined };
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_DETAIL_BANNER_REQUEST));
    callApi(
      bannerAPI.updateDetailBanner(id),
      configParamsPUTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_DETAIL_BANNER_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_DETAIL_BANNER_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_BANNER'
    );
  };
};
const deleteBanner = (id, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_BANNER_REQUEST));
    callApi(
      bannerAPI.deleteBanner(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_BANNER_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_BANNER_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_BANNER',
      null,
      navigate
    );
  };
};
const createBanner = (data, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATE_BANNER_REQUEST));
    callApi(
      bannerAPI.createBanner,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(updateStatus(TYPES.CREATE_BANNER_SUCCESS, response));
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_BANNER_FAILED, { error: error.errors }));
      },
      dispatch,
      'CREATE_BANNER'
    );
  };
};
export { getListBanner, getDetailBanner, updateDetailBanner, deleteBanner, createBanner };
