import {
  FormCreatSettingEffect,
  FormSettingEffect,
  FormSettingImg,
  TitleSettingEffect,
} from './styled';
import { Checkbox } from '@mui/material';
import { LanguageContext } from '../../../Context/translation.context';
import { AlertDialog } from 'components/Dialog';
import { FormInputText } from 'components/FromFeature/form-components';
import { TitleItem } from 'pages/SettingDisplaySite/styled';
import { useEffect, useState,useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TabsParent from '../tabParent';
import DeleteIcon from '@mui/icons-material/Delete';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCreateSettingEffect } from './schema';
import StaticImg from './StaticImg';
import StaticGif from './StaticGif';
import {
  deleteSettingEffect,
  deleteSettingMessageDonate,
  getListSettingEffect,
  getListSettingMessageDonate,
  postCreateSettingEffect,
  postCreateSettingMessageDonate,
  putCreatorSettingEffect,
  putCreatorSettingMessageDonate,
} from 'store/actions/settingsite.action';
import { EfectCreator } from './effectCreator';

const paramsEffect = {
  name: '',
  active_flag: 0,
  point: '',
  icon: '',
  gift: '',
};

export const SettingEffectStamp = () => {
  const { listSettingEffect, listSettingMessageDonate } = useSelector(
    (state) => state.settingsite
  );
  const dispatch = useDispatch();
  const methods = useForm({
    mode: 'all',
    defaultValues: paramsEffect,
    resolver: yupResolver(schemaCreateSettingEffect),
  });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const [data, setData] = useState([]);
  const [dataDonate, setDataDonate] = useState([]);
  const [showImg, setShowImg] = useState('');
  const [showGif, setShowGif] = useState('');
  const [showImgMessageDonate, setShowImgMessageDonate] = useState('');

  useEffect(() => {
    setData(listSettingEffect);
    setDataDonate(listSettingMessageDonate);
  }, [listSettingEffect, listSettingMessageDonate]);

  useEffect(() => {
    dispatch(getListSettingEffect({ sort_setting_category: 1 }));
    dispatch(getListSettingMessageDonate({ sort_setting_category: 1 }));
  }, []);

  const handleChangeFormChecked = (e) => {
    setValue('active_flag', e.target.checked ? 1 : 0);
  };

  const handleChangeFormCheckedMessageDonate = (e) => {
    setValue('active_flag', e.target.checked ? 1 : 0);
  };

  const handleShowIcon = (img) => {
    setShowImg(img);
  };

  const handleShowIconMessageDonate = (img) => {
    setShowImgMessageDonate(img);
  };

  const handleShowGif = (img) => {
    setShowGif(img);
  };

  const { tSettingsite } = useContext(LanguageContext);
  
  const formCreateSettingGif = (
    <FormSettingImg>
      <div>
        {Object.keys(StaticGif)?.map((item, index) => {
          return (
            <div tabIndex={index} key={item} onClick={() => handleShowGif(item)}>
              <img
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  marginLeft: '10px',
                }}
                src={StaticGif[item]}
                alt=''
              />
            </div>
          );
        })}
      </div>
    </FormSettingImg>
  );

  const formCreateSettingImg = (
    <FormSettingImg>
      <div>
        {Object.keys(StaticImg)?.map((item, index) => {
          return (
            <div tabIndex={index} key={item} onClick={() => handleShowIcon(item)}>
              <img
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  marginLeft: '10px',
                }}
                src={StaticImg[item]}
                alt=''
              />
            </div>
          );
        })}
      </div>
    </FormSettingImg>
  );

  const formCreateSettingImgMessageDonate = (
    <FormSettingImg>
      <div>
        {Object.keys(StaticImg)?.map((item, index) => {
          return (
            <div tabIndex={index} key={item} onClick={() => handleShowIconMessageDonate(item)}>
              <img
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  marginLeft: '10px',
                }}
                src={StaticImg[item]}
                alt=''
              />
            </div>
          );
        })}
      </div>
    </FormSettingImg>
  );

  const handleCreateSettingImg = () => {
    setShowImg(showImg);
    setValue('icon', showImg);
  };

  const handleCreateSettingImgMessageDonate = () => {
    setShowImgMessageDonate(showImgMessageDonate);
    setValue('icon', showImgMessageDonate);
  };

  const handleCreateSettingGif = () => {
    setShowGif(showGif);
    setValue('gift', showGif);
  };

  const formCreateSettingEffect = (
    <FormCreatSettingEffect>
      <div className='form-create-setting-effect'>
        <div className='label-title'>{tSettingsite("settingEffectStamp.effectStampName")}</div>
        <FormInputText className='input' control={control} name='name' />
      </div>
      <div className='form-create-setting-effect'>
        <div className='label-title'>{tSettingsite("points")}</div>
        <FormInputText
          className='input'
          control={control}
          type='number'
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
          }}
          name='point'
        />
      </div>
      <div className='form-create-setting-effect btn-img'>
        <div className='label-title-icon'>{tSettingsite("settingEffectStamp.stamp")}</div>
        <div className='icon'>
          <AlertDialog
            contentElement={formCreateSettingImg}
            title={tSettingsite("settingEffectStamp.selectStampImage")}
            updateStatus={tSettingsite("settingEffectStamp.imageSelection")}
            handleAccpet={handleCreateSettingImg}
            handleCancel={() => setShowImg('')}
            propsButtonNo={{
              disabled: !showImg.length ? true : false,
            }}
          />
          {!!showImg.length ? <div>{showImg}</div> : ''}
        </div>
      </div>
      <div className='form-create-setting-effect btn-img'>
        <div className='label-title-icon'>{tSettingsite("settingEffectStamp.effect")}</div>
        <div className='gift'>
          <AlertDialog
            contentElement={formCreateSettingGif}
            title={tSettingsite("settingEffectStamp.selectGIFImage")}
            updateStatus={tSettingsite("settingEffectStamp.selectionGIF")}
            handleAccpet={handleCreateSettingGif}
            handleCancel={() => setShowGif('')}
            propsButtonNo={{
              disabled: !showGif.length ? true : false,
            }}
          />
          {!!showGif.length ? <div>{showGif}</div> : ''}
        </div>
      </div>
      <div className='form-create-setting-effect'>
        <div className='label-title-active'>{tSettingsite("displayFlag")}</div>
        <Checkbox
          className='input-checkbox'
          control={control}
          name='active_flag'
          onChange={handleChangeFormChecked}
        />
      </div>
    </FormCreatSettingEffect>
  );

  const formCreateSettingMessageDonate = (
    <FormCreatSettingEffect>
      <div className='form-create-setting-effect'>
        <div className='label-title'>{tSettingsite("settingEffectStamp.effectStampName")}</div>
        <FormInputText className='input' control={control} name='name' />
      </div>
      <div className='form-create-setting-effect'>
        <div className='label-title'>{tSettingsite("points")}</div>
        <FormInputText
          className='input'
          control={control}
          type='number'
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
          }}
          name='point'
        />
      </div>
      <div className='form-create-setting-effect btn-img'>
        <div className='label-title-icon'>{tSettingsite("stamp")}</div>
        <div className='icon'>
          <AlertDialog
            contentElement={formCreateSettingImgMessageDonate}
            title={tSettingsite("settingEffectStamp.selectStampImage")}
            updateStatus={tSettingsite("settingEffectStamp.imageSelection")}
            handleAccpet={handleCreateSettingImgMessageDonate}
            handleCancel={() => setShowImgMessageDonate('')}
            propsButtonNo={{
              disabled: !showImgMessageDonate.length ? true : false,
            }}
          />
          {!!showImgMessageDonate.length ? <div>{showImgMessageDonate}</div> : ''}
        </div>
      </div>
      <div className='form-create-setting-effect'>
        <div className='label-title-active'>{tSettingsite("displayFlag")}</div>
        <Checkbox
          className='input-checkbox'
          control={control}
          name='active_flag'
          onChange={handleChangeFormCheckedMessageDonate}
        />
      </div>
    </FormCreatSettingEffect>
  );

  const handleCreateSettingEffect = (data) => {
    dispatch(
      postCreateSettingEffect(data, getListSettingEffect({ sort_setting_category: 1 }))
    );
    reset();
    setShowImg('');
    setShowGif('');
  };

  const handleCreateSettingMessageDonate = (data) => {
    dispatch(
      postCreateSettingMessageDonate(
        data,
        getListSettingMessageDonate({ sort_setting_category: 1 })
      )
    );
    reset();
    setShowImgMessageDonate('');
  };

  const handleDeleteSettingEffect = (id) => {
    dispatch(deleteSettingEffect(id, getListSettingEffect({ sort_setting_category: 1 })));
  };

  const handleDeleteSettingMessageDonate = (id) => {
    dispatch(
      deleteSettingMessageDonate(id, getListSettingMessageDonate({ sort_setting_category: 1 }))
    );
  };

  const onChangeCheckTable = (id, index, e) => {
    const idx = data.find((item) => item.id === id);

    if (idx) {
      setData((prev) => {
        const list = [...prev];
        list[index].active_flag = e.target.checked ? 1 : 0;
        return list;
      });
    }
  };

  const onChangeCheckTableMessageDonate = (id, index, e) => {
    const idx = dataDonate.find((item) => item.id === id);

    if (idx) {
      setDataDonate((prev) => {
        const list = [...prev];
        list[index].active_flag = e.target.checked ? 1 : 0;
        return list;
      });
    }
  };

  const handleCancelSettingEffect = () => {
    reset();
    setShowImg('');
    setShowGif('');
  };

  const handleCancelSettingMessageDonate = () => {
    reset();
    setShowImgMessageDonate('');
  };

  const handleClickOpenDonate = () => {
    reset();
    setShowImgMessageDonate('');
  }

  const handleClickOpen = () => {
    reset();
    setShowImg('');
    setShowGif('');
  }

  const handleSubmitSettingEffect = () => {
    const paramSubmit = [];

    data.forEach((item) => {
      paramSubmit.push({
        id: item.id,
        name: item.name,
        active_flag: item.active_flag,
        icon: item.icon,
        gift: item.gift,
        point: item.point,
      });
    });

    const paramPutSettingEffect = {
      ids: paramSubmit,
    };

    dispatch(
      putCreatorSettingEffect(
        paramPutSettingEffect,
        getListSettingEffect({ sort_setting_category: 1 })
      )
    );
  };

  const handleSubmitSettingMessageDonate = () => {
    const paramSubmit = [];

    dataDonate.forEach((item) => {
      paramSubmit.push({
        id: item.id,
        name: item.name,
        active_flag: item.active_flag,
        icon: item.icon,
        point: item.point,
      });
    });

    const paramPutSettingMessageDonate = {
      ids: paramSubmit,
    };

    dispatch(
      putCreatorSettingMessageDonate(
        paramPutSettingMessageDonate,
        getListSettingMessageDonate({ sort_setting_category: 1 })
      )
    );
  };

  const valueName = watch('name');
  const valuePoint = watch('point');

  return (
    <FormSettingEffect>
      <TabsParent />
      <div className='form-setting-group'>
        <div className='form-setting-point'>
          <TitleSettingEffect>
            <span>ユーザー専用 - メッセージ投げ銭用</span>
          </TitleSettingEffect>

          <div className='table-effect-stamp'>
            <div className='thead-table'>
              <div className='thead'>
                <div className='row row-stt'>{tSettingsite("displayRank")}</div>
                <div className='row row-name'>{tSettingsite("settingEffectStamp.effectStampName")}</div>
                <div className='row row-icon'>{tSettingsite("stamp")}</div>
                <div className='row row-checkbox'>{tSettingsite("displayFlag")}</div>
                <div className='row row-point'>{tSettingsite("points")}</div>
                <div className='row row-action'></div>
              </div>
            </div>
            <div className='tbody-table'>
              {dataDonate?.length
                ? dataDonate.map((item, index) => {
                    return (
                      <div key={index} className='tbody'>
                        <div className='row row-stt'>{index + 1}</div>
                        <div className='row row-name'>{item.name}</div>
                        <div className='row row-icon'>
                          <img
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              overflow: 'hidden',
                            }}
                            src={`${StaticImg[item.icon]}`}
                            alt=''
                          />
                        </div>
                        <div className='row row-checkbox'>
                          <input
                            type='checkbox'
                            name='active_flag'
                            id='active_flag'
                            checked={!!item?.active_flag}
                            onChange={(e) =>
                              onChangeCheckTableMessageDonate(item.id, index, e)
                            }
                          />
                        </div>
                        <div className='row row-point'>
                          {item.point.toLocaleString('en-US')}
                        </div>
                        <div className='row row-action'>
                          <TitleItem borderRight minWidth='100px'>
                            <AlertDialog
                              addDelete
                              title={tSettingsite("delete")}
                              content={tSettingsite("settingEffectStamp.removeThisEffect")}
                              handleAccpet={() => handleDeleteSettingMessageDonate(item.id)}
                              propsButton={{
                                min: '60px',
                                height: '30px',
                                startIcon: <DeleteIcon />,
                                variant: 'text',
                              }}
                            />
                          </TitleItem>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>

          <div>{tSettingsite("settingEffectStamp.pressingSettingButton")}</div>

          <div className='form-action'>
            <AlertDialog
              contentElement={formCreateSettingMessageDonate}
              title={tSettingsite("settingEffectStamp.addStampEffect")}
              updateStatus={tSettingsite("addLine")}
              handleAccpet={handleSubmit(handleCreateSettingMessageDonate)}
              handleCancel={handleCancelSettingMessageDonate}
              onOpen={handleClickOpenDonate}
              buttonConfimrNobutton={tSettingsite("toAdd")}
              buttonCacnel={tSettingsite("cancel")}
              propsButtonNo={{
                disabled:
                  !valueName.length ||
                  !valuePoint.length ||
                  !showImgMessageDonate.length ||
                  Object.keys(errors).length
                    ? true
                    : false,
              }}
            />

            <AlertDialog
              title={tSettingsite("setting")}
              content={tSettingsite("wantToSetIt")}
              updateStatus={tSettingsite("setting")}
              propsButton={{ bgtheme: 'secondary' }}
              handleAccpet={handleSubmitSettingMessageDonate}
            />
          </div>
        </div>

        <div className='form-setting-effect'>
          <TitleSettingEffect>
            <span>ユーザー専用 - ライブ配信投げ銭用</span>
          </TitleSettingEffect>

          <div className='table-effect-stamp'>
            <div className='thead-table'>
              <div className='thead'>
                <div className='row row-stt'>{tSettingsite("displayRank")}</div>
                <div className='row row-name'>{tSettingsite("settingEffectStamp.effectStampName")}</div>
                <div className='row row-icon'>{tSettingsite("stamp")}</div>
                <div className='row row-gift'>{tSettingsite("settingEffectStamp.effect")}</div>
                <div className='row row-checkbox'>{tSettingsite("displayFlag")}</div>
                <div className='row row-point'>{tSettingsite("points")}</div>
                <div className='row row-action'></div>
              </div>
            </div>
            <div className='tbody-table'>
              {data?.length
                ? data.map((item, index) => {
                    return (
                      <div key={index} className='tbody'>
                        <div className='row row-stt'>{index + 1}</div>
                        <div className='row row-name'>{item.name}</div>
                        <div className='row row-icon'>
                          <img
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              overflow: 'hidden',
                            }}
                            src={`${StaticImg[item.icon]}`}
                            alt=''
                          />
                        </div>
                        <div className='row row-gift'>
                          <img
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              overflow: 'hidden',
                            }}
                            src={`${StaticGif[item.gift]}`}
                            alt=''
                          />
                        </div>
                        <div className='row row-checkbox'>
                          <input
                            type='checkbox'
                            name='active_flag'
                            id='active_flag'
                            checked={!!item?.active_flag}
                            onChange={(e) => onChangeCheckTable(item.id, index, e)}
                          />
                        </div>
                        <div className='row row-point'>
                          {item.point.toLocaleString('en-US')}
                        </div>
                        <div className='row row-action'>
                          <TitleItem borderRight minWidth='100px'>
                            <AlertDialog
                              addDelete
                              title={tSettingsite("delete")}
                              content={tSettingsite("settingEffectStamp.removeThisEffect")}
                              handleAccpet={() => handleDeleteSettingEffect(item.id)}
                              propsButton={{
                                min: '60px',
                                height: '30px',
                                startIcon: <DeleteIcon />,
                                variant: 'text',
                              }}
                            />
                          </TitleItem>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>

          <div>{tSettingsite("settingEffectStamp.pressingSettingButton")}</div>

          <div className='form-action'>
            <AlertDialog
              contentElement={formCreateSettingEffect}
              title={tSettingsite("settingEffectStamp.addStampEffect")}
              updateStatus={tSettingsite("addLine")}
              handleAccpet={handleSubmit(handleCreateSettingEffect)}
              handleCancel={handleCancelSettingEffect}
              onOpen={handleClickOpen}
              buttonConfimrNobutton= {tSettingsite("toAdd")}
              buttonCacnel={tSettingsite("cancel")}
              propsButtonNo={{
                disabled:
                  !valueName.length ||
                  !valuePoint.length ||
                  !showImg.length ||
                  !showGif.length ||
                  Object.keys(errors).length
                    ? true
                    : false,
              }}
            />

            <AlertDialog
              title={tSettingsite("setting")}
              content={tSettingsite("wantToSetIt")}
              updateStatus={tSettingsite("setting")}
              propsButton={{ bgtheme: 'secondary' }}
              handleAccpet={handleSubmitSettingEffect}
            />
          </div>
        </div>
        <EfectCreator />
      </div>
    
    </FormSettingEffect>
  );
};
