import { yupEmailCreate } from 'constants';
import { yupEmail, yupRomajiName, yupFullName, yupBirthday } from 'constants';
import * as yup from 'yup';
const schemaCreate = yup.object().shape({
  ...yupFullName('氏名を入力してください。', '氏名を50文字まで入力してください。'),
  ...yupRomajiName(),
  ...yupEmailCreate(),
  ...yupBirthday(),
});

export { schemaCreate };
