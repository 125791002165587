import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import { FormEdit } from 'pages/notification/EditForm';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
const NoticationPage = lazy(() =>
  lazyRoute(() =>
    import('pages/notification').then((module) => ({
      default: module.NotificationPage,
    }))
  )
);

export const listRouteNotification = [
  {
    path: `${PATH_URL.NOTIFICATION}/manage`,
    element: <NoticationPage />,
    title: 'インフォメーション ',
    currentPath: `/${PATH_URL.NOTIFICATION}/manage`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.NOTIFICATION}/create`,
    element: <FormEdit isCreate/>,
    title: 'お知らせ新規作成 ',
    currentPath: `/${PATH_URL.NOTIFICATION}/create`,
    backPath: `${PATH_URL.NOTIFICATION}/manage`,
  },
  {
    path: `${PATH_URL.NOTIFICATION}/detail/:id`,
    element: <FormEdit isDetail/>,
    title: 'お知らせ詳細 ',
    currentPath: `/${PATH_URL.NOTIFICATION}/detail`,
    backPath: `${PATH_URL.NOTIFICATION}/manage`,
  },
  {
    path: `${PATH_URL.NOTIFICATION}/edit/:id`,
    element: <FormEdit isEdit/>,
    title: 'お知らせ編集',
    currentPath: `/${PATH_URL.NOTIFICATION}/edit`,
    backPath: `${PATH_URL.NOTIFICATION}/manage`,
  },
  {
    path: `${PATH_URL.NOTIFICATION}/duplicate/:id`,
    element: <FormEdit isDuplicate/>,
    title: 'お知らせ複製',
    currentPath: `/${PATH_URL.NOTIFICATION}/duplicate`,
    backPath: `${PATH_URL.NOTIFICATION}/manage`,
  },
  {
    path: PATH_URL.NOTIFICATION,
    currentPath: `/${PATH_URL.NOTIFICATION}/manage`,
    element: <Navigate to='manage' replace />,
  },
];
