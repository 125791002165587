import styled from 'styled-components';

const TitleCategoryReport = styled.div`
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: #fff;
  width: 100%;
  height: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
  }
`;

const FormSettingCategoryReport = styled.div`
  .table-category {
    min-width: 670px;
    margin-top: 20px;
    margin-bottom: 30px;
    border-collapse: collapse;
    border: 1px solid rgba(224, 224, 224, 1);
    .thead-table {
      .thead {
        text-align: center;
        background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
        color: #fff;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 40px;
        .row-stt {
          width: 10%;
          min-width: 90px;
        }
        .row-name {
          width: 30%;
        }
        .row-checkbox {
          width: 15%;
        }
        .row-action {
          width: 15%;
        }
      }
    }
    .tbody-table {
      .tbody {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 40px;
        .row {
          border: 1px solid rgba(224, 224, 224, 1);
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .row {
          border: 1px solid rgba(224, 224, 224, 1);
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .row-stt {
          text-align: center;
          width: 10%;
          min-width: 90px;
        }
        .row-name {
          padding-left: 5px;
          width: 30%;
          justify-content: initial;
          align-items: center;
        }
        .row-checkbox {
          width: 15%;
          text-align: center;
        }
        .row-action {
          width: 15%;
          text-align: center;
          color: red;
        }
      }
    }
  }
  .form-action {
    display: flex;
    justify-content: space-between;
  }
  input {
    width: 20px;
    height: 20px;
  }
`;

const FormCreateCategoryReport = styled.div`
  .form-create-report {
    display: flex;
    align-items: center;
    gap: 10px;
    .label-name {
      width: 30%;
    }
    .label-active {
      width: 22%;
    }
    margin-bottom: 10px;
  }
`;

export { TitleCategoryReport, FormSettingCategoryReport, FormCreateCategoryReport };
