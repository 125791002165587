import TabMenu from 'components/TabMenu';
import { PATH_URL } from 'constants/routes/settingUrl';
import { useParams } from 'react-router-dom';
const TabsCreator = () => {
  const navItems = [
    {
      id: 'アカウント情報閲覧',
      path: `/${PATH_URL.CREATOR}/account`,
      text: 'アカウント情報閲覧',
    },
    {
      id: 'ライブ配信中一覧',
      path: `/${PATH_URL.CREATOR}/play-live`,
      text: 'ライブ配信中一覧',
    },
    {
      id: '購入履歴閲覧',
      path: `/${PATH_URL.CREATOR}/purchase-history`,
      text: '購入履歴閲覧',
    },
    {
      id: 'ポイント購入履歴閲覧',
      path: `/${PATH_URL.CREATOR}/point-history`,
      text: 'ポイント購入履歴閲覧',
    },
    {
      id: '設定',
      path: `/${PATH_URL.CREATOR}/setting`,
      text: '設定',
    },
  ];

  return <TabMenu tabs={navItems} />;
};

const TabsSecond = () => {
  const { id } = useParams();
  const navItems = [
    {
      id: 'アカウント情報詳細',
      path: `/${PATH_URL.CREATOR}/user/detail/${id}`,
      text: 'アカウント情報詳細',
    },
    {
      id: '出演者情報',
      path: `/${PATH_URL.CREATOR}/contract/${id}`,
      text: '出演者情報',
    },
    {
      id: '販売履歴閲覧',
      path: `/${PATH_URL.CREATOR}/sale-history/${id}`,
      text: '販売履歴閲覧',
    },
    {
      id: '投稿履歴',
      path: `/${PATH_URL.CREATOR}/post-history/${id}`,
      text: '投稿履歴',
    },
    {
      id: 'ライブアーカイブリスト',
      path: `/${PATH_URL.CREATOR}/list-record-livestream/${id}`,
      text: 'ライブアーカイブリスト',
    },
    {
      id: '支払い履歴',
      path: `/${PATH_URL.CREATOR}/payment-history/${id}`,
      text: '支払い履歴',
    },
    {
      id: 'サブスクリプション購入履歴',
      path: `/${PATH_URL.CREATOR}/history-subscription/${id}`,
      text: 'サブスクリプション購入履歴',
    },
    {
      id: 'コンテンツ購入履歴',
      path: `/${PATH_URL.CREATOR}/history-content/${id}`,
      text: 'コンテンツ購入履歴',
    },
    {
      id: 'ポイント購入履歴',
      path: `/${PATH_URL.CREATOR}/history-points/${id}`,
      text: 'ポイント購入履歴',
    },
    {
      id: '投げ銭履歴',
      path: `/${PATH_URL.CREATOR}/history-donate/${id}`,
      text: '投げ銭履歴',
    },
  ];

  return <TabMenu tabs={navItems} />;
};
export { TabsCreator, TabsSecond };
