import { useRequireAuth } from 'hooks';
import React, { useEffect } from 'react';
import { ItemLoading, WrapperLoading, AreaLoading } from './styled';

const Loading = () => {
  const { isAuth } = useRequireAuth();
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <AreaLoading space={!isAuth && '0px'}>
      <WrapperLoading>
        {Array(9)
          .fill(0)
          .map((_, i) => (
            <ItemLoading key={i} />
          ))}
      </WrapperLoading>
    </AreaLoading>
  );
};
export { Loading };
