import WrapperTable from 'components/Table';
import React, { useContext } from 'react';

import {
  columnContent,
  columnDonate,
  columnPoint,
  columnSubscription,
} from '../settings/table';
import { TabsSecond } from '../settings/tabs';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getListFanContent,
  getListFanDonate,
  getListFanPoint,
  getListFanSubcription,
} from 'store/actions/fan.action';

import { LanguageContext } from 'Context/translation.context';
import { Content, WrapperContent } from 'components/FromFeature/form-components';

const DetailRegistration = ({ isNotDetail, type }) => {
  const { tFan } = useContext(LanguageContext);
  const { listSubscription, listContent, listPoint, listDonate, pagination } = useSelector(
    (state) => state.fan
  );
  const { id } = useParams();
  const renderTable = [
    {
      id: 1,
      type: 'subscription',
      col: columnSubscription,
      total: pagination?.total,
      list: listSubscription,
      action: (prev) => getListFanSubcription(id, prev),
      noRow: tFan('detail.result_row_1'),
      noResult: tFan('detail.result_row_1'),
    },
    {
      id: 2,
      type: 'content',
      col: columnContent,
      total: pagination?.total,
      list: listContent,
      action: (prev) => getListFanContent(id, prev),
      noRow: tFan('detail.result_row_2'),
      noResult: tFan('detail.result_row_2'),
    },
    {
      id: 3,
      type: 'point',
      col: columnPoint,
      total: pagination?.total,
      list: listPoint,
      action: (prev) => getListFanPoint(id, prev),
      noRow: tFan('detail.result_row_3'),
      noResult: tFan('detail.result_row_3'),
    },
    {
      id: 4,
      type: 'donate',
      col: columnDonate,
      total: pagination?.total,
      list: listDonate,
      action: (prev) => getListFanDonate(id, prev),
      noRow: tFan('detail.result_row_4'),
      noResult: tFan('detail.result_row_4'),
    },
  ];
  return (
    <>
      <TabsSecond />
      <WrapperContent justifyContentEnd marginBotTop={'10px'} paddingRight={'10px'}>
        <Content marginTop={'0'} marginBottom={'0'} textAlign={'end'}>
          検索結果：
        </Content>
        <div>{pagination?.total}件</div>
      </WrapperContent>
      {isNotDetail &&
        renderTable
          .filter((tp) => tp.type === type)
          .map((item) => {
            let { id, col, total, list, action, noRow, noResult } = item;
            return (
              <WrapperTable
                contentNoRow={noRow}
                contentNoResult={noResult}
                key={id}
                columns={col}
                total={total}
                listRows={list}
                action={action}
              />
            );
          })}
    </>
  );
};
export { DetailRegistration };
DetailRegistration.propTypes = {
  isDetail: PropTypes.bool,
  isNotDetail: PropTypes.bool,
  type: PropTypes.string,
};
DetailRegistration.defaultProps = {
  isDetail: false,
  isNotDetail: false,
  type: '',
};
