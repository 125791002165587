const regexPhone = /^0\d{9,10}$|^0120\d{8}$/i;
const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
const regexEmailCreate =
  /^[a-z0-9]+([\.\-_]?[a-z0-9]+)*([+][a-z0-9]+)?@[a-z0-9]+([\.\-_]?[a-z0-9]+)*(\.[a-z0-9]{2,})+$/i;
// regex only have character japanese
const regexJapanese = /^[ａ-ｚ﻿Ａ-Ｚぁ-んァ-ン一-龥ー　]+$/gu;
const regexRomanji = /^[a-zA-Z\s]+$/;
const regextNameKana = /^[ァ-ンー－　ｧ-ﾝｰ－]+$/gu;
const regexAcceptJapanese = /^[\p{L}\p{M}\s.'-]+$/gu;
const regexRomanjiAgency = /^([a-zA-Z\s.'-])+$/;
// Regex only have string number and one dot (.)
const regexNumberDot = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
export {
  regexPhone,
  regexEmail,
  regexJapanese,
  regexRomanji,
  regexNumberDot,
  regextNameKana,
  regexEmailCreate,
  regexAcceptJapanese,
  regexRomanjiAgency
};
