/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  listCampaign: [],
  pagination: {},
  loadingCampaign: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_CAMPAIGN_REQUEST:
      return {
        ...state,
        listCampaign: [],
        pagination: {},
        loadingCampaign: true
      };
    case TYPES.GET_LIST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        listCampaign: action.data.data,
        pagination: action.data.pagination,
        loadingCampaign: false
      };
    case TYPES.GET_LIST_CAMPAIGN_FAILED:
      return state;

    case TYPES.CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        //listCampaign: [],
        //pagination: {},
      };
    case TYPES.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        //listCampaign: action.data.data,
        //pagination: action.data.pagination,
      };
    case TYPES.CREATE_CAMPAIGN_FAILED:
      return state;

    case TYPES.DELETE_CAMPAIGN_REQUEST:
      return {
        ...state,
        listCampaign: [],
        pagination: {},
      };
    case TYPES.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        listCampaign: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.DELETE_CAMPAIGN_FAILED:
      return state;

    case TYPES.EDIT_CAMPAIGN_REQUEST:
      return {
        ...state,
        listCampaign: [],
        pagination: {},
      };
    case TYPES.EDIT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        listCampaign: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.EDIT_CAMPAIGN_FAILED:
      return state;

    default:
      return state;
  }
};
