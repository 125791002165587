const paramsCreate = {
  email: '',
  phone: '',
  name_romaji: '',
  full_name: '',
  company_name: '',
  company_address: '',
  department: '',
  position: '',
  commission_sales: '',
  commission_subscription: '',
  commission_livestream: '',
  commission_message: '',
};
const paramsEdit = {
  email: '',
  phone: '',
  name_romaji: '',
  full_name: '',
  company_name: '',
  created_at: '',
  company_address: '',
  department: '',
  position: '',
  commission_sales: '',
  commission_subscription: '',
  commission_livestream: '',
  commission_message: '',
  status: '',
  parent_company_name: ''
};

const paramsEdit1 = {
  email: '',
  phone: '',
  name_romaji: '',
  full_name: '',
  company_name: '',
  created_at: '',
  company_address: '',
  position: '',
  commission_sales: '',
  commission_subscription: '',
  commission_livestream: '',
  commission_message: '',
  status: '',
  parent_company_name: ''
};

export { paramsCreate, paramsEdit, paramsEdit1 };
