import { checkTypeCell, removeDash } from 'utils/utils';

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo,
});

const checkParams = (data) => {
  for (let key in data) {
    if (data[key] === '') {
      data[key] = undefined;
    } else {
      if (
        key === 'phone' ||
        key === 'commission_sales' ||
        key === 'commission_subscription' ||
        key === 'commission_livestream' ||
        key === 'commission_message'
      ) {
        data[key] = removeDash(data[key]);
      }
      if (
        key === 'start_time_from' ||
        key === 'start_time_to' ||
        key === 'end_time_from' ||
        key === 'end_time_to' ||
        key === 'from_start_time' ||
        key === 'to_start_time' ||
        key === 'from_end_time' ||
        key === 'to_end_time'
      ) {
        data[key] = checkTypeCell('date_api', data[key]) || undefined;
      }
      if (key === 'keyword') {
        let str = removeDash(data[key]);
        if (str?.match(/^[0-9]+$/)) {
          data[key] = str;
        }
      }
    }
  }
  return data;
};

export { updateStatus, checkParams };
export * from './textToStatus';
export * from './dropdown';
export * from './url';
export * from './chooseMess';
export * from './multiValue';
