import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
const PaymentPage = lazy(() =>
  lazyRoute(() =>
    import('pages/payment').then((module) => ({
      default: module.PaymentPage,
    }))
  )
);
export const listRoutePaymentPage = [
  {
    path: `${PATH_URL.PAYMENT}/users`,
    element: <PaymentPage isUser />,
    title: '支払管理',
    currentPath: `/${PATH_URL.PAYMENT}/users`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.PAYMENT}/agency`,
    element: <PaymentPage isAgency />,
    currentPath: `/${PATH_URL.PAYMENT}/agency`,
    title: '支払管理',
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.PAYMENT}/affiliator`,
    element: <PaymentPage isAffiliator />,
    title: '支払管理',
    currentPath: `/${PATH_URL.PAYMENT}/affiliator`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.PAYMENT}/history-csv`,
    element: <PaymentPage isHistoryCsv />,
    title: '支払管理',
    currentPath: `/${PATH_URL.PAYMENT}/history-csv`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.PAYMENT}`,
    element: <Navigate to='users' replace />,
    title: '支払管理',
  },
];
