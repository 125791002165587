import { yupRomajiName, yupBirthday, yupFullName, yupEmail } from 'constants';
import * as yup from 'yup';

const schemaEdit = yup.object().shape({
  ...yupEmail(),
  ...yupBirthday(),
  ...yupFullName('氏名を入力してください。', '氏名を50文字まで入力してください。'),
  ...yupRomajiName(),
});
export { schemaEdit };

