const timezone = () => {
  const moment = require('moment-timezone');
  const currentZone = moment.tz.guess();
  const day = moment.utc();
  return day.tz(currentZone).format('Z');
};
const configParamsGETJson = (token) => ({
  method: 'GET',
  headers: {
    timezone: timezone(),
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token().auth.token}`,
  },
});

const configParamsPOSTNoJson = (token, body) => ({
  method: 'POST',
  headers: {
    timezone: timezone(),
    Authorization: `Bearer ${token().auth.token}`,
  },
  body,
});
const configParamsPOSTJson = (token, body) => ({
  method: 'POST',
  headers: {
    timezone: timezone(),
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token().auth.token}`,
  },
  body: JSON.stringify(body),
});
const configParamsPUTJson = (token, body) => ({
  method: 'PUT',
  headers: {
    timezone: timezone(),
    Authorization: `Bearer ${token().auth.token}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  body: JSON.stringify(body),
});
const configParamsPUTNoJson = (token, body) => ({
  method: 'PUT',
  headers: {
    timezone: timezone(),
    Authorization: `Bearer ${token().auth.token}`,
  },
  body: body,
});

const configParamsPOSTJsonDelete = (token) => ({
  method: 'DELETE',
  headers: {
    timezone: timezone(),
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token().auth.token}`,
  },
});
export {
  configParamsGETJson,
  configParamsPOSTNoJson,
  configParamsPOSTJsonDelete,
  configParamsPUTNoJson,
  configParamsPUTJson,
  configParamsPOSTJson,
};

export * from './auth.api';
export * from './creator.api';
export * from './agent.api';
export * from './affiliator.api';
export * from './admin.api';
export * from './settingsite.api';
export * from './tag.api';
export * from './fan.api';
export * from './report.api';
export * from './banner.api';
export * from './common.api';
export * from './contact.api';
export * from './notification.api';
export * from './point.api';
export * from './revenue.api';
export * from './payment.api';
export * from './email.api';
export * from './campaign.api'
export * from './voucher.api'
export * from './chart.api'