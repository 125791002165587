export const AGENT = {
  GET_AGENT_LIST: 'GET_AGENT_LIST',
  GET_AGENT_LIST_CREATOR: 'GET_AGENT_LIST_CREATOR',
  CREATE_AGENT: 'CREATE_AGENT',
  GET_AGENT_DETAIL: 'GET_AGENT_DETAIL',
  UPDATE_AGENT: 'UPDATE_AGENT',
  DELETE_AGENT: 'DELETE_AGENT',
  GET_AGENT_SALES_HISTORY: 'GET_AGENT_SALES_HISTORY',
  GET_AGENT_PAYMENT_HISTORY: 'GET_AGENT_PAYMENT_HISTORY',
};
