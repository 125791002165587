import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';
const adminAPI = {
  getListCSAdmin: (...params) => `${API_URL_V2}/cs-admins?${stringify(...params)}`,
  createCSAdmin: `${API_URL_V2}/cs-admins`,
  updateCSAdmin: (id) => `${API_URL_V2}/cs-admins/${id}`,
  deleteCSAdmin: (id) => `${API_URL_V2}/cs-admins/${id}`,
  getDetailCSAdmin: (id) => `${API_URL_V2}/cs-admins/${id}`,
  getPermissionsCSAdmin: (id) => `${API_URL_V2}/cs-admins/${id}/permissions`,
  updatePermissionsCSAmin: (id) => `${API_URL_V2}/cs-admins/${id}/permissions`,
  getListOperationsAdmin: (...params) => `${API_URL_V2}/operations?${stringify(...params)}`,
  getDetailOperationAdmin: (id) => `${API_URL_V2}/operations/${id}`,
  createOperationAdmin: `${API_URL_V2}/operations`,
  updateOperationAdmin: (id) => `${API_URL_V2}/operations/${id}`,
  deleteOperationAdmin: (id) => `${API_URL_V2}/operations/${id}`,
  getListAccountLogManagement: (...params) => `${API_URL_V2}/logs?${stringify(...params)}`,
};

export { adminAPI };
