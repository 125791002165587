export const FAN = {
  GET_LIST_FANS: 'GET_LIST_FANS',
  GET_FAN_DETAIL: 'GET_FAN_DETAIL',
  DELETE_FAN_ACCOUNT: 'DELETE_FAN_ACCOUNT',
  UPDATE_FAN_ACCOUNT: 'UPDATE_FAN_ACCOUNT',
  GET_FAN_SUBSCRIPTION: 'GET_FAN_SUBSCRIPTION',
  GET_FAN_CONTENT: 'GET_FAN_CONTENT',
  GET_FAN_POINT: 'GET_FAN_POINT',
  GET_FAN_DONATE: 'GET_FAN_DONATE',
  GET_FAN_PAYMENT_HISTORY: 'GET_FAN_PAYMENT_HISTORY',
  GET_FAN_POINT_HISTORY: 'GET_FAN_POINT_HISTORY',
  APPROVE_FAN_ACCOUNT: 'APPROVE_FAN_ACCOUNT',
};
