import { API_URL, API_URL_GLOBAL, API_URL_GLOBAL_V2, API_URL_V2 } from 'constants';
import { stringify } from 'qs';

const commonAPI = {
  getListCountry: `${API_URL_GLOBAL}/countries`,
  getListAgency: (...props) => `${API_URL_V2}/agencies?${stringify(...props)}`,
  getListAffiliate: (...props) => `${API_URL}/common/affiliates?${stringify(...props)}`,
  getListCreator: (...props) => `${API_URL_V2}/search-users?${stringify(...props)}`,
  getListFan: (...props) => `${API_URL}/common/fans?${stringify(...props)}`,
  getListReportCategory: (...props) =>
    `${API_URL_GLOBAL_V2}/report-categories?${stringify(...props)}`,
};
export { commonAPI };
