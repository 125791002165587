import { ButtonSubmit } from 'components/FromFeature/form-components';
import React, { useCallback, useEffect } from 'react';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { pushNotify } from 'components/Notification';

export const VideoInput = ({ setVideos, height }) => {
  const inputRef = React.useRef();
  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (
      file?.name.endsWith('.mp4') ||
      file?.name.endsWith('.MP4') ||
      file?.name.endsWith('.mov') ||
      file?.name.endsWith('.MOV') ||
      file?.name.endsWith('.wmv') ||
      file?.name.endsWith('.WMV')
    ) {
      if (file?.size < 1 * 1024 * 1024 * 1024) {
        setVideos(file);
      } else {
        pushNotify('error', '動画は1GB以下に選択してください。');
      }
    } else {
      pushNotify('error', 'wmv, mp4, movの動画をアップロードしてください。');
    }
  }, []);

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        className='VideoInput_input'
        type='file'
        onChange={handleFileChange}
        accept='video/*'
        hidden
      />
      <ButtonSubmit
        mgl='5px'
        margin='5px'
        min='55px'
        bgtheme='primary'
        variant='contained'
        height={height}
        onClick={handleChoose}>
        {<VideoLibraryIcon />}
      </ButtonSubmit>
    </>
  );
};
