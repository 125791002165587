/* eslint-disable import/extensions */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const composeEnhancers =
  typeof window === 'object' &&
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const middleware = [thunk];

// if (process.env.NODE_ENV !== 'production') {
//   middleware.push(createLogger());
// }

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

