const VoucherIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.25067 11.8375C1.9686 11.8955 1.71512 12.0489 1.53288 12.2719C1.35063 12.4948 1.25074 12.7738 1.25 13.0618L1.25 15C1.25038 15.3314 1.3822 15.6491 1.61654 15.8835C1.85087 16.1178 2.1686 16.2496 2.5 16.25L17.5 16.25C17.8314 16.2496 18.1491 16.1178 18.3835 15.8835C18.6178 15.6491 18.7496 15.3314 18.75 15L18.75 13.0618C18.7493 12.7738 18.6494 12.4948 18.4671 12.2719C18.2849 12.0489 18.0314 11.8955 17.7493 11.8375C17.326 11.7508 16.9456 11.5206 16.6724 11.1858C16.3992 10.851 16.25 10.4321 16.25 10C16.25 9.56788 16.3992 9.14902 16.6724 8.81421C16.9456 8.47941 17.326 8.2492 17.7493 8.1625C18.0314 8.10448 18.2849 7.95109 18.4671 7.72812C18.6494 7.50515 18.7493 7.22622 18.75 6.93825L18.75 5C18.7496 4.6686 18.6178 4.35087 18.3835 4.11654C18.1491 3.8822 17.8314 3.75038 17.5 3.75L2.5 3.75C2.1686 3.75038 1.85088 3.88219 1.61654 4.11653C1.3822 4.35087 1.25038 4.66859 1.25 5L1.25 6.93825C1.25074 7.22622 1.35063 7.50515 1.53288 7.72812C1.71512 7.95108 1.9686 8.10448 2.25067 8.1625C2.674 8.2492 3.05441 8.4794 3.3276 8.81421C3.60079 9.14902 3.74999 9.56788 3.74999 10C3.74999 10.4321 3.60079 10.851 3.3276 11.1858C3.05441 11.5206 2.674 11.7508 2.25067 11.8375ZM17.5 6.93764C16.7942 7.08174 16.1599 7.46522 15.7044 8.0232C15.2488 8.58117 15 9.27937 15 9.99969C15 10.72 15.2488 11.4182 15.7044 11.9762C16.1599 12.5342 16.7942 12.9176 17.5 13.0618L17.5 15L13.125 15L13.125 5L17.5 5L17.5 6.93764Z'
        fill='white'
      />
    </svg>
  );
};

export default VoucherIcon;
