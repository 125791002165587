export const SETTINGSITE = {
  GET_LIST_PACKAGE_PAYMENT: 'GET_LIST_PACKAGE_PAYMENT',
  DELETE_PACKAGE_PAYMENT: 'DELETE_PACKAGE_PAYMENT',
  CREATE_PACKAGE_PAYMENT: 'CREATE_PACKAGE_PAYMENT',
  PUT_SETTING_PACKAGE_PAYMENT: 'PUT_SETTING_PACKAGE_PAYMENT',
  CREATE_PACKAGE_PAYMENT_POINT: 'CREATE_PACKAGE_PAYMENT_POINT',
  GET_LIST_PACKAGE_PAYMENT_POINT: 'GET_LIST_PACKAGE_PAYMENT_POINT',
  GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS: 'GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS',
  GET_LIST_SETTING_SYSTEM: 'GET_LIST_SETTING_SYSTEM',
  GET_LIST_SETTING_CATEGORY_CONTACT: 'GET_LIST_SETTING_CATEGORY_CONTACT',
  CREATE_SETTING_CATEGORY_CONTACT: 'CREATE_SETTING_CATEGORY_CONTACT',
  DELETE_SETTING_CATEGORY_CONTACT: 'DELETE_SETTING_CATEGORY_CONTACT',
  PUT_SETTING_CATEGORY_CONTACT: 'PUT_SETTING_CATEGORY_CONTACT',
  GET_LIST_SETTING_CATEGORY_REPORT: 'GET_LIST_SETTING_CATEGORY_REPORT',
  CREATE_SETTING_CATEGORY_REPORT: 'CREATE_SETTING_CATEGORY_REPORT',
  DELETE_SETTING_CATEGORY_REPORT: 'DELETE_SETTING_CATEGORY_REPORT',
  PUT_SETTING_CATEGORY_REPORT: 'PUT_SETTING_CATEGORY_REPORT',
  GET_LIST_SETTING_EFFECT: 'GET_LIST_SETTING_EFFECT',
  CREATE_SETTING_EFFECT: 'CREATE_SETTING_EFFECT',
  DELETE_SETTING_EFFECT: 'DELETE_SETTING_EFFECT',
  PUT_SETTING_EFFECT: 'PUT_SETTING_EFFECT',
  GET_LIST_SETTING_MESSAGE_DONATE: 'GET_LIST_SETTING_MESSAGE_DONATE',
  CREATE_SETTING_MESSAGE_DONATE: 'CREATE_SETTING_MESSAGE_DONATE',
  DELETE_SETTING_MESSAGE_DONATE: 'DELETE_SETTING_MESSAGE_DONATE',
  PUT_SETTING_MESSAGE_DONATE: 'PUT_SETTING_MESSAGE_DONATE',
  GET_LIST_SETTING_SITE: 'GET_LIST_SETTING_SITE',
  PUT_SETTING_SITE: 'PUT_SETTING_SITE',
  GET_EFFECT_CREATOR: 'GET_EFFECT_CREATOR',
  PUT_EFFECT_CREATOR: 'PUT_EFFECT_CREATOR',
  DELETE_EFFECT_CREATOR: 'DELETE_EFFECT_CREATOR',
  POST_EFFECT_CREATOR: 'POST_EFFECT_CREATOR',
  GET_LIST_SETTING_PAYMENT: 'GET_LIST_SETTING_PAYMENT',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
};
