/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  dataList: [],
  pagination: {},
  detailBanner: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_BANNER_REQUEST:
      return {
        ...state,
        pagination: {},
      };
    case TYPES.DELETE_BANNER_REQUEST:
    case TYPES.CREATE_BANNER_REQUEST:
      return {
        ...state,
      };
    case TYPES.GET_DETAIL_BANNER_REQUEST:
      return {
        ...state,
        detailBanner: {},
      };
    case TYPES.GET_LIST_BANNER_SUCCESS:
      return {
        ...state,
        dataList: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_DETAIL_BANNER_SUCCESS:
      return {
        ...state,
        detailBanner: action.data.data,
      };
    case TYPES.DELETE_BANNER_SUCCESS:
    case TYPES.CREATE_BANNER_SUCCESS:
      return {
        ...state,
      };
    case TYPES.CREATE_BANNER_FAILED:
      return {
        ...state,
        dataList: [],
      };
    case TYPES.DELETE_BANNER_FAILED:
    case TYPES.GET_LIST_BANNER_FAILED:
    case TYPES.GET_DETAIL_BANNER_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
