import { ButtonCCF } from 'styled';
import { cellToTooltip } from 'utils/utils';
const columns = (onClick) => [
  { headerName: '通報日時', ...cellToTooltip('created_at', 'date_time') },
  { headerName: '通報したユーザーの氏名', ...cellToTooltip('account_name') },
  {
    headerName: '通報されたユーザーのアカウントID・投稿ID',
    ...cellToTooltip('report_id', 'text', 350),
  },
  { headerName: 'カテゴリー', ...cellToTooltip('name') },
  { headerName: '問題の詳細', ...cellToTooltip('content') },
  { headerName: 'ステータス', ...cellToTooltip('status', 'status_report') },
  {
    field: 'action',
    headerName: 'アクション',
    minWidth: 100,
    sortable: false,
    renderCell: (cellValues) => (
      <ButtonCCF
        onClick={() => onClick(cellValues?.id)}
        variant='contained'
        size='small'
        bgtheme='primary'>
        詳細
      </ButtonCCF>
    ),
  },
];
const columnsFan = (onClick) => [
  { headerName: '通報日時', ...cellToTooltip('created_at', 'date_time') },
  {
    headerName: '通報したクリエイターのアカウントID',
    ...cellToTooltip('report_id', 'text', 300),
  },
  {
    headerName: '通報されたファンの氏名',
    ...cellToTooltip('full_name'),
  },
  { headerName: 'カテゴリー', ...cellToTooltip('name') },
  { headerName: '問題の詳細', ...cellToTooltip('content') },
  { headerName: 'ステータス', ...cellToTooltip('status', 'status_report') },
  {
    field: 'action',
    headerName: 'アクション',
    minWidth: 100,
    sortable: false,
    renderCell: (cellValues) => (
      <ButtonCCF
        onClick={() => onClick(cellValues?.id)}
        variant='contained'
        size='small'
        bgtheme='primary'>
        詳細
      </ButtonCCF>
    ),
  },
];
export { columns, columnsFan };
