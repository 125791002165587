import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
const ContactPage = lazy(() =>
  lazyRoute(() =>
    import('pages/contact').then((module) => ({
      default: module.ContactPage,
    }))
  )
);
export const listRouteContactPage = [
  // {
  //   path: `${PATH_URL.CONTACT}/fans`,
  //   element: <ContactPage typeGroup='fan' />,
  //   title: `お問い合わせ画面`,
  //   currentPath: `/${PATH_URL.CONTACT}/fans`,
  //   backPath: `/${PATH_URL.REVENUE}`,
  // },
  {
    path: `${PATH_URL.CONTACT}/users`,
    element: <ContactPage typeGroup='user' />,
    title: `お問い合わせ画面`,
    currentPath: `/${PATH_URL.CONTACT}/users`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.CONTACT}/agencies`,
    element: <ContactPage typeGroup='agency' />,
    title: `お問い合わせ画面`,
    currentPath: `/${PATH_URL.CONTACT}/agencies`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: PATH_URL.CONTACT,
    currentPath: `/${PATH_URL.CONTACT}/users`,
    element: <Navigate to='users' replace />,
  },
];
