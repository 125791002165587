import { Tab, Tabs } from '@material-ui/core';
import styled, { css } from 'styled-components';
const TabWrapper = styled(Tab)`
  position: relative;
  ${({ havecontent }) =>
    havecontent &&
    css`
      &:before {
        position: absolute;
        right: ${({ right }) => right || '20px'};
        font-size: 16px;
        top: 9px;
        color: #df3434;
        content: '${({ havecontent }) => havecontent}';
      }
    `}
`;
const TabsWrapper = styled(Tabs)`
  flex-wrap: nowrap;
  overflow-x: auto !important;
  margin-bottom: 2rem;
  a > span {
    font-size: 15px;
    font-weight: 600;
  }
  .Mui-selected {
    font-weight: bold;
    color: #303f9f;
  }
  .MuiTabs-scroller {
    overflow-x: auto !important;
    > div {
      border-bottom: 3px solid #cdcdcd;
    }
    ::-webkit-scrollbar {
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 50px;
    }
  }
  .MuiTabs-indicator {
    bottom: 0px;
    height: 3px;
    background-color: #303f9f;
  }
`;
export { TabWrapper, TabsWrapper };
