import WrapperTable from 'components/Table';
import TabsAccount from 'pages/account/tabs';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormLogManagement } from '../styled';
import DateSelectGroup from 'components/WrapperSelect/select.date';
import { getListAccountLogManagement } from 'store/actions/admin.action';
import { cellToTooltip, enterToSearch } from 'utils/utils';
import { Content, WrapperContent, WrapperTitle } from 'components/FromFeature/form-components';
import { checkParams } from 'constants';
import { FormInputText } from 'components/FromFeature/form-components';
import { useForm } from 'react-hook-form';
import {
  WrapperButton,
  ButtonSubmit,
} from 'components/FromFeature/form-element/styledElement';
import { LanguageContext } from 'Context/translation.context';
import { Text, TooltipHover } from 'components/Text';

export const AccountLogManagement = () => {
  const defaultValues = {
    full_name: '',
  };
  const { tAdmincs } = useContext(LanguageContext);
  const [handleSearch, setSearch] = useState(0);
  const [dateValue, setDateValue] = useState({});
  const [query, setQuery] = useState({});
  const methods = useForm({ mode: 'all', defaultValues: defaultValues });
  const { handleSubmit, control } = methods;

  const columns = [
    { headerName: tAdmincs('log.full_name'), ...cellToTooltip('full_name') },
    { headerName: tAdmincs('log.created_at'), ...cellToTooltip('created_at', 'date_time') },
    { headerName: tAdmincs('log.action'), ...cellToTooltip('action', 'status_admin_cs') },
    { headerName: tAdmincs('log.type'), ...cellToTooltip('type') },
    {
      headerName: tAdmincs('log.name_account'),
      ...cellToTooltip('name_romaji'),
      renderCell: (cell) => (
        <TooltipHover title={cell?.row['action'] === 1 ? '' : cell?.row['name_romaji']}>
          <Text maxWidth={250}>
            {cell?.row['action'] === 1 ? '' : cell?.row['name_romaji']}
          </Text>
        </TooltipHover>
      ),
    },
  ];

  const onSubmit = (data) => {
    setQuery({ ...checkParams(data), ...checkParams(dateValue) });
    setSearch(handleSearch + 1);
  };

  const { pagination, listAccountLogManagement } = useSelector((state) => state.admin);

  return (
    <FormLogManagement>
      <TabsAccount />
      <WrapperTitle className='table-filter-date'>
        {tAdmincs('log.date')}
        <DateSelectGroup
          handleSelect={(key, value) => {
            setDateValue((prev) => ({ ...prev, [`${key}`]: value }));
          }}
        />
      </WrapperTitle>

      <WrapperButton justifyContent wrap='true'>
        <WrapperButton width='450px'>
          <FormInputText
            isSearch
            name='full_name'
            control={control}
            label={tAdmincs('log.input')}
            inputProps={{
              onKeyDown: (e) => {
                enterToSearch(e, handleSubmit(onSubmit));
              },
            }}
          />
          <ButtonSubmit onClick={handleSubmit(onSubmit)} variant='contained' bgtheme='primary'>
            {tAdmincs('admin.search')}
          </ButtonSubmit>
        </WrapperButton>
      </WrapperButton>
      <WrapperContent justifyContentEnd marginBotTop={'10px'} paddingRight={'10px'}>
        <Content marginTop={'0'} marginBottom={'0'} textAlign={'end'}>
          検索結果：
        </Content>
        <div>{pagination?.total}件</div>
      </WrapperContent>
      <WrapperTable
        columns={columns}
        page={pagination?.current_page}
        countSearch={handleSearch}
        query={query}
        total={pagination?.total}
        listRows={listAccountLogManagement}
        action={getListAccountLogManagement}
      />
    </FormLogManagement>
  );
};

export default AccountLogManagement;
