import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TableGrid, Wrapper } from './styled';
import { Pagination, Stack } from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-root :focus, & .MuiDataGrid-cell:focus-within,.MuiDataGrid-cell:focus, .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-main :focus':
      {
        outline: 'none !important',
      },
  },
});

// const Table = React.forwardRef((props, ref) => <DataGrid ref={ref} {...props} />);
const ref = createRef();

const WrapperTable = ({
  dataPayload,
  query = {},
  total = 0,
  action = () => () => {},
  listRows = [],
  countSearch = 0,
  firstSearch = true,
  limit = 20,
  contentNoRow = '検索結果がありません',
  contentNoResult = '検索結果がありません',
  resetPage,
  isLoad,
  setFlgRender,
  flgPage,
  setFlgPage,
  handleNavigate,
  ...props
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const [searchFirst, setSearchFirst] = useState(firstSearch);
  const [pageSizes, setPageSizes] = useState(limit);
  const [pageIndex, setPageIndex] = useState(1);
  const [pauseSearch, setPauseSearch] = useState(false);
  const { pagination: paginationVoucher } = useSelector((state) => state.voucher);
  const { pagination } = useSelector((state) => state.campaign);
  const handleSearch = useCallback(
    (page) => {
      isLoad ? 
      isLoggedIn && dispatch(action(false, { page, limit: pageSizes, ...query }))
      : isLoggedIn && dispatch(action({ page, limit: pageSizes, ...query }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, pageSizes, isLoggedIn]
  );
  useEffect(() => {
    searchFirst && !pauseSearch && handleSearch(pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSizes, pauseSearch]);
  useEffect(() => {
    if (countSearch > 0) {
      setPageIndex(1);
      setSearchFirst(true);
      handleSearch(1);
      setPauseSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countSearch]);

  useEffect(() => {
    if (resetPage) {
      setPageIndex(1);
      setFlgRender && setFlgRender(false)
    }
  }, [resetPage]);

  useEffect(() => {
    if (flgPage && pageIndex > 1 && (!paginationVoucher?.count || !pagination?.count)) {
      setPageIndex(pageIndex - 1);
    }
    setFlgPage && setFlgPage(false);
  }, [flgPage]);

  return (
    <Wrapper>
      <TableGrid
        ref={ref}
        className={classes.root}
        rows={listRows || []}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        rowCount={total}
        pagination
        paginationMode='server'
        loading={loading}
        componentsProps={{
          pagination: {
            classes: null,
            count: searchFirst
              ? total === 0
                ? 0
                : Number.parseInt((total - 1) / pageSizes) + 1
              : 0,
            page: handleNavigate ? query?.page || pageIndex : pageIndex,
            color: 'primary',
            onChange: (e, value) => {
              if (handleNavigate) {
                handleNavigate(value);
              } else {
                setPageIndex(value);
                setPauseSearch(false);
              }
            },
          },
        }}
        components={{
          Pagination: Pagination,
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              {contentNoRow}
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              {contentNoResult}
            </Stack>
          ),
        }}
        {...props}
      />
    </Wrapper>
  );
};

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      bgtheme='primary'
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
const WrapperTableStatic = ({ listRows, total, columns, ...props }) => {
  const classes = useStyles();
  return (
    <Wrapper>
      <TableGrid
        ref={ref}
        className={classes.root}
        rows={listRows || []}
        columns={columns || []}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        rowsPerPageOptions={[20]}
        pagination
        pageSize={20}
        components={{
          Pagination: CustomPagination,
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              検索結果がありません
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              検索結果がありません
            </Stack>
          ),
        }}
        {...props}
      />
    </Wrapper>
  );
};
export { WrapperTable, WrapperTableStatic };
export default WrapperTable;
