import { WrapperShowListCard } from 'components/ListCard';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteRecordLivestream, getListRecordLivestream } from 'store/actions/creator.action';
import { CardRecordLivestreams } from './components/CardRecordLivestreams';
import { TabsSecond } from './settings/tabs';
const ListRecordLivestream = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { listRecordLivestream, pagination } = useSelector((state) => state.creator);
  const { current_page, total_pages, count, per_page } = pagination;

  const handleDeleteRecordLiveStream = (id_live) => {
    if (current_page === total_pages && count === 1) {
      dispatch(
        deleteRecordLivestream(
          id_live,
          getListRecordLivestream(id, { page: current_page - 1, limit: per_page })
        )
      );
    } else {
      dispatch(
        deleteRecordLivestream(
          id_live,
          getListRecordLivestream(id, { page: current_page, limit: per_page })
        )
      );
    }
  };

  return (
    <>
      <TabsSecond />
      <WrapperShowListCard
        limit={6}
        count={pagination?.total_pages}
        action={(page, limit) => getListRecordLivestream(id, { page: page.page, limit: 6 })}
        listCard={listRecordLivestream}
        itemCard={(el, i) => (
          <CardRecordLivestreams
            key={i}
            data={el}
            handleDeleteRecordLiveStream={() => handleDeleteRecordLiveStream(el.id)}
          />
        )}
        gmin='445px'
      />
    </>
  );
};
export { ListRecordLivestream };
