import React from 'react';

const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      // clear time out
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};
export {
  useDebounce,
}