const paramsCreate = {
    role_type: 0,
    delivery_type: '1',
    title: '',
    content: '',
    start_time: null,
    end_time: null,
}
const paramsDetail = {
    role_type: 0,
    delivery_type: '1',
    title: '',
    content: '',
    start_time: null,
    end_time: null,
}
export {
    paramsCreate,
    paramsDetail
}