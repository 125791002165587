// import Header from 'components/Header';
import HeaderTop from 'components/HeaderTop';
import MainWrapper from 'components/MainWrapper';
import { LazyLoaderPage } from 'components/MainWrapper/styled';
import Sidebar from 'components/Sidebar';
import React, { Suspense } from 'react';

export const WrapperAuth = ({ children }) => (
  <>
    <Sidebar />
    <Suspense fallback={<LazyLoaderPage />}>
      <HeaderTop />
      <MainWrapper>{children}</MainWrapper>
    </Suspense>
  </>
);
