/* eslint-disable no-unused-expressions */
/* Call API with token */
import { pushNotify } from '../components/Notification';
import { clearLocal } from 'constants';
import { messageError, messageSuccess } from 'constants/errorMessage';
import { updateStatus } from 'constants';
const SYSTEM_ERROR = 1;
const API_ERROR = 2;
export const TOKEN_TIMEOUT = 3;
export const SYSTEM_IS_MAINTAINING = 4;
class ApiError extends Error {
  constructor(error, type) {
    super();
    this.errors = error;
    this.type = type;
  }
}

async function validateResponse(response) {
  if (!response.ok) {
    let error = {};
    let type = API_ERROR;
    const err = await response.json();
    switch (response.status) {
      case 403:
      case 429:
      case 422:
      case 500:
        // eslint-disable-next-line no-case-declarations
        type = API_ERROR; // TOKEN_TIMEOUT;
        if (Array.isArray(err.message)) {
          error = {
            messageId: err.status,
            message: err.message,
            status: err.success,
          };
          break;
        } else {
          error = {
            messageId: err.code,
            message: Object.values(err.errors),
            status: err.success,
          };
          break;
        }
      case 404:
      case 400:
        error = {
          messageId: err.code,
          message: err.message,
          status: err.success,
        };
        break;
      case 401:
        clearLocal();
        window.location.reload();
        type = API_ERROR; // TOKEN_TIMEOUT;
        if (Array.isArray(err.message)) {
          error = {
            messageId: err.status,
            message: err.message,
            status: err.success,
          };
          break;
        } else {
          error = {
            messageId: err.code,
            message: err.message,
            status: err.success,
          };
          break;
        }
      case 503:
        type = SYSTEM_IS_MAINTAINING;
        clearLocal();
        window.location.reload();
        error = {
          messageId: err.status,
          message: 'SYSTEM_IS_MAINTAINING',
        };
        break;
      default:
        type = SYSTEM_ERROR;
        error = {
          messageId: 'INTERNAL_ERROR',
          message: 'INTERNAL_ERROR',
          // message: response.statusText,
        };
        break;
    }
    if (error && type !== SYSTEM_ERROR) {
      throw new ApiError(error);
    } else {
      throw Error(error);
    }
  }
  return response.json();
}

function logError(error) {
  if (error instanceof ApiError) {
    const selectMess = Array.isArray(error.errors.message)
      ? error.errors.message[0]
      : error.errors.message;
    pushNotify(
      'error',
      messageError[selectMess] !== undefined
        ? messageError[selectMess]
        : messageError[selectMess] === null
        ? ''
        : selectMess
    );
    if (selectMess === 'auth.token_does_not_exist') clearLocal();
  }
  // throw error;
  return false;
}
function logSuccess(mess) {
  pushNotify('success', messageSuccess[mess] !== undefined && messageSuccess[mess]);
}

const getKey = (item) => {
  // account_id
  if (item[0] === 'ACCOUNT' && item[1] === 'ID') {
    return 'account_id';
  }
  // name_romaji
  if (item[0] === 'NAME' && item[1] === 'ROMAJI') {
    return 'name_romaji';
  }
  // name_japanese
  if (item[0] === 'NAME' && item[1] === 'JAPANESE') {
    return 'name_japanese';
  }
  if (item[0] === 'COMMISSION' && item[1] === 'SALES') {
    return 'commission_sales';
  }
  if (item[0] === 'COMMISSION' && item[1] === 'SUBSCRIPTION') {
    return 'commission_subscription';
  }
  if (item[0] === 'COMMISSION' && item[1] === 'LIVESTREAM') {
    return 'commission_livestream';
  }
  if (item[0] === 'COMMISSION' && item[1] === 'MESSAGE') {
    return 'commission_message';
  }
  return item[0]?.toLowerCase();
};
const checkErrorApiForm = (arrErr = [], listObj = {}) => {
  if (Object.keys(listObj).length > 0) {
    let listErr = [];
    if (Array.isArray(arrErr) && arrErr.length > 0) {
      const findKey = arrErr.map((item) => {
        const splitKey = item.split('_');
        return {
          key: getKey(splitKey),
          mess: messageError[item],
        };
      });
      findKey.map((item) =>
        Object.keys(listObj).map((key) => key === item.key && listErr.push(true))
      );
    }
    return listErr.length > 0 ? false : true;
  } else {
    return true;
  }
};
const callApi = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure,
  onLoading = null,
  typeMess = null,
  hideErrMess = false,
  handleFinally,
  paramsCheckErr = {}
) => {
  onLoading && onLoading(updateStatus('LOADING_API_REQUEST'));
  onLoading && onLoading(updateStatus('CHECK_ERROR_START'));
  fetch(url, config)
    .then(validateResponse)
    .then((data) => {
      if (data) {
        onRequestFinish && onRequestFinish();
        onRequestSuccess && onRequestSuccess(data);
        onLoading && onLoading(updateStatus('CHECK_ERROR_COMPLETE', {}));
      }
      return data;
    })
    .then(() => typeMess && logSuccess(typeMess))
    .catch((err) => {
      checkErrorApiForm(err.errors?.message, paramsCheckErr) && !hideErrMess && logError(err);
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
      onLoading && onLoading(updateStatus('CHECK_ERROR_COMPLETE', err.errors));
    })
    .finally(() => {
      handleFinally && handleFinally();
      onLoading && onLoading(updateStatus('LOADING_API_COMPLETE'));
    });
};

// let prResolve = {}

// const clearPrResolve = () => {
//   prResolve = {};
//   // return prResolve;
// }

export { callApi, logSuccess, getKey };
