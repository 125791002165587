import ForgotPassword from '../pages/Auth/forgot-password';
import ResetPassword from '../pages/Auth/reset-password';

import VerificationEmail from '../pages/Auth/verification-email';
import { LoginPage } from 'pages/Auth/sign-in';
import { Navigate } from 'react-router-dom';
export const listRouteAuth = [
  {
    path: '/sign-in',
    element: <LoginPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password/:token/:email',
    element: <ResetPassword />,
  },
  {
    path: '/verification-email/:flag',
    element: <VerificationEmail />,
  },
  {
    path: '',
    element: <Navigate to='sign-in' replace />,
  },
];
