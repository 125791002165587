import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';
const InputText = styled(TextField)`
  width: ${({ width }) => width || '100%'} !important;
  max-width: ${({ mwidth }) => mwidth || '100%'} !important;
  margin-right: ${({ mgr }) => mgr || '0'} !important;
  min-width: ${({ miwidth }) => miwidth || '180px'} !important;
  ${({ mb }) => `margin-bottom: ${mb || '0'} !important;`}
  input {
    padding-right: 35px !important;
  }
  /* fieldset {
    top: 0;
  } */
`;
const WrapperDate = styled.div`
  position: relative;
`;
const WrapperInput = styled.div`
  position: relative;
  width: 100%;

  .input-setting-site {
    .MuiFormHelperText-root.Mui-error {
      position: absolute;
      min-width: 500px;
      bottom: -22px;
      right: -22px;
      display: flex;
      justify-content: end;
    }
  }
`;
const ButtonClear = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
`;
const DeleteInputDate = styled.div`
  position: absolute;
  right: 32px;
  top: 11.5px;
  border-radius: 50%;
  background: red;
  color: #fff;
  height: 17px;
  width: 17px;
  cursor: pointer;
  & > svg {
    font-size: 17px;
  }
`;
const InputTextDate = styled(TextField)`
  width: ${({ width }) => width || '200px'};
  position: relative;

  button {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    & > svg {
      position: absolute;
      right: 10px;
    }
  }
  label {
    display: none;
  }
  fieldset {
    top: 0;
    legend {
      display: none;
    }
  }
  input {
    height: 40px !important;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: uppercase;
  }
`;
const MessError = styled.div`
  margin: 10px 0;
  ${({ marginTop }) => marginTop && `margin-top:${marginTop};`}
  ${({ paddingLeft }) => paddingLeft && `padding-left:${paddingLeft};`}
  color: #f44336;
  ${({ errInput }) =>
    errInput &&
    css`
      position: absolute;
      right: 0px;
      width: 100%;
    `}
  ${({ isMessAbs }) =>
    isMessAbs &&
    `
    margin: 10px 0;
    color: #f44336;
    position: absolute;
    top: 30px;
    left: 210px;
  `}
`;
const MessRequired = styled.div`
  margin: 10px 0;
  width: 70px;
  background-color: #f45836e8;
  font-size: 12px;
  color: #fff;
  height: 26px;
  padding: 3px 10px;
  max-width: fit-content;
  margin-top: ${({ marginTop }) => marginTop || '2px'};
  border-radius: 9px;
  margin-right: 10px;
`;
export {
  InputTextDate,
  InputText,
  MessError,
  WrapperDate,
  DeleteInputDate,
  MessRequired,
  WrapperInput,
  ButtonClear,
};
