import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  configParamsGETJson,
  notificationAPI,
  configParamsPUTJson,
  configParamsPOSTJsonDelete,
  configParamsPOSTJson,
} from 'apis';
const getListNotification = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_NOTIFICATION_REQUEST));
    callApi(
      notificationAPI.getListNotification(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_NOTIFICATION_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_NOTIFICATION_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const createNotification = (data, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATE_NOTIFICATION_REQUEST));
    callApi(
      notificationAPI.createNotification,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(updateStatus(TYPES.CREATE_NOTIFICATION_SUCCESS, response));
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_NOTIFICATION_FAILED, { error: error.errors }));
      },
      dispatch,
      data?.is_save === 1 ? 'CREATE_NOTIFICATION' : 'SAVE_NOTIFICATION'
    );
  };
};
const updateNotification = (id, data, navigate) => {
    return (dispatch, s) => {
      dispatch(updateStatus(TYPES.UPDATE_NOTIFICATION_REQUEST));
      callApi(
        notificationAPI.updateNotification(id),
        configParamsPUTJson(s, data),
        null,
        async (response) => {
          if (response) {
            await dispatch(updateStatus(TYPES.UPDATE_NOTIFICATION_SUCCESS, response));
            await navigate();
          }
        },
        (error) => {
          dispatch(updateStatus(TYPES.UPDATE_NOTIFICATION_FAILED, { error: error.errors }));
        },
        dispatch,
        data?.is_save === 1 ? 'UPDATE_NOTIFICATION' : 'SAVE_NOTIFICATION'
      );
    };
  };
const getDetailNotification = (id, navigate = () => {}, errNavigate = () => {}) => {
    return (dispatch, s) => {
      dispatch(updateStatus(TYPES.GET_DETAIL_NOTIFICATION_REQUEST));
      callApi(
        notificationAPI.getDetailNotification(id),
        configParamsGETJson(s),
        null,
        async (response) => {
          await dispatch(updateStatus(TYPES.GET_DETAIL_NOTIFICATION_SUCCESS, response));
          await navigate();
        },
        (error) => {
          dispatch(updateStatus(TYPES.GET_DETAIL_NOTIFICATION_FAILED, { error: error.errors }));
          errNavigate();
        },
        dispatch,
        null
      );
    };
  };
  const deleteNotification = (id, navigate) => {
    return (dispatch, s) => {
      dispatch(updateStatus(TYPES.DELETE_NOTIFICATION_REQUEST));
      callApi(
        notificationAPI.deleteNotification(id),
        configParamsPOSTJsonDelete(s),
        null,
        async (response) => {
          await dispatch(updateStatus(TYPES.DELETE_NOTIFICATION_SUCCESS, response));
        },
        (error) => {
          dispatch(updateStatus(TYPES.DELETE_NOTIFICATION_FAILED, { error: error.errors }));
        },
        dispatch,
        'DELETE_NOTIFICATION',
        null,
        navigate
      );
    };
  };
export { getListNotification, createNotification, getDetailNotification, updateNotification , deleteNotification};
