const TOKEN_AUTH = 'USER_ACCESS_TOKEN';
const setLocal = (key, value) => localStorage.setItem(key, value);
const getLocal = (key) => localStorage.getItem(key);
const removeLocal = (key) => localStorage.removeItem(key);
const clearLocal = () => localStorage.clear();
const getAllLocal = () => localStorage;

const setToken = (token) => setLocal(TOKEN_AUTH, token);
const removeToken = () => removeLocal(TOKEN_AUTH);
const getToken = () => getLocal(TOKEN_AUTH);

export { clearLocal, getAllLocal, setToken, removeToken, getToken };
