import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
  settingSiteAPI,
} from 'apis';
import { pushNotify } from 'components/Notification';

const getListFavoriteCreators = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_CREATOR_FAVORITE_REQUEST));
    callApi(
      settingSiteAPI.getListFavoriteCreators(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_CREATOR_FAVORITE_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_CREATOR_FAVORITE_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const getListFavoriteCreatorsType2 = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_CREATOR_FAVORITE_REQUEST));
    callApi(
      settingSiteAPI.getListFavoriteCreatorsType2(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_CREATOR_FAVORITE_TYPE2_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_CREATOR_FAVORITE_TYPE2_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const getListNewCreators = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_NEW_CREATOR_REQUEST));
    callApi(
      settingSiteAPI.getListNewCreators(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_NEW_CREATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_NEW_CREATOR_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListNewCreatorsType2 = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_NEW_CREATOR_TYPE2_REQUEST));
    callApi(
      settingSiteAPI.getListNewCreatorsType2(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_NEW_CREATOR_TYPE2_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_NEW_CREATOR_TYPE2_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const getListPickupCreators = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_PICKUP_REQUEST));
    callApi(
      settingSiteAPI.getListPickupCreators(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_PICKUP_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_PICKUP_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListRankingCreators = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_RANKING_CREATOR_REQUEST));
    callApi(
      settingSiteAPI.getListRankingCreators(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_RANKING_CREATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_RANKING_CREATOR_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListRankingCreatorsType2 = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_RANKING_CREATOR_TYPE2_REQUEST));
    callApi(
      settingSiteAPI.getListRankingCreatorsType2(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_RANKING_CREATOR_TYPE2_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_RANKING_CREATOR_TYPE2_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const getListRecommends = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_RECOMMENDS_REQUEST));
    callApi(
      settingSiteAPI.getListRecommends(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_RECOMMENDS_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_RECOMMENDS_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListRecommendsType2 = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_RECOMMENDS_TYPE2_REQUEST));
    callApi(
      settingSiteAPI.getListRecommendsType2(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_RECOMMENDS_TYPE2_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_RECOMMENDS_TYPE2_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const deleteCreatorType2 = (priority_id, cb) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_CREATOR_TYPE2_REQUEST));
    callApi(
      settingSiteAPI.deleteCreatorType2(priority_id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        // pushNotify('優先リストからユーザーアカウントの削除が正常に完了しました。');
        await dispatch(
          updateStatus(TYPES.DELETE_CREATOR_TYPE2_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_CREATOR_TYPE2_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'DELETED_CREATOR',
      null,
      cb
    );
  };
};
const putCreatorFavorite = (id, data, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.PUT_CREATOR_FAVORITE_REQUEST));
    callApi(
      settingSiteAPI.putCreatorFavorite(id),
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PUT_CREATOR_FAVORITE_SUCCESS, {
              res: response,
            })
          );
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.PUT_CREATOR_FAVORITE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'PUT_CREATOR_FAVORITE'
    );
  };
};
const postCreatorFavoriteSaved = (data, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.POST_CREATOR_FAVORITE_SAVED_REQUEST));
    callApi(
      settingSiteAPI.postCreatorFavoriteSaved(),
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.POST_CREATOR_FAVORITE_SAVED_SUCCESS, {
              res: response,
            })
          );
          await navigate();
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.POST_CREATOR_FAVORITE_SAVED_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'SAVE_SUCCESS'
    );
  };
};
const addCreator = (data, cb) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.ADD_CREATOR_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.addCreator,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          pushNotify('success', 'ユーザーアカウントの追加が正常に完了しました。');
          await dispatch(
            updateStatus(TYPES.ADD_CREATOR_SUCCESS, {
              res: response,
            })
          );
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.ADD_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      null,
      null,
      cb
    );
  };
};
const getListChangeTypeListFavoriteGenres = (data, cb) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CHANGE_TYPE_LIST_FAVORITE_GENRES_REQUEST));
    callApi(
      settingSiteAPI.getListChangeTypeListFavoriteGenres(data),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.CHANGE_TYPE_LIST_FAVORITE_GENRES_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CHANGE_TYPE_LIST_FAVORITE_GENRES_FAILED, { error: error.errors })
        );
      },
      dispatch,
      null,
      null,
      cb
    );
  };
};
const getListBanners = () => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_BANNERS_REQUEST));
    callApi(
      settingSiteAPI.getListBanners,
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_BANNERS_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_BANNERS_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const createBannerAdmin = (data, cb) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_BANNER_ADMIN_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createBannerAdmin,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_BANNER_ADMIN_SUCCESS, {
              res: response,
            })
          );
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_BANNER_ADMIN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATE_BANNER_ADMIN',
      null,
      cb
    );
  };
};
const updateBannerAdmin = (data, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UP_DATE_BANNER_ADMIN_REQUEST));
    console.log('settingSiteAPI', settingSiteAPI.updateBannerAdmin);
    callApi(
      settingSiteAPI.updateBannerAdmin,
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.UP_DATE_BANNER_ADMIN_SUCCESS, {
              res: response,
            })
          );
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.UP_DATE_BANNER_ADMIN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'UP_DATE_BANNER_ADMIN'
    );
  };
};
const deleteBannerAdmin = (id, cb) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_BANNER_ADMIN_REQUEST));
    callApi(
      settingSiteAPI.deleteBannerAdmin(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_BANNER_ADMIN_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_BANNER_ADMIN_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'DELETE_BANNER_ADMIN',
      null,
      cb
    );
  };
};
const getListCheckBoxType = (cb, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_CHECK_BOX_TYPE_REQUEST));
    callApi(
      settingSiteAPI.getListCheckBoxType(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_CHECK_BOX_TYPE_SUCCESS, response));
        cb(response?.data.public_flag);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_CHECK_BOX_TYPE_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};

export {
  getListCheckBoxType,
  getListFavoriteCreators,
  getListFavoriteCreatorsType2,
  getListNewCreators,
  getListNewCreatorsType2,
  getListPickupCreators,
  getListRankingCreators,
  getListRankingCreatorsType2,
  getListRecommends,
  getListRecommendsType2,
  deleteCreatorType2,
  putCreatorFavorite,
  postCreatorFavoriteSaved,
  addCreator,
  getListChangeTypeListFavoriteGenres,
  getListBanners,
  createBannerAdmin,
  updateBannerAdmin,
  deleteBannerAdmin,
};
