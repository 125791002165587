export const SETTINGPAGE = {
  GET_LIST_CHECK_BOX_TYPE: 'GET_LIST_CHECK_BOX_TYPE',
  GET_LIST_CREATOR_FAVORITE: 'GET_LIST_CREATOR_FAVORITE',
  GET_LIST_CREATOR_FAVORITE_TYPE2: 'GET_LIST_CREATOR_FAVORITE_TYPE2',
  GET_LIST_NEW_CREATOR: 'GET_LIST_NEW_CREATOR',
  GET_LIST_NEW_CREATOR_TYPE2: 'GET_LIST_NEW_CREATOR_TYPE2',
  GET_LIST_RANKING_CREATOR: 'GET_LIST_RANKING_CREATOR',
  GET_LIST_RANKING_CREATOR_TYPE2: 'GET_LIST_RANKING_CREATOR_TYPE2',
  GET_LIST_RECOMMENDS: 'GET_LIST_RECOMMENDS',
  GET_LIST_RECOMMENDS_TYPE2: 'GET_LIST_RECOMMENDS_TYPE2',
  GET_LIST_PICKUP: 'GET_LIST_PICKUP',
  DELETE_CREATOR_TYPE2: 'DELETE_CREATOR_TYPE2',
  PUT_CREATOR_FAVORITE: 'PUT_CREATOR_FAVORITE',
  POST_CREATOR_FAVORITE_SAVED: 'POST_CREATOR_FAVORITE_SAVED',
  CHANGE_TYPE_LIST_FAVORITE_GENRES: 'CHANGE_TYPE_LIST_FAVORITE_GENRES',
  ADD_CREATOR: 'ADD_CREATOR',
  GET_LIST_BANNERS: 'GET_LIST_BANNERS',
  CREATE_BANNER_ADMIN: 'CREATE_BANNER_ADMIN',
  UP_DATE_BANNER_ADMIN: 'UP_DATE_BANNER_ADMIN',
  DELETE_BANNER_ADMIN: 'DELETE_BANNER_ADMIN',
};
