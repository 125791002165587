import { useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';
import { listRouterAcceptAuth, listRouterRejectAuth } from 'routes';

const useLocationRouter = () => {
  const history = useLocation();
  const getPathName = history.pathname;
  const getLinkName = getPathName.substring(1);
  const getSearch = history.search;
  const getHash = history.hash;
  const getState = history.state;
  const getKey = history.key;
  return { getPathName, getSearch, getHash, getState, getKey, getLinkName };
};
const useCheckURLParams = () => {
  const location = useLocation();
  const { permissions } = useSelector((state) => state.auth);
  const verifyUrlNoAuth = matchRoutes(listRouterRejectAuth, location);
  const verifyUrlAuth = matchRoutes(listRouterAcceptAuth(permissions), location);
  // Check have URL
  const checkUrlNoAuth =
    verifyUrlNoAuth &&
    listRouterRejectAuth.some((item) => item.path === verifyUrlNoAuth[0].route.path);
  const checkUrlAuth =
    verifyUrlAuth &&
    listRouterAcceptAuth(permissions).some(
      (item) => item.path === verifyUrlAuth[0].route.path
    );
  return { checkUrlNoAuth, checkUrlAuth };
};
export { useLocationRouter, useCheckURLParams };
