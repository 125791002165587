import { updateStatus } from 'constants';
import { callApi, logSuccess } from '../../utils/request';
import { TYPES } from './index';
import {
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
  creatorAPI,
} from 'apis';
import { formatDateOnly } from 'constants/FilterDate';
import { findMess, DOMAIN_USER } from 'constants';

const adminGetListCreator = ({ ...props }) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_CREATOR_LIST_REQUEST));
    callApi(
      creatorAPI.getListCreator({ ...props }),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CREATOR_LIST_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_CREATOR_LIST_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const creatorGetSettingLimit = () => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_SETTING_LIMIT_CREATOR_REQUEST));
    callApi(
      creatorAPI.settingLimitPayment,
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_SETTING_LIMIT_CREATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_SETTING_LIMIT_CREATOR_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const creatorChangeSettingLimit = (data, callback) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_SETTING_LIMIT_CREATOR_REQUEST));
    callApi(
      creatorAPI.settingLimitPayment,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_SETTING_LIMIT_CREATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.UPDATE_SETTING_LIMIT_CREATOR_FAILED, { error: error.errors })
        );
      },
      dispatch,
      'UPDATE_SETTING_LIMIT_CREATOR',
      null,
      callback
    );
  };
};
const getDetailCreator = (id, navigate, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_CREATOR_DETAIL_REQUEST));
    callApi(
      creatorAPI.getDetailCreator(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CREATOR_DETAIL_SUCCESS, {
            res: response.data,
          })
        );
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_CREATOR_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch
    );
  };
};
const getDetailContract = (id, contractId, navigate, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_CONTRACT_DETAIL_REQUEST));
    callApi(
      creatorAPI.getContractDetail(id, contractId),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CONTRACT_DETAIL_SUCCESS, {
            res: response.data,
          })
        );
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_CONTRACT_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch
    );
  };
};
const updateCreatorAccount = (data, id, navigate, errParams) => {
  const params = () => {
    if (data.birthday) {
      data.birthday = formatDateOnly(data.birthday);
    }
    return data;
  };
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_DETAIL_CREATOR_REQUEST));
    callApi(
      creatorAPI.updateCreator(id),
      configParamsPUTJson(s, params()),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_DETAIL_CREATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_DETAIL_CREATOR_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_CREATOR_ACCOUNT',
      null,
      () => {},
      errParams
    );
  };
};

const updateUserContract = (data, id, contractId, navigate, errParams) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_DETAIL_CONTRACT_REQUEST));
    callApi(
      creatorAPI.updateContract(id, contractId),
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_DETAIL_CONTRACT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_DETAIL_CONTRACT_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_CONTRACT_ACCOUNT',
      null,
      () => {},
      errParams
    );
  };
};
const approveCreatorAccount = (data, id, func, reject = null) => {
  // const rejectCause = data === 4 ? { reject_cause: reject } : {};
  return async (dispatch, s) => {
    const statusUser = s().creator.detailCreator?.detail_user?.status;
    dispatch(updateStatus(TYPES.APPROVE_CREATOR_REQUEST));
    callApi(
      creatorAPI.approveCreator(id),
      configParamsPOSTJson(s, {
        status: data,
        // ...rejectCause,
      }),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.APPROVE_CREATOR_SUCCESS, response));
        logSuccess(findMess(statusUser, response.data.status));
        await func();
      },
      (error) => {
        dispatch(updateStatus(TYPES.APPROVE_CREATOR_FAILED, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};
const deleteAccountCreator = (id, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_FAN_ACCOUNT_REQUEST));
    callApi(
      creatorAPI.deleteCreator(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_FAN_ACCOUNT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_FAN_ACCOUNT_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_CREATOR_ACCOUNT'
    );
  };
};

const deleteAccountContract = (id, contractId, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_CONTRACT_ACCOUNT_REQUEST));
    callApi(
      creatorAPI.deleteContract(id, contractId),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_CONTRACT_ACCOUNT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_CONTRACT_ACCOUNT_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_CONTRACT_ACCOUNT'
    );
  };
};

const getListHistorySale = (id, navigate, errNavigate = () => {}, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_SALE_HISTORY_CREATOR_REQUEST));
    callApi(
      creatorAPI.getSaleHistoryCreator(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_SALE_HISTORY_CREATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_SALE_HISTORY_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch
    );
  };
};
const getListContract = (id, navigate, errNavigate = () => {}, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_CONTRACT_REQUEST));
    callApi(
      creatorAPI.getListContract(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_CONTRACT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_CONTRACT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch
    );
  };
};
const getListHistoryPayment = (id, navigate, errNavigate = () => {}, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_PAYMENT_HISTORY_CREATOR_REQUEST));
    callApi(
      creatorAPI.getPaymentHistoryCreator(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_PAYMENT_HISTORY_CREATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_PAYMENT_HISTORY_CREATOR_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch
    );
  };
};
const getListHistoryPost = (id, navigate, errNavigate = () => {}, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_POST_HISTORY_CREATOR_REQUEST));
    callApi(
      creatorAPI.getPostHistoryCreator(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_POST_HISTORY_CREATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_POST_HISTORY_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch
    );
  };
};
const deletePostCreator = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_POST_CREATOR_REQUEST));
    callApi(
      creatorAPI.deletePostCreator(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_POST_CREATOR_SUCCESS, response));
        await dispatch(action);
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_POST_CREATOR_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_POST_CREATOR'
    );
  };
};
const getListLivestreamCreator = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_LIVESTREAM_CREATOR_REQUEST));
    callApi(
      creatorAPI.getListLiveStreamCreator(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_LIVESTREAM_CREATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_LIVESTREAM_CREATOR_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const deleteSubscriptionCreator = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_SUBSCRIPTION_CREATOR_REQUEST));
    callApi(
      creatorAPI.deleteSubscriptionCreator(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        if (response) {
          await dispatch(updateStatus(TYPES.DELETE_SUBSCRIPTION_CREATOR_SUCCESS, response));
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.DELETE_SUBSCRIPTION_CREATOR_FAILED, { error: error.errors })
        );
      },
      dispatch,
      'DELETE_SUB_CREATOR'
    );
  };
};
const updateStopLivestream = (id, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATOR_STOP_LIVE_REQUEST));
    callApi(
      creatorAPI.creatorStopLivestream(id),
      configParamsPUTJson(s, null),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.CREATOR_STOP_LIVE_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_STOP_LIVE_FAILED, { error: error.errors }));
      },
      dispatch,
      'STOP_LIVE_STREAM'
    );
  };
};

const postAdminLoginCreator = (id, type = 'post', csAdminId) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.ADMIN_LOGIN_CREATOR_REQUEST));
    callApi(
      creatorAPI.adminLoginCreatorSecond,
      configParamsPOSTJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.ADMIN_LOGIN_CREATOR_SUCCESS, response));
        const tokenCreator = await response.data.access_token;
        if (csAdminId) {
          window.open(
            `${DOMAIN_USER}${
              type && `/${type}`
            }/${id}?adminToken=${tokenCreator}&csAdminId=${csAdminId}`,
            '_blank'
          );
        } else
          window.open(
            `${DOMAIN_USER}${type && `/${type}`}/${id}?adminToken=${tokenCreator}`,
            '_blank'
          );
      },
      (error) => {
        dispatch(updateStatus(TYPES.ADMIN_LOGIN_CREATOR_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListSubscriptionCreator = (
  id,
  navigate = () => {},
  errNavigate = () => {},
  ...props
) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_SUBSCRIPTION_CREATOR_REQUEST));
    callApi(
      creatorAPI.getSubscriptionCreator(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_SUBSCRIPTION_CREATOR_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_SUBSCRIPTION_CREATOR_FAILED, { error: error.errors }));
        errNavigate();
      }
      // dispatch
    );
  };
};
const createTokenAgoraGuest = ({ name = '', action = () => {} }) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_GUEST_REQUEST));
    callApi(
      creatorAPI.createTokenAgoraGuest(name),
      configParamsGETJson,
      null,
      (resp) => {
        dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_GUEST_SUCCESS, resp));
        action(resp?.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_GUEST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const getListRecordLivestream = (id, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_RECORD_LIVESTREAM_REQUEST));
    callApi(
      creatorAPI.getListRecordLiveStream(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_RECORD_LIVESTREAM_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_RECORD_LIVESTREAM_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};
const deleteRecordLivestream = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_RECORD_LIVESTREAM_REQUEST));
    callApi(
      creatorAPI.deleteRecordLiveStream(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        if (response) {
          await dispatch(updateStatus(TYPES.DELETE_RECORD_LIVESTREAM_SUCCESS, id));
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_RECORD_LIVESTREAM_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_SUB_CREATOR',
      null
    );
  };
};
export {
  adminGetListCreator,
  getDetailCreator,
  updateCreatorAccount,
  deleteAccountCreator,
  approveCreatorAccount,
  getListHistorySale,
  getListHistoryPayment,
  getListHistoryPost,
  creatorGetSettingLimit,
  creatorChangeSettingLimit,
  deletePostCreator,
  getListLivestreamCreator,
  deleteSubscriptionCreator,
  updateStopLivestream,
  postAdminLoginCreator,
  getListSubscriptionCreator,
  createTokenAgoraGuest,
  getListRecordLivestream,
  deleteRecordLivestream,
  getListContract,
  getDetailContract,
  updateUserContract,
  deleteAccountContract,
};
