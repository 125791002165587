import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const ButtonUpload = styled(Button)`
  width: 160px;
  padding: 10px !important;
  border: 1px solid rgba(63, 81, 181, 0.5) !important;
  color: #3f51b5 !important;
  border-radius: 5px !important;
  height: 35px;
  & > span {
    font-size: 12px;
  }
`;
const WrapperDragUpload = styled(Button)`
  margin: 10px 0 !important;
  width: 100%;
  height: 300px;
  border: 2px dashed ${({ isdrag }) => (isdrag ? '#e78383' : '#000')} !important;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
const ContentDrag = styled.div`
  pointer-events: none;
  text-transform: none;
  letter-spacing: 1.5px;
  color: #000;
`;
const ImageDrag = styled.img`
  pointer-events: none;
  width: 150px;
`;
const ImageShow = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
`;
const ImageWraper = styled.div`
  position: relative;
  padding: 10px;
  width: ${({ width }) => width || '150px'};
  height: ${({ width }) => width || '150px'};
  border: 1px dashed;
  ${({ pointer }) => pointer && `cursor: pointer`};
`;
const FeatureImage = styled.div`
  cursor: pointer;
  position: absolute;
  ${({ pos }) => (pos === 'right' ? ' right: 5px;' : ' left: 5px;')}
  top: 5px;
`;
const WrapperViewImage = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 10px;
  width: ${({ width }) => width};
`;
const ViewVideoX = styled.video`
  width: 535px;
`;
const ViewVideo = (props) => {
  const Render = React.useMemo(() => {
    return <ViewVideoX {...props} />;
  }, []);

  return Render;
};
export {
  ButtonUpload,
  WrapperDragUpload,
  ContentDrag,
  ImageDrag,
  ImageShow,
  ImageWraper,
  WrapperViewImage,
  FeatureImage,
  ViewVideo,
};
