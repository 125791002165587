const paramsCreate = {
  title: '',
  public_flag: '1',
  show_affiliator: 1,
  banner_url: '',
  start_time: null,
  end_time: null,
  list_affiliator: 0,
}
const paramsDetail = {
  title: '',
  banner_image: '',
  public_flag: '',
  show_affiliator: 0,
  banner_url: '',
  start_time: null,
  end_time: null,
  affiliator_name: '',
  user_id: '',
  list_affiliator: 0,


};
const paramsEdit = {
  title: '',
  public_flag: '',
  show_affiliator: 0,
  banner_url: '',
  start_time: null,
  end_time: null,
  list_affiliator: 0,
};
export { paramsEdit, paramsDetail , paramsCreate};
