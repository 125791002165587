import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { lazyRoute } from 'effect';

import { PATH_URL } from 'constants/routes/settingUrl';

const VoucherPage = lazy(() =>
  lazyRoute(() =>
    import('pages/voucher').then((module) => ({
      default: module.VoucherPage,
    }))
  )
);
export const listRouteVoucher = [
  // {
  //   path: `${PATH_URL.VOUCHER}/manage`,
  //   element: <VoucherPage />,
  //   title: 'クーポン管理',
  //   currentPath: `/${PATH_URL.VOUCHER}/manage`,
  //   backPath: `/${PATH_URL.REVENUE}`,
  // },
  {
    path: `${PATH_URL.VOUCHER}/list`,
    element: <VoucherPage />,
    title: 'クーポン一覧',
    currentPath: `/${PATH_URL.VOUCHER}/list`,
    backPath: `/${PATH_URL.VOUCHER}/manage`,
  },
  {
    path: `${PATH_URL.VOUCHER}/chart`,
    element: <VoucherPage />,
    title: 'グラフ',
    currentPath: `/${PATH_URL.VOUCHER}/chart`,
    backPath: `/${PATH_URL.VOUCHER}/manage`,
  },
  {
    path: `${PATH_URL.VOUCHER}`,
    element: <Navigate to='manage' replace />,
    currentPath: `/${PATH_URL.VOUCHER}/manage`,
  },
];
