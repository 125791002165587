import React, { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Content,
  WrapperContent,
  WrapperForm,
  WrapperButtonForm,
} from '../../../components/FromFeature/form-element/styled';

import { paramsDetail } from './dataForm';
import { AlertDialog } from 'components/Dialog';
import { getRenderInput } from 'constants';
import { getDetailReports, updateStatusReport } from 'store/actions/report.action';
import { checkTypeCell } from 'utils/utils';
import { PATH_URL } from 'constants/routes/settingUrl';
import { renderForm } from './configForm';
import { LanguageContext } from '../../../Context/translation.context';

export const FormEdit = ({ isCreator = false, isFan = false }) => {
  const location = useLocation();
  const { detailReport } = useSelector((state) => state.report);
  const methods = useForm({ mode: 'all', defaultValues: paramsDetail });
  const { control, setValue, watch } = methods;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showForm = renderForm({
    control,
    isCreator,
    detailReport,
    watch,
    dispatch,
    isFan,
    type: 'post',
  });

  const handleRedirectBack = (url) => {
    if (location?.state?.prevPath) {
      return navigate(`${url}${location?.state?.prevPath}`);
    }
    return navigate(`${url}`);
  };

  const returnHome = () => handleRedirectBack(isCreator && `/${PATH_URL.REPORT}/manage-reports-users`);

  useEffect(() => {
    Object.keys(detailReport).length === 0 &&
      dispatch(
        getDetailReports(
          id,
          () => {},
          () => returnHome()
        )
      );
  }, []);

  useEffect(() => {
    if (Object.keys(detailReport).length > 0) {
      for (let key in paramsDetail) {
        setValue(key, detailReport[key] || '');
        key === 'created_at' &&
          setValue(key, checkTypeCell('date_time', detailReport[key]) || '');
        key === 'status' &&
          setValue(key, checkTypeCell('status_report', detailReport[key]) || '');
      }
    }
  }, [detailReport]);

  const updateStatus = () => {
    dispatch(updateStatusReport(id, () => returnHome()));
  };

  const { tReport } = useContext(LanguageContext);

  return (
    <WrapperForm>
      {showForm.map((item, index) => {
        let { content, show = true } = item;
        return (
          <WrapperContent key={index}>
            {show && <Content width='200px'>{content}</Content>}
            {getRenderInput(item)}
          </WrapperContent>
        );
      })}

      {detailReport?.status === 1 && (
        <WrapperButtonForm>
          <AlertDialog
            handleAccpet={updateStatus}
            updateStatus={tReport('statusChange')}
            title={tReport('statusChange')}
            content={tReport('wantToChange')}
          />
        </WrapperButtonForm>
      )}
    </WrapperForm>
  );
};

export default FormEdit;
