export const CAMPAIGN = {
  GET_LIST_CAMPAIGN: 'GET_LIST_CAMPAIGN',
  GET_LIST_CAMPAIGN_REQUEST: 'GET_LIST_CAMPAIGN_REQUEST',
  GET_LIST_CAMPAIGN_SUCCESS: 'GET_LIST_CAMPAIGN_SUCCESS',
  GET_LIST_CAMPAIGN_FAILED: 'GET_LIST_CAMPAIGN_FAILED',

  CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILED: 'CREATE_CAMPAIGN_FAILED',

  DELETE_CAMPAIGN_REQUEST: 'DELETE_CAMPAIGN_REQUEST',
  DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_FAILED: 'DELETE_CAMPAIGN_FAILED',

  EDIT_CAMPAIGN_REQUEST: 'EDIT_CAMPAIGN_REQUEST',
  EDIT_CAMPAIGN_SUCCESS: 'EDIT_CAMPAIGN_SUCCESS',
  EDIT_CAMPAIGN_FAILED: 'EDIT_CAMPAIGN_FAILED',
};
