export const PATH_URL = {
  AGENCY: 'agency-management',
  CREATOR: 'users-management',
  CONTACT: 'contacts-management',
  OPERATION_ACCOUNT: 'manage-account-operation',
  NOTIFICATION: 'notification-management',
  FAN: 'fans-management',
  SETTING_SITE: 'setting-site',
  POINT: 'points-management',
  REVENUE: 'revenue-management',
  PAYMENT: 'payment-management',
  SETTING_PAGE: 'setting-page',
  REPORT: 'reports-management',
  EMAIL: 'email-management',
  POST: 'post-management',
  SUBSCRIPTION: 'subscription-management',
  VOUCHER: 'voucher-management',
  SETTING_PAYMENT: 'setting-payment',
  DETAIL_SALE: 'detailed-sales',
};
