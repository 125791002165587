/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  error: null,
  listReport: [],
  pagination: {},
  detailReport: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.UPDATE_STATUS_REPORT_REQUEST:
      return {
        ...state,
      };
    case TYPES.REPORT_DETAIL_REQUEST:
      return {
        ...state,
        status: action.type,
        detailReport: {},
      };
    case TYPES.LIST_REPORTS_REQUEST:
      return {
        ...state,
        status: action.type,
        listReport: [],
        pagination: {},
      };
    case TYPES.LIST_REPORTS_SUCCESS:
      return {
        ...state,
        status: action.type,
        listReport: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        status: action.type,
        detailReport: action.data.data,
      };
    case TYPES.UPDATE_STATUS_REPORT_SUCCESS:
      return {
        ...state,
      };
    case TYPES.REPORT_DETAIL_FAILED:
    case TYPES.LIST_REPORTS_FAILED:
    case TYPES.UPDATE_STATUS_REPORT_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
      };
    default:
      return state;
  }
};
