/* eslint-disable import/no-anonymous-default-export */

const initialState = {
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHECK_ERROR_START':
      return {
        error: {},
      };
    case 'CHECK_ERROR_COMPLETE':
      return {
        error: action.data,
      };
    
    default:
      return state;
  }
};
