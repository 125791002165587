import { Navigate } from 'react-router-dom';
import { PATH_URL } from 'constants/routes/settingUrl';
import { lazy } from 'react';
import { lazyRoute } from 'effect';

const PointPage = lazy(() =>
  lazyRoute(() =>
    import('pages/point').then((module) => ({
      default: module.PointPage,
    }))
  )
);
export const listRoutePointPage = [
  {
    path: `${PATH_URL.POINT}/manage`,
    element: <PointPage />,
    title: 'ポイント付与画面',
    currentPath: `/${PATH_URL.POINT}/manage`,
    backPath: `/${PATH_URL.REVENUE}`,
    showRight: 'showPoint',
  },
  {
    path: `${PATH_URL.POINT}`,
    element: <Navigate to='manage' replace />,
    currentPath: `/${PATH_URL.POINT}/manage`,
  },
];
