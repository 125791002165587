import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';

const campaignApi = {
  getListCampaign: (...data) => `${API_URL_V2}/campaigns?${stringify(...data)}`,
  createCampaign: (data) => `${API_URL_V2}/campaigns`,
  deleteCampaign: (id) => `${API_URL_V2}/campaigns/${id}`,
  editCampaign: (data) => `${API_URL_V2}/campaigns/${data?.id}`,
};

export { campaignApi };
