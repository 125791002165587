import { API_URL, API_URL_V2 } from 'constants';
import { stringify } from 'qs';

const newHost = `${API_URL_V2}/users`;

const fanAPI = {
  getListFanAccount: (...data) => `${newHost}?${stringify(...data)}`,
  getDetailFanAccount: (id) => `${newHost}/${id}`,
  deleteFanAccount: (id) => `${newHost}/${id}`,
  updateFanAccount: (id) => `${newHost}/${id}`,
  getListFanSubcription: (id, ...data) =>
    `${newHost}/${id}/subscription-histories?${stringify(...data)}`,
  getListFanContent: (id, ...data) =>
    `${newHost}/${id}/content-histories?${stringify(...data)}`,
  getListFanPoint: (id, ...data) => `${newHost}/${id}/point-histories?${stringify(...data)}`,
  getListFanDonate: (id, ...data) => `${newHost}/${id}/donate-histories?${stringify(...data)}`,
  getListFanTransaction: (...data) => `${newHost}/transaction-histories?${stringify(...data)}`,
  getListFanPayment: (...data) => `${newHost}/payment-histories?${stringify(...data)}`,
  updateStatusFan: (id) => `${API_URL}/update-status/fan/${id}`,
};

export { fanAPI };
