import {
  ExpandLess,
  ExpandMore,
  AccountCircle,
  Settings,
  Notifications,
  Group,
  Add,
  Send,
  Home,
  Category,
  Comment,
  PermMedia,
  Extension,
} from '@material-ui/icons';
import {
  TableView,
  VisibilitySharp,
  VisibilityOffSharp,
  AttachMoney,
  Paid,
  ManageAccounts,
  Info,
  ArrowBackIos,
  ArrowCircleRight,
  Assignment,
  Face,
  SupervisorAccount,
  CloudSync,
  CameraAlt,
  Redeem,
  KeyboardArrowUp,
  KeyboardArrowDown,
  SupervisedUserCircle,
  Tag,
  Clear,
  FileUploadRounded,
  AddPhotoAlternateOutlined,
  HighlightOff,
  MarkAsUnread,
  MarkUnreadChatAlt,
  Description,
} from '@mui/icons-material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VoucherIcon from 'assets/icons/VoucherIcon';
import IconSubscription from 'assets/imgs/IconSubscription';

const ICON_SEND = <Send />;
const ICON_LESS = <ExpandLess />;
const ICON_MORE = <ExpandMore />;
const ICON_HOME = <Home />;
const ICON_ADD = <Add />;
const ICON_ACCOUNT = <AccountCircle />;
const ICON_SETTINGS = <Settings />;
const ICON_NOTIFICATION = <Notifications />;
const ICON_USER = <Group />;
const ICON_CATEGORY = <Category />;
const ICON_COMMENT = <Comment />;
const ICON_MEDIA = <PermMedia />;
const ICON_EXTENSION = <Extension />;
const ICON_TABLE_VIEW = <TableView />;
const ICON_SALES_MANAMENT = <AttachMoney />;
const ICON_PAID = <Paid />;
const ICON_MANAGE = <ManageAccounts />;
const ICON_INFO = <Info />;
const ICON_BACK = <ArrowBackIos style={{ fontSize: '35px' }} />;
const ICON_LOGOUT = <ArrowCircleRight style={{ fontSize: '40px' }} />;
const ICON_LOG = <Assignment />;
const ICON_CREATOR = <Face />;
const ICON_OPERATION = <SupervisorAccount />;
const ICON_AGENCY = <SupervisedUserCircle />;
const ICON_AFFILICATE = <CloudSync />;
const ICON_CAMERA = <CameraAlt />;
const ICON_GIFT = <Redeem />;
const ICON_UP = <KeyboardArrowUp />;
const ICON_DOWN = <KeyboardArrowDown />;
const ICON_TAG = <Tag />;
const ICON_SHOW_PASSWORD = <VisibilitySharp />;
const ICON_HIDE_PASSWORD = <VisibilityOffSharp />;
const ICON_CLEAR = <Clear />;
const ICON_UPLOAD = <FileUploadRounded />;
const ICON_ADD_PHOTO = <AddPhotoAlternateOutlined />;
const ICON_AWARD = <EmojiEventsIcon />;
const ICON_ACCOUT_MANEMENT = <ManageAccountsIcon />;
const ICON_REPORT = <ReportProblemIcon />;
const ICON_CLEAR_INPUT = <HighlightOff />;
const ICON_EMAIL = <MarkAsUnread />;
const ICON_MESSAGE = <MarkUnreadChatAlt />;
const ICON_POST = <Description />;
const ICON_SUBSCRIPTION = <IconSubscription />;
const ICON_VOUCHER = <VoucherIcon />;

export {
  ICON_SEND,
  ICON_LESS,
  ICON_MORE,
  ICON_HOME,
  ICON_ADD,
  ICON_ACCOUNT,
  ICON_SETTINGS,
  ICON_NOTIFICATION,
  ICON_USER,
  ICON_CATEGORY,
  ICON_COMMENT,
  ICON_MEDIA,
  ICON_EXTENSION,
  ICON_TABLE_VIEW,
  ICON_SALES_MANAMENT,
  ICON_PAID,
  ICON_MANAGE,
  ICON_INFO,
  ICON_BACK,
  ICON_LOGOUT,
  ICON_LOG,
  ICON_CREATOR,
  ICON_OPERATION,
  ICON_AGENCY,
  ICON_AFFILICATE,
  ICON_CAMERA,
  ICON_GIFT,
  ICON_UP,
  ICON_DOWN,
  ICON_TAG,
  ICON_SHOW_PASSWORD,
  ICON_HIDE_PASSWORD,
  ICON_CLEAR,
  ICON_UPLOAD,
  ICON_ADD_PHOTO,
  ICON_AWARD,
  ICON_ACCOUT_MANEMENT,
  ICON_REPORT,
  ICON_CLEAR_INPUT,
  ICON_EMAIL,
  ICON_MESSAGE,
  ICON_POST,
  ICON_SUBSCRIPTION,
  ICON_VOUCHER,
};
