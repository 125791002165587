import Stamp_2 from '../../../assets/stamp/icons/Stamp_2.png';
import Stamp_3 from '../../../assets/stamp/icons/Stamp_3.png';
import Stamp_5 from '../../../assets/stamp/icons/Stamp_5.png';
import Stamp_6 from '../../../assets/stamp/icons/Stamp_6.png';
import Stamp_10 from '../../../assets/stamp/icons/Stamp_10.png';
import Stamp_12 from '../../../assets/stamp/icons/Stamp_12.png';
import Stamp_13 from '../../../assets/stamp/icons/Stamp_13.png';
import Stamp_19 from '../../../assets/stamp/icons/Stamp_19.png';
import Stamp_23 from '../../../assets/stamp/icons/Stamp_23.png';
import Stamp_28 from '../../../assets/stamp/icons/Stamp_28.png';
const StaticImg = {
  Stamp_2,
  Stamp_3,
  Stamp_5,
  Stamp_6,
  Stamp_10,
  Stamp_12,
  Stamp_13,
  Stamp_19,
  Stamp_23,
  Stamp_28,
};

export default StaticImg;
