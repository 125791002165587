import {
  ContentDetail,
  WrapperContentDetail,
  WrapperDetail,
} from 'components/FromFeature/form-components';
import WrapperTable from 'components/Table';
import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailReports, getListReports } from 'store/actions/report.action';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_URL } from 'constants/routes/settingUrl';
import { ReportContext } from 'Context';
import { columns, columnsFan } from '../settings';
import { LanguageContext } from 'Context/translation.context';

export const Table = () => {
  const reportContext = useContext(ReportContext);
  const { tReport } = useContext(LanguageContext);
  const { isCreator, count, query, pushStateParams, dataParams } = reportContext;
  const { listReport, pagination } = useSelector((state) => state.report);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const changePage = useCallback((id) => {
    navigate(isCreator && `/${PATH_URL.REPORT}/manage-reports-users/detail/${id}`, {
      state: {
        prevPath: location?.search,
      },
    });
  }, []);
  const getDetailReport = useCallback((id) => {
    dispatch(
      getDetailReports(
        id,
        () => changePage(id),
        () => {}
      )
    );
  }, []);

  const handleNavigate = (page) => {
    pushStateParams({
      ...dataParams,
      page: page || 1,
    });
  };

  return (
    <>
      <WrapperDetail maxWidth='200px'>
        <WrapperContentDetail justify='end'>
          <ContentDetail>
            {tReport('table.total')}: 
          </ContentDetail>
          <div style={{ marginLeft: "3px"}}>
          {pagination?.total || '---'}件
          </div>
        </WrapperContentDetail>
      </WrapperDetail>
      <WrapperTable
        getRowId={(row) => row['id']}
        columns={isCreator ? columns(getDetailReport) : columnsFan(getDetailReport)}
        total={pagination?.total}
        listRows={listReport}
        countSearch={count}
        query={{ ...query, role_type: 3 }}
        action={getListReports}
        contentNoRow={
          isCreator ? tReport('table.note_creator') : tReport('table.note_no_creator')
        }
        contentNoResult={
          isCreator ? tReport('table.note_creator') : tReport('table.note_no_creator')
        }
        handleNavigate={handleNavigate}
      />
    </>
  );
};
