import React, { useContext, useEffect } from 'react';
import {
  WrapperTitleContent,
  Content,
  WrapperForm,
  WrapperContent,
  FormInputText,
  WrapperButton,
} from 'components/FromFeature/form-components';
import { useForm } from 'react-hook-form';
import { TabsCreator } from './settings/tabs';
import { useDispatch, useSelector } from 'react-redux';
import {
  creatorChangeSettingLimit,
  creatorGetSettingLimit,
} from 'store/actions/creator.action';
import { AlertDialog } from 'components/Dialog';
import { LanguageContext } from 'Context/translation.context';
export const Setting = () => {
  const { tCreator } = useContext(LanguageContext);
  const { limitSetting, error } = useSelector((state) => state.creator);
  const methods = useForm({ mode: 'all', defaultValues: { min_price: '' } });
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    dispatch(creatorGetSettingLimit());
  }, []);

  useEffect(() => {
    setValue('min_price', limitSetting);
  }, [limitSetting]);

  const handleChange = (data) => {
    dispatch(creatorChangeSettingLimit(data, () => dispatch(creatorGetSettingLimit())));
  };
  return (
    <>
      <TabsCreator />
      <WrapperTitleContent>
        <Content>{tCreator('withdrawal_upper_and_lower_limits')}</Content>
      </WrapperTitleContent>
      <WrapperForm maxWidth='400px'>
        <WrapperContent justifyContent>
          <Content minWidth='50px'>{tCreator('lower_limit')}</Content>
          <FormInputText
            control={control}
            width='300px'
            name='min_price'
            label=''
            disabled={error === 'PERMISSION_ERRORS'}
          />
          <Content minWidth='50px' mgl='10px'>
            {tCreator('circle')}
          </Content>
        </WrapperContent>
        <WrapperButton padding='0 60px' justify='end'>
          <AlertDialog
            handleAccpet={handleSubmit(handleChange)}
            updateStatus={tCreator('setting')}
            title={tCreator('setting')}
            content={tCreator('are_you_sure_you_want_to_set_it')}
            propsButton={{
              disabled: error === 'PERMISSION_ERRORS',
            }}
          />
        </WrapperButton>
      </WrapperForm>
    </>
  );
};
