import { TYPES } from './index';
import { callApi } from '../../utils/request';
import { setToken, clearLocal, updateStatus } from 'constants';
import { authAPI, configParamsGETJson, configParamsPOSTNoJson } from 'apis';

const preLogin = (data, navigate = () => {}) => {
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.PRE_SIGN_IN_REQUEST));
    callApi(
      authAPI.postPreLogin,
      configParams,
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PRE_SIGN_IN_SUCCESS, {
              email: data.email,
            })
          );
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.PRE_SIGN_IN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
      // 'LOGIN'
    );
  };
};
const verifyLogin = (data, navigate = () => {}, navigateErr) => {
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SIGN_IN_REQUEST));
    callApi(
      authAPI.postLogin,
      configParams,
      null,
      async (response) => {
        if (response) {
          setToken(response?.data?.access_token);
          await dispatch(
            updateStatus(TYPES.SIGN_IN_SUCCESS, {
              token: response?.data?.access_token,
            })
          );
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.SIGN_IN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        // navigateErr()
      },
      dispatch
      // 'VERIFY_LOGIN'
    );
  };
};

const signOut = (navigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.SIGN_OUT_REQUEST));
    callApi(
      authAPI.postLogout,
      configParamsPOSTNoJson(s, undefined),
      null,
      async (response) => {
        if (response) {
          clearLocal();
          await dispatch(updateStatus(TYPES.SIGN_OUT_SUCCESS));
          await navigate();
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.SIGN_OUT, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        navigate();
      },
      dispatch,
      null,
      // "LOGOUT"
      true
    );
  };
};

const sendEmailResetPassword = (data, navigate = () => {}, navigateErr) => {
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEND_EMAIL_RESET_PASSWORD_REQUEST));
    callApi(
      authAPI.postMailResetPassword(data),
      configParams,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.SEND_EMAIL_RESET_PASSWORD_SUCCESS));
        await navigate();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.SEND_EMAIL_RESET_PASSWORD_FAILED, { error: error.errors })
        );
      },
      dispatch
      // 'VERIFY_LOGIN'
    );
  };
};
const checkTokenResetPassword = (data, navigate = () => {}, navigateErr) => {
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHECK_TOKEN_RESET_PASSWORD_REQUEST));
    callApi(
      authAPI.checkToken(data),
      configParams,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.CHECK_TOKEN_RESET_PASSWORD_SUCCESS));
        await navigate();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CHECK_TOKEN_RESET_PASSWORD_FAILED, { error: error.errors })
        );
        navigateErr();
      },
      dispatch
      // 'VERIFY_LOGIN'
    );
  };
};
const putToResetPassword = (data, navigate = () => {}, navigateErr) => {
  const paramsData = {
    email: data.email,
    password: data.password,
    re_password: data.re_password,
  };
  const configParams = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(paramsData),
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.RESET_PASSWORD_REQUEST));
    callApi(
      authAPI.resetPassword(data.token),
      configParams,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.RESET_PASSWORD_SUCCESS));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.RESET_PASSWORD_FAILED, { error: error.errors }));
      },
      dispatch,
      'RESET_PASSWORD'
    );
  };
};
const getProfileAuth = (cb = () => {}, navigateErr = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_PROFILE_AUTH_REQUEST));
    callApi(
      authAPI.getProfile,
      configParamsGETJson(s),
      null,
      async (response) => {
        if (response) {
          await dispatch(updateStatus(TYPES.GET_PROFILE_AUTH_SUCCESS, response));
          await cb(response);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_PROFILE_AUTH_FAILED, { error: error.errors }));
        navigateErr();
      },
      dispatch
    );
  };
};

const getPermissionAuth = (id, navigate = () => {}, navigateErr = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_PERMISSION_REQUEST));
    callApi(
      authAPI.checkPermission(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        if (response) {
          await dispatch(updateStatus(TYPES.GET_PERMISSION_SUCCESS, response));
          await navigate(response);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_PERMISSION_FAILED, { error: error.errors }));
        navigateErr();
      },
      dispatch
    );
  };
};

export {
  preLogin,
  signOut,
  verifyLogin,
  sendEmailResetPassword,
  checkTokenResetPassword,
  putToResetPassword,
  getProfileAuth,
  getPermissionAuth,
};
