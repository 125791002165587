import TabMenu from 'components/TabMenu';
import { PATH_URL } from 'constants/routes/settingUrl';

export const TabsAgent = () => {
  const navItems = [
    {
      id: 'agent-manage',
      path: `/${PATH_URL.AGENCY}/manage-agency`,
      text: 'アカウント情報閲覧',
    },
    {
      id: 'agent-user',
      path: `/${PATH_URL.AGENCY}/manage-agency-user`,
      text: '所属ユーザー情報閲覧',
    },
    {
      id: 'agent-sale-history',
      path: `/${PATH_URL.AGENCY}/sale-history`,
      text: '販売履歴閲覧',
    },
    {
      id: 'agent-payment',
      path: `/${PATH_URL.AGENCY}/payment-history`,
      text: '支払い履歴',
    },
    // {
    //   id: 'agent-setting',
    //   path: '/${PATH_URL.AGENCY}/setting',
    //   text: '設定',
    // },
  ];

  return <TabMenu tabs={navItems} />;
};
