import styled from 'styled-components';

const WrapperListCard = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${({ gmin }) => gmin || '475px'}, 1fr));
  gap: 30px;
  ${({ marginTopBottom }) =>
    marginTopBottom && `margin-top: ${marginTopBottom}; margin-bottom: ${marginTopBottom}`};
`;
const CardInfo = styled('div')`
  ${({display}) => display && `display: ${display}`};
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: ${({ height }) => height || '740px'};
  min-height: 370px
`;
const ContentCard = styled.div`
  ${({mgt}) => mgt && `margin-top: ${mgt};`}
  ${({bg}) => bg && `background-color: ${bg};`}
  ${({bora}) => bora && `border-radius: ${bora};`}
`;
const ImageCard = styled.img`
  width: 100%;
  cursor: pointer;
  vertical-align: bottom;
  height: ${({ height }) => height || '490px'};
`;
export { WrapperListCard, CardInfo, ContentCard, ImageCard };
