import { LINK_S3 } from 'constants';
import { API_URL_GLOBAL_V2 } from 'constants';
import { getToken } from 'constants';

const linkS3 = ``;
const linkImgS3 = '';
const pathS3 = 'uploads/output/images';
const API_S3 = `${API_URL_GLOBAL_V2}/upload`;
const randomFilename = (type) => {
  const random =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  const randomDate = new Date().getTime();
  const random30number = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
  return `${random}-${randomDate}-${random30number}.${type}`;
};
const uploadFileS3 = (file, url) =>
  fetch(url, {
    method: 'PUT',
    body: file,
  }).then((res) => res.ok);

const getPresignedUrl = async (path = '', type, data) => {
  const typeImg = data?.name?.split('.').pop();
  const params = {
    file_name: randomFilename(typeImg),
    path: `${pathS3}${path}`,
    type: type || 'image',
    upload_type: path.replace('/', ''),
  };
  const getPreUrl = await fetch(API_S3, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then(async (pre) => {
      const status = await uploadFileS3(data, pre.data.pre_signed);
      return status ? pre.data.file_path : false;
    })
    .catch((err) => {
      return false;
    });
  return getPreUrl;
};

export { getPresignedUrl, linkS3, linkImgS3 };
