import { useCallback, useState } from 'react';
import { getPresignedUrl } from 'apis/s3';
import { useDispatch } from 'react-redux';
import { updateStatus } from 'constants';
import ReactImageUploading, { UIDragUpload } from 'components/UploadFiles';
import { pushNotify } from 'components/Notification';
import { UIInputUpload } from 'components/UploadFiles/UIInputUploadImage';
import { VideoInput } from 'components/UploadFiles/UIUploadVideo';
import { UIListImage } from 'components/UploadFiles/UIMultipleImage';
import { ShowVideo } from 'components/UploadFiles/UIShowVideo';

export const useUploadImage = () => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState(null);
  const onChangeImage = (images) => {
    setImages(images);
  };
  const onChangeVideo = (video) => {
    setVideos(video);
  };
  const UploadInputDragDrop = (propsInput) => (
    <ReactImageUploading
      value={images}
      onChange={onChangeImage}
      dataURLKey='data_url'
      acceptType={['jpg', 'gif', 'jpeg', 'png']}
      maxFileSize={10 * 1024 * 1024}
      onError={(e) => {
        e?.acceptType &&
          pushNotify('error', 'jpg, jpeg, pngの画像をアップロードしてください。');
        e?.maxFileSize &&
          pushNotify('error', 'ファイル容量は10MB以内でアップロードしてください。');
      }}>
      {(children) => <UIDragUpload {...propsInput} {...children} />}
    </ReactImageUploading>
  );
  const UploadInputImage = (propsInput) => (
    <ReactImageUploading
      value={images}
      onChange={onChangeImage}
      dataURLKey='data_url'
      acceptType={['jpg', 'gif', 'jpeg', 'png']}
      multiple
      maxFileSize={10 * 1024 * 1024}
      onError={(e) => {
        e?.acceptType &&
          pushNotify('error', 'jpg, jpeg, pngの画像をアップロードしてください。');
        e?.maxFileSize &&
          pushNotify('error', 'ファイル容量は10MB以内でアップロードしてください。');
      }}>
      {(children) => <UIInputUpload {...propsInput} {...children} />}
    </ReactImageUploading>
  );

  const UploadInputImageGif = (propsInput) => (
    <ReactImageUploading
      value={images}
      onChange={onChangeImage}
      dataURLKey='data_url'
      acceptType={['gif']}
      maxFileSize={10 * 1024 * 1024}
      onError={(e) => {
        e?.acceptType && pushNotify('error', 'gifの画像をアップロードしてください。');
        e?.maxFileSize &&
          pushNotify('error', 'ファイル容量は10MB以内でアップロードしてください。');
      }}>
      {(children) => <UIInputUpload {...propsInput} {...children} />}
    </ReactImageUploading>
  );

  const UploadVideo = (propsInput) => <VideoInput setVideos={setVideos} {...propsInput} />;
  const GetUIListImage = (propsInput) => (
    <ReactImageUploading
      value={images}
      onChange={onChangeImage}
      dataURLKey='data_url'
      acceptType={['jpg', 'gif', 'jpeg', 'png']}
      multiple
      maxFileSize={10 * 1024 * 1024}
      onError={(e) => {
        e?.acceptType &&
          pushNotify('error', 'jpg, jpeg, pngの画像をアップロードしてください。');
        e?.maxFileSize &&
          pushNotify('error', 'ファイル容量は10MB以内でアップロードしてください。');
        e?.maxNumber && pushNotify('error', '画像を10枚以内でアップロードしてください。');
      }}>
      {(children) => <UIListImage {...propsInput} {...children} />}
    </ReactImageUploading>
  );
  const GetUIVideo = useCallback(
    (propsInput) => <ShowVideo setVideos={setVideos} videos={videos} {...propsInput} />,
    [videos]
  );
  const handleGetPathFile = (callback, position = '', type = 'image') => {
    dispatch(updateStatus('LOADING_API_REQUEST'));
    getPresignedUrl(position, type, images[0]?.file)
      .then((res) => callback(res))
      .catch((err) => dispatch(updateStatus('LOADING_API_COMPLETE')))
      .finally(() => {});
  };
  const handlePostMulImage = async (callback, position = '', type = '') => {
    dispatch(updateStatus('LOADING_API_REQUEST'));
    let data = [];
    const list = images.map((img) => {
      return getPresignedUrl(position, type, img?.file)
        .then((res) => {
          data.push(res);
        })
        .catch((err) => dispatch(updateStatus('LOADING_API_COMPLETE')))
        .finally(() => {});
    });
    await Promise.all(list).then(() => {
      callback(data);
      setImages([]);
    });
  };
  const handlePostVideo = (callback, position = '', type = '') => {
    dispatch(updateStatus('LOADING_API_REQUEST'));
    getPresignedUrl(position, type, videos)
      .then((res) => {
        callback(res);
      })
      .catch((err) => dispatch(updateStatus('LOADING_API_COMPLETE')))
      .finally(() => {});
  };
  return {
    UploadInputDragDrop,
    handleGetPathFile,
    UploadInputImageGif,
    images,
    videos,
    UploadVideo,
    handlePostMulImage,
    UploadInputImage,
    handlePostVideo,
    GetUIListImage,
    GetUIVideo,
    setImages,
    setVideos,
    onChangeImage,
    onChangeVideo,
  };
};
