import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { linkS3 } from 'apis/s3';
import { AlertDialog } from 'components/Dialog';
import {
  FormInputAsyncSelect,
  FormInputDropdown,
  FormInputRadio,
} from 'components/FromFeature/form-components';
import WrapperTable from 'components/Table';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Text, TooltipHover } from 'components/Text';
import { IMG_ERR } from 'constants';
import { ButtonCCF } from 'styled';
import { checkTypeCell, decryptPath } from 'utils/utils';
import {
  AvatarDefault,
  ColumnContent,
  MainTitle,
  TitleItem,
  WrapperButton,
  WrapperDropdown,
  WrapperHeaderTable,
  WrapperSelectBox,
  WrapperTitle,
} from '../styled';
import { TabsDisplaySetting } from '../tabs';
import {
  addCreator,
  deleteCreatorType2,
  getListChangeTypeListFavoriteGenres,
  getListCheckBoxType,
  getListRecommends,
  getListRecommendsType2,
  postCreatorFavoriteSaved,
  putCreatorFavorite,
} from 'store/actions/settingpage.action';
import { LanguageContext } from '../../../Context/translation.context';
import { getListFavoriteGenres } from 'store/actions/common.action';

const Recommended = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [getLabel, setGetLabel] = useState({ label: 1, value: 1 });
  const [listCreatorID, setListCreatorID] = useState(null);
  const [updateCreatorID, setUpdateCreatorID] = useState(null);
  const [flg, setFlg] = useState(false);
  const { listRecommends, listRecommendsType2, dataCheckBox } = useSelector(
    (state) => state.settingpage
  );
  const { listfavoritegenres } = useSelector((state) => state.common);

  const { tSettingDisplaySite } = useContext(LanguageContext);

  useEffect(() => {
    getFavorite();
    getCheckBoxType();
  }, []);

  const getFavorite = async () => {
    dispatch(await getListFavoriteGenres());
  };

  const getCheckBoxType = async () => {
    dispatch(
      await getListCheckBoxType(
        (data) => {
          setValue('checkedOption_4', `${data}`);
        },
        {
          type: 'suggest',
        }
      )
    );
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      checkedOption_4: `${dataCheckBox.public_flag}` || '0',
      creator_sugg_id: 1,
      tag: dataCheckBox.tag_id,
    },
  });
  const { handleSubmit, reset, control, setValue, watch } = methods;

  let watchCheckOption = watch('checkedOption_4', `${dataCheckBox.public_flag}`);

  const handleSumitForm = (priority_id) => {
    dispatch(
      putCreatorFavorite(
        priority_id,
        {
          type: 'suggest',
          user_id: updateCreatorID.value,
        },
        () => setCount(count + 1)
      )
    );
  };

  useEffect(() => {
    setValue('tag', dataCheckBox.tag_id);
  }, [dataCheckBox]);

  const conlumsType3 = [
    {
      field: '1',
      sortable: false,
      minWidth: 600,
      headerAlign: 'center',
      flex: 3,
      renderHeader: () => {
        return (
          <WrapperHeaderTable borderRight>
            <MainTitle>{tSettingDisplaySite('recommended.currentList')}</MainTitle>
            <WrapperTitle>
              <TitleItem borderRight minWidth='100px'>
                {tSettingDisplaySite('rank')}
              </TitleItem>
              <TitleItem borderRight minWidth='350px'>
                {tSettingDisplaySite('changer')}
              </TitleItem>
              <TitleItem minWidth='150px'>
                {tSettingDisplaySite('recommended.numberOfFollowers')}
              </TitleItem>
            </WrapperTitle>
          </WrapperHeaderTable>
        );
      },
      renderCell: (data) => {
        const { rank, account_id, account_name, avatar, number_follower } = data.row;
        return (
          <WrapperTitle>
            <TitleItem borderRight minWidth='100px'>
              {rank}
            </TitleItem>
            <TitleItem borderRight minWidth='350px'>
              <TitleItem minWidth='100px'>
                <AvatarDefault
                  src={`${linkS3}${decryptPath(avatar)}`}
                  alt=''
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = IMG_ERR;
                  }}
                />
              </TitleItem>
              <ColumnContent minWidth='200px'>
                <TitleItem newJistifyContent='flex-start'>
                  <TooltipHover title={checkTypeCell('text', account_name) || ''}>
                    <Text maxWidth={200}>{checkTypeCell('text', account_name)}</Text>
                  </TooltipHover>
                </TitleItem>
                <TitleItem newJistifyContent='flex-start'>
                  <TooltipHover title={checkTypeCell('text', account_id) || ''}>
                    <Text maxWidth={200}>{checkTypeCell('text', account_id)}</Text>
                  </TooltipHover>
                </TitleItem>
              </ColumnContent>
            </TitleItem>
            <TitleItem minWidth='150px'>{number_follower}</TitleItem>
          </WrapperTitle>
        );
      },
    },
  ];
  const conlumsType2 = [
    {
      field: '2',
      sortable: false,
      minWidth: 700,
      headerAlign: 'center',
      flex: 4,
      renderHeader: () => {
        return (
          <WrapperHeaderTable>
            <MainTitle>{tSettingDisplaySite('recommended.priorityList')}</MainTitle>
            <WrapperTitle>
              <TitleItem borderRight minWidth='50px'>
                {tSettingDisplaySite('rank')}
              </TitleItem>
              <TitleItem borderRight minWidth='300px'>
                {tSettingDisplaySite('changer')}
              </TitleItem>
              <TitleItem borderRight minWidth='150px'>
                {tSettingDisplaySite('recommended.numberOfFollowers')}
              </TitleItem>
              <TitleItem minWidth='200px'></TitleItem>
            </WrapperTitle>
          </WrapperHeaderTable>
        );
      },
      renderCell: (data) => {
        const { rank, account_id, account_name, avatar, number_follower, priority_id } =
          data.row;
        return (
          <WrapperTitle>
            <TitleItem borderRight minWidth='50px'>
              {rank}
            </TitleItem>
            <TitleItem borderRight minWidth='300px'>
              <TitleItem minWidth='100px'>
                <AvatarDefault
                  src={`${linkS3}${decryptPath(avatar)}`}
                  alt=''
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = IMG_ERR;
                  }}
                />
              </TitleItem>
              <ColumnContent minWidth='200px'>
                <TitleItem newJistifyContent='flex-start'>
                  <TooltipHover title={checkTypeCell('text', account_name) || ''}>
                    <Text maxWidth={200}>{checkTypeCell('text', account_name)}</Text>
                  </TooltipHover>
                </TitleItem>
                <TitleItem newJistifyContent='flex-start'>
                  <TooltipHover title={checkTypeCell('text', account_id) || ''}>
                    <Text maxWidth={200}>{checkTypeCell('text', account_id)}</Text>
                  </TooltipHover>
                </TitleItem>
              </ColumnContent>
            </TitleItem>
            <ColumnContent borderRight minWidth='150px'>
              <TitleItem>{number_follower}</TitleItem>
            </ColumnContent>
            <TitleItem minWidth='200px'>
              <TitleItem borderRight minWidth='100px'>
                <AlertDialog
                  addDelete
                  title={tSettingDisplaySite('delete')}
                  content={tSettingDisplaySite('wantToDelete')}
                  handleAccpet={() => handleDeleteCreator(priority_id)}
                  propsButton={{
                    min: '60px',
                    height: '30px',
                    startIcon: <DeleteIcon />,
                    variant: 'text',
                  }}
                />
              </TitleItem>

              <TitleItem minWidth='100px'>
                <AlertDialog
                  styleDialog={{
                    width: 600,
                    height: 500,
                  }}
                  title={tSettingDisplaySite('edit')}
                  buttonCacnel={tSettingDisplaySite('cancel')}
                  buttonConfimrNobutton={tSettingDisplaySite('edit')}
                  handleCancel={() => reset({ ...watch(), to_user_id1: '' })}
                  handleAccpet={() => {
                    handleSumitForm(priority_id);
                    reset({ ...watch(), to_user_id1: '' });
                  }}
                  propsButtonNo={{
                    disabled: watch('to_user_id1') ? false : true,
                  }}
                  addAccept
                  contentElement={
                    <>
                      <FormInputAsyncSelect
                        control={control}
                        name='to_user_id1'
                        type={'user'}
                        enableFiltering={false}
                        hiddenTooltip={true}
                        isSearchable={true}
                        placeholder={tSettingDisplaySite('recommended.accountNameToSearch')}
                        propsForm={{
                          width: '100%',
                          height: 200,
                        }}
                        vl='user_id'
                        lb='account_name'
                        getMulti={(e) => {
                          setUpdateCreatorID(e);
                        }}
                      />
                    </>
                  }
                  propsButton={{
                    startIcon: <EditIcon />,
                    min: '60px',
                    height: '30px',
                  }}
                />
              </TitleItem>
            </TitleItem>
          </WrapperTitle>
        );
      },
    },
  ];

  // Option checked type
  const options = [
    {
      value: '0',
      label: tSettingDisplaySite('showDefaultList'),
    },
    {
      value: '1',
      label: tSettingDisplaySite('priorityList'),
    },
  ];

  let newArr = [];
  listRecommendsType2.forEach((item, index) => {
    if (index < 29) {
      newArr.push({ value: index + 2, label: index + 2 });
    }
  });

  const handleDeleteCreator = (priority_id) => {
    dispatch(deleteCreatorType2(priority_id, () => setCount(count + 1)));
  };

  const { creator_sugg_id, user_id } = watch();
  const onSubmit = () => {
    setFlg(true);
    dispatch(
      getListChangeTypeListFavoriteGenres(
        { tag: listCreatorID ? listCreatorID.value : 0 },
        () => {
          setCount(count + 1);
        }
      )
    );
  };
  return (
    <>
      <TabsDisplaySetting />

      <WrapperDropdown>
        <WrapperSelectBox>
          <div style={{ whiteSpace: 'nowrap', marginRight: '10px', marginTop: '10px' }}>
            ジャンル
          </div>
          <div style={{ width: '350px' }}>
            <FormInputAsyncSelect
              control={control}
              name='tag'
              type={'favoriteGenres'}
              hiddenTooltip={true}
              isSearchable={false}
              placeholder={tSettingDisplaySite('all')}
              propsForm={{
                width: '100%',
                height: 200,
              }}
              vl='id'
              lb='tag'
              defaultValue={{
                value: `${dataCheckBox.tag_id ? dataCheckBox.tag_id : 0}`,
                label: `${
                  dataCheckBox.tag_id
                    ? listfavoritegenres?.find(
                        (e) => Number(e?.id) === Number(dataCheckBox.tag_id)
                      )?.tag
                    : '全て'
                }`,
              }}
              bannerAllOption
              isClearable={false}
              getMulti={(e) => {
                setListCreatorID(e);
              }}
            />
          </div>
        </WrapperSelectBox>
        <ButtonCCF
          style={{
            maxWidth: '10em',
            maxHeight: '10em',
            minWidth: '10em',
            minHeight: '3em',
          }}
          onClick={handleSubmit(onSubmit)}
          variant='contained'
          bgtheme='success'
          className='btn_blue'>
          {tSettingDisplaySite('search')}
        </ButtonCCF>
      </WrapperDropdown>

      <FormInputRadio options={options} control={control} name='checkedOption_4' />

      <div style={{ display: 'flex' }}>
        <WrapperTable
          headerHeight={80}
          columns={conlumsType3}
          page={0}
          countSearch={count}
          getRowId={(row) => row?.id}
          dblock
          limit={30}
          query={{ type: 3, tag: flg ? listCreatorID?.value : dataCheckBox?.tag_id }}
          listRows={listRecommends}
          action={getListRecommends}
          hideFooter
        />
        <WrapperTable
          headerHeight={80}
          columns={conlumsType2}
          page={0}
          countSearch={count}
          getRowId={(row) => row?.id}
          dblock
          limit={30}
          query={{ type: 2 }}
          listRows={listRecommendsType2}
          action={getListRecommendsType2}
          hideFooter
        />
      </div>

      <WrapperButton>
        <AlertDialog
          styleDialog={{
            width: 600,
            height: 500,
          }}
          title={tSettingDisplaySite('addCreatorAccounts')}
          titleCenter
          buttonCacnel={tSettingDisplaySite('cancel')}
          buttonConfimrNobutton={tSettingDisplaySite('toAdd')}
          handleCancel={() => reset({ ...watch(), user_id: '' })}
          propsButtonNo={{
            disabled: user_id ? false : true,
          }}
          handleAccpet={() => {
            dispatch(
              addCreator(
                {
                  user_id: user_id,
                  type: 'suggest',
                  position: creator_sugg_id,
                },
                () => setCount(count + 1)
              )
            );
            reset({ ...watch(), user_id: '' });
          }}
          updateStatus={tSettingDisplaySite('addition')}
          contentElement={
            <>
              <div style={{ marginBottom: '10px' }}>
                {tSettingDisplaySite('recommended.selectCreator')}
              </div>
              <FormInputAsyncSelect
                control={control}
                name='user_id'
                type={'user'}
                enableFiltering={false}
                hiddenTooltip={true}
                placeholder={tSettingDisplaySite('recommended.accountNameToSearch')}
                propsForm={{
                  width: '100%',
                  height: 200,
                }}
                vl='user_id'
                lb='account_name'
                // getMulti={(e) => setListCreatorID(e)}
              />
              <div style={{ marginBottom: '10px' }}>
                {tSettingDisplaySite('recommended.displayPosition')}
              </div>
              <FormInputDropdown
                getLabel={setGetLabel}
                control={control}
                option={[{ value: 1, label: 1 }, ...newArr]}
                name='creator_sugg_id'
                noDefault={true}
              />
            </>
          }
          propsButton={{
            startIcon: <AddIcon />,
          }}
        />

        <AlertDialog
          title={tSettingDisplaySite('keep')}
          updateStatus={tSettingDisplaySite('setting')}
          content={tSettingDisplaySite('wantToSet')}
          handleAccpet={() => {
            dispatch(
              postCreatorFavoriteSaved(
                {
                  type: 'suggest',
                  public_flag: watchCheckOption,
                  tag_id: listCreatorID?.value === 0 ? null : listCreatorID?.value,
                },
                () => {
                  setCount(count + 1);
                  setFlg(true);
                }
              )
            );
          }}
        />
      </WrapperButton>
    </>
  );
};

export { Recommended };
