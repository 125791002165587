import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import { HeaderTopWrapper, ButtonHeader, WrapperButtonLogout } from './styled';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'store/actions/auth.action';
import { listRouterAcceptAuth } from 'routes';
import { PATH_URL } from 'constants/routes/settingUrl';

const HeaderTop = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname, state } = location || '';
  const { id, contract_id } = useParams();
  const { permissions, profile } = useSelector((state) => state.auth);
  let currentUrl = pathname;
  if (id) {
    currentUrl = currentUrl.substring(0, pathname?.length - (id.length + 1));
    if (contract_id) {
      currentUrl = currentUrl.substring(
        0,
        pathname?.length - (id.length + 1) - (contract_id.length + 8)
      );
    }
  }
  const titleScreen = listRouterAcceptAuth(permissions).find(
    (item) => currentUrl === item.currentPath
  );
  const logout = () => {
    dispatch(signOut(() => window.location.reload()));
  };
  // Area import selector from store
  const { detailCSAdmin } = useSelector((state) => state.admin);
  const { detailFan } = useSelector((state) => state.fan);
  const { not_granted } = useSelector((state) => state.point);
  const renderNameHeader = {
    titleDetailPermisson: {
      title: detailCSAdmin?.full_name,
    },
    titleDetailFan: {
      title: detailFan?.full_name,
    },
  };
  const renderRightContent = {
    '': {
      title: '',
    },
    showPoint: {
      title: `（未付与：${not_granted || 0}件）`,
    },
  };

  const pathBack = React.useMemo(() => {
    let url = '';
    if (profile.user_type === 6) {
      url = `/${PATH_URL.REPORT}`;
    } else if (contract_id) {
      url = `/${PATH_URL.CREATOR}/contract/${id}`;
    } else if (state && state.prevUrl) {
      url = state.prevUrl;
    } else if (state && state.prevPath) {
      url = `${titleScreen && titleScreen.backPath}${state.prevPath}`;
    } else {
      url = titleScreen && titleScreen.backPath;
    }
    return url;
  }, [contract_id, id, profile.user_type, titleScreen, state]);

  return !titleScreen ? (
    <></>
  ) : (
    <HeaderTopWrapper>
      {titleScreen?.backPath === '/dashboard' ? (
        <div />
      ) : (
        <Link to={pathBack} className='header-back'>
          <IconButton size='medium'>
            <ArrowBackIcon fontSize='medium' />
          </IconButton>
        </Link>
      )}

      <h3 className='header-top-title'>{`${
        renderNameHeader[titleScreen?.showName || '']?.title || ''
      }${titleScreen?.title}${
        renderRightContent[titleScreen?.showRight || '']?.title || ''
      }`}</h3>
      <WrapperButtonLogout>
        {/* <ListItemButton onClick={handleClick}>
         
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton> */}
        {/* <Collapse in={open} timeout='auto' unmountOnExit> */}
        <ButtonHeader
          bgtheme='primary'
          variant='contained'
          endIcon={<LogoutIcon />}
          onClick={logout}>
          ログアウト
        </ButtonHeader>
        {/* </Collapse> */}
      </WrapperButtonLogout>
    </HeaderTopWrapper>
  );
};

export default HeaderTop;
