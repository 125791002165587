/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  listNotification: [],
  detailNotification: {},
  pagination: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_NOTIFICATION_REQUEST:
      return {
        ...state,
        listNotification: [],
        pagination: {},
      };
    case TYPES.DELETE_NOTIFICATION_REQUEST:
    case TYPES.CREATE_NOTIFICATION_REQUEST:
    case TYPES.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
      };
    case TYPES.GET_DETAIL_NOTIFICATION_REQUEST:
      return {
        ...state,
        detailNotification: {},
      };
    case TYPES.GET_LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        listNotification: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_DETAIL_NOTIFICATION_SUCCESS:
        return {
            ...state,
            detailNotification: action.data.data,
        };
    case TYPES.DELETE_NOTIFICATION_SUCCESS:
    case TYPES.CREATE_NOTIFICATION_SUCCESS:
    case TYPES.UPDATE_NOTIFICATION_SUCCESS:
        return {
            ...state,
        };
    case TYPES.CREATE_NOTIFICATION_FAILED:
    case TYPES.DELETE_NOTIFICATION_FAILED:
    case TYPES.GET_LIST_NOTIFICATION_FAILED:
    case TYPES.GET_DETAIL_NOTIFICATION_FAILED:
    case TYPES.UPDATE_NOTIFICATION_FAILED:
        return {
            ...state
        };

    default:
      return state;
  }
};
