/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  listVoucher: [],
  pagination: {},
  loadingVoucher: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_VOUCHER_REQUEST:
      return {
        ...state,
        //listVoucher: [],
        //pagination: {},
        loadingVoucher: true
      };
    case TYPES.GET_LIST_VOUCHER_SUCCESS:
      return {
        ...state,
        listVoucher: action.data.data,
        pagination: action.data.pagination,
        loadingVoucher: false
      };
    case TYPES.GET_LIST_VOUCHER_FAILED:
      return state;

    case TYPES.CREATE_VOUCHER_REQUEST:
      return {
        ...state,
        //listVoucher: [],
        //pagination: {},
      };
    case TYPES.CREATE_VOUCHER_SUCCESS:
      return {
        ...state,
        listVoucher: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.CREATE_VOUCHER_FAILED:
      return state;

    case TYPES.DELETE_VOUCHER_REQUEST:
      return {
        ...state,
        listVoucher: [],
        pagination: {},
      };
    case TYPES.DELETE_VOUCHER_SUCCESS:
      return {
        ...state,
        listVoucher: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.DELETE_VOUCHER_FAILED:
      return state;

    case TYPES.EDIT_VOUCHER_REQUEST:
      return {
        ...state,
        //listVoucher: [],
        //pagination: {},
      };
    case TYPES.EDIT_VOUCHER_SUCCESS:
      return {
        ...state,
        listVoucher: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.EDIT_VOUCHER_FAILED:
      return state;

    default:
      return state;
  }
};
