import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { WrapperDropdownForm } from './styleSelect';
import { MessError } from './styled';

export const FormInputDropdown = ({
  name,
  control,
  propsForm,
  defaultSelect,
  noDefault = false,
  getLabel,
  placeholder,
  isVoucher,
  ...props
}) => {
  return (
    <WrapperDropdownForm {...propsForm}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              styles={{
                menu: provided => ({ ...provided, zIndex: 998 }),
              }}
              placeholder={placeholder}
              isSearchable={false}
              variant='outlined'
              value={
                isVoucher
                  ? props.option.find((c) => c.value === value)
                  : props.option.find((c) => c.value === value) || { value: '', label: '---' }
              }
              onChange={(val) => {
                onChange(val.value);
                getLabel && getLabel(val.label);
              }}
              options={[{ value: '', label: '---' }, ...props.option].filter((c) => {
                if (noDefault) {
                  return c.label !== '---';
                }
                return c;
              })}
              {...props}
            />
            {error && 
              <MessError bottom='0' marginTop='5px'>
                {error?.message}
              </MessError>
            }
          </>
        )}
        control={control}
        name={name}
      />
    </WrapperDropdownForm>
  );
};
FormInputDropdown.propTypes = {
  option: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  propsForm: PropTypes.object,
};
FormInputDropdown.defaultProps = {
  option: [],
};
