import styled from 'styled-components';
import { FormControl } from '@material-ui/core';
const WrapperDropdownForm = styled(FormControl)`
  width: ${({ width }) => width || '250px'};
  ${({ mbot }) => mbot && `margin-bottom: ${mbot} !important`};
  ${({ mleft }) => mleft && `margin-left: ${mleft} !important`};
  > div {
    pointer-events: all;
  }
  .hide-custom {
    display: none;
    position: absolute;
    left: 220px;
    height: 30px;
    line-height: 30px;
    background: #52525294;
    padding: 0 10px;
    color: #f7f7f7;
    border-radius: 5px;
    width: max-content;
  }

  .select-custom:hover + .hide-custom {
    display: block;
  }
`;
export { WrapperDropdownForm };
