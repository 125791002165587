import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const useRequireAuth = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const location = useLocation();
  const ProtectedRoutes = () =>
    isAuth ? <Outlet /> : <Navigate to='/sign-in' replace state={{ from: location }} />;
  return { ProtectedRoutes, isAuth };
};
