import { Skeleton, Stack } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 18.825rem;
  transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-top: ${({marginTop}) => marginTop || '58px'};
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
  padding: 15px 15px 30px 15px;
  position: relative;
`;
const LazyLoaderPage = () => (
  <Wrapper marginTop="0">
    <Stack spacing={1}>
      <Skeleton variant='text' height='110px' />
      <Skeleton variant='rectangular' width='100%' height='82vh' />
    </Stack>
  </Wrapper>
);
export { Wrapper, LazyLoaderPage };
