import styled from 'styled-components';
import { InputForm } from '../../../components/InputForm';
import { FormGridLayout } from '../../../components/FormGridLayout';
import { ButtonCCF } from 'styled';

const FormSignIn = styled(FormGridLayout)``;

const Input = styled(InputForm)`
  margin-bottom: 16px !important;
`;
const ButtonSubmit = styled(ButtonCCF)`
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 20px 0 !important;
  cursor: pointer;
  vertical-align: middle;
  min-width: 64px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem !important;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  background-image: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(255, 255, 255);
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
  background-size: 150% !important;
  background-position-x: 25% !important;
  &:hover {
    box-shadow: rgb(26 115 232 / 40%) 0rem 0.875rem 1.625rem -0.75rem,
      rgb(26 115 232 / 15%) 0rem 0.25rem 1.4375rem 0rem,
      rgb(26 115 232 / 20%) 0rem 0.5rem 0.625rem -0.3125rem !important;
  }
`;
export { Input, ButtonSubmit, FormSignIn };
