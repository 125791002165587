import { Navigate } from 'react-router-dom';
import { FormEdit } from 'pages/fan/EditForm';
import { DetailRegistration } from 'pages/fan/DetailRegistration';
import { PATH_URL } from 'constants/routes/settingUrl';
import { lazy } from 'react';
import { lazyRoute } from 'effect';
const AccountFanPage = lazy(() =>
  lazyRoute(() =>
    import('pages/fan').then((module) => ({
      default: module.AccountFanPage,
    }))
  )
);
export const listRouteFanAccountPage = [
  {
    path: `${PATH_URL.FAN}/edit/:id`,
    element: <FormEdit isEdit />,
    title: 'ファンアカウント編集画面',
    currentPath: `/${PATH_URL.FAN}/edit`,
    backPath: `/${PATH_URL.FAN}/manage`,
  },
  {
    path: `${PATH_URL.FAN}/manage`,
    element: <AccountFanPage isManage />,
    title: 'ファンアカウント管理画面',
    currentPath: `/${PATH_URL.FAN}/manage`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.FAN}/detail-registration/:id`,
    element: <DetailRegistration isDetail />,
    currentPath: `/${PATH_URL.FAN}/detail-registration`,
    title: 'の登録情報詳細画面',
    backPath: `/${PATH_URL.FAN}/manage`,
    showName: 'titleDetailFan',
  },
  {
    path: `${PATH_URL.FAN}/history-subscription/:id`,
    element: <DetailRegistration type='subscription' isNotDetail />,
    currentPath: `/${PATH_URL.FAN}/history-subscription`,
    title: 'の登録情報詳細画面',
    backPath: `/${PATH_URL.FAN}/manage`,
    showName: 'titleDetailFan',
  },
  {
    path: `${PATH_URL.FAN}/history-content/:id`,
    element: <DetailRegistration type='content' isNotDetail />,
    currentPath: `/${PATH_URL.FAN}/history-content`,
    title: 'の登録情報詳細画面',
    backPath: `/${PATH_URL.FAN}/manage`,
    showName: 'titleDetailFan',
  },
  {
    path: `${PATH_URL.FAN}/history-points/:id`,
    element: <DetailRegistration type='point' isNotDetail />,
    currentPath: `/${PATH_URL.FAN}/history-points`,
    title: 'の登録情報詳細画面',
    backPath: `/${PATH_URL.FAN}/manage`,
    showName: 'titleDetailFan',
  },
  {
    path: `${PATH_URL.FAN}/history-donate/:id`,
    element: <DetailRegistration type='donate' isNotDetail />,
    currentPath: `/${PATH_URL.FAN}/history-donate`,
    title: 'の登録情報詳細画面',
    backPath: `/${PATH_URL.FAN}/manage`,
    showName: 'titleDetailFan',
  },
  {
    path: `${PATH_URL.FAN}/purchase-history`,
    element: <AccountFanPage isHistoryPayment />,
    title: 'ファンアカウント管理画面',
    currentPath: `/${PATH_URL.FAN}/purchase-history`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.FAN}/point-history`,
    element: <AccountFanPage isPointHistory />,
    title: 'ファンアカウント管理画面',
    currentPath: `/${PATH_URL.FAN}/point-history`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.FAN}`,
    element: <Navigate to='manage' replace />,
    currentPath: `/${PATH_URL.FAN}/manage`,
  },
];
