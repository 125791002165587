import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './notification.css';

toast.configure();
// eslint-disable-next-line consistent-return
const pushNotify = (type, message, id) => {

  if (!toast.isActive(id)) {
    toast.dismiss();
    const customMessage = message?.split(',,')?.join(`\n`);
    switch (type) {
      case 'success':
        return toast.success(customMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          toastId: id,
          theme: 'colored',
          className: 'display-linebreak',
        });
      case 'error':
        return toast.error(customMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          // toastId: 'refresh',
          toastId: id,
          theme: 'colored',
          className: 'display-linebreak',
        });
      case 'warn':
        return toast.warn(customMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          toastId: id,
          theme: 'colored',
          className: 'display-linebreak',
        });
      case 'info':
        return toast.info(customMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          toastId: id,
          theme: 'colored',
          className: 'display-linebreak',
        });
      default:
        return toast(customMessage);
    }
  }
};


// eslint-disable-next-line react/prefer-stateless-function
class Notification extends Component {
  render() {
    return <ToastContainer hideProgressBar autoClose={3000} closeOnClick />;
  }
}

export default Notification;
export { pushNotify };
