const lazyRoute = (lazyComponent, attemptsLeft = 3, interval = 500) =>
  new Promise((resolve, reject) => {
    lazyComponent()
      .then((component) => {
        setTimeout(() => resolve(component), interval);
      })
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          lazyRoute(lazyComponent, attemptsLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export * from './loading';
export { lazyRoute };
