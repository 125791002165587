import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Content,
  WrapperContent,
  WrapperButtonForm,
  ButtonSubmit,
  WrapperForm,
} from 'components/FromFeature/form-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAccountContract,
  getDetailContract,
  updateUserContract,
} from 'store/actions/creator.action';
import { useNavigate, useParams } from 'react-router-dom';
import { Lightbox } from 'react-modal-image';
import { ImgShowDetail, WrapperShowImgDetail } from '../styled';

import { paramsEdit } from './contractForm';
import { AlertDialog } from 'components/Dialog';
import { schemaEdit } from './schema';
import { getRenderInput } from 'constants';
import { linkS3 } from 'apis/s3';
import { decryptPath } from 'utils/utils';
import { PATH_URL } from 'constants/routes/settingUrl';
import { LanguageContext } from 'Context/translation.context';
import { LINK_S3 } from 'constants';
const DetailContract = () => {
  const { tCreator } = useContext(LanguageContext);
  const methods = useForm({
    mode: 'all',
    defaultValues: paramsEdit,
    resolver: yupResolver(schemaEdit),
  });
  const { handleSubmit, control, setValue, watch } = methods;
  const { id, contract_id } = useParams();
  const dispatch = useDispatch();
  const navagation = useNavigate();
  const { detailContract } = useSelector((state) => state.creator);
  useEffect(() => {
    Object.keys(detailContract).length === 0 &&
      dispatch(
        getDetailContract(
          id,
          contract_id,
          () => {},
          () => navagation(`/${PATH_URL.CREATOR}/contract/${id}`)
        )
      );
  }, []);
  useEffect(() => {
    if (!detailContract) return;
    if (Object.keys(detailContract).length > 0) {
      for (let key in paramsEdit) {
        detailContract[key] !== null && setValue(key, detailContract[key]);
      }
    }
  }, [detailContract]);
  const [isOpenFront, setIsopenFront] = useState([]);
  const handleShowImage = (key, value) => {
    setIsopenFront([key, value]);
  };
  const handleUpdateContract = (data) => {
    const dataToUpdate = () => {
      for (let key in paramsEdit) {
        paramsEdit[key] = data[key];
      }
      return paramsEdit;
    };

    dispatch(
      updateUserContract(
        dataToUpdate(),
        id,
        contract_id,
        () => navagation(`/${PATH_URL.CREATOR}/contract/${id}`),
        paramsEdit
      )
    );
  };
  const deleteCreator = () => {
    dispatch(
      deleteAccountContract(id, contract_id, () =>
        navagation(`/${PATH_URL.CREATOR}/contract/${id}`)
      )
    );
  };
  const render = [
    {
      content: '性',
      name: 'last_name',
      control,
      type: 'text',
      props: {
        width: '480px',
      },
    },
    {
      content: '性（ローマ字）',
      name: 'last_name_romaji',
      control,
      type: 'text',
      props: {
        width: '480px',
      },
    },
    {
      content: '名',
      name: 'first_name',
      control,
      type: 'text',
      props: {
        width: '480px',
      },
    },
    {
      content: '名（ローマ字）',
      name: 'first_name_romaji',
      control,
      type: 'text',
      props: {
        width: '480px',
      },
    },
    {
      content: '契約書',
      name: '',
      type: '',
      render: (
        <a
          href={`${LINK_S3}/${decryptPath(watch('contract_file'))}`}
          download={`${LINK_S3}/${decryptPath(watch('contract_file'))}`}>
          {watch('contract_file').split('/').pop()}
        </a>
      ),
    },
    {
      content: tCreator('form_1.image'),
      name: '',
      type: '',
      newProps: {
        height: '132px',
        marginBotTop: '20px',
        width: '300px'
      },
      render: (
        <WrapperShowImgDetail>
          {watch('avatar') && (
            <ImgShowDetail
              onClick={() => handleShowImage(`${linkS3}${decryptPath(watch('avatar'))}`, true)}
              src={watch('avatar') && `${linkS3}${decryptPath(watch('avatar'))}`}
              alt=''
            />
          )}

          {watch('avatar_identify') && (
            <ImgShowDetail
              onClick={() =>
                handleShowImage(`${linkS3}${decryptPath(watch('avatar_identify'))}`, true)
              }
              src={
                watch('avatar_identify') && `${linkS3}${decryptPath(watch('avatar_identify'))}`
              }
              alt=''
            />
          )}

          {watch('identify_img_front') && (
            <ImgShowDetail
              onClick={() =>
                handleShowImage(`${linkS3}${decryptPath(watch('identify_img_front'))}`, true)
              }
              src={
                watch('identify_img_front') &&
                `${linkS3}${decryptPath(watch('identify_img_front'))}`
              }
              alt=''
            />
          )}

          {watch('identify_img_back') && (
            <ImgShowDetail
              onClick={() =>
                handleShowImage(`${linkS3}${decryptPath(watch('identify_img_back'))}`, true)
              }
              src={
                watch('identify_img_back') &&
                `${linkS3}${decryptPath(watch('identify_img_back'))}`
              }
              alt=''
            />
          )}
        </WrapperShowImgDetail>
      ),
    },
  ];

  const contentDetail = (
    <WrapperForm>
      {isOpenFront[1] && (
        <Lightbox
          small={isOpenFront[0]}
          medium={isOpenFront[0]}
          large={isOpenFront[0]}
          onClose={() => handleShowImage(isOpenFront[0], false)}
          showRotate
          hideDownload
          className='modal-image'
        />
      )}
      {render.map((item, index) => {
        let { content, show = true, type } = item;
        return (
          <WrapperContent key={index} {...item?.newProps}>
            {show && <Content>{content}</Content>}
            {type === 'text' ? <div>{getRenderInput(item)}</div> : getRenderInput(item)}
          </WrapperContent>
        );
      })}
    </WrapperForm>
  );

  return (
    <>
      {contentDetail}
      <WrapperButtonForm justifyContent='center' wrap='true' width='600px' gap='5px'>
        <ButtonSubmit
          onClick={() => navagation(`/${PATH_URL.CREATOR}/contract/${id}`)}
          variant='outlined'>
          {tCreator('edit_form.cancel')}
        </ButtonSubmit>
        <AlertDialog
          propsButton={{
            disabled: detailContract?.user?.deleted_at !== null,
          }}
          handleAccpet={handleSubmit(handleUpdateContract)}
          addAccept
          title={'出演者情報編集'}
          content={'出演者情報を編集してもよろしいですか？'}
        />

        <AlertDialog
          handleAccpet={deleteCreator}
          addDelete
          propsButton={{
            disabled: detailContract?.user?.deleted_at !== null,
          }}
          title={'出演者情報削除'}
          content={'出演者情報を削除してもよろしいでしょうか。'}
        />
      </WrapperButtonForm>
    </>
  );
};
export { DetailContract };
