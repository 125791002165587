import TabMenu from 'components/TabMenu';
export default function TabsParent() {
  const navItems = [
    {
      id: '全般',
      path: [`/setting-site/general-setting`],
      text: '全般',
    },
    {
      id: 'カテゴリー',
      path: ['/setting-site/setting-category-contact', '/setting-site/setting-category-report'],
      text: 'カテゴリー',
    },
    {
      id: 'エフェクト・スタンプ',
      path: ['/setting-site/setting-effect'],
      text: 'エフェクト・スタンプ',
    },
    {
      id: 'ポイント',
      path: ['/setting-site/setting-point'],
      text: 'ポイント',
    },
    {
      id: 'PAYMENT_METHODS',
      path: ['/setting-site/setting-payment'],
      text: 'お支払い方法',
    },
  ];

  return (
    <TabMenu tabs={navItems} />
  );
}
