import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
  settingSiteAPI,
} from 'apis';
import { pushNotify } from 'components/Notification';

const getListPackagePayment = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_REQUEST));
    callApi(
      settingSiteAPI.getListPackagePayment(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const getListPackagePaymentCampaignPoints = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS_REQUEST));
    callApi(
      settingSiteAPI.getListPackagePayment(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS_SUCCESS, response)
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_CAMPAIGN_POINTS_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const getListSettingSystem = () => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_SETTING_SYSTEM_REQUEST));
    callApi(
      settingSiteAPI.getListSettingSystem(),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_SETTING_SYSTEM_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_SETTING_SYSTEM_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const postCreatePackagePayment = (data, action) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_PACKAGE_PAYMENT_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createPackagePayment,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_PACKAGE_PAYMENT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_PACKAGE_PAYMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATED_SETTING_PACKAGE_PAYMENT'
    );
  };
};

const deletePackagePayment = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_PACKAGE_PAYMENT_REQUEST));
    callApi(
      settingSiteAPI.deletePackagePayment(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_PACKAGE_PAYMENT_SUCCESS, response));
        await dispatch(action);
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_PACKAGE_PAYMENT_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETED_SETTING_PACKAGE_PAYMENT'
    );
  };
};

const putCreatorSettingPackagePayment = (data, action, actionNext) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.PUT_SETTING_PACKAGE_PAYMENT_REQUEST));
    callApi(
      settingSiteAPI.putSettingPackagePayment,
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PUT_SETTING_PACKAGE_PAYMENT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
          await dispatch(actionNext);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.PUT_SETTING_PACKAGE_PAYMENT_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'UPDATED_SETTING_PACKAGE_PAYMENT'
    );
  };
};

const getListPackagePaymentPoint = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_POINT_REQUEST));
    callApi(
      settingSiteAPI.getListPackagePaymentPoint(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_POINT_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_PACKAGE_PAYMENT_POINT_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const postCreatePackagePaymentPoint = (data, action) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_PACKAGE_PAYMENT_POINT_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createPackagePaymentPoint,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_PACKAGE_PAYMENT_POINT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATE_PACKAGE_PAYMENT_POINT_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATED_SETTING_PACKAGE_PAYMENT_POINT'
    );
  };
};
const getListSettingCategoryContact = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_SETTING_CATEGORY_CONTACT_REQUEST));
    callApi(
      settingSiteAPI.getListSettingCategoryContact(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_SETTING_CATEGORY_CONTACT_SUCCESS, response)
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_SETTING_CATEGORY_CONTACT_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const postCreateSettingCategoryContact = (data, action) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_SETTING_CATEGORY_CONTACT_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createSettingCategoryContact,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_SETTING_CATEGORY_CONTACT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATE_SETTING_CATEGORY_CONTACT_FAILED, { error: error.errors })
        );
        const getError = error.errors?.message[0];
        switch (getError) {
          case 'CATEGORY_NAME_IS_UNIQUE':
            pushNotify('error', 'このカテゴリー名は既存しました。');
            break;
          case 'CATEGORY_NAME_EN_IS_UNIQUE':
            pushNotify('error', 'このカテゴリー名（英語）は既存しました。');
            break;
          default:
            break;
        }
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATED_SETTING_CATEGORY_CONTACT',
      true
    );
  };
};

const deleteSettingCategoryContact = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_SETTING_CATEGORY_CONTACT_REQUEST));
    callApi(
      settingSiteAPI.deleteSettingCategoryContact(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_SETTING_CATEGORY_CONTACT_SUCCESS, response));
        await dispatch(action);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.DELETE_SETTING_CATEGORY_CONTACT_FAILED, { error: error.errors })
        );
      },
      dispatch,
      'DELETE_SETTING_CATEGORY_CONTACT'
    );
  };
};

const putCreatorSettingCategoryContact = (data, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.PUT_SETTING_CATEGORY_CONTACT_REQUEST));
    callApi(
      settingSiteAPI.putSettingCategoryContact,
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PUT_SETTING_CATEGORY_CONTACT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.PUT_SETTING_CATEGORY_CONTACT_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'UPDATED_SETTING_CATEGORY_CONTACT'
    );
  };
};

const getListSettingCategoryReport = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_SETTING_CATEGORY_REPORT_REQUEST));
    callApi(
      settingSiteAPI.getListSettingCategoryReport(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_SETTING_CATEGORY_REPORT_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_SETTING_CATEGORY_REPORT_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const postCreateSettingCategoryReport = (data, action) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_SETTING_CATEGORY_REPORT_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createSettingCategoryReport,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_SETTING_CATEGORY_REPORT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATE_SETTING_CATEGORY_REPORT_FAILED, { error: error.errors })
        );
        const getError = error.errors?.message[0];
        switch (getError) {
          case 'NAME_EXISTS':
            pushNotify('error', 'このカテゴリー名は既存しました。');
            break;
          case 'NAME_EN_EXISTS':
            pushNotify('error', 'このカテゴリー名（英語）は既存しました。');
            break;
          default:
            break;
        }
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATED_SETTING_REPORT',
      true
    );
  };
};

const deleteSettingCategoryReport = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_SETTING_CATEGORY_REPORT_REQUEST));
    callApi(
      settingSiteAPI.deleteSettingCategoryReport(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_SETTING_CATEGORY_REPORT_SUCCESS, response));
        await dispatch(action);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.DELETE_SETTING_CATEGORY_REPORT_FAILED, { error: error.errors })
        );
      },
      dispatch,
      'DELETED_SETTING_REPORT'
    );
  };
};

const putCreatorSettingCategoryReport = (data, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.PUT_SETTING_CATEGORY_REPORT_REQUEST));
    callApi(
      settingSiteAPI.putSettingCategoryReport,
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PUT_SETTING_CATEGORY_REPORT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.PUT_SETTING_CATEGORY_REPORT_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'UPDATED_SETTING_REPORT'
    );
  };
};

const getListSettingEffect = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_SETTING_EFFECT_REQUEST));
    callApi(
      settingSiteAPI.getListSettingEffect(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_SETTING_EFFECT_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_SETTING_EFFECT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const postCreateSettingEffect = (data, action) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_SETTING_EFFECT_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createSettingEffect,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_SETTING_EFFECT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_SETTING_EFFECT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATED_SETTING_EFFECT'
    );
  };
};

const deleteSettingEffect = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_SETTING_EFFECT_REQUEST));
    callApi(
      settingSiteAPI.deleteSettingEffect(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_SETTING_EFFECT_SUCCESS, response));
        await dispatch(action);
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_SETTING_EFFECT_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_SETTING_EFFECT'
    );
  };
};

const putCreatorSettingEffect = (data, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.PUT_SETTING_EFFECT_REQUEST));
    callApi(
      settingSiteAPI.putSettingEffect,
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PUT_SETTING_EFFECT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.PUT_SETTING_EFFECT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'UPDATED_SETTING_EFFECT'
    );
  };
};

const getListSettingMessageDonate = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_SETTING_MESSAGE_DONATE_REQUEST));
    callApi(
      settingSiteAPI.getListSettingMessageDonate(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_SETTING_MESSAGE_DONATE_SUCCESS, response.data));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_SETTING_MESSAGE_DONATE_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const postCreateSettingMessageDonate = (data, action) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_SETTING_MESSAGE_DONATE_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createSettingMessageDonate,
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_SETTING_MESSAGE_DONATE_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATE_SETTING_MESSAGE_DONATE_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATED_SETTING_EFFECT'
    );
  };
};

const deleteSettingMessageDonate = (id, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_SETTING_MESSAGE_DONATE_REQUEST));
    callApi(
      settingSiteAPI.deleteSettingMessageDonate(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_SETTING_MESSAGE_DONATE_SUCCESS, response));
        await dispatch(action);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.DELETE_SETTING_MESSAGE_DONATE_FAILED, { error: error.errors })
        );
      },
      dispatch,
      'DELETE_SETTING_EFFECT'
    );
  };
};

const putCreatorSettingMessageDonate = (data, action) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.PUT_SETTING_MESSAGE_DONATE_REQUEST));
    callApi(
      settingSiteAPI.putSettingMessageDonate,
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PUT_SETTING_MESSAGE_DONATE_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.PUT_SETTING_MESSAGE_DONATE_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'UPDATED_SETTING_EFFECT'
    );
  };
};

const getListSettingSite = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_SETTING_SITE_REQUEST));
    callApi(
      settingSiteAPI.getListSettingSite(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_SETTING_SITE_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_SETTING_SITE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const getListSettingPayment = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_SETTING_PAYMENT_REQUEST));
    callApi(
      settingSiteAPI.getListSettingPayment(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_SETTING_PAYMENT_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_SETTING_PAYMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};

const postCreatePayment = (data, action) => {
  return (dispatch, s) => {
    dispatch(
      updateStatus(TYPES.CREATE_PAYMENT_REQUEST, {
        create: data,
      })
    );
    callApi(
      settingSiteAPI.createPayment,
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.CREATE_PAYMENT_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_PAYMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      'CREATED_SETTING_METHOD'
    );
  };
};

const putCreatorSettingSite = (type, data, action, typeNoti) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.PUT_SETTING_SITE_REQUEST));
    callApi(
      settingSiteAPI.putSettingSite(type),
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if (response) {
          await dispatch(
            updateStatus(TYPES.PUT_SETTING_SITE_SUCCESS, {
              res: response,
            })
          );
          await dispatch(action);
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.PUT_SETTING_SITE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      typeNoti === 'STOP'
        ? 'MESSAGE_SUCCESS_STOP'
        : typeNoti === 'START'
        ? 'MESSAGE_SUCCESS_START'
        : 'UPDATE_SETTING_SITE'
    );
  };
};
// Creator effect
const getEffectCreator = (params, event) => {
  return (dispatch, store) => {
    dispatch(updateStatus(TYPES.GET_EFFECT_CREATOR_REQUEST));
    callApi(
      settingSiteAPI.getEffectCreator(params),
      configParamsGETJson(store),
      null,
      async (res) => {
        await dispatch(updateStatus(TYPES.GET_EFFECT_CREATOR_SUCCESS, res));
        event(res.data);
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_EFFECT_CREATOR_FAILED));
      },
      dispatch
    );
  };
};
const postEffectCreator = (params, event = () => {}) => {
  return (dispatch, store) => {
    dispatch(updateStatus(TYPES.POST_EFFECT_CREATOR_REQUEST));
    callApi(
      settingSiteAPI.postEffectCreator,
      configParamsPOSTJson(store, params),
      null,
      async (res) => {
        await dispatch(updateStatus(TYPES.POST_EFFECT_CREATOR_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(TYPES.POST_EFFECT_CREATOR_FAILED));
      },
      dispatch,
      'CREATE_EFFECT_CREATOR',
      null,
      event
    );
  };
};
const updateEffectCreator = (data, event) => {
  return (dispatch, store) => {
    dispatch(updateStatus(TYPES.PUT_EFFECT_CREATOR_REQUEST));
    callApi(
      settingSiteAPI.updateEffectCreator,
      configParamsPUTJson(store, data),
      null,
      async (res) => {
        await dispatch(updateStatus(TYPES.PUT_EFFECT_CREATOR_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(TYPES.PUT_EFFECT_CREATOR_FAILED));
      },
      dispatch,
      'UPDATE_EFFECT_CREATOR',
      null,
      event
    );
  };
};
const deleteEffectCreator = (id, event) => {
  return (dispatch, store) => {
    dispatch(updateStatus(TYPES.DELETE_EFFECT_CREATOR_REQUEST));
    callApi(
      settingSiteAPI.deleteEffectCreator(id),
      configParamsPOSTJsonDelete(store),
      null,
      async (res) => {
        await dispatch(updateStatus(TYPES.DELETE_EFFECT_CREATOR_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(TYPES.DELETE_EFFECT_CREATOR_FAILED));
      },
      dispatch,
      'DELETE_EFFECT_CREATOR',
      null,
      event
    );
  };
};
const ActionCreatorEffect = {
  getEffectCreator,
  updateEffectCreator,
  deleteEffectCreator,
  postEffectCreator,
};
export {
  getListPackagePayment,
  getListPackagePaymentCampaignPoints,
  getListSettingSystem,
  postCreatePackagePayment,
  deletePackagePayment,
  putCreatorSettingPackagePayment,
  getListPackagePaymentPoint,
  postCreatePackagePaymentPoint,
  getListSettingCategoryContact,
  postCreateSettingCategoryContact,
  deleteSettingCategoryContact,
  putCreatorSettingCategoryContact,
  getListSettingCategoryReport,
  postCreateSettingCategoryReport,
  deleteSettingCategoryReport,
  putCreatorSettingCategoryReport,
  getListSettingEffect,
  postCreateSettingEffect,
  deleteSettingEffect,
  putCreatorSettingEffect,
  getListSettingSite,
  putCreatorSettingSite,
  getListSettingMessageDonate,
  postCreateSettingMessageDonate,
  deleteSettingMessageDonate,
  putCreatorSettingMessageDonate,
  ActionCreatorEffect,
  getListSettingPayment,
  postCreatePayment
};
