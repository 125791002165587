/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  listPost: [],
  pagination: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_POST_REQUEST:
    case TYPES.APPROVE_POST_REQUEST:
      return {
        ...state,
        listPost: [],
        pagination: {},
      };
    case TYPES.GET_LIST_POST_SUCCESS:
    case TYPES.APPROVE_POST_SUCCESS:
      return {
        ...state,
        listPost: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_POST_FAILED:
    case TYPES.APPROVE_POST_FAILED:
      return state;

    default:
      return state;
  }
};
