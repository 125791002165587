import { PATH_URL } from 'constants/routes/settingUrl';
import { AccountCS } from 'pages/account/cs-account';
import { FormEdit } from 'pages/account/EditForm';
import { AccountLogManagement } from 'pages/account/log-management';
import { PermissionsAccount } from 'pages/account/permissions-account';
import { lazy } from 'react';
import { lazyRoute } from 'effect';
import { Navigate } from 'react-router-dom';

const AccountAdministrator = lazy(() =>
  lazyRoute(() =>
    import('pages/account/administrator/index').then((module) => ({
      default: module.AccountAdministrator,
    }))
  )
);
export const listRouteManageAccountOperationPage = [
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/administrator`,
    element: <AccountAdministrator />,
    title: '運営アカウント管理画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/administrator`,
    backPath: `/${PATH_URL.REVENUE}`,

  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/administrator/create`,
    element: <FormEdit isAdmin />,
    title: 'Administratorアカウント新規登録画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/administrator/create`,
    backPath: `/${PATH_URL.OPERATION_ACCOUNT}/administrator`,
  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/administrator/edit/:id`,
    element: <FormEdit isEdit />,
    title: 'Administratorアカウント編集画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/administrator/edit`,
    backPath: `/${PATH_URL.OPERATION_ACCOUNT}/administrator`,
  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/cs-account`,
    element: <AccountCS />,
    title: '運営アカウント管理画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account`,
    backPath: `/${PATH_URL.REVENUE}`,

  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/cs-account/create`,
    element: <FormEdit isCsCreate />,
    title: 'CSアカウント新規登録画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account/create`,
    backPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account`,
  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/cs-account/edit/:id`,
    element: <FormEdit isCsEdit />,
    title: 'CSアカウント編集画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account/edit`,
    backPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account`,
  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/cs-account/update-role/:id`,
    element: <div />,
    title: 'OOのCSアカウント権限変更画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account/update-role`,
    backPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account`,
    disabled: true,
  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/log-management`,
    element: <AccountLogManagement />,
    title: '運営アカウント管理画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/log-management`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}/cs-account/permissions/:id`,
    element: <PermissionsAccount />,
    title: 'のCSアカウント権限変更画面',
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account/permissions`,
    backPath: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account`,
    showName: 'titleDetailPermisson',
  },
  {
    path: `${PATH_URL.OPERATION_ACCOUNT}`,
    currentPath: `/${PATH_URL.OPERATION_ACCOUNT}/administrator`,
    element: <Navigate to='administrator' replace />,
  },
];
