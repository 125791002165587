import TabMenu from 'components/TabMenu';
import { LanguageContext } from 'Context/translation.context';
import { useContext } from 'react';

const TabsDisplaySetting = () => {
  const { tSettingDisplaySite} = useContext(LanguageContext);
  const navItems = [
    // {
    //   id: '⼈気クリエイター',
    //   path: '/setting-page/creator-favorite',
    //   text: tSettingDisplaySite("recommended.popularCreator"),
    // },
    // {
    //   id: '新規クリエイター',
    //   path: '/setting-page/new-creator',
    //   text: tSettingDisplaySite("recommended.newCreator"),
    // },
    // {
    //   id: '運営者側からのピックアップ',
    //   path: '/setting-page/operator-side',
    //   text: tSettingDisplaySite("recommended.pickUp"),
    // },
    // {
    //   id: 'ランキング',
    //   path: '/setting-page/ranking-list',
    //   text: tSettingDisplaySite("recommended.ranking"),
    // },
    {
      id: '利⽤者に向けてのおすすめ',
      path: '/setting-page/recommended',
      text:tSettingDisplaySite("recommended.recommendations"),
    },
    // {
    //   id: 'advertisement',
    //   path: '/setting-page/advertisement',
    //   text: tSettingDisplaySite("recommended.advertisement"),
    // },
  ];

  return <TabMenu tabs={navItems} />;
};

export { TabsDisplaySetting };
