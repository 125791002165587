import { yupEndTime } from 'constants';
import { yupContent } from 'constants';
import { yupTitle } from 'constants';
import { yupStartTime } from 'constants';
import * as yup from 'yup';
const schemaCreate = yup.object().shape({
  ...yupTitle(),
  ...yupContent('お知らせ本文を入力してください。'),
  ...yupStartTime(),
  ...yupEndTime(),
});
const schemaEdit = yup.object().shape({
  ...yupTitle(),
  ...yupContent('お知らせ本文を入力してください。'),
  ...yupStartTime(),
  ...yupEndTime(),
});
export { schemaCreate, schemaEdit };
