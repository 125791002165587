import { updateStatus } from 'constants';
import { callApi, logSuccess } from '../../utils/request';
import { TYPES } from './index';
import {
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
  creatorAPI,
  fanAPI,
} from 'apis';
import { formatDateOnly } from 'constants/FilterDate';
import { findMess } from 'constants';
import { DOMAIN_USER } from 'constants';

const getListFanAccount = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_FANS_REQUEST));
    callApi(
      fanAPI.getListFanAccount(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_FANS_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_FANS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getDetailFanAccount = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_FAN_DETAIL_REQUEST));
    callApi(
      fanAPI.getDetailFanAccount(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_FAN_DETAIL_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_FAN_DETAIL_FAILED, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};
const updateFanAccount = (data, id, navigate, errParams) => {
  const params = () => {
    if (data.birthday) {
      data.birthday = formatDateOnly(data.birthday);
    }
    return data;
  };
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_FAN_ACCOUNT_REQUEST));
    callApi(
      fanAPI.updateFanAccount(id),
      configParamsPUTJson(s, params()),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_FAN_ACCOUNT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_FAN_ACCOUNT_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_FAN_ACCOUNT',
      null,
      () => {},
      errParams
    );
  };
};
const deleteAccountFan = (id, navigate) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_FAN_ACCOUNT_REQUEST));
    callApi(
      fanAPI.deleteFanAccount(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_FAN_ACCOUNT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_FAN_ACCOUNT_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETE_FAN_ACCOUNT'
    );
  };
};
const getListFanSubcription = (id, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_FAN_SUBSCRIPTION_REQUEST));
    callApi(
      fanAPI.getListFanSubcription(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_FAN_SUBSCRIPTION_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_FAN_SUBSCRIPTION_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListFanContent = (id, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_FAN_CONTENT_REQUEST));
    callApi(
      fanAPI.getListFanContent(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_FAN_CONTENT_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_FAN_CONTENT_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListFanPoint = (id, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_FAN_POINT_REQUEST));
    callApi(
      fanAPI.getListFanPoint(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_FAN_POINT_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_FAN_POINT_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListFanDonate = (id, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_FAN_DONATE_REQUEST));
    callApi(
      fanAPI.getListFanDonate(id, ...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_FAN_DONATE_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_FAN_DONATE_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListFanPayment = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_FAN_PAYMENT_HISTORY_REQUEST));
    callApi(
      fanAPI.getListFanPayment(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_FAN_PAYMENT_HISTORY_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_FAN_PAYMENT_HISTORY_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getListFanTransaction = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_FAN_POINT_HISTORY_REQUEST));
    callApi(
      fanAPI.getListFanTransaction(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_FAN_POINT_HISTORY_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_FAN_POINT_HISTORY_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const approveFanAccount = (id, func) => {
  return async (dispatch, s) => {
    const statusUser = s().fan.detailFan?.status;
    dispatch(updateStatus(TYPES.APPROVE_FAN_ACCOUNT_REQUEST));
    callApi(
      fanAPI.updateStatusFan(id),
      configParamsPUTJson(s, null),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.APPROVE_FAN_ACCOUNT_SUCCESS, response));
        logSuccess(findMess(statusUser, response.data.status));
        await func();
      },
      (error) => {
        dispatch(updateStatus(TYPES.APPROVE_FAN_ACCOUNT_FAILED, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};

const postAdminLoginCreator = (id_user) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.ADMIN_LOGIN_CREATOR_REQUEST));
    callApi(
      creatorAPI.adminLoginCreator,
      configParamsPOSTJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.ADMIN_LOGIN_CREATOR_SUCCESS, response));
        const tokenCreator = await response.data.access_token;
        window.open(
          `${DOMAIN_USER}/message?adminFanToken=${tokenCreator}&userID=${id_user}`,
          '_blank'
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.ADMIN_LOGIN_CREATOR_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
export {
  deleteAccountFan,
  updateFanAccount,
  getListFanAccount,
  getDetailFanAccount,
  getListFanSubcription,
  getListFanContent,
  getListFanPoint,
  getListFanDonate,
  getListFanPayment,
  getListFanTransaction,
  approveFanAccount,
  postAdminLoginCreator,
};
