export const CONTACT = {
  GET_LIST_GROUP_MESSAGE: 'GET_LIST_GROUP_MESSAGE',
  GET_MESS_OF_GROUP: 'GET_MESS_OF_GROUP',
  CREATE_MESSAGE: 'CREATE_MESSAGE',
  DELETE_GROUP_MESSAGE: 'DELETE_GROUP_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  EDIT_MESSAGE: 'EDIT_MESSAGE',
  CREATE_MULTIPLE_MESSAGE: 'CREATE_MULTIPLE_MESSAGE',
  GET_LIST_AGENCY_LV2_FROM_AGENCY: 'GET_LIST_AGENCY_LV2_FROM_AGENCY',
  CREATE_MULTIPLE_MESSAGE_AGENCY: 'CREATE_MULTIPLE_MESSAGE_AGENCY',
  GET_MORE_GROUP_MESSAGE: 'GET_MORE_GROUP_MESSAGE',
  GET_MORE_MESS_OF_GROUP: 'GET_MORE_MESS_OF_GROUP',
};
