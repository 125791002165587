import { AlertDialog } from 'components/Dialog';
import { FormInputRadio } from 'components/FromFeature/form-components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TabsParent from '../tabParent';
import { FormSettingSite, TitleSettingSite } from './styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCreateSettingSite } from './schema';
import { getListSettingPayment, postCreatePayment } from 'store/actions/settingsite.action';
import { useContext } from 'react';
import { LanguageContext } from '../../../Context/translation.context';

const paramsEdit = {
  radio_limit1: '2',
  radio_limit2: '1',
};

export const SettingPayments = () => {
  const { listSettingPayment } = useSelector((state) => state.settingsite);
  const dispatch = useDispatch();
  const { tSettingsite } = useContext(LanguageContext);

  const methods = useForm({
    mode: 'all',
    defaultValues: paramsEdit,
    resolver: yupResolver(schemaCreateSettingSite),
  });
  const { handleSubmit, control, setValue, watch } = methods;

  useEffect(() => {
    dispatch(getListSettingPayment());
  }, []);

  const options = [
    {
      value: '1',
      label: 'Vendo',
    },
    {
      value: '2',
      label: 'Billmont',
    },
  ];

  useEffect(() => {
    if (listSettingPayment.length > 0) {
      listSettingPayment.forEach((item) => {
        for (let key in paramsEdit) {
          switch (key) {
            case 'radio_limit1':
              if (item.key === '11') {
                setValue(key, item['value']);
              }
              break;
            case 'radio_limit2':
              if (item.key === '12') {
                setValue(key, item['value']);
              }
              break;

            default:
              break;
          }
        }
      });
    }
  }, [listSettingPayment, paramsEdit]);

  const handleSubmitSettingLimit = (data) => {
    const paramPutSettingMethod = [
      {
        key: 11,
        //value: data.value11,
        value: 2
      },
      {
        key: 12,
        value: 1
        //value: data.value12,
      },
    ];
    const value = {
      ids: paramPutSettingMethod,
    };
    dispatch(postCreatePayment(value, getListSettingPayment()));
  };

  return (
    <FormSettingSite>
      <TabsParent />
      <TitleSettingSite>
        <span>ポイント購入</span>
      </TitleSettingSite>
      <div className='form-time-limit'>
        <div className='time-limit'>
          <div className='title'>
            <span>支払いゲートウェイ</span>
          </div>
          <div className='radio-form' style={{ display: 'flex', width: '100%'}}>
            <div className='radio' style={{ display: "flex", width: '10%', alignItems: "center", gap: "15px"}}>
              <input disabled type='radio' checked={false} name='normal' style={{ width: '20px', height: '20px'}} />
              Vendo
            </div>
            <div className='radio' style={{ display: "flex", alignItems: "center", gap: "15px"}}>
              <input type='radio' checked={true} name='campaign' style={{ width: '20px', height: '20px'}}/>
              Billmont
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <TitleSettingSite>
        <span>サブスクリプション</span>
      </TitleSettingSite>

      <div className='form-time-limit'>
        <div className='time-limit'>
          <div className='title'>
            <span>支払いゲートウェイ</span>
          </div>
          <div className='radio-form' style={{ display: 'flex', width: '100%'}}>
            <div className='radio' style={{ display: "flex", width: '10%', alignItems: "center", gap: "15px"}}>
              <input type='radio' checked={true} name='normal' style={{ width: '20px', height: '20px'}} />
              Vendo
            </div>
            <div className='radio' style={{ display: "flex", alignItems: "center", gap: "15px"}}>
              <input disabled type='radio' checked={false} name='campaign' style={{ width: '20px', height: '20px'}} />
              Billmont
            </div>
          </div>
        </div>
        <div className='btn-action-setting'>
          <AlertDialog
            title={tSettingsite('setting')}
            content={tSettingsite('wantToSetIt')}
            updateStatus={tSettingsite('setting')}
            propsButton={{ bgtheme: 'secondary' }}
            handleAccpet={() =>
              handleSubmitSettingLimit({
                key11: 11,
                value11: watch('radio_limit1'),
                key12: 12,
                value12: watch('radio_limit2'),
              })
            }
          />
        </div>
      </div>
    </FormSettingSite>
  );
};
