import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { lazyRoute } from 'effect';

import { PATH_URL } from 'constants/routes/settingUrl';

const SubscriptionPage = lazy(() =>
  lazyRoute(() =>
    import('pages/subscription').then((module) => ({
      default: module.SubscriptionPage,
    }))
  )
);
export const listRouteSubscription = [
  {
    path: `${PATH_URL.SUBSCRIPTION}/manage`,
    element: <SubscriptionPage />,
    title: 'サブスクリプション管理',
    currentPath: `/${PATH_URL.SUBSCRIPTION}/manage`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.SUBSCRIPTION}`,
    element: <Navigate to='manage' replace />,
    currentPath: `/${PATH_URL.SUBSCRIPTION}/manage`,
  },
];
