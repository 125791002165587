import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonSubmit } from 'components/FromFeature/form-element/styledElement';
import { useRef } from 'react';
import { ButtonCCF } from 'styled';

export function AlertDialog({
  noButton = false,
  close = false,
  handleCancel = () => {},
  handleAccpet = () => {},
  addAccept = false,
  noSubmit = true,
  addDelete = false,
  contentElement = null,
  updateStatus = '',
  content = '',
  title = '',
  buttonCacnel = 'いいえ',
  titleCenter = false,
  showCancel = true,
  styleDialog = {},
  propsButton = {},
  propsButtonNo = {},
  contentElementFunc = () => {},
  buttonConfimrNobutton = 'はい',
  onOpen,
  propsDialog,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    onOpen?.();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const refButton = useRef(null);

  return (
    <>
      {!noButton && (
        <ButtonSubmit
          onClick={handleClickOpen}
          variant='contained'
          bgtheme={addDelete ? 'error' : 'primary'}
          {...propsButton}>
          {addDelete ? '削除' : addAccept ? '編集' : updateStatus ? updateStatus : 'button'}
        </ButtonSubmit>
      )}
      <Dialog open={close ? close : open} onClose={handleClose} {...propsDialog}>
        <DialogTitle
          style={{
            ...(titleCenter ? { textAlign: 'center' } : {}),
          }}>
          {title}
        </DialogTitle>
        <DialogContent style={styleDialog}>
          <DialogContentText
            style={{
              minWidth: '400px',
            }}>
            {content}
          </DialogContentText>
          {contentElement}
          {contentElementFunc(handleClose)}
        </DialogContent>
        <DialogActions>
          {noSubmit && (
            <ButtonCCF
              ref={refButton}
              bgtheme='primary'
              variant='contained'
              onClick={() => {
                handleAccpet();
                handleClose();
                refButton.current.disabled = true;
              }}
              {...propsButtonNo}>
              {buttonConfimrNobutton}
            </ButtonCCF>
          )}
          {showCancel && (
            <ButtonCCF
              bgtheme='error'
              variant='contained'
              onClick={() => {
                handleClose();
                handleCancel();
              }}>
              {buttonCacnel}
            </ButtonCCF>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
