import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux';
import 'translate';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { GlobalStyle } from 'styled';
import { LanguageProvider } from 'Context/translation.context';

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider
      children={
        <>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <GlobalStyle />
        </>
      }
    />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
