import styled from 'styled-components';
import { Box, Grid, Paper } from '@material-ui/core';

const WrapperLoginPage = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  color: rgb(52, 71, 103);
  background: linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6)) center
      center / cover no-repeat,
    url(${({ bgi }) => bgi}) transparent;
  background-position: center center;
  background-repeat: no-repeat;
`;
const WrapperLoginForm = styled.div`
  padding: 0 8px;
  height: 100vh;
`;
const WrapperFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
  width: 100%;
  margin: 0 4px;
  height: 100%;
`;
const GridLayout = styled(Grid)``;
const BoxForm = styled(Box)`
  margin: -24px 16px 8px;
  padding: 16px;
  text-align: center;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(52, 71, 103);
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem,
    rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
`;
const TitleForm = styled.h4`
  margin: 8px 0px 0px;
  font-size: 1.5rem;
  line-height: 1.375;
  color: rgb(255, 255, 255);
  font-weight: 600;
`;
const ListLogoForm = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: calc(100% + 24px);
  margin-left: -24px;
  margin-top: 8px;
  /* margin-bottom: 16px; */ // Open when have icons
  margin-bottom: 8px;
`;
const CardForm = styled(Paper)`
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgb(255, 255, 255);
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
`;
const CardFormContent = styled(Box)`
  padding: 32px 24px 24px;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
`;
const FormContent = styled.form`
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
`;
export {
  WrapperLoginPage,
  WrapperLoginForm,
  WrapperFlex,
  GridLayout,
  BoxForm,
  TitleForm,
  CardForm,
  ListLogoForm,
  CardFormContent,
  FormContent,
};
