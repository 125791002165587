import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import { lazy } from 'react';
const RevenuePage = lazy(() =>
  lazyRoute(() =>
    import('pages/revenue').then((module) => ({
      default: module.RevenuePage,
    }))
  )
);
export const listRouteRevenuePage = [
  {
    path: PATH_URL.REVENUE,
    element: <RevenuePage />,
    title: '月別売上一覧',
    currentPath:`/${PATH_URL.REVENUE}`,
    backPath: `/${PATH_URL.REVENUE}`,

  },
  //   {
  //     path: 'payment-management',
  //     element: <Navigate to='creator' replace />,
  //     title: '支払管理',
  //     // currentPath: '/payment-management/creator',
  //     // backPath: '/dashboard',
  //   },
];
