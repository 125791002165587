import {
  ButtonSubmit,
  ContentMin,
  FormInputAsyncSelect,
  FormInputDropdown,
  FormInputText,
  WrapperButton,
  WrapperTitle,
} from 'components/FromFeature/form-components';
import DateSelectGroup from 'components/WrapperSelect/select.date';
import { checkParams } from 'constants';
import { STATUS_REPORT } from 'constants';
import { ReportContext } from 'Context';
import { LanguageContext } from 'Context/translation.context';
import React, { useCallback, useContext, useState } from 'react';
import { enterToSearch } from 'utils/utils';

export const FilterBox = () => {
  const reportContext = useContext(ReportContext);
  const { tReport } = useContext(LanguageContext);
  const { count, dispatchState, control, handleSubmit, pushStateParams, dataParams } = reportContext;
  const [dateValue, setDateValue] = useState({});

  const handleSetCount = useCallback(
    () => dispatchState({ type: 'SET_COUNT', payload: count + 1 }),
    [count]
  );
  const setQuery = useCallback(
    (data) => dispatchState({ type: 'SET_QUERY', payload: data }),
    []
  );
  const onSubmit = useCallback(
    (data) => {
      // setQuery(checkParams({ ...data, ...dateValue }));
      pushStateParams({
        ...data,
        ...dateValue,
        page: 1,
      });
      handleSetCount();
    },
    [count, dateValue]
  );

  return (
    <>
      <WrapperButton wrap='true' gap='10px'>
        <WrapperTitle>
          <ContentMin min='30px'>{tReport('filter.date')}</ContentMin>
          <DateSelectGroup
            initDateTo={dataParams?.end_day}
            initDateFrom={dataParams?.start_day}
            handleSelect={(key, value) => {
              setDateValue((prev) => ({ ...prev, [`${key}`]: value }));
            }}
          />
        </WrapperTitle>
        <WrapperTitle gap='5px'>
          <ContentMin min='70px'> {tReport('filter.status')} </ContentMin>
          <FormInputDropdown name='status' control={control} option={STATUS_REPORT} />
        </WrapperTitle>
        <WrapperTitle gap='5px'>
          <ContentMin min='70px'> {tReport('filter.category')} </ContentMin>
          <FormInputAsyncSelect
            name='category'
            hiddenTooltip
            control={control}
            vl='id'
            lb='name'
            type='report_category'
            propsForm={{
              mbot: '0',
            }}
          />
        </WrapperTitle>
        <WrapperButton width='450px'>
          <FormInputText
            name='keyword'
            inputProps={{
              onKeyDown: (e) => {
                enterToSearch(e, handleSubmit(onSubmit));
              },
            }}
            isSearch
            control={control}
            label={tReport('filter.label')}
          />
          <ButtonSubmit onClick={handleSubmit(onSubmit)} variant='contained' bgtheme='primary'>
            {tReport('filter.submit')}
          </ButtonSubmit>
        </WrapperButton>
      </WrapperButton>
    </>
  );
};
