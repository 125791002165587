/* eslint-disable import/no-anonymous-default-export */

const initialState = {
  loading: false,
  loadingProfile: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_API_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'LOADING_PROFILE_REQUEST':
      return {
        ...state,
        loadingProfile: true,
      };
    case 'LOADING_PROFILE_COMPLETE':
      return {
        ...state,
        loadingProfile: false,
      };

    case 'LOADING_API_COMPLETE':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
