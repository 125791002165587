import { translate } from 'Context/translation.context';
import { ButtonCCF } from 'styled';
import { cellToTooltip, checkTypeCell } from 'utils/utils';
const hSub = (key) => translate(`table_sub.${key}`, 'fan');
const hContent = (key) => translate(`table_content.${key}`, 'fan');
const hPoint = (key) => translate(`table_point.${key}`, 'fan');
const hDonate = (key) => translate(`table_donate.${key}`, 'fan');
const hPayment = (key) => translate(`table_payment.${key}`, 'fan');
const hpHistory = (key) => translate(`table_point_history.${key}`, 'fan');
const hMain = (key) => translate(`main_table.${key}`, 'fan');
const columnSubscription = [
  { headerName: hSub('title'), ...cellToTooltip('title') },
  { headerName: hSub('description'), ...cellToTooltip('description') },
  { headerName: hSub('username'), ...cellToTooltip('username') },
  { headerName: hSub('account_id'), ...cellToTooltip('account_id') },
  { headerName: hSub('updated_at'), ...cellToTooltip('updated_at', 'date_time') },
  { headerName: hSub('price'), ...cellToTooltip('price', 'amount_pt') },
];
const columnContent = [
  { headerName: hContent('title'), ...cellToTooltip('title') },
  { headerName: hContent('content'), ...cellToTooltip('content') },
  { headerName: hContent('username'), ...cellToTooltip('username') },
  { headerName: hContent('account_id'), ...cellToTooltip('account_id') },
  { headerName: hContent('updated_at'), ...cellToTooltip('updated_at', 'date_time') },
  { headerName: hContent('price'), ...cellToTooltip('price', 'amount_pt') },
];
const columnPoint = [
  { headerName: hPoint('point'), ...cellToTooltip('point', 'amount_pt') },
  { headerName: hPoint('price'), ...cellToTooltip('price', 'amount') },
  { headerName: hPoint('updated_at'), ...cellToTooltip('updated_at', 'date_time') },
  {
    headerName: hPoint('payment_method'),
    ...cellToTooltip('payment_method', 'status_payment_method'),
  },
];
const columnDonate = [
  {
    headerName: hDonate('transaction_type'),
    ...cellToTooltip('transaction_type', 'transaction'),
  },
  { headerName: hDonate('username'), ...cellToTooltip('username') },
  { headerName: hDonate('account_id'), ...cellToTooltip('account_id') },
  { headerName: hDonate('updated_at'), ...cellToTooltip('updated_at', 'date_time') },
  { headerName: hDonate('price'), ...cellToTooltip('price', 'amount_pt') },
];
const columnPaymentHistory = [
  { headerName: hPayment('updated_at'), ...cellToTooltip('updated_at', 'date_time') },
  { headerName: hPayment('account_name'), ...cellToTooltip('account_name') },
  { headerName: hPayment('role'), ...cellToTooltip('role', 'fan_name') },
  { headerName: hPayment('point'), ...cellToTooltip('point', 'amount_pt') },
  { headerName: hPayment('price'), ...cellToTooltip('price', 'amount') },
  {
    headerName: hPayment('payment_method'),
    ...cellToTooltip('payment_method', 'status_payment_method'),
  },
];
const columnPointHistory = [
  { headerName: hpHistory('updated_at'), ...cellToTooltip('updated_at', 'date_time') },
  { headerName: hpHistory('account_name_from'), ...cellToTooltip('account_name_from') },
  {
    headerName: hpHistory('transaction_type'),
    ...cellToTooltip('transaction_type', 'transaction'),
  },
  { headerName: hpHistory('username'), ...cellToTooltip('account_name') },
  { headerName: hpHistory('account_id'), ...cellToTooltip('account_id') },
  { headerName: hpHistory('price'), ...cellToTooltip('price', 'amount_pt') },
];
const mainColumn = (goToDetail, goToEdit, goToLogin) => [
  { headerName: hMain('full_name'), ...cellToTooltip('full_name') },
  { headerName: hMain('name_romaji'), ...cellToTooltip('name_romaji') },
  { headerName: hMain('birthday'), ...cellToTooltip('birthday', 'date') },
  { headerName: hMain('gender'), ...cellToTooltip('gender', 'gender') },
  { headerName: hMain('email'), ...cellToTooltip('email', 'email') },
  {
    headerName: hMain('status'),
    ...cellToTooltip('status', 'status'),
    renderCell: (cell) =>
      cell?.row['first_login'] === 0
        ? '未ログイン'
        : checkTypeCell('status', cell?.row['status']),
  },
  {
    headerName: hMain('name_subscription'),
    ...cellToTooltip('name_subscription', 'name_subscription', 300, true),
  },
  {
    field: 'action',
    headerName: hMain('action'),
    minWidth: 350,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <>
          <ButtonCCF
            onClick={() => goToDetail(cellValues.id)}
            style={{ marginRight: '10px' }}
            variant='contained'
            size='small'
            bgtheme='primary'>
            詳細
          </ButtonCCF>
          {cellValues?.row['first_login'] === 0 ? (
            <div style={{ width: '74px' }} />
          ) : (
            <ButtonCCF
              onClick={() => goToEdit(cellValues.id)}
              style={{ marginRight: '10px' }}
              variant='contained'
              size='small'
              bgtheme='primary'>
              編集
            </ButtonCCF>
          )}
          <ButtonCCF
            onClick={() => goToLogin(cellValues.id)}
            variant='contained'
            size='small'
            // disabled={true}
            bgtheme='primary'>
            メッセージ履歴ページへ
          </ButtonCCF>
        </>
      );
    },
  },
];
export {
  columnSubscription,
  columnContent,
  columnPoint,
  columnDonate,
  columnPaymentHistory,
  columnPointHistory,
  mainColumn,
};
