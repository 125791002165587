import { API_URL_GLOBAL } from 'constants';
import { stringify } from 'qs';
const tagAPI = {
  getTagsList: (...params) => `${API_URL_GLOBAL}/tags?${stringify(...params)}`,
  createTag: `${API_URL_GLOBAL}/tag`,
  updateTag: (id) => `${API_URL_GLOBAL}/tag/${id}`,
  deleteTag: (id) => `${API_URL_GLOBAL}/tag/${id}`,
};

export { tagAPI };
