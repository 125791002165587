import { Collapse } from '@material-ui/core';
import { Content } from 'components/FromFeature/form-components';
import { TooltipHover } from 'components/Text';
import { ICON_LESS, ICON_MORE, menuSidebar, BannerLogo, menuSidebarCSAdmin } from 'constants';
import { useLocationRouter } from 'hooks';
// import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  WrapperSidebar,
  Logo,
  Lightline,
  ListItem,
  ItemButton,
  ItemIcon,
  ItemText,
  ItemContent,
  ProfileEndMenu,
} from './styled';
const Sidebar = () => {
  const [openMenu, setOpenMenu] = useState({});
  const { getLinkName } = useLocationRouter();

  const handleOpenMenu = (key) => setOpenMenu((prev) => ({ ...prev, [key]: !prev[key] }));
  const { profile, checkPermission, permissions } = useSelector((state) => state.auth);

  const listSidebar = profile?.user_type === 6 ? menuSidebarCSAdmin(permissions) :  menuSidebar(permissions);
  const childrenTitle = listSidebar.find((item) =>
    item.toggleList.find((child) => getLinkName?.includes(child.link))
  );
  useEffect(() => {
    childrenTitle && handleOpenMenu(childrenTitle?.title);
    setOpenMenu({
      "月別売上一覧": true,
      "売上管理": true
    })
  }, []);

  return (
    <WrapperSidebar>
      <Logo>
        <img src={BannerLogo} width={143} height={32} alt='Banner' />
        {/* <div>Sharegram - Admin</div>  */}
      </Logo>
      <Lightline />
      {(profile?.user_type === 6 ? checkPermission : profile?.id) && (
        <ListItem disablePadding>
          {listSidebar.map(({ title, icon, toggleList, link }) => (
            <div key={title}>
              {!link || link === '#' ? (
                <ItemButton component='li'>
                  <ItemContent
                    onClick={() =>
                      toggleList.length > 0 &&
                      setOpenMenu({ [title]: !openMenu[title], [childrenTitle?.title]: true })
                    }>
                    <ItemIcon>{icon}</ItemIcon>
                    <ItemText primary={title} />
                    {toggleList.length > 0 && (openMenu[title] ? ICON_LESS : ICON_MORE)}
                  </ItemContent>
                </ItemButton>
              ) : (
                <Link to={link}>
                  <ItemButton component='li'>
                    <ItemContent
                      className={getLinkName.includes(link) && 'active'}
                      onClick={() =>
                        toggleList.length > 0 ? handleOpenMenu(title) : setOpenMenu({})
                      }>
                      <ItemIcon>{icon}</ItemIcon>
                      <ItemText primary={title} />
                      {toggleList.length > 0 && (openMenu[title] ? ICON_LESS : ICON_MORE)}
                    </ItemContent>
                  </ItemButton>
                </Link>
              )}
              {toggleList.length > 0 &&
                toggleList.map((item) => (
                  <Collapse key={item.title} in={openMenu[title]} timeout='auto' unmountOnExit>
                    <ListItem disablePadding>
                      <Link to={item.link}>
                        <ItemButton component='li'>
                          <ItemContent
                            className={`child-item ${
                              getLinkName.includes(item.link) && 'active'
                            }`}
                            onClick={() => setOpenMenu({ [title]: true })}>
                            <ItemText primary={item.title} />
                          </ItemContent>
                        </ItemButton>
                      </Link>
                    </ListItem>
                  </Collapse>
                ))}
            </div>
          ))}
        </ListItem>
      )}
      <ProfileEndMenu>
        <TooltipHover title={`${profile?.full_name || '---'}`}>
          <Content
            textAlign='center'
            overText='true'
            paddingTop='5px'
            pdrl='10px'
            removeMargin>{`${profile?.full_name || '---'}`}</Content>
        </TooltipHover>
      </ProfileEndMenu>
    </WrapperSidebar>
  );
};

export { Sidebar };
export default Sidebar;
Sidebar.propTypes = {};
