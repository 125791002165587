import {
  FormInputDate,
  FormInputDropdown,
  FormInputRadio,
  FormInputText,
} from 'components/FromFeature/form-components';
import { FormInputAsyncSelect } from 'components/FromFeature/form-components/FormInputAsyncSelect';
import PropsType from 'prop-types';
const getRenderInput = (data) => {
  let { label = '', name, type, render, control, props, show = true, isCommission } = data;
  switch (type) {
    case 'text':
      return show && <FormInputText name={name} label={label} control={control} isCommission={isCommission} {...props} />;
    case 'select':
      return (
        show && (
          <FormInputDropdown name={name} control={control} option={props?.option} {...props} />
        )
      );
    case 'asyncSelect':
      return (
        show && (
          <FormInputAsyncSelect
            name={name}
            control={control}
            option={props?.option}
            {...props}
          />
        )
      );
    case 'date':
      return show && <FormInputDate name={name} label={label} control={control} {...props} />;
    case 'radio':
      return show && <FormInputRadio name={name} label={label} control={control} {...props} />;
    default:
      return show && render;
  }
};
getRenderInput.propTypes = {
  data: PropsType.object.isRequired,
};
export { getRenderInput };
export * from './regex';
export * from './regexCommon'
