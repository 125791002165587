import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonSubmit } from '../../../components/FromFeature/form-element/styledElement';
import {
  Content,
  WrapperContent,
  WrapperForm,
  WrapperButtonForm,
} from '../../../components/FromFeature/form-element/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaEdit } from './schema';
import { paramsDetail } from './dataForm';
import { AlertDialog } from 'components/Dialog';
import { getRenderInput } from 'constants';
import {
  approveAffiliatorAccount,
  deleteAccountAffiliator,
  getDetailAffiliator,
  updateDetailAffiliator,
} from 'store/actions/affiliate.action';
import { GENDER } from 'constants';
import { STATUS_USER } from 'constants';
import { statusToTextButton } from 'constants';
import { LanguageContext } from 'Context/translation.context';

export const FormEdit = () => {
  const { tAdmincs } = useContext(LanguageContext)
  const { detailAccount } = useSelector((state) => state.affiliator);
  const methods = useForm({
    mode: 'all',
    defaultValues: paramsDetail,
    resolver: yupResolver(schemaEdit),
  });
  const { handleSubmit, reset, control, setValue, watch } = methods;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(detailAccount).length === 0 &&
      dispatch(
        getDetailAffiliator(
          id,
          () => {},
          () => navigate('/affiliate-management/manage-affiliate')
        )
      );
  }, []);

  useEffect(() => {
    if (Object.keys(detailAccount).length > 0) {
      for (let key in paramsDetail) {
        setValue(key, detailAccount[key] || (key === 'gender' ? 0 : ''));
      }
    }
  }, [detailAccount]);
  const returnHome = () => () => navigate('/affiliate-management/manage-affiliate');
  const onSubmit = (data) => {
    // dispatch(postCreateAgent(data, returnHome()));
  };
  const updateAccount = (data) => {
    dispatch(updateDetailAffiliator(data, id, returnHome(), paramsDetail));
  };
  const deleteAccount = (account) => {
    dispatch(deleteAccountAffiliator(account, returnHome()));
  };
  const approveAccount = (account) => {
    dispatch(approveAffiliatorAccount(account, returnHome()));
  };
  const renderForm = [
    {
      content: tAdmincs('table_admin.full_name'),
      label: tAdmincs('table_admin.full_name'),
      name: 'full_name',
      control,
      type: 'text',
      props: {
        isErrForm: true, width: '480px'
      },
    },
    {
      content: tAdmincs('table_admin.name_romaji'),
      label: tAdmincs('table_admin.name_romaji'),
      name: 'name_romaji',
      control,
      type: 'text',
      props: {
        isErrForm: true, width: '480px'
      },
    },
    {
      content: tAdmincs('table_admin.birthday'),
      label: tAdmincs('table_admin.birthday'),
      name: 'birthday',
      control,
      type: 'date',
    },
    {
      content: tAdmincs('table_admin.gender'),
      label: tAdmincs('table_admin.gender'),
      name: 'gender',
      control,
      type: 'select',
      props: {
        option: GENDER,
        noDefault: true,
      },
    },
    {
      content: tAdmincs('table_admin.email'),
      label: tAdmincs('table_admin.email'),
      name: 'email',
      control,
      type: 'text',
      props: {
        isErrForm: true, width: '480px'
      },
    },
    {
      content: tAdmincs('table_admin.status'),
      label: tAdmincs('table_admin.status'),
      name: 'status',
      control,
      type: 'select',
      props: {
        option: STATUS_USER,
        isDisabled: true,
      },
    },
  ];

  return (
    <WrapperForm>
      {renderForm.map((item, index) => {
        let { content, show = true, type } = item;
        return (
          <WrapperContent key={index}>
            {show && <Content>{content}</Content>}
            {type === 'text' ? <div>{getRenderInput(item)}</div> : getRenderInput(item)}
          </WrapperContent>
        );
      })}

      <WrapperButtonForm width="480px">
        <ButtonSubmit
          onClick={() => {
            navigate('/affiliate-management/manage-affiliate');
            reset();
          }}
          variant='outlined'>
          {tAdmincs('form.submit')}
        </ButtonSubmit>

        <>
          <AlertDialog
            handleAccpet={handleSubmit(updateAccount)}
            addAccept
            title={tAdmincs('form.title_no_admin')}
            content={tAdmincs('form.admin_edit')}
          />
          {detailAccount?.status !== 5 && (
            <AlertDialog
              handleAccpet={() => {
                approveAccount(id);
              }}
              updateStatus={statusToTextButton[detailAccount?.status]?.text || '---'}
              title={statusToTextButton[detailAccount?.status]?.title}
              content={statusToTextButton[detailAccount?.status]?.confirm}
            />
          )}

          <AlertDialog
            handleAccpet={() => {
              deleteAccount(id);
            }}
            addDelete
            title={tAdmincs('form.delete_title')}
            content={`${watch('full_name')}${tAdmincs('form.areYouSureYouWantToDeleteYourAccount')}`}
          />
        </>
      </WrapperButtonForm>
    </WrapperForm>
  );
};

export default FormEdit;
