/* eslint-disable import/no-anonymous-default-export */
import creator from './pages/creator.json';
import agency from './pages/agency.json';
import admincs from './pages/admincs.json';
import banner from './pages/banner.json';
import email from './pages/email.json';
import fan from './pages/fan.json';
import home from './pages/home.json';
import notification from './pages/notification.json';
import payment from './pages/payment.json';
import point from './pages/point.json';
import report from './pages/report.json';
import revenue from './pages/revenue.json';
import settingsite from './pages/settingsite.json';
import auth from './pages/auth.json';
import schema from './form/schema.json';
import contact from './pages/contact.json';
import tags from './pages/tags.json';
import settingDisplaySite from './pages/settingDisplaySite';
export default {
  creator,
  agency,
  admincs,
  banner,
  email,
  fan,
  home,
  notification,
  payment,
  point,
  report,
  revenue,
  settingsite,
  schema,
  contact,
  auth,
  tags,
  settingDisplaySite,
};
