import { css } from 'styled-components';
const Reset = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif !important;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    margin: 0;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.25rem;
  }
`;
export { Reset };
