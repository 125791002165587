import styled from 'styled-components';

const TitleSettingEffect = styled.div`
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: #fff;
  width: 100%;
  height: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
  }
`;

const FormSettingEffect = styled.div`
  .form-setting-group {
    overflow: auto;
    .form-setting-effect {
      .table-effect-stamp {
        min-width: 700px;
        width: 70%;
        margin-top: 20px;
        margin-bottom: 10px;
        border-collapse: collapse;
        border: 1px solid rgba(224, 224, 224, 1);
        .thead-table {
          .thead {
            text-align: center;
            background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
            color: #fff;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 40px;
            .row {
              border: 1px solid rgba(224, 224, 224, 1);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .row-stt {
              width: 10%;
            }
            .row-name {
              width: 30%;
            }
            .row-icon {
              width: 10%;
            }
            .row-gift {
              width: 10%;
            }
            .row-checkbox {
              width: 10%;
            }
            .row-point {
              width: 15%;
            }
            .row-action {
              width: 15%;
            }
          }
        }
        .tbody-table {
          .tbody {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 50px;
            .row {
              border: 1px solid rgba(224, 224, 224, 1);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .row-stt {
              text-align: center;
              width: 10%;
            }
            .row-name {
              padding-left: 5px;
              width: 30%;
              justify-content: initial;
              align-items: center;
            }
            .row-icon {
              width: 10%;
            }
            .row-gift {
              width: 10%;
            }
            .row-checkbox {
              width: 10%;
              text-align: center;
            }
            .row-point {
              width: 15%;
            }
            .row-action {
              width: 15%;
              text-align: center;
              color: red;
              cursor: pointer;
            }
          }
        }
      }
    }
    .form-setting-point {
      .table-effect-stamp {
        min-width: 700px;
        width: 70%;
        margin-top: 20px;
        margin-bottom: 10px;
        border-collapse: collapse;
        border: 1px solid rgba(224, 224, 224, 1);
        .thead-table {
          .thead {
            text-align: center;
            background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
            color: #fff;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 40px;
            .row {
              border: 1px solid rgba(224, 224, 224, 1);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .row-stt {
              width: 10%;
            }
            .row-name {
              width: 40%;
            }
            .row-icon {
              width: 10%;
            }
            .row-checkbox {
              width: 10%;
            }
            .row-point {
              width: 15%;
            }
            .row-action {
              width: 15%;
            }
          }
        }
        .tbody-table {
          .tbody {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 50px;
            .row {
              border: 1px solid rgba(224, 224, 224, 1);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .row-stt {
              text-align: center;
              width: 10%;
            }
            .row-name {
              padding-left: 5px;
              width: 40%;
              justify-content: initial;
              align-items: center;
            }
            .row-icon {
              width: 10%;
            }
            .row-checkbox {
              width: 10%;
              text-align: center;
            }
            .row-point {
              width: 15%;
            }
            .row-action {
              width: 15%;
              text-align: center;
              color: red;
              cursor: pointer;
            }
          }
        }
      }
    }
    .form-setting-creator {
      .table-effect-stamp {
        min-width: 700px;
        width: 70%;
        margin-top: 20px;
        margin-bottom: 10px;
        border-collapse: collapse;
        border: 1px solid rgba(224, 224, 224, 1);
        .thead-table {
          .thead {
            text-align: center;
            background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
            color: #fff;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 40px;
            .row {
              border: 1px solid rgba(224, 224, 224, 1);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .row-stt {
              width: 10%;
            }
            .row-name {
              width: 40%;
            }
            .row-gift {
              width: 17.5%;
            }
            .row-checkbox {
              width: 17.5%;
            }
            .row-action {
              width: 15%;
            }
          }
        }
        .tbody-table {
          .tbody {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 50px;
            .row {
              border: 1px solid rgba(224, 224, 224, 1);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .row-stt {
              text-align: center;
              width: 10%;
            }
            .row-name {
              padding-left: 5px;
              width: 40%;
              justify-content: initial;
              align-items: center;
            }
            .row-gift {
              width: 17.5%;
            }
            .row-checkbox {
              width: 17.5%;
              text-align: center;
            }
            .row-action {
              width: 15%;
              text-align: center;
              color: red;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .form-action {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  input {
    width: 20px;
    height: 20px;
  }
`;

const FormCreatSettingEffect = styled.div`
  .form-create-setting-effect {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    .label-title {
      width: 30%;
    }
    .label-title-icon {
      width: 23.5%;
    }
    .label-title-active {
      width: 21.5%;
    }
    .input {
      width: 70%;
    }
  }
  .btn-img {
    gap: 1px;
    .icon {
      display: flex;
      align-items: center;
    }
    .gift {
      display: flex;
      align-items: center;
    }
  }
`;

const FormSettingImg = styled.div`
  overflow: auto;
  height: 140px;
  div {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 5px;
    align-items: center;
    cursor: pointer;
    > div {
      padding-top: 10px;
      text-align: center;
      &:focus {
        background-color: blue;
      }
    }
  }
`;

export { TitleSettingEffect, FormSettingEffect, FormCreatSettingEffect, FormSettingImg };
