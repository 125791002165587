import styled from 'styled-components';

const TitleSettingSite = styled.div`
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: #fff;
  width: 100%;
  height: 40px;
  margin: 25px 0px;
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
  }
`;

const FormSettingSite = styled.div`
  .form-time-limit {
    min-width: 750px;
    margin-top: 15px;
    .time-limit {
      display: flex;
      gap: 20px;
      .title {
        border-radius: 3px;
        display: flex;
        align-items: center;
        > span {
          margin-left: 10px;
        }
        height: 40px;
        width: 12%;
        background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
        color: #fff;
      }
      .form-date-time-limit {
        .form-date-time {
          display: flex;
          gap: 20px;
        }
      }
    }
    .btn-action-setting {
      display: flex;
      justify-content: end;
    }
  }

  .form-time-stop-start {
    .form-date-time-stop-start {
      display: flex;
      gap: 20px;
    }
    .btn-stop-start {
      display: flex;
      justify-content: center;
      gap: 60px;
    }
  }

  .form-setting-system-usage {
    min-width: 750px;
    .point {
      display: flex;
      width: 50%;
      gap: 20px;
      align-items: center;
      margin-bottom: 25px;
      .title {
        border-radius: 4px;
        height: 40px;
        min-width: 300px;
        background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
        color: #fff;
        width: 95%;
        display: flex;
        align-items: center;
        > span {
          margin-left: 10px;
        }
      }
    }
    .btn-action-setting {
      display: flex;
      justify-content: end;
    }
  }

  .setting-countries {
    min-width: 1200px;
    .form-setting-countries {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 5px;
      border: 1px solid rgba(224, 224, 224, 1);
      border-radius: 4px;
      width: 30%;
      .country-name {
        width: 50%;
        display: flex;
        align-items: center;
        > span {
          margin-left: 10px;
        }
        height: 40px;
        background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
        color: #fff;
      }
    }
  }
  .other {
    margin-top: 20px;
  }
  .action-setting-countries {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 60px;
  }
`;

export { TitleSettingSite, FormSettingSite };
