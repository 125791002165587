import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Content,
  FormInputText,
  WrapperContent,
  WrapperButtonForm,
  ButtonSubmit,
  WrapperForm,
  WrapperTitleContent,
  WrapperShowTable,
} from 'components/FromFeature/form-components';
import { TabsSecond } from '../settings/tabs';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveCreatorAccount,
  deleteAccountCreator,
  deletePostCreator,
  deleteSubscriptionCreator,
  getDetailCreator,
  getListSubscriptionCreator,
  postAdminLoginCreator,
  updateCreatorAccount,
} from 'store/actions/creator.action';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { Lightbox as LightboxModal } from 'react-modal-image';
import 'react-image-lightbox/style.css';
import { ImgShowDetail, WrapperShowImgDetail } from '../styled';

import { paramsDetail, paramsDetailEdit, paramsEdit } from './dataForm';
import { STATUS_USER } from 'constants';
import { WrapperTableStatic } from 'components/Table';
import { AlertDialog } from 'components/Dialog';
import { columnContent, columnSubcription } from '../settings';
import { schemaEdit } from './schema';
import { getRenderInput } from 'constants';
import { GENDER } from 'constants';
import { linkS3 } from 'apis/s3';
import { decryptPath, formatNumberPhone, removeDash, removePercent } from 'utils/utils';
import { statusToTextButton } from 'constants';
import { PATH_URL } from 'constants/routes/settingUrl';
import { textToStatusMoneyPackage } from 'constants';
import { LanguageContext } from 'Context/translation.context';
import { ButtonCCF } from 'styled';
const DetailCreator = ({ isDetail = false, isEdit = false }) => {
  const { tCreator } = useContext(LanguageContext);
  const methods = useForm({
    mode: 'all',
    defaultValues: isDetail ? paramsDetail : isEdit ? paramsDetailEdit : {},
    resolver: yupResolver(isEdit ? schemaEdit : {}),
  });
  const { handleSubmit, control, setValue, watch } = methods;
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navagation = useNavigate();
  const { detailCreator } = useSelector((state) => state.creator);
  const [rejectText] = useState(' ');

  const handleRedirectBack = () => {
    if (location?.state?.prevPath) {
      return navagation(`/${PATH_URL.CREATOR}/account${location?.state?.prevPath}`);
    }
    return navagation(`/${PATH_URL.CREATOR}/account`);
  };

  useEffect(() => {
    (isEdit || isDetail) &&
      dispatch(
        getDetailCreator(
          id,
          () => {},
          () => navagation(`/${PATH_URL.CREATOR}`)
        )
      );
  }, [id]);
  useEffect(() => {
    if (!detailCreator?.detail_user) return;
    if ((isDetail || isEdit) && Object.keys(detailCreator).length > 0) {
      for (let key in isDetail ? paramsDetail : paramsDetailEdit) {
        detailCreator?.detail_user[key] !== null &&
          setValue(
            key,
            key === 'phone' || key === 'account_phone'
              ? formatNumberPhone(detailCreator?.detail_user[key])
              : key === 'commission_sales' ||
                key === 'commission_subscription' ||
                key === 'commission_livestream' ||
                key === 'commission_message'
              ? `${detailCreator?.detail_user[key]}%`
              : key === 'type'
              ? textToStatusMoneyPackage[detailCreator?.detail_user[key]]
              : detailCreator?.detail_user[key]
          );
        if (key === 'company_name' && !detailCreator?.detail_user[key]) {
          setValue(key, 'Sharegram');
        }
      }
    }
  }, [detailCreator]);
  const [listImage, setListImage] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isShowImageContent, setIsShowImageContent] = useState(false);
  const handleShowImageContent = (listImage, value) => {
    setListImage(listImage);
    setIsShowImageContent(value);
    setPhotoIndex(0);
  };

  const onClickOnMovePrevRequest = () => {
    setPhotoIndex((photoIndex + listImage.length - 1) % listImage.length);
  };
  const { profile } = useSelector((state) => state.auth);

  const onClickOnMoveNextRequest = () => {
    setPhotoIndex((photoIndex + 1) % listImage.length);
  };
  const [isOpenFront, setIsopenFront] = useState([]);
  const handleShowImage = (key, value) => {
    setIsopenFront([key, value]);
  };
  const handleUpdateCreator = (data) => {
    const dataToUpdate = () => {
      for (let key in paramsEdit) {
        paramsEdit[key] =
          key === 'phone'
            ? removeDash(data[key])
            : key === 'commission_sales' ||
              key === 'commission_subscription' ||
              key === 'commission_livestream' ||
              key === 'commission_message'
            ? removePercent(data[key])
            : data[key];
      }
      return paramsEdit;
    };

    dispatch(updateCreatorAccount(dataToUpdate(), id, () => handleRedirectBack(), paramsEdit));
  };
  const deleteCreator = () => {
    dispatch(deleteAccountCreator(id, () => handleRedirectBack()));
  };
  const handleApproveCreator = (stt) => {
    dispatch(approveCreatorAccount(stt, id, () => handleRedirectBack()));
  };

  // const handleErr = () => {
  //   let mess = '';
  //   let status;
  //   if (rejectText === '') {
  //     mess = '拒否理由を入力してください。';
  //     status = false;
  //   }
  //   if (rejectText.length > 500) {
  //     mess = '拒否理由を500文字まで入力してください。';
  //     status = false;
  //   }
  //   return {
  //     status,
  //     mess,
  //   };
  // };
  const handleDeleteSub = (idPost) => {
    dispatch(
      deleteSubscriptionCreator(
        idPost,
        getDetailCreator(
          id,
          () => {},
          () => handleRedirectBack()
        )
      )
    );
  };
  const handleGetSubcription = (idPost, cb, errcb) => {
    dispatch(
      getListSubscriptionCreator(
        idPost,
        () => cb(),
        () => errcb()
      )
    );
  };
  const handleDeleteContent = (idPost) => {
    dispatch(
      deletePostCreator(
        idPost,
        getDetailCreator(
          id,
          () => {},
          () => handleRedirectBack()
        )
      )
    );
  };
  const handleViewContent = (idPost) => {
    dispatch(postAdminLoginCreator(idPost, 'post', profile?.id));
  };
  const renderLineOne = [
    {
      content: tCreator('form_1.account_name'),
      name: 'account_name',
      control,
      type: 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.account_id'),
      name: 'account_id',
      control,
      type: 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.first_name'),
      name: 'first_name',
      control,
      type: 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.last_name'),
      name: 'last_name',
      control,
      type: 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.first_name_kana'),
      name: 'first_name_kana',
      control,
      type: 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.last_name_kana'),
      name: 'last_name_kana',
      control,
      type: 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.birthday'),
      name: 'birthday',
      control,
      type: 'date',
      props: {
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.gender'),
      name: 'gender',
      control,
      type: 'select',
      props: {
        option: GENDER,
        width: '480px',
        isDisabled: isDetail,
        noDefault: true,
      },
    },
    {
      content: tCreator('form_1.email'),
      name: 'email',
      control,
      type: !isDetail ? '' : 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: true,
      },
      render: <p style={{ marginTop: 5 }}>{watch('email')}</p>,
    },
    {
      content: tCreator('form_1.phone'),
      name: 'phone',
      control,
      type: 'text',
      label: '0X-XXXX-XXXX・0XX-XXXX-XXXX・0120-XXXX-XXXX',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: isDetail,
      },
    },
    {
      content: tCreator('form_1.country_name'),
      name: 'country_id',
      control,
      type: 'asyncSelect',
      props: {
        width: '400px',
        isDisabled: isDetail,
        type: 'country',
        vl: 'id',
        lb: 'country_name',
      },
    },
    {
      content: tCreator('form_1.image'),
      name: '',
      type: '',
      newProps: {
        height: '90px',
        marginBotTop: '25px',
      },
      render: (
        <WrapperShowImgDetail marginTop='-15px'>
          {watch('identify_img_front') && (
            <ImgShowDetail
              onClick={() =>
                handleShowImage(`${decryptPath(watch('identify_img_front'))}`, true)
              }
              src={decryptPath(watch('identify_img_front'))}
              alt=''
            />
          )}
          {watch('identify_img_back') && (
            <ImgShowDetail
              onClick={() =>
                handleShowImage(`${decryptPath(watch('identify_img_back'))}`, true)
              }
              src={decryptPath(watch('identify_img_back'))}
              alt=''
            />
          )}
        </WrapperShowImgDetail>
      ),
    },
    {
      content: '契約ファイル',
      name: 'contract',
      control,
      type: '',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: true,
      },
      render: (
        <>
          {watch('contract') ? (
            <a href={watch('contract')} download={watch('contract')}>
              {watch('contract').split('/')[watch('contract').split('/').length - 1]}
            </a>
          ) : (
            <p>-</p>
          )}
        </>
      ),
    },
    {
      content: tCreator('form_1.status'),
      name: 'status',
      control,
      type: !isDetail ? '' : 'select',
      props: {
        option: STATUS_USER,
        isDisabled: true,
      },
      render: (
        <p style={{ marginTop: 5 }}>
          {STATUS_USER.find((item) => item.value === watch('status'))?.label}
        </p>
      ),
    },
    {
      content: tCreator('form_1.company_name'),
      name: 'company_name',
      control,
      type: !isDetail ? '' : 'text',
      props: {
        isErrForm: !isDetail,
        width: '480px',
        disabled: true,
      },
      render: <p style={{ marginTop: 5 }}>{watch('company_name')}</p>,
    },
    {
      content: tCreator('form_1.commission_sales'),
      name: 'commission_sales',
      control,
      type: '',
      props: {
        isErrForm: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('commission_sales')}</p>,
    },
    {
      content: tCreator('form_1.commission_subscription'),
      name: 'commission_subscription',
      control,
      type: '',
      props: {
        isErrForm: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('commission_subscription')}</p>,
    },
    {
      content: tCreator('form_1.commission_livestream'),
      name: 'commission_livestream',
      control,
      type: '',
      props: {
        isErrForm: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('commission_livestream')}</p>,
    },
    {
      content: tCreator('form_1.commission_message'),
      name: 'commission_message',
      control,
      type: '',
      props: {
        isErrForm: true,
        width: '480px',
      },
      render: <p style={{ marginTop: 5 }}>{watch('commission_message')}</p>,
    },
    isDetail && {
      content: '',
      name: 'account_id_origin',
      control,
      type: '',
      props: {
        isErrForm: true,
        width: '480px',
      },
      show: false,
      showRender: true,
      render: (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <ButtonCCF
            style={{ marginBottom: '20px' }}
            variant='contained'
            size='small'
            bgtheme='primary'
            onClick={() => dispatch(postAdminLoginCreator(watch('account_id_origin'), ''))}>
            ホームページへ
          </ButtonCCF>
        </div>
      ),
    },
  ];
  const contentDetailLineOne = (
    <WrapperForm>
      {isOpenFront[1] && (
        <LightboxModal
          small={isOpenFront[0]}
          medium={isOpenFront[0]}
          large={isOpenFront[0]}
          onClose={() => handleShowImage(isOpenFront[0], false)}
          showRotate='true'
          hideDownload
          className='modal-image'
        />
      )}
      {renderLineOne.map((item, index) => {
        let { content, show = true, type, render, showRender } = item;
        return (
          <WrapperContent key={index} {...item?.newProps}>
            {show && <Content>{content}</Content>}
            {type === 'text' ? <div>{getRenderInput(item)}</div> : getRenderInput(item)}
            {showRender && render}
          </WrapperContent>
        );
      })}
    </WrapperForm>
  );
  const contentDetailLineTwo = (
    <WrapperForm>
      <WrapperContent>
        <Content>銀行名（英字）</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='bank_name'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>口座種類 普通又は当座</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='type'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>SWIFTコード</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='swift_code'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>{tCreator('form_2.bank_name')}</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='bank_code'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>支店名（英字）</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='branch_name'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>支店番号</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='branch_code'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>支店住所（英字）</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='branch_address'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>{tCreator('form_2.account_number')}</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='account_number'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>{tCreator('form_2.bank_account_name')}</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='bank_account_name'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>住所（英字）</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='account_address'
          control={control}
          label=''
        />
      </WrapperContent>
      <WrapperContent>
        <Content>電話番号</Content>
        <FormInputText
          width='520px'
          disabled={isDetail}
          name='account_phone'
          control={control}
          label=''
        />
      </WrapperContent>
    </WrapperForm>
  );
  const contentDetailLineThree = (
    <WrapperShowTable>
      <WrapperTableStatic
        columns={columnSubcription(handleDeleteSub, handleGetSubcription)}
        total={detailCreator?.user_subscriptions?.length}
        listRows={detailCreator?.user_subscriptions}
      />
    </WrapperShowTable>
  );
  const contentDetailLineFour = (
    <WrapperShowTable>
      {isShowImageContent && (
        <Lightbox
          mainSrc={listImage[photoIndex]}
          nextSrc={listImage[photoIndex + 1]}
          prevSrc={listImage[photoIndex - 1]}
          onCloseRequest={() => setIsShowImageContent(false)}
          onMovePrevRequest={onClickOnMovePrevRequest}
          onMoveNextRequest={onClickOnMoveNextRequest}
        />
      )}
      <WrapperTableStatic
        className='table-sales-content'
        columns={columnContent(handleDeleteContent, handleViewContent, handleShowImageContent)}
        total={detailCreator?.user_contents?.length}
        listRows={detailCreator?.user_contents}
        getRowHeight={() => 'auto'}
      />
    </WrapperShowTable>
  );
  const renderPage = [
    { id: 1, content: tCreator('content.content_1'), component: contentDetailLineOne },
    { id: 2, content: tCreator('content.content_2'), component: contentDetailLineTwo },
    { id: 3, content: tCreator('content.content_3'), component: contentDetailLineThree },
    { id: 4, content: tCreator('content.content_4'), component: contentDetailLineFour },
  ];

  return (
    <>
      {!isEdit && <TabsSecond />}
      {isDetail &&
        renderPage.map((item) => (
          <div key={item.id}>
            <WrapperTitleContent>
              <Content>{item.content}</Content>
            </WrapperTitleContent>
            {item.component}
          </div>
        ))}
      {isEdit && (
        <>
          {contentDetailLineOne}
          <WrapperButtonForm justifyContent='center' wrap='true' width='600px' gap='5px'>
            <ButtonSubmit onClick={() => handleRedirectBack()} variant='outlined'>
              {tCreator('edit_form.cancel')}
            </ButtonSubmit>
            {detailCreator?.detail_user?.status !== 5 && detailCreator?.detail_user?.deleted_at === null && (
              <AlertDialog
                handleAccpet={handleSubmit(handleUpdateCreator)}
                updateStatus={tCreator('edit_form.title_update_new')}
                title={tCreator('edit_form.edit_account')}
                content={tCreator('edit_form.are_you_sure_you_want_to_edit_your_account')}
              />
            )}
            {detailCreator?.detail_user?.status !== 2 && detailCreator?.detail_user?.deleted_at === null ? (
              <>
                {(detailCreator?.detail_user?.status === 3 ||
                  detailCreator?.detail_user?.status === 4) && (
                  <AlertDialog
                    handleAccpet={() => handleApproveCreator(statusToTextButton?.[1]?.payload)}
                    updateStatus={statusToTextButton?.[1]?.text}
                    title={statusToTextButton?.[1]?.title}
                    content={statusToTextButton?.[1]?.confirm}
                  />
                )}
                {detailCreator?.detail_user?.status === 3 && (
                  <AlertDialog
                    handleAccpet={() => handleApproveCreator(statusToTextButton?.[4]?.payload)}
                    updateStatus={statusToTextButton?.[4]?.text}
                    title={statusToTextButton?.[4]?.title}
                    content={statusToTextButton?.[4]?.confirm}
                  />
                )}
                {(detailCreator?.detail_user?.status === 1 ||
                  detailCreator?.detail_user?.status === 2 ||
                  detailCreator?.detail_user?.status === 3 ||
                  detailCreator?.detail_user?.status === 7) && (
                  <AlertDialog
                    handleAccpet={() =>
                      handleApproveCreator(
                        statusToTextButton?.[detailCreator?.detail_user?.status]?.payload
                      )
                    }
                    updateStatus={
                      statusToTextButton?.[detailCreator?.detail_user?.status]?.text || '---'
                    }
                    title={statusToTextButton?.[detailCreator?.detail_user?.status]?.title}
                    content={statusToTextButton?.[detailCreator?.detail_user?.status]?.confirm}
                  />
                )}
              </>
            ) : (detailCreator?.detail_user?.deleted_at === null && (
                <AlertDialog
                  handleAccpet={() =>
                    handleApproveCreator(detailCreator?.detail_user?.is_approved ? 1 : 7)
                  }
                  updateStatus={statusToTextButton?.[2]?.text}
                  title={statusToTextButton?.[2]?.title}
                  content={statusToTextButton?.[2]?.confirm}
                />
              )
            )}
            {detailCreator?.detail_user?.deleted_at === null && (
              <AlertDialog
                handleAccpet={deleteCreator}
                addDelete
                title={tCreator('edit_form.delete_account')}
                content={`${watch('account_name')}${tCreator(
                  'edit_form.are_you_sure_you_want_to_delete_your_account'
                )}`}
              />
            )}
          </WrapperButtonForm>
        </>
      )}
    </>
  );
};
export { DetailCreator };
