import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  voucherApi,
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
} from 'apis';
import { pushNotify } from 'components/Notification';

const getListVoucher = (flg, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_VOUCHER_REQUEST));
    callApi(
      voucherApi.getListVoucher(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_VOUCHER_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_VOUCHER_FAILED, { error: error.errors }));
      },
      flg ? null: dispatch
    );
  };
};

const createVoucher = (data, callback, callback1, callback2, isPublic ) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATE_VOUCHER_REQUEST));
    callApi(
      isPublic ? voucherApi.createVoucherPublic() : voucherApi.createVoucher(),
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        if(response?.data?.is_public === 1) {
          pushNotify('success', 'クーポンは正常に作成され、公開されました。');
        } else {
          pushNotify('success', 'クーポンが正常に作成されました。');
        }
        await dispatch(updateStatus(TYPES.CREATE_VOUCHER_SUCCESS, response));
        callback !== null && await dispatch(callback);
        callback1 && callback1();
        callback2 && callback2();
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_VOUCHER_FAILED, { error: error.errors }));
        //dispatch(callback);
        callback1 && callback1(error?.errors?.message[0]);
      },
      dispatch
    );
  };
};

const deleteVoucher = (id, callback, callApi1) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_VOUCHER_REQUEST));
    callApi(
      voucherApi.deleteVoucher(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        pushNotify('success', 'クーポンが正常に削除されました。');
        await dispatch(updateStatus(TYPES.DELETE_VOUCHER_SUCCESS, response));
        callback && await dispatch(callback);
        callApi1 && callApi1();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_VOUCHER_FAILED, { error: error.errors }));
        callback && dispatch(callback);
      },
      dispatch
    );
  };
};

const editVoucher = (data, callback, callback1, isPublic) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.EDIT_VOUCHER_REQUEST));
    callApi(
      isPublic ? voucherApi.editVoucherPublic(data) : voucherApi.editVoucher(data),
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        if(response?.data?.is_public === 1) {
          pushNotify('success', 'クーポンは正常に編集され、公開されました。');
        } else {
          pushNotify('success', 'クーポンの編集が正常に完了しました。');
        }
        await dispatch(updateStatus(TYPES.EDIT_VOUCHER_SUCCESS, response));
        callback && await dispatch(callback);
        callback1 && callback1();
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_VOUCHER_FAILED, { error: error.errors }));
        callback1 && callback1(error?.errors?.message[0]);
      },
      dispatch
    );
  };
};

export { getListVoucher, createVoucher, deleteVoucher, editVoucher };
