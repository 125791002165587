import TabMenu from 'components/TabMenu';

export const TabsAffiliate = () => {
  const navItems = [
    {
      id: 'affiliate-main',
      path: `/affiliate-management/manage-affiliate`,
      text: 'アカウント情報閲覧',
    },
    {
      id: 'affiliate-sale',
      path: '/affiliate-management/sale-history',
      text: '販売履歴閲覧',
    },
    {
      id: 'affiliate-payment',
      path: '/affiliate-management/payment-history',
      text: '支払い履歴',
    },
    {
      id: 'affiliate-banner',
      path: '/affiliate-management/banner',
      text: 'バナークリック数一覧',
    },
    {
      id: 'affiliate-setting',
      path: '/affiliate-management/setting',
      text: '設定',
    },
  ];

  return <TabMenu tabs={navItems} />;
};
