const findMess = (org, apv) => {
  if (org === 3 && apv === 2) {
    return 'APPROVE_MESS_1';
  }
  if (org === 3 && apv === 1) {
    return 'APPROVE_MESS_2';
  }
  if (org === 3 && apv === 4) {
    return 'APPROVE_MESS_3';
  }
  if (org === 1 && apv === 2) {
    return 'APPROVE_MESS_4';
  }
  if (org === 7 && apv === 2) {
    return 'APPROVE_MESS_4';
  }
  if (org === 2 && apv === 1) {
    return 'APPROVE_MESS_5';
  }
  if (org === 4 && apv === 2) {
    return 'APPROVE_MESS_6';
  }
  if (org === 4 && apv === 1) {
    return 'APPROVE_MESS_7';
  }
  if (org === 2 && apv === 7) {
    return 'APPROVE_MESS_5';
  }
};

export { findMess };
