import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';
const revenueAPI = {
  getRevenueSummary: `${API_URL_V2}/revenue-sumarys`,
  getListRevenue: (...data) => `${API_URL_V2}/revenues?${stringify(...data)}`,
  getDetailRevenue: (id, ...data) => `${API_URL_V2}/revenue/${id}?${stringify(...data)}`,
  getDataCsvRevenue: (...data) => `${API_URL_V2}/revenue-exports?${stringify(...data)}`,
};

export { revenueAPI };
