import effect_3 from '../../../assets/stamp/gifs/effect_3.gif';
import effect_4 from '../../../assets/stamp/gifs/effect_4.gif';
import effect_6 from '../../../assets/stamp/gifs/effect_6.gif';
import effect_7 from '../../../assets/stamp/gifs/effect_7.gif';
import effect_8 from '../../../assets/stamp/gifs/effect_8.gif';
import effect_13 from '../../../assets/stamp/gifs/effect_13.gif';
import effect_14 from '../../../assets/stamp/gifs/effect_14.gif';
import effect_16 from '../../../assets/stamp/gifs/effect_16.gif';
import effect_19 from '../../../assets/stamp/gifs/effect_19.gif';
import effect_21 from '../../../assets/stamp/gifs/effect_21.gif';
const StaticGif = {
  effect_3,
  effect_4,
  effect_6,
  effect_7,
  effect_8,
  effect_13,
  effect_14,
  effect_16,
  effect_19,
  effect_21,
};

export default StaticGif;
