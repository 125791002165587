import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import { configParamsGETJson, configParamsPUTJson, reportAPI } from 'apis';

const getListReports = (...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.LIST_REPORTS_REQUEST));
    callApi(
      reportAPI.getListReport(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_REPORTS_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_REPORTS_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getDetailReports = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.REPORT_DETAIL_REQUEST));
    callApi(
      reportAPI.getDetailReport(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.REPORT_DETAIL_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.REPORT_DETAIL_FAILED, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};
const updateStatusReport = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_STATUS_REPORT_REQUEST));
    callApi(
      reportAPI.updateStatusReport(id),
      configParamsPUTJson(s, null),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_STATUS_REPORT_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_STATUS_REPORT_FAILED, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      'UPDATE_STATUS_REPORT'
    );
  };
}
export { getListReports, getDetailReports, updateStatusReport };
