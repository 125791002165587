/* eslint-disable import/no-anonymous-default-export */
import { getToken } from 'constants';
import { TYPES } from '../actions';
const initialState = {
  error: undefined,
  token: getToken() || undefined,
  isAuth: getToken() ? true : false,
  // isLoggedIn: false, //Đợi API Check profile để set lại
  isLoggedIn: getToken() ? true : false,
  isLogging: false,
  code: '',
  email: '',
  isSendEmail: false,
  isTrueToken: false,
  isResetSuccess: false,
  profile: {},
  permissions: [],
  checkPermission: false,
};
const clearInitialState = {
  token: undefined,
  isAuth: false,
  user: undefined,
  isLoggedIn: false,
  code: '',
  profile: {},
  checkPermission: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PRE_SIGN_IN_REQUEST:
    case TYPES.SIGN_IN_REQUEST:
      return {
        ...state,
        ...clearInitialState,
        status: action.type,
        error: null,
      };
    case TYPES.GET_PROFILE_AUTH_REQUEST:
      return {
        ...state,
        profile: {},
      };
    case TYPES.SEND_EMAIL_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isSendEmail: false,
      };
    case TYPES.CHECK_TOKEN_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isTrueToken: false,
      };
    case TYPES.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetSuccess: false,
      };
    case TYPES.GET_PERMISSION_REQUEST:
      return {
        ...state,
        permissions: [],
        checkPermission: false,
      };
    case TYPES.PRE_SIGN_IN_SUCCESS:
      return {
        ...state,
        email: action.data.email,
      };
    case TYPES.SIGN_IN_SUCCESS:
      return {
        ...state,
        type: action.data.type,
        token: action.data.token,
        isAuth: true,
        isLoggedIn: true,
        isLogging: true,
      };
    case TYPES.SEND_EMAIL_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSendEmail: true,
      };
    case TYPES.CHECK_TOKEN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isTrueToken: true,
      };
    case TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetSuccess: true,
      };
    case TYPES.GET_PROFILE_AUTH_SUCCESS:
      return {
        ...state,
        profile: action.data.data,
      };
    case TYPES.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: action.data.map((item) => ({ [item.code]: item.status })),
        checkPermission: true,
      };
    case TYPES.SIGN_IN_FAILED:
    case TYPES.PRE_SIGN_IN_FAILED:
      return {
        ...state,
        ...clearInitialState,
        error: action.data.error,
      };
    case TYPES.SIGN_OUT_SUCCESS:
    case TYPES.GET_PROFILE_AUTH_FAILED:
    case TYPES.GET_PERMISSION_FAILED:
      return {
        ...state,
        ...clearInitialState,
      };
    case 'RESET_IS_SEND':
      return {
        ...state,
        isSendEmail: false,
      };

    case TYPES.SEND_EMAIL_RESET_PASSWORD_FAILED:
    case TYPES.CHECK_TOKEN_RESET_PASSWORD_FAILED:
    case TYPES.RESET_PASSWORD_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
