import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  adminAPI,
  configParamsGETJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
  configParamsPOSTJson,
} from 'apis';
import { formatDateOnly } from 'constants/FilterDate';

const getListOperationsAdmin = ({ ...props }) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_OPERATION_ADMIN_REQUEST));
    callApi(
      adminAPI.getListOperationsAdmin({ ...props }),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_OPERATION_ADMIN_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_OPERATION_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};

const getDetailOperationAdmin = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_OPERATION_ADMIN_REQUEST));
    callApi(
      adminAPI.getDetailOperationAdmin(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_DETAIL_OPERATION_ADMIN_SUCCESS, {
            res: response.data,
          })
        );
        await navigate();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_DETAIL_OPERATION_ADMIN_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};

const updateOperationAdmin = (data, id, navigate = () => {}) => {
  const params = { ...data, birthday: formatDateOnly(data.birthday) };

  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_OPERATION_ADMIN_REQUEST));
    callApi(
      adminAPI.updateOperationAdmin(id),
      configParamsPUTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_OPERATION_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_OPERATION_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_ADMIN_CS'
    );
  };
};

const getListCSAdmin = ({ ...props }) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_CS_ADMIN_REQUEST));
    callApi(
      adminAPI.getListCSAdmin({ ...props }),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_CS_ADMIN_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_CS_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};
const getDetailAdminCS = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_CS_ADMIN_REQUEST));
    callApi(
      adminAPI.getDetailCSAdmin(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_DETAIL_CS_ADMIN_SUCCESS, {
            res: response.data,
          })
        );
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_CS_ADMIN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};

const getDetailPermissionsAS = (id, navigate = () => {}, errNavigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_PERMISSIONS_CS_ADMIN_REQUEST));
    callApi(
      adminAPI.getPermissionsCSAdmin(id),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_PERMISSIONS_CS_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_PERMISSIONS_CS_ADMIN_FAILED, { error: error.errors }));
        errNavigate();
      },
      dispatch,
      null
    );
  };
};

const createCSAdmin = (data, navigate = () => {}) => {
  const params = { ...data, birthday: formatDateOnly(data.birthday) };

  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATE_CS_ADMIN_REQUEST));
    callApi(
      adminAPI.createCSAdmin,
      configParamsPOSTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.CREATE_CS_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_CS_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch,
      'CREATE_ADMIN'
    );
  };
};

const createOperationAdmin = (data, navigate = () => {}) => {
  const params = { ...data, birthday: formatDateOnly(data.birthday) };

  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATE_OPERATION_ADMIN_REQUEST));
    callApi(
      adminAPI.createOperationAdmin,
      configParamsPOSTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.CREATE_OPERATION_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_OPERATION_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch,
      'CREATE_ADMIN'
    );
  };
};

const updateCSAdmin = (data, id, navigate = () => {}) => {
  const params = { ...data, birthday: formatDateOnly(data.birthday) };

  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_CS_ADMIN_REQUEST));
    callApi(
      adminAPI.updateCSAdmin(id),
      configParamsPUTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_CS_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_CS_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch,
      'UPDATE_ADMIN_CS'
    );
  };
};

const updateCSPermissions = (data, id, navigate = () => {}) => {
  const params = { ...data };

  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.UPDATE_PERMISSIONS_CS_ADMIN_REQUEST));
    callApi(
      adminAPI.updatePermissionsCSAmin(id),
      configParamsPUTJson(s, params),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.UPDATE_PERMISSIONS_CS_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.UPDATE_PERMISSIONS_CS_ADMIN_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};

const deleteCSAdmin = (id, navigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_CS_ADMIN_REQUEST));
    callApi(
      adminAPI.deleteCSAdmin(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_CS_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_CS_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETED_ADMIN_CS'
    );
  };
};

const deleteOpeationAdmin = (id, navigate = () => {}) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_OPERATION_ADMIN_REQUEST));
    callApi(
      adminAPI.deleteOperationAdmin(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_OPERATION_ADMIN_SUCCESS, response));
        await navigate();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_OPERATION_ADMIN_FAILED, { error: error.errors }));
      },
      dispatch,
      'DELETED_ADMIN_CS'
    );
  };
};

const getListAccountLogManagement = ({ ...props }) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_ACCOUNT_LOG_MANAGEMENT_REQUEST));
    callApi(
      adminAPI.getListAccountLogManagement({ ...props }),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_ACCOUNT_LOG_MANAGEMENT_SUCCESS, response));
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_ACCOUNT_LOG_MANAGEMENT_FAILED, { error: error.errors })
        );
      },
      dispatch
    );
  };
};

export {
  getListCSAdmin,
  createCSAdmin,
  updateCSAdmin,
  deleteCSAdmin,
  getDetailAdminCS,
  getListOperationsAdmin,
  getDetailOperationAdmin,
  createOperationAdmin,
  updateOperationAdmin,
  deleteOpeationAdmin,
  updateCSPermissions,
  getDetailPermissionsAS,
  getListAccountLogManagement,
};
