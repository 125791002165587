import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';
const paymentAPI = {
  getListPayment: (type, ...data) =>
    `${API_URL_V2}/payments?type=${type}&${stringify(...data)}`,
  exportCsvPayment: (data) =>
    `${API_URL_V2}/payments/export?ids=${data?.ids}&type=${data?.type}&history_flag=${data?.history_flag}`,
  updateStatusPayment: `${API_URL_V2}/payments/update-status`,
  getHistoryPayment: `${API_URL_V2}/payments/export-history`,
};

export { paymentAPI };
