import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';
const notificationAPI = {
  getListNotification: (...params) => `${API_URL_V2}/notifications?${stringify(...params)}`,
  createNotification: `${API_URL_V2}/notifications`,
  updateNotification: (id) => `${API_URL_V2}/notifications/${id}`,
  deleteNotification: (id) => `${API_URL_V2}/notifications/${id}`,
  getDetailNotification: (id) => `${API_URL_V2}/notifications/${id}`,
};

export { notificationAPI };
