const defaultColor = {
  primary: '#3174ef',
  secondary: '#ff9800',
  success: '#4caf50',
  error: '#f50057 ',
  warning: '#ff9800',
  info: '#2196f3',
  light: '#fafafa',
  dark: '#212121',
  white: '#ffffff',
  black: '#000000',
  gray: '#9e9e9e',
  delete: '#ffffff',
  edit: '#ffffff',
};
const colorText = {
  primary: '#ffffff',
  secondary: '#ffffff',
  success: '#ffffff',
  error: '#ffffff',
  warning: '#ffffff',
  info: '#ffffff',
  light: '#000000',
  dark: '#ffffff',
  white: '#ffffff',
  black: '#ffffff',
  gray: '#ffffff',
  delete: '#f50057',
  edit: '#3174ef',
  red: '#f44336'
};
const hoverColor = {
  primary: '#2f80ed',
  secondary: '#ffa726',
  success: '#388e3c',
  error: '#ce1a5a',
  warning: '#ffa726',
  info: '#1976d2',
  light: '#f5f5f5',
  dark: '#212121',
  white: '#ffffff',
  black: '#000000',
  gray: '#9e9e9e',
  delete: '#fafafa',
  edit: '#fafafa',
};
export { defaultColor, colorText, hoverColor };
