import { API_URL_V2 } from 'constants';
const authAPI = {
  postPreLogin: `${API_URL_V2}/pre-login`,
  postLogin: `${API_URL_V2}/login`,
  postLogout: `${API_URL_V2}/logout`,
  postMailResetPassword: (email) =>
    `${API_URL_V2}/reset-password?email=${encodeURIComponent(email)}`,
  checkToken: (token) => `${API_URL_V2}/check-token/${token}`,
  resetPassword: (token) => `${API_URL_V2}/reset-password/${token}`,
  getProfile: `${API_URL_V2}/profile`,
  checkPermission: (id) => `${API_URL_V2}/cs-admins/${id}/permissions`,
};
export { authAPI };
