import { PATH_URL } from 'constants/routes/settingUrl';
// import { Advertisement } from 'pages/SettingDisplaySite/Advertisement';
// import { CreatorFavorite } from 'pages/SettingDisplaySite/CreatorFavorite';
// import { NewCreatorSetting } from 'pages/SettingDisplaySite/NewCreatorSetting';
// import { OperatorSide } from 'pages/SettingDisplaySite/OperatorSide';
// import { RankingList } from 'pages/SettingDisplaySite/RankingList';
import { Recommended } from 'pages/SettingDisplaySite/Recommended';
import { Navigate } from 'react-router-dom';

export const listRouteSettingPage = [
  // {
  //   // A005
  //   path: `${PATH_URL.SETTING_PAGE}/creator-favorite`,
  //   element: <CreatorFavorite />,
  //   title: 'サイト内表示設定画面',
  //   currentPath: `/${PATH_URL.SETTING_PAGE}/creator-favorite`,
  //   backPath: `${PATH_URL.SETTING_PAGE}`,
  // },
  // {
  //   path: `${PATH_URL.SETTING_PAGE}/new-creator`,
  //   element: <NewCreatorSetting />,
  //   title: 'サイト内表示設定画面',
  //   currentPath: `/${PATH_URL.SETTING_PAGE}/new-creator`,
  //   backPath: `${PATH_URL.SETTING_PAGE}`,
  // },
  // {
  //   path: `${PATH_URL.SETTING_PAGE}/operator-side`,
  //   element: <OperatorSide />,
  //   title: 'サイト内表示設定画面',
  //   currentPath: `/${PATH_URL.SETTING_PAGE}/operator-side`,
  //   backPath: `${PATH_URL.SETTING_PAGE}`,
  // },

  // {
  //   path: `${PATH_URL.SETTING_PAGE}/ranking-list`,
  //   element: <RankingList />,
  //   title: 'サイト内表示設定画面',
  //   currentPath: `/${PATH_URL.SETTING_PAGE}/ranking-list`,
  //   backPath: `${PATH_URL.SETTING_PAGE}`,
  // },
  {
    path: `${PATH_URL.SETTING_PAGE}/recommended`,
    element: <Recommended />,
    title: 'サイト内表示設定画面',
    currentPath: `/${PATH_URL.SETTING_PAGE}/recommended`,
    backPath: `${PATH_URL.SETTING_PAGE}`,
  },
  // {
  //   path: `${PATH_URL.SETTING_PAGE}/advertisement`,
  //   element: <Advertisement />,
  //   title: 'サイト内表示設定画面',
  //   currentPath: `/${PATH_URL.SETTING_PAGE}/advertisement`,
  //   backPath: `${PATH_URL.SETTING_PAGE}`,
  // },
  {
    path: PATH_URL.SETTING_PAGE,
    // currentPath: `/${PATH_URL.SETTING_PAGE}/creator-favorite`,
    element: <Navigate to='recommended' replace />,
    title: 'サイト内表示設定',
  },
];
