import { PATH_URL } from 'constants/routes/settingUrl';
import { lazyRoute } from 'effect';
import { DetailContract } from 'pages/Creator/ContractForm';
import { DetailRegistration } from 'pages/Creator/DetailRegistration';
import { DetailCreator } from 'pages/Creator/EditForm';
import { ListRecordLivestream } from 'pages/Creator/listRecordLivestream';
import { LiveDetail } from 'pages/Creator/liveDetail';
import { Setting } from 'pages/Creator/setting';
import { TableDetail } from 'pages/Creator/tableDetail';
import { AccountFanPage } from 'pages/fan';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
const CreatorPage = lazy(() =>
  lazyRoute(() =>
    import('pages/Creator/index').then((module) => ({
      default: module.CreatorPage,
    }))
  )
);
export const listRouteCreatorManagementPage = [
  {
    path: `${PATH_URL.CREATOR}/account`,
    element: <CreatorPage />,
    title: `クリエイター管理`,
    currentPath: `/${PATH_URL.CREATOR}/account`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.CREATOR}/setting`,
    element: <Setting />,
    title: `ユーザーアカウント管理`,
    currentPath: `/${PATH_URL.CREATOR}/setting`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.CREATOR}/play-live`,
    element: <LiveDetail />,
    title: `ライブ配信中一覧画面`,
    currentPath: `/${PATH_URL.CREATOR}/play-live`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `/${PATH_URL.CREATOR}/purchase-history`,
    element: <AccountFanPage isHistoryPayment />,
    title: '購入履歴閲覧',
    currentPath: `/${PATH_URL.CREATOR}/purchase-history`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `/${PATH_URL.CREATOR}/point-history`,
    element: <AccountFanPage isPointHistory />,
    title: 'ポイント購入履歴閲覧',
    currentPath: `/${PATH_URL.CREATOR}/point-history`,
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: `${PATH_URL.CREATOR}/user/edit/:id`,
    element: <DetailCreator isEdit />,
    title: `ユーザーアカウント編集画面`,
    currentPath: `/${PATH_URL.CREATOR}/user/edit`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    path: `${PATH_URL.CREATOR}/contract/:id`,
    element: <TableDetail isContract />,
    title: `出演者情報`,
    currentPath: `/${PATH_URL.CREATOR}/contract`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    path: `${PATH_URL.CREATOR}/contract/:id/detail/:contract_id`,
    element: <DetailContract />,
    title: `出演者情報`,
    currentPath: `/${PATH_URL.CREATOR}/account`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    path: `${PATH_URL.CREATOR}/user/detail/:id`,
    element: <DetailCreator isDetail />,
    title: `アカウント詳細画面`,
    currentPath: `/${PATH_URL.CREATOR}/user/detail`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    path: `${PATH_URL.CREATOR}/post-history/:id`,
    element: <TableDetail isPostHistory />,
    title: `アカウント詳細画面`,
    currentPath: `/${PATH_URL.CREATOR}/post-history`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    path: `${PATH_URL.CREATOR}/list-record-livestream/:id`,
    element: <ListRecordLivestream />,
    title: `アカウント詳細画面`,
    currentPath: `/${PATH_URL.CREATOR}/list-record-livestream`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    path: `${PATH_URL.CREATOR}/sale-history/:id`,
    element: <TableDetail isSaleHistory />,
    title: `アカウント詳細画面`,
    currentPath: `/${PATH_URL.CREATOR}/sale-history`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    path: `${PATH_URL.CREATOR}/payment-history/:id`,
    element: <TableDetail isPaymentHistory />,
    title: `アカウント詳細画面`,
    currentPath: `/${PATH_URL.CREATOR}/payment-history`,
    backPath: `/${PATH_URL.CREATOR}/account`,
  },
  {
    currentPath: `/${PATH_URL.CREATOR}/history-subscription`,
    path: `/${PATH_URL.CREATOR}/history-subscription/:id`,
    title: 'サブスクリプション購入履歴',
    backPath: `/${PATH_URL.CREATOR}/account`,
    element: <DetailRegistration type='subscription' isNotDetail />,
  },
  {
    currentPath: `/${PATH_URL.CREATOR}/history-content`,
    path: `/${PATH_URL.CREATOR}/history-content/:id`,
    title: 'コンテンツ購入履歴',
    backPath: `/${PATH_URL.CREATOR}/account`,
    element: <DetailRegistration type='content' isNotDetail />,
  },
  {
    currentPath: `/${PATH_URL.CREATOR}/history-points`,
    path: `/${PATH_URL.CREATOR}/history-points/:id`,
    title: 'ポイント購入履歴',
    backPath: `/${PATH_URL.CREATOR}/account`,
    element: <DetailRegistration type='point' isNotDetail />,
  },
  {
    currentPath: `/${PATH_URL.CREATOR}/history-donate`,
    path: `/${PATH_URL.CREATOR}/history-donate/:id`,
    title: '投げ銭履歴',
    backPath: `/${PATH_URL.CREATOR}/account`,
    element: <DetailRegistration type='donate' isNotDetail />,
  },
  {
    path: `${PATH_URL.CREATOR}`,
    currentPath: `/${PATH_URL.CREATOR}/account`,
    element: <Navigate to='account' replace />,
  },
];
