import styled from 'styled-components';

const TitleSettingPackagePayment = styled.div`
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: #fff;
  width: 100%;
  height: 40px;
  margin: 25px 0px 20px 0px;
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
  }
`;

const FormSettingPackagePayment = styled.div`
  .group-table {
    display: flex;
    min-width: 500px;
    overflow: auto;
    gap: 30px;
    .table-setting-point {
      margin-top: 20px;
      width: 43.5%;
      min-width: 660px;
      border-collapse: collapse;
      .thead-table {
        .thead {
          text-align: center;
          background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
          color: #fff;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 40px;
          .row {
            border: 1px solid rgba(224, 224, 224, 1);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .row-stt {
            width: 10%;
          }
          .row-value {
            width: 30%;
          }
          .row-checkbox {
            width: 20%;
          }
          .row-price {
            width: 25%;
          }
          .row-action {
            width: 15%;
          }
        }
      }
      .tbody-table {
        .tbody {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 40px;
          .row {
            border: 1px solid rgba(224, 224, 224, 1);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .row-stt {
            text-align: center;
            width: 10%;
          }
          .row-value {
            padding-left: 5px;
            width: 30%;
            justify-content: initial;
            align-items: center;
          }
          .row-checkbox {
            width: 20%;
            text-align: center;
          }
          .row-price {
            width: 25%;
          }
          .row-action {
            width: 15%;
            text-align: center;
            color: red;
            cursor: pointer;
          }
        }
      }
    }
    .table-setting-campaign-point {
      margin-top: 20px;
      width: 43.5%;
      min-width: 660px;
      border-collapse: collapse;
      .thead-table {
        .thead {
          text-align: center;
          background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
          color: #fff;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 40px;
          .row {
            border: 1px solid rgba(224, 224, 224, 1);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .row-stt {
            width: 10%;
          }
          .row-value {
            width: 30%;
          }
          .row-checkbox {
            width: 20%;
          }
          .row-price {
            width: 25%;
          }
          .row-action {
            width: 15%;
          }
        }
      }
      .tbody-table {
        .tbody {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 40px;
          .row {
            border: 1px solid rgba(224, 224, 224, 1);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .row-stt {
            text-align: center;
            width: 10%;
          }
          .row-value {
            padding-left: 5px;
            width: 30%;
            justify-content: initial;
            align-items: center;
          }
          .row-checkbox {
            width: 20%;
            text-align: center;
          }
          .row-price {
            width: 25%;
          }
          .row-action {
            width: 15%;
            text-align: center;
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
  .radio-form {
    display: flex;
    margin-left: 20px;
    .radio {
      width: 46%;
      display: flex;
      align-items: center;
      gap: 15px;
      input {
        cursor: pointer;
      }
    }
  }
  .form-action {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  input {
    width: 20px;
    height: 20px;
  }
`;

const FormPoint = styled.div`
  .form-point {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    .title {
      display: flex;
      min-width: 160px;
      align-items: center;
      > span {
        margin-left: 10px;
      }
      height: 40px;
      width: 12%;
      background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
      color: #fff;
    }
    .input {
      width: 27%;
    }
  }
  .form-point-limit {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    .title {
      min-width: 160px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 10px;
      }
      height: 41px;
      width: 12%;
      background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
      color: #fff;
    }
    .input-1 {
      width: 27%;
    }
    .input-2 {
      width: 27%;
    }
  }
  .form-point-button {
    display: flex;
    justify-content: flex-end;
  }
  input {
    width: 100%;
    height: 41px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    padding: 10px 10px;
  }
`;
const FormCreatSettingPackage = styled.div`
  .form-create-setting-package {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    .label-title {
      width: 30%;
    }
    .label-title-active {
      width: 21.5%;
    }
    .input {
      width: 70%;
    }
  }
`;

export {
  FormSettingPackagePayment,
  FormPoint,
  TitleSettingPackagePayment,
  FormCreatSettingPackage,
};
