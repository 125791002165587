import { API_URL } from 'constants';
import { stringify } from 'qs';

const emailAPI = {
  getTemplateEmail: (...data) => `${API_URL}/email_templates?${stringify(...data)}`,
  getTemplateEmailDetail: (id) => `${API_URL}/email_templates/${id}`,
  updateTemplateEmail: (id) => `${API_URL}/email_templates/${id}`,
};

export { emailAPI };
