import { useForm } from 'react-hook-form';
import React, { useContext, useState } from 'react';
import { ButtonSubmit, TextForm, FormSignIn } from './styled';
import { useNavigate, Link } from 'react-router-dom';
import { BACKGROUND_LOGIN } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { yupEmail } from 'constants';
import {
  Content,
  FormInputText,
  WrapperContent,
} from 'components/FromFeature/form-components';
import { sendEmailResetPassword } from 'store/actions/auth.action';
import { AlertDialog } from 'components/Dialog';
import { useEffect } from 'react';
import { updateStatus } from 'constants';
import { LanguageContext } from 'Context/translation.context';
import { yupEmailCreate } from 'constants';

export const ForgotPasswordPage = () => {
  const { tAuth } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSendEmail } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(updateStatus('RESET_IS_SEND'));
  }, []);
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        ...yupEmailCreate(),
      })
    ),
  });
  const { handleSubmit, control } = methods;
  const onSubmit = (data) => {
    dispatch(sendEmailResetPassword(data.email));
  };
  return (
    <>
      <FormSignIn
        onSubmit={handleSubmit(onSubmit)}
        bgi={BACKGROUND_LOGIN}
        xs={11}
        sm={9}
        md={5}
        lg={4}
        xl={3}
        title={tAuth('forgotPassword.passwordResetScreen')}
        item>
        <WrapperContent display='block'>
          <Content marginBottom='1rem' paddingTop='4px' minWidth='110px'>
            {tAuth('forgotPassword.emailAddress')}
          </Content>
          <FormInputText
            label={tAuth('forgotPassword.pleaseEnterYourEmailAddress')}
            name='email'
            control={control}
          />
        </WrapperContent>
        <ButtonSubmit
          type='submit'
          variant='contained'
          bgtheme='primary'
          size='medium'
          fullWidth>
          {tAuth('forgotPassword.send')}
        </ButtonSubmit>
        <TextForm>
          {tAuth('loginIs')}
          <Link to='/sign-in'>{tAuth('here')}</Link>
        </TextForm>
      </FormSignIn>

      <AlertDialog
        close={isSendEmail}
        title={tAuth('forgotPassword.passwordReset')}
        content={tAuth('forgotPassword.aResetEmailHasBeenSentPleaseConfirm')}
        handleCancel={() => navigate('/sign-in')}
        noSubmit={false}
        noButton
        buttonCacnel={tAuth('yes')}
      />
    </>
  );
};

export default ForgotPasswordPage;
