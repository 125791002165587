export const AFFILIATOR = {
  GET_LIST_AFFILIATOR: 'GET_LIST_AFFILIATOR',
  GET_LIST_BANNER_AFFILIATOR: 'GET_LIST_BANNER_AFFILIATOR',
  GET_DETAIL_AFFILIATOR: 'GET_DETAIL_AFFILIATOR',
  UPDATE_DETAIL_AFFILIATOR: 'UPDATE_DETAIL_AFFILIATOR',
  DELETE_AFFILIATOR: 'DELETE_AFFILIATOR',
  GET_LIST_HISTORY_SALE_AFFILIATOR: 'GET_LIST_HISTORY_SALE_AFFILIATOR',
  GET_LIST_HISTORY_PAYMENT_AFFILIATOR: 'GET_LIST_HISTORY_PAYMENT_AFFILIATOR',
  GET_SETTING_LIMIT_AFFILIATOR: 'GET_SETTING_LIMIT_AFFILIATOR',
  UPDATE_SETTING_LIMIT_AFFILIATOR: 'UPDATE_SETTING_LIMIT_AFFILIATOR',
  CHANGE_STATUS_AFFILIATOR: 'CHANGE_STATUS_AFFILIATOR',
};
