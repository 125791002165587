import React from 'react';
import { InputSearch } from './styled';
import propTypes from 'prop-types';

const InputForm = React.forwardRef((props, ref) => (
  <InputSearch inputRef={ref} width='100%' {...props} children={props.children} />
));
export { InputForm };
InputForm.propTypes = {
  width: propTypes.string,
};
