import React, { useContext, useEffect } from 'react';
import {
  WrapperTitleContent,
  Content,
  WrapperForm,
  WrapperContent,
  FormInputText,
  WrapperButton,
} from 'components/FromFeature/form-components';
import { useForm } from 'react-hook-form';
import { TabsAffiliate } from './settings/tabs';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AlertDialog } from 'components/Dialog';
import {
  affiliatorChangeSettingLimit,
  affiliatorGetSettingLimit,
} from 'store/actions/affiliate.action';
import { yupMinPrice } from 'constants';
import { LanguageContext } from 'Context/translation.context';
export const Setting = () => {
  const { tAdmincs } = useContext(LanguageContext);
  const { limitSetting, error } = useSelector((state) => state.affiliator);
  const methods = useForm({
    mode: 'all',
    defaultValues: { min_price: 0 },
    resolver: yupResolver(
      yup.object().shape({
        ...yupMinPrice(),
      })
    ),
  });
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    dispatch(affiliatorGetSettingLimit());
  }, []);

  useEffect(() => {
    setValue('min_price', limitSetting);
  }, [limitSetting]);
  const handleChange = (data) => {
    dispatch(affiliatorChangeSettingLimit(data, () => dispatch(affiliatorGetSettingLimit())));
  };
  return (
    <>
      <TabsAffiliate />
      <WrapperTitleContent>
        <Content>{tAdmincs('affiliator.withdrawalUpperAndLowerLimits')}</Content>
      </WrapperTitleContent>
      <WrapperForm maxWidth='400px'>
        <WrapperContent justifyContent>
          <Content minWidth='50px'>{tAdmincs('affiliator.lowerLimit')}</Content>
          <FormInputText
            control={control}
            width='300px'
            name='min_price'
            label=''
            disabled={error === 'PERMISSION_ERRORS'}
          />
          <Content minWidth='50px' mgl='10px'>
            {tAdmincs('affiliator.circle')}
          </Content>
        </WrapperContent>
        <WrapperButton padding='0 60px' justify='end'>
          <AlertDialog
            handleAccpet={handleSubmit(handleChange)}
            updateStatus={tAdmincs('affiliator.setting')}
            title={tAdmincs('affiliator.setting')}
            content={tAdmincs('affiliator.areYouSureYouWantToSetIt')}
            propsButton={{
              disabled: error === 'PERMISSION_ERRORS',
            }}
          />
        </WrapperButton>
      </WrapperForm>
    </>
  );
};
