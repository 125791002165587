import React from 'react';
import {
  WrapperLoginPage,
  WrapperLoginForm,
  WrapperFlex,
  GridLayout,
  BoxForm,
  TitleForm,
  CardForm,
  ListLogoForm,
  CardFormContent,
  FormContent,
} from './styled';
import propTypes from 'prop-types';
const FormGridLayout = ({ children, title, bgi, onSubmit, propsForm, ...props }) => (
  <WrapperLoginPage bgi={bgi}>
    <WrapperLoginForm>
      <WrapperFlex>
        <GridLayout {...props}>
          <CardForm>
            <BoxForm>
              <TitleForm>{title}</TitleForm>
              {/* <ListLogoForm /> */}
            </BoxForm>
            <CardFormContent>
              <FormContent
                {...propsForm}
                role='form'
                onSubmit={onSubmit}
                children={children}
              />
            </CardFormContent>
          </CardForm>
        </GridLayout>
      </WrapperFlex>
    </WrapperLoginForm>
  </WrapperLoginPage>
);
export { FormGridLayout };
FormGridLayout.propTypes = {
  children: propTypes.node,
  bgi: propTypes.string,
  title: propTypes.string,
  onSubmit: propTypes.func,
  propsForm: propTypes.object,
};
