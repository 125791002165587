import { useState, useCallback, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
const useCreatePortal = (el) => {
  const [portal, setPortal] = useState({
    render: () => null,
    remove: () => null,
  });

  const createPortal = useCallback((el) => {
    const Portal = ({ children }) => ReactDOM.createPortal(children, el);
    const remove = () => ReactDOM.unmountComponentAtNode(el);
    return { render: Portal, remove };
  }, []);

  useEffect(() => {
    if (el) portal.remove();
    const newPortal = createPortal(el);
    setPortal(newPortal);
    return () => newPortal.remove(el);
  }, [el]);

  return portal.render;
};
useCreatePortal.propTypes = {
  el: PropTypes.instanceOf(Element),
};
/**
 * docs by dunghh
 * @param {HTMLElement} el
 * @returns {function}
 * @example
 * const Portal = useCreatePortal(document.getElementById('portal'));
 * <div>
 *  <Portal>
 *   <div>Portal</div>
 * </Portal>
 * </div>
 */
export {
    useCreatePortal
}