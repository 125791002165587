const paramsEdit = {
  phone: '',
  birthday: '',
  gender: '',
  country_id: '',
  account_name: '',
  account_id: '',
  first_name: '',
  first_name_kana: '',
  last_name: '',
  last_name_kana: '',
  commission_sales: '',
  commission_subscription: '',
  commission_livestream: '',
  commission_message: '',
  contract: '',
  account_id_origin: ''
};
const paramsDetailEdit = {
  ...paramsEdit,
  email: '',
  identify_img_back: '',
  identify_img_front: '',
  status: '',
  company_name: '',
  commission_percent: '',
};
const paramsDetail = {
  ...paramsEdit,
  identify_img_back: '',
  identify_img_front: '',
  commission_percent: '',
  company_name: '',
  email: '',
  country_id: '',
  status: '',
  bank_code: '',
  branch_code: '',
  type: '',
  account_number: '',
  bank_account_name: '',
  account_address: '',
  account_phone: '',
  bank_name: '',
  swift_code: '',
  branch_name: '',
  branch_address: '',

};
export { paramsEdit, paramsDetail, paramsDetailEdit };
