import { Clear } from '@material-ui/icons';
import { ButtonSubmit, WrapperButton } from 'components/FromFeature/form-components';
import { ViewVideo } from './styled';

const ShowVideo = ({ videos, setVideos }) => {
  const getUrlVideo = () => {
    if (videos) {
      return URL.createObjectURL(videos);
    }
    return null;
  };
  return (
    <>
      <ViewVideo controls src={getUrlVideo()} preload='none' />
      <WrapperButton justify='end'>
        <ButtonSubmit
          bgtheme='primary'
          variant='contained'
          height='30px'
          min='60px'
          onClick={() => setVideos(null)}
          startIcon={<Clear />}>
          キャンセル
        </ButtonSubmit>
      </WrapperButton>
    </>
  );
};
export { ShowVideo };
