import { AlertDialog } from 'components/Dialog';
import { TitleItem } from 'pages/SettingDisplaySite/styled';
import { useEffect, useState } from 'react';
import StaticGif from './StaticGif';
import { FormCreatSettingEffect, FormSettingImg, TitleSettingEffect } from './styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from 'react-hook-form';
import { FormInputText } from 'components/FromFeature/form-components';
import { Checkbox } from '@mui/material';
import StaticImg from './StaticImg';
import { useDispatch } from 'react-redux';
import { ActionCreatorEffect } from 'store/actions/settingsite.action';
import { schemaEffectCreator } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
const paramsEffect = {
  name: '',
  active_flag: 0,
  gift: '',
};
export const EfectCreator = () => {
  const [data, setData] = useState([]);
  const [showGif, setShowGif] = useState('');
  const dispatch = useDispatch();
  const methods = useForm({
    mode: 'all',
    defaultValues: paramsEffect,
    resolver: yupResolver(schemaEffectCreator),
  });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const valueName = watch('name');
  const valuePoint = watch('point');
  useEffect(() => {
    dispatch(
      ActionCreatorEffect.getEffectCreator({ sort_setting_category: 1 }, (res) => setData(res))
    );
  }, []);
  const onChangeCheckTable = (id, index, e) => {
    const idx = data.find((item) => item.id === id);

    if (idx) {
      setData((prev) => {
        const list = [...prev];
        list[index].active_flag = e.target.checked ? 1 : 0;
        return list;
      });
    }
  };
  const handleCreateSettingGif = () => {
    setShowGif(showGif);
    setValue('gift', showGif);
  };

  //   const formCreateSettingImg = (
  //     <FormSettingImg>
  //       <div>
  //         {Object.keys(StaticImg)?.map((item, index) => {
  //           return (
  //             <div tabIndex={index} key={item} onClick={() => handleShowIcon(item)}>
  //               <img
  //                 style={{
  //                   width: '50px',
  //                   height: '50px',
  //                   borderRadius: '50%',
  //                   overflow: 'hidden',
  //                   marginLeft: '10px',
  //                 }}
  //                 src={StaticImg[item]}
  //                 alt=''
  //               />
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </FormSettingImg>
  //   );
  const handleShowGif = (img) => {
    setShowGif(img);
  };
  const formCreateSettingGif = (
    <FormSettingImg>
      <div>
        {Object.keys(StaticGif)?.map((item, index) => {
          return (
            <div tabIndex={index} key={item} onClick={() => handleShowGif(item)}>
              <img
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  marginLeft: '10px',
                }}
                src={StaticGif[item]}
                alt=''
              />
            </div>
          );
        })}
      </div>
    </FormSettingImg>
  );
  const handleSubmitSettingEffect = () => {
    const paramSubmit = [];

    data.forEach((item) => {
      paramSubmit.push({
        id: item.id,
        name: item.name,
        active_flag: item.active_flag,
        icon: item.icon,
        gift: item.gift,
        point: item.point,
      });
    });

    const paramPutSettingEffect = {
      ids: paramSubmit,
    };

    dispatch(
      ActionCreatorEffect.updateEffectCreator(paramPutSettingEffect, () =>
        dispatch(
          ActionCreatorEffect.getEffectCreator({ sort_setting_category: 1 }, (res) =>
            setData(res)
          )
        )
      )
    );
  };
  const handleCreateSettingEffect = (data) => {
    dispatch(
      ActionCreatorEffect.postEffectCreator(data, () =>
        dispatch(
          ActionCreatorEffect.getEffectCreator({ sort_setting_category: 1 }, (res) =>
            setData(res)
          )
        )
      )
    );
    reset();
    setShowGif('');
  };
  const handleCancelSettingEffect = () => {
    reset();
    setShowGif('');
  };
  const handleDeleteSettingEffect = (id) => {
    dispatch(
      ActionCreatorEffect.deleteEffectCreator(id, () =>
        dispatch(
          ActionCreatorEffect.getEffectCreator({ sort_setting_category: 1 }, (res) =>
            setData(res)
          )
        )
      )
    );
  };
  const handleChangeFormChecked = (e) => {
    setValue('active_flag', e.target.checked ? 1 : 0);
  };
  const formCreateSettingEffect = (
    <FormCreatSettingEffect>
      <div className='form-create-setting-effect'>
        <div className='label-title'>エフェクトスタンプ名</div>
        <FormInputText className='input' control={control} name='name' />
      </div>
      <div className='form-create-setting-effect btn-img'>
        <div className='label-title-icon'>エフェクト</div>
        <div className='gift'>
          <AlertDialog
            contentElement={formCreateSettingGif}
            title='エフェクトGIF画像を選択'
            updateStatus='GIF画像選択'
            handleAccpet={handleCreateSettingGif}
            handleCancel={() => setShowGif('')}
            propsButtonNo={{
              disabled: !showGif.length ? true : false,
            }}
          />
          {!!showGif.length ? <div>{showGif}</div> : ''}
        </div>
      </div>
      <div className='form-create-setting-effect'>
        <div className='label-title-active'>表示フラグ</div>
        <Checkbox
          className='input-checkbox'
          control={control}
          name='active_flag'
          onChange={handleChangeFormChecked}
        />
      </div>
    </FormCreatSettingEffect>
  );
  return (
    <div className='form-setting-creator'>
      <TitleSettingEffect>
        <span>ユーザー専用エフェクト</span>
      </TitleSettingEffect>

      <div className='table-effect-stamp'>
        <div className='thead-table'>
          <div className='thead'>
            <div className='row row-stt'>表示順位</div>
            <div className='row row-name'>エフェクトステップ名</div>
            <div className='row row-gift'>エフェクト</div>
            <div className='row row-checkbox'>表示フラグ</div>
            <div className='row row-action'></div>
          </div>
        </div>
        <div className='tbody-table'>
          {data?.length
            ? data.map((item, index) => {
                return (
                  <div key={index} className='tbody'>
                    <div className='row row-stt'>{index + 1}</div>
                    <div className='row row-name'>{item.name}</div>
                    <div className='row row-gift'>
                      <img
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                        }}
                        src={`${StaticGif[item.gift]}`}
                        alt=''
                      />
                    </div>
                    <div className='row row-checkbox'>
                      <input
                        type='checkbox'
                        name='active_flag'
                        id='active_flag'
                        checked={!!item?.active_flag}
                        onChange={(e) => onChangeCheckTable(item.id, index, e)}
                      />
                    </div>
                    <div className='row row-action'>
                      <TitleItem borderRight minWidth='100px'>
                        <AlertDialog
                          addDelete
                          title='削除'
                          content='このエフェクトを削除してもよろしいですか。'
                          handleAccpet={() => {
                            handleDeleteSettingEffect(item.id);
                          }}
                          propsButton={{
                            min: '60px',
                            height: '30px',
                            startIcon: <DeleteIcon />,
                            variant: 'text',
                          }}
                        />
                      </TitleItem>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>

      <div>※設定ボタンを押下すると変更が反映されます。</div>

      <div className='form-action'>
        <AlertDialog
          contentElement={formCreateSettingEffect}
          title='スタンプ・エフェクトを追加'
          updateStatus='行を追加'
          handleAccpet={handleSubmit(handleCreateSettingEffect)}
          handleCancel={handleCancelSettingEffect}
          onOpen={handleCancelSettingEffect}
          buttonConfimrNobutton='追加する'
          buttonCacnel='キャンセル'
          propsButtonNo={{
            disabled:
              !valueName.length || !showGif.length || Object.keys(errors).length
                ? true
                : false,
          }}
        />

        <AlertDialog
          title='設定'
          content='設定してもよろしいですか。'
          updateStatus='設定'
          propsButton={{ bgtheme: 'secondary' }}
          handleAccpet={handleSubmitSettingEffect}
        />
      </div>
    </div>
  );
};
