import { yupPoint } from 'constants';
import { yupNameEffect } from 'constants';
import * as yup from 'yup';
const schemaCreateSettingEffect = yup.object().shape({
  ...yupNameEffect(),
  ...yupPoint(),
});
const schemaEffectCreator = yup.object().shape({
  ...yupNameEffect(),
});
export { schemaCreateSettingEffect , schemaEffectCreator};
