const STATUS_PAYMENT = [
  { value: 1, label: '未払い' },
  { value: 2, label: '未払い「CSV出力済み」' },
  { value: 3, label: 'ペンディング' },
  { value: 4, label: '支払い済み' },
];
const STATUS_USER = [
  {
    value: 0,
    label: '作成した',
  },
  {
    value: 1,
    label: '利用中',
  },
  {
    value: 3,
    label: '承認待ち',
  },
  {
    value: 2,
    label: '停止中',
  },
  {
    value: 5,
    label: '退会済み',
  },
  {
    value: 4,
    label: '拒否',
  },
  {
    value: 7,
    label: '未承認',
  },
];

const GENDER = [
  {
    value: 0,
    label: '女性',
  },
  {
    value: 1,
    label: '男性',
  },
];

const STATUS_REPORT = [
  {
    value: 1,
    label: '未解決',
  },
  {
    value: 2,
    label: '解決',
  },
];
const STATUS_FILTER = [
  {
    value: 'user_id',
    label: 'ID',
  },
  {
    value: 'account_name',
    label: 'アカウント名',
  },
  {
    value: 'account_id',
    label: 'アカウントID',
  },
  {
    value: 'email',
    label: 'メールアドレス',
  },
  {
    value: 'phone',
    label: '電話番号',
  },
];
const STATUS_BANNER = [
  { value: 0, label: 'OFF' },
  { value: 1, label: 'ON' },
];
const STATUS_SEND_AGENCY = [
  {
    value: 1,
    label: '一次代理店の担当者名のみ送信',
  },
  {
    value: 2,
    label: '二次代理店の担当者名のみ送信',
  },
  {
    value: 3,
    label: '両方で送信',
  },
];
const STATUS_ROLE_TYPE = [
  {
    value: 0,
    label: '全体',
  },
  {
    value: 4,
    label: '代理店',
  },
  {
    value: 5,
    label: 'ユーザ',
  },
];
const STATUS_NOTIFICATION = [
  {
    value: 0,
    label: '一時保存',
  },
  {
    value: 1,
    label: '予約配信',
  },
  {
    value: 2,
    label: '掲載中',
  },
  {
    value: 3,
    label: '掲載終了',
  },
];
const STATUS_TRANSACTION = [
  {
    value: 1,
    label: '投げ銭（メッセージ）',
  },
  {
    value: 2,
    label: '投げ銭（ライブ配信）',
  },
  {
    value: 3,
    label: 'サブスクリプション',
  },
  {
    value: 4,
    label: '単体販売',
  },
];

const STATUS_POINT = [
  {
    value: 1,
    label: '付与済み',
  },
  {
    value: 2,
    label: '未付与',
  },
  {
    value: 3,
    label: '失敗',
  },
  {
    value: 4,
    label: '返金',
  },
  {
    value: 5,
    label: '確認中',
  },
];

const METHOD_PAYMENT = [
  {
    value: 1,
    label: 'クレジットカード',
  },
  {
    value: 2,
    label: 'TigerPay',
  },
];

const STATUS_REVENUE = [
  {
    value: 1,
    label: '見込み',
  },
  {
    value: 2,
    label: '確定',
  },
];
const STATUS_POSITION_DISPLAY = [
  {
    value: 1,
    label: '広告枠1 864px x 200px',
  },
  {
    value: 2,
    label: '広告枠2 344px x 122px',
  },
  {
    value: 3,
    label: '広告枠3 344px x 122px',
  },
  {
    value: 4,
    label: '広告枠4 344px x 122px',
  },
];
const TYPE_POST = [
  { value: 1, label: '投稿' },
  { value: 2, label: '販売コンテンツ' },
  { value: 4, label: 'ショート動画' },
];
const APPROVAL_STATUS = [
  { value: 0, label: 'ノーチェック' },
  { value: 1, label: 'チェック' },
];

const POST_STATUS = [
  { value: 'NOT_APPROVAL', label: '未承認' },
  { value: 'PUBLIC', label: '公開' },
  { value: 'REMOVE', label: '削除' },
  { value: 'REJECT', label: '拒否' },
  { value: 'REMOVE_BY_CREATOR', label: '投稿者が削除' },
  { value: 'APPROVED_AGAIN', label: '再承認' },
];

const SUBSCRIPTION_STATUS = [
  { value: 'using', label: '使用中' },
  { value: 'cancel', label: 'キャンセル' },
  { value: 'fail', label: '失敗' },
];

const CARD_TYPE = [
  { value: 'visa', label: 'Visa' },
  { value: 'master_card', label: 'Mastercard' },
  { value: 'jcb', label: 'JCB' },
  { value: 'maestro', label: 'Maestro' },
  { value: 'cartes_bancaires', label: 'Cartes Bancaires' },
  { value: 'interac', label: 'Interac' },
  { value: 'paygarden', label: 'PayGarden' },
];

const TYPE_FILTER_CHART = [
  { value: 'week', label: '週間' },
  { value: 'month', label: '月間' },
  { value: 'year', label: '年間' },
];

const COLOR_CHART = [
  '#63b598',
  '#ce7d78',
  '#ea9e70',
  '#a48a9e',
  '#c6e1e8',
  '#648177',
  '#0d5ac1',
  '#f205e6',
  '#1c0365',
  '#14a9ad',
  '#4ca2f9',
  '#a4e43f',
  '#d298e2',
  '#6119d0',
  '#d2737d',
  '#c0a43c',
  '#f2510e',
  '#651be6',
  '#79806e',
  '#61da5e',
  '#cd2f00',
  '#9348af',
  '#01ac53',
  '#c5a4fb',
  '#996635',
  '#b11573',
  '#4bb473',
  '#75d89e',
  '#2f3f94',
  '#2f7b99',
  '#da967d',
  '#34891f',
  '#b0d87b',
  '#ca4751',
  '#7e50a8',
  '#c4d647',
  '#e0eeb8',
  '#11dec1',
  '#289812',
  '#566ca0',
  '#ffdbe1',
  '#2f1179',
  '#935b6d',
  '#916988',
  '#513d98',
  '#aead3a',
  '#9e6d71',
  '#4b5bdc',
  '#0cd36d',
  '#250662',
  '#cb5bea',
  '#228916',
  '#ac3e1b',
  '#df514a',
  '#539397',
  '#880977',
  '#f697c1',
  '#ba96ce',
  '#679c9d',
  '#c6c42c',
  '#5d2c52',
  '#48b41b',
  '#e1cf3b',
  '#5be4f0',
  '#57c4d8',
  '#a4d17a',
  '#225b8',
  '#be608b',
  '#96b00c',
  '#088baf',
  '#f158bf',
  '#e145ba',
  '#ee91e3',
  '#05d371',
  '#5426e0',
  '#4834d0',
  '#802234',
  '#6749e8',
  '#0971f0',
  '#8fb413',
  '#b2b4f0',
  '#c3c89d',
  '#c9a941',
  '#41d158',
  '#fb21a3',
  '#51aed9',
  '#5bb32d',
  '#807fb',
  '#21538e',
  '#89d534',
  '#d36647',
  '#7fb411',
  '#0023b8',
  '#3b8c2a',
  '#986b53',
  '#f50422',
  '#983f7a',
  '#ea24a3',
  '#79352c',
  '#521250',
  '#c79ed2',
  '#d6dd92',
  '#e33e52',
  '#b2be57',
  '#fa06ec',
  '#1bb699',
  '#6b2e5f',
  '#64820f',
  '#1c271',
  '#21538e',
  '#89d534',
  '#d36647',
  '#7fb411',
  '#0023b8',
  '#3b8c2a',
  '#986b53',
  '#f50422',
  '#983f7a',
  '#ea24a3',
  '#79352c',
  '#521250',
  '#c79ed2',
  '#d6dd92',
  '#e33e52',
  '#b2be57',
  '#fa06ec',
  '#1bb699',
  '#6b2e5f',
  '#64820f',
  '#1c271',
  '#9cb64a',
  '#996c48',
  '#9ab9b7',
  '#06e052',
  '#e3a481',
  '#0eb621',
  '#fc458e',
  '#b2db15',
  '#aa226d',
  '#792ed8',
  '#73872a',
  '#520d3a',
  '#cefcb8',
  '#a5b3d9',
  '#7d1d85',
  '#c4fd57',
  '#f1ae16',
  '#8fe22a',
  '#ef6e3c',
  '#243eeb',
  '#1dc18',
  '#dd93fd',
  '#3f8473',
  '#e7dbce',
  '#421f79',
  '#7a3d93',
  '#635f6d',
  '#93f2d7',
  '#9b5c2a',
  '#15b9ee',
  '#0f5997',
  '#409188',
  '#911e20',
  '#1350ce',
  '#10e5b1',
  '#fff4d7',
  '#cb2582',
  '#ce00be',
  '#32d5d6',
  '#17232',
  '#608572',
  '#c79bc2',
  '#00f87c',
  '#77772a',
  '#6995ba',
  '#fc6b57',
  '#f07815',
  '#8fd883',
  '#060e27',
  '#96e591',
  '#21d52e',
  '#d00043',
  '#b47162',
  '#1ec227',
  '#4f0f6f',
  '#1d1d58',
  '#947002',
  '#bde052',
  '#e08c56',
  '#28fcfd',
  '#bb09b',
  '#36486a',
  '#d02e29',
  '#1ae6db',
  '#3e464c',
  '#a84a8f',
  '#911e7e',
  '#3f16d9',
  '#0f525f',
  '#ac7c0a',
  '#b4c086',
  '#c9d730',
  '#30cc49',
  '#3d6751',
  '#fb4c03',
  '#640fc1',
  '#62c03e',
  '#d3493a',
  '#88aa0b',
  '#406df9',
  '#615af0',
  '#4be47',
  '#2a3434',
  '#4a543f',
  '#79bca0',
  '#a8b8d4',
  '#00efd4',
  '#7ad236',
  '#7260d8',
  '#1deaa7',
  '#06f43a',
  '#823c59',
  '#e3d94c',
  '#dc1c06',
  '#f53b2a',
  '#b46238',
  '#2dfff6',
  '#a82b89',
  '#1a8011',
  '#436a9f',
  '#1a806a',
  '#4cf09d',
  '#c188a2',
  '#67eb4b',
  '#b308d3',
  '#fc7e41',
  '#af3101',
  '#ff065',
  '#71b1f4',
  '#a2f8a5',
  '#e23dd0',
  '#d3486d',
  '#00f7f9',
  '#474893',
  '#3cec35',
  '#1c65cb',
  '#5d1d0c',
  '#2d7d2a',
  '#ff3420',
  '#5cdd87',
  '#a259a4',
  '#e4ac44',
  '#1bede6',
  '#8798a4',
  '#d7790f',
  '#b2c24f',
  '#de73c2',
  '#d70a9c',
  '#25b67',
  '#88e9b8',
  '#c2b0e2',
  '#86e98f',
  '#ae90e2',
  '#1a806b',
  '#436a9e',
  '#0ec0ff',
  '#f812b3',
  '#b17fc9',
  '#8d6c2f',
  '#d3277a',
  '#2ca1ae',
  '#9685eb',
  '#8a96c6',
  '#dba2e6',
  '#76fc1b',
  '#608fa4',
  '#20f6ba',
  '#07d7f6',
  '#dce77a',
  '#77ecca',
];

export {
  STATUS_PAYMENT,
  STATUS_USER,
  GENDER,
  STATUS_REPORT,
  STATUS_FILTER,
  STATUS_BANNER,
  STATUS_SEND_AGENCY,
  STATUS_ROLE_TYPE,
  STATUS_NOTIFICATION,
  STATUS_TRANSACTION,
  STATUS_POINT,
  STATUS_REVENUE,
  STATUS_POSITION_DISPLAY,
  METHOD_PAYMENT,
  TYPE_POST,
  APPROVAL_STATUS,
  POST_STATUS,
  SUBSCRIPTION_STATUS,
  CARD_TYPE,
  TYPE_FILTER_CHART,
  COLOR_CHART,
};
