/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  listPayment: [],
  pagination: {},
  listHistoryCsv: [],
  dataCsvPayment: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_PAYMENT_REQUEST:
      return {
        ...state,
        listPayment: [],
        pagination: {},
      };
    case TYPES.UPDATE_STATUS_PAYMENT_REQUEST:
      return {
        ...state,
      };
    case TYPES.GET_DATA_CSV_PAYMENT_REQUEST:
      return {
        ...state,
        dataCsvPayment: [],
      }
    case TYPES.GET_HISTORY_CSV_PAYMENT_REQUEST:
      return {
        ...state,
        listHistoryCsv: [],
      };
    case TYPES.GET_LIST_PAYMENT_SUCCESS:
      return {
        ...state,
        listPayment: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.UPDATE_STATUS_PAYMENT_SUCCESS:
      return {
        ...state,
      };
    case TYPES.GET_DATA_CSV_PAYMENT_SUCCESS:
      return {
        ...state,
        dataCsvPayment: action.data.data,
      };
    case TYPES.GET_HISTORY_CSV_PAYMENT_SUCCESS:
      return {
        ...state,
        listHistoryCsv: action.data.data,
      };
    case TYPES.GET_LIST_PAYMENT_FAILED:
    case TYPES.UPDATE_STATUS_PAYMENT_FAILED:
    case TYPES.GET_HISTORY_CSV_PAYMENT_FAILED:
    case TYPES.GET_DATA_CSV_PAYMENT_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
