import { ButtonSubmit } from 'components/FromFeature/form-components';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export const UIInputUpload = ({
  imageList,
  onImageUpload,
  isDragging,
  dragProps,
  onImageUpdate,
  onImageRemove,
  height,
}) => {
  return (
    <>
      <ButtonSubmit
        mgl='5px'
        margin='5px'
        min='55px'
        bgtheme='primary'
        variant='contained'
        height={height}
        onClick={onImageUpload}>
        <AddPhotoAlternateIcon />
      </ButtonSubmit>
    </>
  );
};
