/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  listGroupMessage: [],
  listMessOfGroup: [],
  listAgencyLv2FromAgency: [],
  count_unread_fan: 0,
  count_unread_agency: 0,
  conut_unread_creator: 0,
  pagination: {},
  paginationMess : {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DELETE_MESSAGE_REQUEST:
    case TYPES.CREATE_MESSAGE_REQUEST:
    case TYPES.EDIT_MESSAGE_REQUEST:
    case TYPES.CREATE_MULTIPLE_MESSAGE_REQUEST:
    case TYPES.CREATE_MULTIPLE_MESSAGE_AGENCY_REQUEST:
    case TYPES.GET_MORE_GROUP_MESSAGE_REQUEST:
    case TYPES.GET_MORE_MESS_OF_GROUP_REQUEST:
      return {
        ...state,
      };
    case TYPES.GET_LIST_AGENCY_LV2_FROM_AGENCY_REQUEST:
      return {
        ...state,
        listAgencyLv2FromAgency: [],
      };
    case TYPES.GET_LIST_GROUP_MESSAGE_REQUEST:
      return {
        ...state,
        listGroupMessage: [],
        listMessOfGroup: [],
        pagination: {},
        count_unread_fan: 0,
        count_unread_agency: 0,
        conut_unread_creator: 0,
      };
    case TYPES.GET_MESS_OF_GROUP_REQUEST:
      return {
        ...state,
        listMessOfGroup: [],
        paginationMess: {},
      };
    case TYPES.GET_LIST_GROUP_MESSAGE_SUCCESS:
      return {
        ...state,
        listGroupMessage: action.data.data,
        pagination: action.data.pagination,
        count_unread_fan: action.data['fan_not_seen'],
        count_unread_agency: action.data['agency_not_seen'],
        conut_unread_creator: action.data['userNotSeen'],
      };
    case TYPES.GET_MESS_OF_GROUP_SUCCESS:
      return {
        ...state,
        listMessOfGroup: action.data.data,
        paginationMess: action.data.pagination,
        count_unread_fan: action.data['fan_not_seen'],
        count_unread_agency: action.data['agency_not_seen'],
        conut_unread_creator: action.data['userNotSeen'],
      };
    case TYPES.GET_MORE_MESS_OF_GROUP_SUCCESS:
      return {
        ...state,
        listMessOfGroup: [...state.listMessOfGroup, ...action.data.data],
        paginationMess: action.data.pagination,
      };
    case TYPES.GET_LIST_AGENCY_LV2_FROM_AGENCY_SUCCESS:
      return {
        ...state,
        listAgencyLv2FromAgency: action.data.data,
      };
    case TYPES.GET_MORE_GROUP_MESSAGE_SUCCESS:
      return {
        ...state,
        listGroupMessage: [...state.listGroupMessage, ...action.data.data],
        pagination: action.data.pagination,
      };
    case TYPES.DELETE_MESSAGE_SUCCESS:
    case TYPES.CREATE_MESSAGE_SUCCESS:
    case TYPES.EDIT_MESSAGE_SUCCESS:
    case TYPES.CREATE_MULTIPLE_MESSAGE_SUCCESS:
    case TYPES.CREATE_MULTIPLE_MESSAGE_AGENCY_SUCCESS:
      return {
        ...state,
        // listMessOfGroup: [...state.listMessOfGroup, action.data.data],
      };
    case TYPES.GET_LIST_GROUP_MESSAGE_FAILED:
    case TYPES.GET_MESS_OF_GROUP_FAILED:
    case TYPES.CREATE_MESSAGE_FAILED:
    case TYPES.DELETE_MESSAGE_FAILED:
    case TYPES.EDIT_MESSAGE_FAILED:
    case TYPES.CREATE_MULTIPLE_MESSAGE_FAILED:
    case TYPES.GET_LIST_AGENCY_LV2_FROM_AGENCY_FAILED:
    case TYPES.CREATE_MULTIPLE_MESSAGE_AGENCY_FAILED:
    case TYPES.GET_MORE_GROUP_MESSAGE_FAILED:
    case TYPES.GET_MORE_MESS_OF_GROUP_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
