
import { ButtonCCF } from 'styled';
import styled from 'styled-components';
const AddNewButton = styled(ButtonCCF)`
  width: auto;
  text-decoration: none;
  min-width: 115px;
`;
const ButtonSubmit = styled(ButtonCCF)`
  width: 100%;
`;
const WrapperButton = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;
const FormSearch = styled.div`
  padding: 20px 0;
`;
const FormSearchDate = styled.div`
  padding: 10px 0 20px 0;
  .search-date-single {
    display: flex;
    align-items: center;
    > label {
    }
  }
  .group-input-search {
    display: flex;
    align-items: center;
    .form-control {
      margin: 10px auto;
    }
  }
`;

const ParentColums = styled.div`
  .parent-column {
    cursor: pointer;
    color: #fff;
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    .div-name {
      width: 75%;
      margin-left: 10px;
    }
  }
  .table-comlumn {
    border-collapse: collapse;
    > div {
      border: 1px solid rgba(224, 224, 224, 1);
      border-radius: 4px;
    }
  }
  .d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      .li-name {
        margin-left: 30px;
      }
    }
    .switch-column {
      margin-right: 10px;
    }
  }
`;

const LabelPermission = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 25px;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: #fff;
`;

const FormPermissions = styled.div`
  width: 57%;
  margin: 0 auto;
  padding-top: 20px;
  .first-parent-column {
    margin-bottom: 20px;
  }
`;

const FormEdit = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const FormLogManagement = styled.div`
  .table-filter-date {
    justify-content: center;
  }
`;

const PermissionSCAcount = styled.div`
  .btn-change {
    justify-content: center;
  }
`;

export {
  ButtonSubmit,
  AddNewButton,
  WrapperButton,
  FormSearch,
  FormEdit,
  FormSearchDate,
  ParentColums,
  FormPermissions,
  LabelPermission,
  FormLogManagement,
  PermissionSCAcount,
};
