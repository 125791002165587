import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';

const reportAPI = {
  getListReport: (...params) => `${API_URL_V2}/reports?${stringify(...params)}`,
  getDetailReport: (id) => `${API_URL_V2}/reports/${id}`,
  editDetailReport: (id) => `${API_URL_V2}/reports/${id}`,
  updateStatusReport: (id) => `${API_URL_V2}/reports/${id}`,
};
export { reportAPI };
