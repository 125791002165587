import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';
const agentAPI = {
  GET_LIST: (...params) =>  `${API_URL_V2}/agencies?${stringify(...params)}`,
  GET_LIST_AGENCY: (id, ...params) => `${API_URL_V2}/agencies/users?${stringify(...params)}`,
  GET_DETAIL: (id) => `${API_URL_V2}/agencies/${id}`,
  POST_CREATE_AGENT: `${API_URL_V2}/agencies`,
  UPDATE_AGENT: (id) => `${API_URL_V2}/agencies/${id}`,
  DELETE_AGENT: (id) => `${API_URL_V2}/agencies/${id}`,
  agencySaleHistory: ( ...params) => `${API_URL_V2}/agencies/sale-histories?${stringify(...params)}`,
  agencyPaymentHistory: ( ...params) => `${API_URL_V2}/agencies/payment-histories?${stringify(...params)}`,
  
};

export { agentAPI };
