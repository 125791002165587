import { updateStatus } from 'constants';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import {
  campaignApi,
  configParamsGETJson,
  configParamsPOSTJson,
  configParamsPOSTJsonDelete,
  configParamsPUTJson,
} from 'apis';
import { pushNotify } from 'components/Notification';

const getListCampaign = (flg, ...props) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.GET_LIST_CAMPAIGN_REQUEST));
    callApi(
      campaignApi.getListCampaign(...props),
      configParamsGETJson(s),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_CAMPAIGN_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_CAMPAIGN_FAILED, { error: error.errors }));
      },
      flg ? null : dispatch
    );
  };
};

const createCampaign = (data, callback, callback1) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.CREATE_CAMPAIGN_REQUEST));
    callApi(
      campaignApi.createCampaign(),
      configParamsPOSTJson(s, data),
      null,
      async (response) => {
        pushNotify('success', 'キャンペーンが正常に作成されました。');
        await dispatch(updateStatus(TYPES.CREATE_CAMPAIGN_SUCCESS, response));
        callback !== null && await dispatch(callback);
        callback1 && callback1();
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_CAMPAIGN_FAILED, { error: error.errors }));
      },
      dispatch
    );
  };
};

const deleteCampaign = (id, callback, callApi1) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.DELETE_CAMPAIGN_REQUEST));
    callApi(
      campaignApi.deleteCampaign(id),
      configParamsPOSTJsonDelete(s),
      null,
      async (response) => {
        pushNotify('success', 'キャンペーンが正常に削除されました。');
        await dispatch(updateStatus(TYPES.DELETE_CAMPAIGN_SUCCESS, response));
        callback && await dispatch(callback);
        callApi1 && callApi1();
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_CAMPAIGN_FAILED, { error: error.errors }));
        callback && dispatch(callback);
      },
      dispatch
    );
  };
};

const editCampaign = (data, callback) => {
  return (dispatch, s) => {
    dispatch(updateStatus(TYPES.EDIT_CAMPAIGN_REQUEST));
    callApi(
      campaignApi.editCampaign(data),
      configParamsPUTJson(s, data),
      null,
      async (response) => {
        pushNotify('success', 'キャンペーンの編集が正常に完了しました。');
        await dispatch(updateStatus(TYPES.EDIT_CAMPAIGN_SUCCESS, response));
        await dispatch(callback);
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_CAMPAIGN_FAILED, { error: error.errors }));
        dispatch(callback);
      },
      dispatch
    );
  };
};

export { getListCampaign, createCampaign, deleteCampaign, editCampaign };
