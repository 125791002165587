import { AlertDialog } from 'components/Dialog';
import { ButtonSubmit } from 'components/FromFeature/form-components';
import { TextShow } from 'components/Text';
import { updateStatus } from 'constants';
import { LanguageContext } from 'Context/translation.context';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkTypeCell } from 'utils/utils';
export const Subcription = ({ ids, getDetailSub }) => {
  const { tCreator } = useContext(LanguageContext);
  const [isClose, setIsClose] = React.useState(false);
  const { detailSubscription } = useSelector((state) => state.creator);
  const dispatch = useDispatch();
  return (
    <>
      <ButtonSubmit
        min='40px'
        height='30px'
        bgtheme='primary'
        onClick={() => {
          dispatch(updateStatus('LOADING_API_REQUEST'));
          getDetailSub(
            ids,
            () => {
              dispatch(updateStatus('LOADING_API_COMPLETE'));
              setIsClose(true);
            },
            () => dispatch(updateStatus('LOADING_API_COMPLETE'))
          );
        }}>
        {tCreator('subcription.detail_view')}
      </ButtonSubmit>
      <AlertDialog
        close={isClose}
        updateStatus={tCreator('subcription.detail_view')}
        propsButton={{
          min: '40px',
          height: '30px',
          // disabled: true,
        }}
        noButton={true}
        noSubmit={false}
        buttonCacnel={tCreator('subcription.yes')}
        handleCancel={() => {
          setIsClose(false);
        }}
        contentElement={
          <>
            <TextShow fontSize='20' textAlign='center'>
              {detailSubscription?.title}
            </TextShow>
            <TextShow bgtheme='red' fontSize='24' textAlign='center'>
              {`1ヶ月/ ${checkTypeCell(
                'amount_pt',
                detailSubscription?.price
              )} `}
            </TextShow>
            <TextShow wrapText style={{whiteSpace: 'break-spaces'}}>{detailSubscription?.description}</TextShow>
          </>
        }
      />
    </>
  );
};
