import { getEnv } from 'configs/env';

const API_ENV = `${getEnv('REACT_APP_API_SERVER')}`;
const DOMAIN_USER = getEnv('REACT_APP_USER');
const LINK_S3 = getEnv('REACT_APP_S3');
const LINK_VIDEO = getEnv('REACT_APP_VIDEO_S3');
const API_URL = `${API_ENV}/v1/admin`;
const API_URL_GLOBAL = `${API_ENV}/v1`;
const API_URL_V2 = `${API_ENV}/v2/admin`;
const API_URL_GLOBAL_V2 = `${API_ENV}/v2`;
const AGORA_APP_ID = getEnv('REACT_APP_AGORA_APP_ID');
const PAYMENT_VENDO = getEnv('REACT_APP_PAYMENT_VENDO_IS_TEST');

export {
  API_URL,
  API_URL_GLOBAL,
  AGORA_APP_ID,
  DOMAIN_USER,
  LINK_S3,
  LINK_VIDEO,
  API_URL_GLOBAL_V2,
  API_URL_V2,
  PAYMENT_VENDO,
};
