import { GENDER } from 'constants';
import { translate } from 'Context/translation.context';
const content =  (key) => translate(`form_control.${key}`, 'fan');

const renderForm = ({ control }) => [
  {
    content: content('full_name'),
    name: 'full_name',
    control,
    type: 'text',
    props: {
      width: '480px',
      isErrForm: true,
    },
  },
  {
    content: content('name_romaji'),
    name: 'name_romaji',
    control,
    type: 'text',
    props: {
      width: '480px',
      isErrForm: true,
    },
  },
  {
    content: content('birthday'),
    name: 'birthday',
    control,
    type: 'date',
    props: {
      width: '480px',
      isErrForm: true,
    },
  },
  {
    content: content('gender'),
    name: 'gender',
    control,
    type: 'select',
    props: {
      option: GENDER,
      width: '480px',
      isErrForm: true,
      noDefault: true,
    },
  },
  {
    content: content('email'),
    name: 'email',
    control,
    type: 'text',
    props: {
      width: '480px',
      isErrForm: true,
      disabled: true,
    },
  },
  {
    content: content('status'),
    name: 'status',
    control,
    type: 'text',
    props: {
      disabled: true,
      width: '480px',
      isErrForm: true,
    },
  },
];
export { renderForm };
