const messageError = {
  EMAIL_NOT_EXISTS: 'メールアドレスまたはパスワードが違います。',
  LOGIN_FAIL: 'Login failed',
  PASSWORD_DOES_NOT_MATCH: 'メールアドレスまたはパスワードが違います。',
  FA_CODE_IS_NOT_EXISTS_OR_EXPIRED:
    'この6桁のコードは無効になりました。再度ログインの手続きをしてください。',
  NOT_FOUND_AGENCY: 'Not found agency',
  NAME_ROMAJI_REGEX_FORMAT_INVALID: 'ローマ字で入力してください。',
  ACCOUNT_NOT_ACTIVE: 'メールアドレスまたはパスワードが違います。',
  EMAIL_UNIQUE: 'このメールアドレスは存在しました。別のメールアドレスを入力してください。',
  EMAIL_IS_EXIST: 'このメールアドレスは存在しました。別のメールアドレスを入力してください。',
  EMAIL_EXIST: 'このメールアドレスは存在しました。別のメールアドレスを入力してください。',
  EMAIL_IS_NOT_EXISTS: 'このメールアドレスは存在しません。',
  USERNAME_EXISTS: 'すでに存在するアカウント名です。',
  PHONE_EXISTS: '別の電話番号を入力してください。',
  PHONE_UNIQUE: '別の電話番号を入力してください。',
  MIN_PRICE_IS_REQUIRED: '下限金額を入力してください。',
  MIN_PRICE_IS_NUMERIC: '下限金額を数字のみで入力してください。',
  MUST_BE_OVER_18_YEARS_OLD: '年齢が18歳未満の方はご利用できません。',
  ACCOUNT_ID_EXISTS: 'すでに存在するアカウントIDです。',
  ACCOUNT_NOT_FOUND: 'このメールアドレスは存在しません。',
  NOT_FOUND: 'データが見つかりません',
  BANNER_NOT_FOUND: 'データが見つかりません',
  NOT_AN_ADMIN_ACCOUNT: 'メールアドレスまたはパスワードが違います。',
  PASSWORD_AND_RE_PASSWORD_MUST_BE_THE_SAME:
    '新しいパスワード（再入力）と新しいパスワードが一致しません。',
  PACKAGE_NOT_EXIST: 'データが見つかりません',
  POINT_HAVE_BEEN_AWARDED: 'ポイント付与が承認されました。',
  TOKEN_INVALID: 'トークンが無効です',
  DELETE_AGENCY_FAIL: '配下の代理店・クリエイターがあるので削除できません。',
  USER_HAS_BEEN_LEAVED: 'このアカウントは退会済みです。メッセージが送信できません。',
  'YOU_MADE_THE_REQUEST_TOO_FAST,_PLEASE_TRY_AGAIN_IN_1_MINUTES':
    '1分後パスワードリセットの手続きをしてください。',
  'SOMEONE_IS_LOGGED_IN_WITH_THIS_ACCOUNT_DO_YOU_WANT_TO_LOGIN?': null,
  UNAUTHORIZED_OR_TOKEN_DOES_NOT_EXITS: null,
  USER_ID_EXISTS: 'ユーザーアカウントが優先リストに存在しました。',
  PERMISSION_ERRORS: 'この機能は操作可能な権限がありません。',
  NAME_EXISTS: 'このカテゴリーは既存しました。',
  CATEGORY_NAME_IS_UNIQUE: 'このカテゴリーは既存しました。',
  VOUCHER_CODE_IS_UNIQUE: 'クーポンコードはユニークである必要があります。',
  NAME_MAX_255: 'キャンペーン名を255文字まで入力してください。',
  LIMIT_DIGITS_BETWEEN_100_100000: '100から100,000まで入力してください。',
  MIN_POINT_CANNOT_BE_BIGGER_TO_THE_VALUE_MAX_POINT:
    '上限のポイントは下限のポイント以下入力してください。',
  PAYMENT_PACKAGE_UNIQUE: 'このポイント数は既存しました。',
  POINT_UNIQUE: 'このポイント数は既存しました。',
  NAME_UNIQUE: 'このエフェクトスタンプ名は既存しました。',
  POINT_UNIT_INTEGER_POSITIVE: 'ポイント単位は整数で入力してください。',
  ALREADY_THE_BANNERS_AT_THIS_POSITION: 'この掲載場所では表示ONの広告が既存されました。',
  BANNER_STATUS_SAVE_FAILED: 'この掲載場所では表示ONの広告が既存されました。',
  DATE_FORMAT_1: 'ポイント販売期間を入力してください。',
  DATE_FORMAT_2: 'サイト内全停止の期間を入力してください。',
  MIN_POINT_REQUIRED: '任意ポイント設定で入力が必要です。',
  POINT_TO_JP_REQUIRED: '任意ポイント設定で入力が必要です。',
  POINT_UNIT_REQUIRED: '任意ポイント設定で入力が必要です。',
  TAX_REQUIRED: '任意ポイント設定で入力が必要です。',
  MAX_POINT_REQUIRED: '任意ポイント設定で入力が必要です。',
  NG_WORDS_MAX_500: 'NGワードを500文字まで入力してください。',
  PLEASE_ENTER_END_TIME_AFTER_START_TIME: '開始時刻が終了時刻より前になるように設定してください。',
  LAST_NAME_KANA_IS_REQUIRED: 'セイはカタカナで入力してください。',
  FIRST_NAME_KANA_IS_REQUIRED: 'メイはカタカナで入力してください。',
  NOT_ENOUGH_POINTS: 'ユーザーのポイント残高が不十分なため、返金できません。',
  COMMISSION_SALES_EXCCEP_ALLOWABLE_LIMIT: "販売コンテンツの利用料は許容制限を超えています。",
  COMMISSION_SUBSCRIPTION_EXCCEP_ALLOWABLE_LIMIT: "サブスクリプションの利用料は許容制限を超えています。",
  COMMISSION_LIVESTREAM_EXCCEP_ALLOWABLE_LIMIT: "ライブ投げ銭の利用料は許容制限を超えています。",
  COMMISSION_MESSAGE_EXCCEP_ALLOWABLE_LIMIT: "メッセージ投げ銭の利用料は許容制限を超えています。",
  END_DATE_AFTER_START_DATE_THREE_DAY: '終了日は開始日より 3 日後を選択してください'
};
const messageSuccess = {
  // Auth Start
  LOGIN: 'Login success',
  VERIFY_LOGIN: 'Verify login success',
  LOGOUT: 'Logout success',
  RESET_PASSWORD: 'パスワードを変更しました',
  // Auth End
  // Creator Start
  UPDATE_CREATOR_ACCOUNT: 'アカウントの編集が正常に完了しました。',
  UPDATE_CONTRACT_ACCOUNT: '出演者情報の編集が正常に完了しました。',
  DELETE_CREATOR_ACCOUNT: 'アカウントの削除が正常に完了しました。',
  DELETE_CONTRACT_ACCOUNT: '出演者情報の削除が正常に完了しました。',
  APPROVE_CREATOR_ACCOUNT: 'Approve creator account success',
  APPROVE_MESS_1: 'アカウントの停止が正常に完了しました。',
  APPROVE_MESS_2: 'アカウントの承認が正常に完了しました。',
  APPROVE_MESS_3: 'アカウントの拒否が正常に完了しました。',
  APPROVE_MESS_4: 'アカウントの停止が正常に完了しました。',
  APPROVE_MESS_5: 'アカウントの停止解除が正常に完了しました。',
  APPROVE_MESS_6: 'アカウントの停止が正常に完了しました。',
  APPROVE_MESS_7: 'アカウントの承認が正常に完了しました。',
  DELETE_POST_CREATOR: '投稿削除が正常に完了しました。',
  DELETE_SUB_CREATOR: 'サブスクリプションを削除しました。',
  DELETE_CONTENT_CREATOR: 'コンテンツを削除しました。',
  UPDATE_SETTING_LIMIT_CREATOR: '設定が正常に完了しました。',
  STOP_LIVE_STREAM: 'ライブ配信の強制停止が正常に完了しました。',
  // Creator End
  // Agency Start
  UPDATE_AGENCY: 'アカウントの編集が正常に完了しました。',
  CREATE_AGENCY:
    'アカウント新規登録が正常に完了しました。メールアドレス宛へパスワードを送信しました。ご確認ください。',
  DELETE_AGENCY: 'アカウントの削除が正常に完了しました。',
  // Agency End
  // Fan Account Start
  UPDATE_FAN_ACCOUNT: 'アカウントの編集が正常に完了しました。',
  DELETE_FAN_ACCOUNT: 'アカウントの削除が正常に完了しました。',
  // Fan Account End
  // Setting Page Start
  CREATE_PACKAGE_PAYMENT: 'アカウントの新規作成が正常に完了しました。',
  DELETE_PACKAGE_PAYMENT: 'アカウントの削除が正常に完了しました。',
  // Setting Page End
  // Affiliate Start
  DELETE_AFFILIATOR: 'アカウントの削除が正常に完了しました。',
  UPDATE_AFFILIATOR: 'アカウントの編集が正常に完了しました。',
  SETTING_AFFILIATOR: '設定が正常に完了しました。',
  UPDATE_SETTING_LIMIT_AFFILIATOR: '設定が正常に完了しました。',
  // Affiliate End
  // Banner Start
  CREATE_BANNER: 'バナーを新規作成しました。',
  UPDATE_BANNER: 'バナーを編集しました。',
  DELETE_BANNER: 'バナーの削除が正常に完了しました。',
  // Banner End
  // Notification Start
  CREATE_NOTIFICATION: 'お知らせの新規作成が正常に完了しました。',
  SAVE_NOTIFICATION: 'お知らせ新規作成が正常に完了しました。',
  UPDATE_NOTIFICATION: 'お知らせ編集が正常に完了しました。',
  DELETE_NOTIFICATION: 'お知らせの削除が正常に完了しました。',
  // Notification End

  // Nottification Admin
  CREATE_ADMIN:
    'アカウント新規登録が正常に完了しました。メールアドレス宛へパスワードを送信しました。ご確認ください。',
  DELETED_ADMIN_CS: 'アカウントの削除が正常に完了しました。',
  UPDATE_ADMIN_CS: 'アカウントの編集が正常に完了しました。',

  // A005 - Setting page
  DELETED_CREATOR: '優先リストからユーザーアカウントの削除が正常に完了しました。',
  PUT_CREATOR_FAVORITE: 'クリエイターアカウントの編集が優先リストに既存しました',
  SAVE_SUCCESS: '設定が正常に完了しました。',
  UP_DATE_BANNER_ADMIN: '広告の追加が正常に完了しました',
  CREATE_BANNER_ADMIN: '広告の新規作成が正常に完了しました。',
  DELETE_BANNER_ADMIN: '広告の削除が正常に完了しました。 ',
  // A004 - setting site
  CREATED_SETTING_CATEGORY_CONTACT: 'お問い合わせカテゴリーの追加が正常に完了しました。',
  DELETE_SETTING_CATEGORY_CONTACT: 'お問い合わせカテゴリーの削除が正常に完了しました。',
  UPDATED_SETTING_CATEGORY_CONTACT: '設定が正常に完了しました。',
  CREATED_SETTING_REPORT: '通報カテゴリーの追加が正常に完了しました。',
  DELETED_SETTING_REPORT: '通報カテゴリーの削除が正常に完了しました。',
  UPDATED_SETTING_REPORT: '設定が正常に完了しました。',
  CREATED_SETTING_PACKAGE_PAYMENT: '固定なポイントの追加が正常に完了しました。',
  DELETED_SETTING_PACKAGE_PAYMENT: '固定なポイントの削除が正常に完了しました。',
  UPDATED_SETTING_PACKAGE_PAYMENT: '設定が正常に完了しました。',
  CREATED_SETTING_PACKAGE_PAYMENT_POINT: '設定が正常に完了しました。',
  CREATED_SETTING_EFFECT: 'エフェクトの追加が正常に完了しました。',
  CREATED_SETTING_METHOD: 'お支払い方法の設定が正常に完了しました。',
  DELETE_SETTING_EFFECT: 'エフェクトの削除が正常に完了しました。',
  UPDATED_SETTING_EFFECT: '設定が正常に完了しました。',
  CREATED_SETTING_COUNTRY: '国の追加が正常に完了しました。',
  UPDATE_SETTING_COUNTRY: '設定が正常に完了しました',
  UPDATE_SETTING_SITE: '設定が正常に完了しました',
  MESSAGE_SUCCESS_STOP:
    'サイト内全停止が正常に完了しました。ユーザー側はメンテナンスの状態になりました。',
  MESSAGE_SUCCESS_START:
    'サイト内全停止の回復が正常に完了しました。ユーザー側は通常の状態になりました。',

  APPROVE_REQUEST_POINT: 'ポイントの付与が正常に完了しました。',
  APPROVE_REFUND_POINT: '返金が正常に完了しました。',
  UPDATE_STATUS_PAYMENT: 'ステータス変更が正常に完了しました。',
  UPDATE_STATUS_REPORT: '解決ステータスを変更しました。',

  UPDATE_TEMPLATE_EMAIL: 'メールテンプレートの変更が正常に完了しました。',
  CREATE_EFFECT_CREATOR: 'エフェクトの追加が正常に完了しました。',
  DELETE_EFFECT_CREATOR: 'エフェクトの削除が正常に完了しました。',
  UPDATE_EFFECT_CREATOR: '設定が正常に完了しました。',
};
export { messageError, messageSuccess };
