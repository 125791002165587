import {
  ButtonSubmit,
  FormInputText,
  WrapperButton,
} from 'components/FromFeature/form-components';
import { WrapperShowListCard } from 'components/ListCard';
import { LanguageContext } from 'Context/translation.context';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getListLivestreamCreator } from 'store/actions/creator.action';
import { enterToSearch } from 'utils/utils';
import { CardDetail } from './components/CardContent';
import { TabsCreator } from './settings/tabs';
const LiveDetail = () => {
  const { tCreator } = useContext(LanguageContext);
  const methods = useForm({ mode: 'all', defaultValues: { keyword: '' } });
  const { listLivestream, pagination } = useSelector((state) => state.creator);
  const { handleSubmit, control } = methods;
  const [countSearch, setCountSearch] = useState(0);
  const [query, setQuery] = useState({});
  const [keyLive, setKeyLive] = useState({});
  const handleSearch = (data) => {
    setQuery(data);
    setCountSearch(countSearch + 1);
  };
  const pushSearch = () => {
    setCountSearch(countSearch + 1);
  };
  
  return (
    <>
      <TabsCreator />
      <WrapperButton marginBot='30px' justifyContent wrap='true'>
        <WrapperButton width='400px' margin='0'>
          <FormInputText
            width='400px'
            isSearch
            name='keyword'
            control={control}
            label={tCreator('enter_creator_name_or_creator_ID')}
            inputProps={{
              onKeyDown: (e) => {
                enterToSearch(e, handleSubmit(handleSearch));
              },
            }}
          />
        </WrapperButton>
        <ButtonSubmit
          onClick={handleSubmit(handleSearch)}
          variant='contained'
          bgtheme='primary'>
          {tCreator('button_search')}
        </ButtonSubmit>
      </WrapperButton>
      <WrapperShowListCard
        limit={6}
        count={pagination?.total_pages}
        action={getListLivestreamCreator}
        countSearch={countSearch}
        query={query}
        listCard={listLivestream}
        itemCard={(el, i) => (
          <CardDetail
            keyLive={keyLive}
            setKeyLive={setKeyLive}
            pushSearch={pushSearch}
            key={i}
            data={el}
          />
        )}
        gmin='445px'
      />
    </>
  );
};
export { LiveDetail };
