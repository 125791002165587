import { Loading } from 'components/Loading';
import { useCreatePortal } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const LoadingGlobal = () => {
  const PortalLoading = useCreatePortal(document.body);
  const [loadingEffect, setLoadingEffect] = useState(false);
  const { loading } = useSelector((state) => state.loading);

  useEffect(() => {
    if (loading) {
      setLoadingEffect(true);
    } else {
      setLoadingEffect(false);
    }
  }, [loading]);

  return (
    loadingEffect && (
      <PortalLoading>
        <Loading />
      </PortalLoading>
    )
  );
};
// const LoadingCheckProfile = () => {
//   const PortalLoading = useCreatePortal(document.body);
//   const [loadingEffect, setLoadingEffect] = useState(false);
//   const { loadingProfile } = useSelector((state) => state.loading);

//   useEffect(() => {
//     if (loadingProfile) {
//       setLoadingEffect(true);
//     } else {
//       setLoadingEffect(false);
//     }
//   }, [loadingProfile]);

//   return (
//     loadingEffect && (
//       <PortalLoading>
//         <Loading isProfile={true} />
//       </PortalLoading>
//     )
//   );
// };
export { LoadingGlobal };
