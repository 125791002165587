import TabMenu from 'components/TabMenu';
export default function TabsChildren() {
  const navItems = [
    {
      id: 'お問い合わせカテゴリー',
      path: [`/setting-site/setting-category-contact`],
      text: 'お問い合わせカテゴリー',
    },
    {
      id: '通報のカテゴリー',
      path: ['/setting-site/setting-category-report'],
      text: '通報のカテゴリー',
    },
  ];

  return (
    <TabMenu tabs={navItems}/>
  );
}
