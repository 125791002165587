import { AlertDialog } from 'components/Dialog';
import { cellToTooltip, checkTypeCell, decryptPath } from 'utils/utils';
import { ButtonSubmit } from 'components/FromFeature/form-components';
import { ButtonCCF } from 'styled';
import { Subcription } from '../components/Subcription';
import { translate } from 'Context/translation.context';
import { LINK_S3 } from 'constants';
import { ToolTipName, WrapperImageUser } from '../styled';
import { linkS3 } from 'apis/s3';
import { Lightbox } from 'react-modal-image';
import { Text, TooltipHover } from 'components/Text';
import { Tooltip } from '@mui/material';
const headerMain = (key) => translate(`table_main.${key}`, 'creator');
const headerSubcription = (key) => translate(`table_subcription.${key}`, 'creator');
const headerContent = (key) => translate(`table_content.${key}`, 'creator');
const headerSale = (key) => translate(`table_sale.${key}`, 'creator');
const headerPayment = (key) => translate(`table_payment.${key}`, 'creator');
const headerPost = (key) => translate(`table_post.${key}`, 'creator');

const columnsMain = (goToDetail, goToEdit, goToLogin) => [
  { headerName: headerMain('id'), ...cellToTooltip('id', 'text', 50) },
  { headerName: headerMain('username'), ...cellToTooltip('account_name', 'text', 100) },
  { headerName: headerMain('account_id'), ...cellToTooltip('account_id', 'text', 100) },
  { headerName: headerMain('email'), ...cellToTooltip('email', 'text', 100) },
  {
    headerName: headerMain('status'),
    ...cellToTooltip('status', 'status', 60),
    renderCell: (cell) =>
      cell?.row['first_login'] === 0
        ? '未ログイン'
        : checkTypeCell('status', cell?.row['status']),
  },
  {
    field: 'commission_sales',
    headerName: headerMain('commission_sales'),
    sortable: false,
    minWidth: 120,
    renderCell: (cellValues) => <p>{cellValues?.value}%</p>,
  },
  {
    field: 'commission_subscription',
    headerName: headerMain('commission_subscription'),
    sortable: false,
    minWidth: 120,
    renderCell: (cellValues) => <p>{cellValues?.value}%</p>,
  },
  {
    field: 'commission_livestream',
    headerName: headerMain('commission_livestream'),
    sortable: false,
    minWidth: 120,
    renderCell: (cellValues) => <p>{cellValues?.value}%</p>,
  },
  {
    field: 'commission_message',
    headerName: headerMain('commission_message'),
    sortable: false,
    minWidth: 120,
    renderCell: (cellValues) => <p>{cellValues?.value}%</p>,
  },
  {
    field: 'action',
    headerName: headerMain('action'),
    minWidth: 350,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <>
          <ButtonCCF
            style={{ marginRight: '10px' }}
            onClick={() => goToDetail(cellValues?.id)}
            variant='contained'
            size='small'
            bgtheme='primary'>
            {headerMain('button_detail')}
          </ButtonCCF>
          {cellValues?.row?.first_login > 0 && (
            <div>
              {cellValues?.row?.deleted_at === null &&
                <ButtonCCF
                  onClick={() => goToEdit(cellValues?.id)}
                  variant='contained'
                  size='small'
                  bgtheme='primary'>
                  {headerMain('button_edit')}
                </ButtonCCF>
              }
              <ButtonCCF
                onClick={() => goToLogin(cellValues.id)}
                variant='contained'
                size='small'
                style={{ marginLeft: '10px' }}
                bgtheme='primary'>
                メッセージ履歴ページへ
              </ButtonCCF>
            </div>
          )}
        </>
      );
    },
  },
];
const columnSubcription = (deleteSub, getDetailSub) => [
  { headerName: headerSubcription('title'), ...cellToTooltip('title') },
  {
    headerName: headerSubcription('buy_counter'),
    ...cellToTooltip('buy_counter', 'buy_counter'),
  },
  {
    field: 'action',
    headerName: headerSubcription('action'),
    minWidth: 250,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <>
          <Subcription getDetailSub={getDetailSub} ids={cellValues?.id} />
          <AlertDialog
            addDelete
            propsButton={{
              min: '40px',
              height: '30px',
            }}
            noSubmit={cellValues?.row?.buy_counter === 0}
            buttonCacnel={cellValues?.row?.buy_counter !== 0 ? 'はい' : 'いいえ'}
            handleAccpet={() => deleteSub(cellValues?.id)}
            content={
              cellValues?.row?.buy_counter === 0
                ? `${cellValues?.row?.title}を削除してもよろしいでしょうか。`
                : 'このサブスクリプションは、ユーザーが加入しているため削除できません。'
            }
          />
        </>
      );
    },
  },
];
const columnContent = (deleteContent, viewContent, handleShowImageContent) => [
  { headerName: headerContent('title'), ...cellToTooltip('title') },
  { headerName: headerContent('buy_counter'), ...cellToTooltip('buy_counter', 'buy_counter') },
  {
    field: 'performer_information',
    headerName: headerContent('performer_information'),
    sortable: false,
    minWidth: 250,
    renderCell: (cellValues) => {
      const getPostContracts = cellValues?.row?.post_contracts;
      return (
        <div>
          {getPostContracts.map((item, index) => {
            const name = `${item.first_name_romaji} ${item.last_name_romaji}`;

            return (
              <div key={index} style={{ height: '45px' }}>
                <ToolTipName title={<p>{name}</p>}>
                  <Text>{name.length > 30 ? name.substring(0, 30) + '...' : name}</Text>
                </ToolTipName>
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    field: 'appearance_license_or_contract',
    headerName: headerContent('appearance_license_or_contract'),
    sortable: false,
    minWidth: 250,
    renderCell: (cellValues) => {
      const getPostContracts = cellValues?.row?.post_contracts;
      return (
        <div>
          {getPostContracts.map((item, index) => (
            <div key={index} style={{ height: '45px' }}>
              <a
                href={`${LINK_S3}/${decryptPath(item?.contract_file)}`}
                download={`${LINK_S3}/${decryptPath(item?.contract_file)}`}>
                {item?.contract_file.split('/').pop()}
              </a>
            </div>
          ))}
        </div>
      );
    },
  },
  {
    field: 'image',
    headerName: headerContent('image'),
    sortable: false,
    minWidth: 250,
    renderCell: (cellValues) => {
      const getPostContracts = cellValues?.row?.post_contracts;
      return (
        <WrapperImageUser>
          {getPostContracts.map((item, index) => {
            const listImage = [
              decryptPath(item?.avatar),
              decryptPath(item?.avatar_identify),
              decryptPath(item?.identify_img_back),
              decryptPath(item?.identify_img_front),
            ];

            const quantityImage = listImage.filter((item) => item?.length > 0);

            return (
              <div key={index} className='image-item'>
                {quantityImage.length > 0 ? (
                  <div
                    className='enable'
                    onClick={() => handleShowImageContent(quantityImage, true)}>
                    {quantityImage.length > 0 ? `+${quantityImage.length}` : ''}
                  </div>
                ) : (
                  <></>
                )}

                <img
                  onClick={() => handleShowImageContent(quantityImage, true)}
                  className='image'
                  src={decryptPath(item?.avatar)}
                  alt=''
                />
              </div>
            );
          })}
        </WrapperImageUser>
      );
    },
  },
  {
    field: 'action',
    headerName: headerContent('action'),
    minWidth: 250,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <>
          <ButtonSubmit
            bgtheme='primary'
            variant='contained'
            min='70px'
            height='30px'
            // disabled={true}
            onClick={() => viewContent(cellValues?.id)}>
            詳細表示
          </ButtonSubmit>
          <AlertDialog
            addDelete
            propsButton={{
              min: '40px',
              height: '30px',
            }}
            handleAccpet={() => deleteContent(cellValues?.id)}
            buttonCacnel={'いいえ'}
            content={
              cellValues?.row?.buy_counter === 0
                ? `${cellValues?.row?.title}を削除してもよろしいでしょうか。`
                : 'このコンテンツには既に購入者が居ますが、本当に削除してもよろしいでしょうか？'
            }
          />
        </>
      );
    },
  },
];
const columnSaleHistory = [
  { headerName: headerSale('created_at'), ...cellToTooltip('created_at', 'date_time') },
  {
    headerName: headerSale('transaction_type'),
    ...cellToTooltip('transaction_type', 'transaction'),
  },
  { headerName: headerSale('account_name'), ...cellToTooltip('account_name') },
  { headerName: headerSale('price'), ...cellToTooltip('price', 'amount_pt') },
  { headerName: headerSale('profit_money'), ...cellToTooltip('profit_money', 'amount_pt') },
];
const columnPaymentHistory = [
  { headerName: headerPayment('created_at'), ...cellToTooltip('created_at', 'date_time') },
  { headerName: headerPayment('amount'), ...cellToTooltip('amount', 'amount') },
  { headerName: headerPayment('status'), ...cellToTooltip('status', 'status_payment') },
  {
    headerName: headerPayment('change_status_date'),
    ...cellToTooltip('change_status_date', 'date'),
  },
];
const columnPostHistory = (onClick, onDelete) => [
  { headerName: headerPost('id'), ...cellToTooltip('id') },
  { headerName: headerPost('title'), ...cellToTooltip('title') },
  { headerName: 'タイプ', ...cellToTooltip('type_text') },
  { headerName: headerPost('created_at'), ...cellToTooltip('created_at', 'date_time') },
  {
    field: 'action',
    headerName: headerPost('action'),
    minWidth: 200,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <>
          <ButtonSubmit
            bgtheme='primary'
            variant='contained'
            min='70px'
            height='30px'
            // disabled={true}
            onClick={() => onClick(cellValues?.id, cellValues?.row?.type)}>
            詳細表示
          </ButtonSubmit>
          <AlertDialog
            propsButton={{
              min: '65px',
              height: '30px',
            }}
            title='投稿を削除する'
            content={`${
              cellValues?.row?.title
                ? `${cellValues?.row?.title}を削除してもよろしいでしょうか?`
                : '削除してもよろしいでしょうか?'
            }`}
            handleAccpet={() => onDelete(cellValues?.id)}
            addDelete
          />
        </>
      );
    },
  },
];
const columnContract = (onClick) => [
  { headerName: '登録日時', ...cellToTooltip('created_at', 'date_time') },
  { headerName: '名', ...cellToTooltip('first_name_romaji') },
  { headerName: '性', ...cellToTooltip('last_name_romaji') },
  {
    field: 'action',
    headerName: headerPost('action'),
    minWidth: 200,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <>
          <ButtonSubmit
            bgtheme='primary'
            variant='contained'
            min='70px'
            height='30px'
            onClick={() => onClick(cellValues?.id)}>
            詳細
          </ButtonSubmit>
        </>
      );
    },
  },
];
export {
  columnsMain,
  columnSubcription,
  columnContent,
  columnSaleHistory,
  columnPaymentHistory,
  columnPostHistory,
  columnContract,
};
