// import WrapperTable from 'components/Table';
import { Switch } from '@material-ui/core';
import { WrapperButtonForm } from 'components/FromFeature/form-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getDetailAdminCS,
  getDetailPermissionsAS,
  updateCSPermissions,
} from 'store/actions/admin.action';
import { ButtonSubmit } from 'components/FromFeature/form-element/styledElement';
import { AlertDialog } from 'components/Dialog';
import { FormPermissions, LabelPermission, ParentColums, PermissionSCAcount } from '../styled';
import { useContext } from 'react';
import { LanguageContext } from 'Context/translation.context';

export const PermissionsAccount = () => {
  const { permissionCSAdmin } = useSelector((state) => state.admin);
  const { tAdmincs } = useContext(LanguageContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    const dataPermissionCsAdmin = permissionCSAdmin.filter((item) => item?.public_flag !== 0);
    setData(
      dataPermissionCsAdmin.map((item) => {
        return {
          ...item,
          isShow: true,
        };
      })
    );
  }, [permissionCSAdmin]);

  const handleRedirectBack = (url) => {
    if (location?.state?.prevPath) {
      return navigate(`${url}${location?.state?.prevPath}`);
    }
    return navigate(`${url}`);
  };

  const onSubmitData = () => {
    const ids = [];
    const status = [];
    data.forEach((item) => {
      if (item.user_permission_id !== 0) {
        ids.push(item.user_permission_id);
        status.push(item.status);
      }

      item?.groups.forEach((val) => {
        ids.push(val.id);
        status.push(val.status);
      });
    });

    const paramPermissions = {
      id: ids,
      status,
    };

    dispatch(
      updateCSPermissions(paramPermissions, id, () =>
        handleRedirectBack('/manage-account-operation/cs-account')
      )
    );
  };

  const handleShow = (idx) => {
    setData((prev) => {
      const list = [...prev];
      list[idx].isShow = !list[idx].isShow;

      return list;
    });
  };

  useEffect(() => {
    !permissionCSAdmin.length &&
      dispatch(
        getDetailPermissionsAS(
          id,
          () => {},
          () => handleRedirectBack('/manage-account-operation/cs-account')
        )
      );

    dispatch(getDetailAdminCS(id));
  }, []);

  const renderlistPermissions = useCallback(() => {
    return (
      <FormPermissions>
        {data?.length
          ? data.map((item, index) => {
              return (
                <ParentColums key={index} className='first-parent-column'>
                  <div className='table-comlumn'>
                    <div className={`parent-column d-flex`}>
                      <div className='div-name' onClick={() => handleShow(index)}>
                        {item.name}
                      </div>
                      <div className='switch-column'>
                        {tAdmincs('permission.off')}
                        <Switch
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setData((prev) => {
                                const list = [...prev];
                                list[index].status = 0;
                                list[index].groups = list[index].groups.map((ele) => ({
                                  ...ele,
                                  status: 0,
                                }));
                                return list;
                              });
                            } else {
                              setData((prev) => {
                                const list = [...prev];
                                list[index].groups = list[index].groups.map((ele) => ({
                                  ...ele,
                                  status: 1,
                                }));
                                list[index].status = 1;
                                return list;
                              });
                            }
                          }}
                          checked={!!item.status}
                        />
                        {tAdmincs('permission.on')}
                      </div>
                    </div>
                    <>
                      {item.groups.length
                        ? item.groups.map((val, idx) =>
                            item.isShow ? (
                              <ParentColums key={idx}>
                                <div className={`d-flex`}>
                                  <div>
                                    <ul>
                                      <li className='li-name'>{val.name}</li>
                                    </ul>
                                  </div>
                                  <div className='switch-column'>
                                    {tAdmincs('permission.off')}
                                    <Switch
                                      onChange={(e) => {
                                        setData((prev) => {
                                          const list = [...prev];

                                          console.log('list', list);

                                          list[index].groups[idx].status = e.target.checked
                                            ? 1
                                            : 0;

                                          if (list[index].code === 'A021') {
                                            if (list[2].groups[0].status === 0) {
                                              list[2].groups[1].status = e.target.checked;
                                            }

                                            if (list[2].groups[1].status) {
                                              list[2].groups[0].status = e.target.checked;
                                            }
                                          }

                                          switch (list[index].code === 'A023') {
                                            case idx === 9 && list[3].groups[9].status === 1:
                                              list[3].groups[4].status = 1;
                                              list[3].groups[5].status = 1;
                                              break;
                                            case idx === 5 && list[3].groups[5].status === 1:
                                              list[3].groups[7].status = 1;
                                              break;
                                            case (idx === 4 || idx === 5) &&
                                              (list[3].groups[4].status === 0 ||
                                                list[3].groups[5].status === 0):
                                              list[3].groups[9].status = 0;
                                              break;

                                            default:
                                              break;
                                          }

                                          return list;
                                        });
                                      }}
                                      disabled={!data[index].status}
                                      checked={!!val.status}
                                    />
                                    {tAdmincs('permission.on')}
                                  </div>
                                </div>
                              </ParentColums>
                            ) : (
                              ''
                            )
                          )
                        : ''}
                    </>
                  </div>
                </ParentColums>
              );
            })
          : ''}
      </FormPermissions>
    );
  }, [data]);

  return (
    <PermissionSCAcount>
      <LabelPermission>
        <div>{tAdmincs('permission.label_1')}</div>
        <div>{tAdmincs('permission.label_2')}</div>
      </LabelPermission>

      {renderlistPermissions()}

      <WrapperButtonForm width='400px' className='btn-change'>
        <ButtonSubmit
          onClick={() => {
            handleRedirectBack(`/manage-account-operation/cs-account`);
          }}
          variant='outlined'>
          {tAdmincs('form.submit')}
        </ButtonSubmit>
        <AlertDialog
          handleAccpet={onSubmitData}
          updateStatus={tAdmincs('permission.button')}
          title={tAdmincs('permission.title')}
          content={tAdmincs('permission.content')}
        />
      </WrapperButtonForm>
    </PermissionSCAcount>
  );
};

export default PermissionsAccount;
