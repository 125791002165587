import { useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import { ButtonSubmit, TextForm, FormSignIn } from './styled';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { BACKGROUND_LOGIN } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { yupEmail } from 'constants';
import {
  Content,
  FormInputText,
  WrapperContent,
} from 'components/FromFeature/form-components';
import { checkTokenResetPassword, putToResetPassword } from 'store/actions/auth.action';
import { IconShowPassword } from '../sign-in/styled';
import { ICON_HIDE_PASSWORD } from 'constants';
import { ICON_SHOW_PASSWORD } from 'constants';
import { yupPassword } from 'constants';
import { yupRePassword } from 'constants';
import { LanguageContext } from 'Context/translation.context';
export const ResetPasswordPage = () => {
  const { tAuth } = useContext(LanguageContext)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, email } = useParams();
  const { isTrueToken } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepassword, setShowRepassword] = useState(false);
  useEffect(() => {
    if (token && email) {
      dispatch(
        checkTokenResetPassword(
          token,
          () => {},
          () => navigate('/sign-in')
        )
      );
    }
  }, []);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      password: '',
      re_password: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        ...yupPassword(),
        ...yupRePassword(),
      })
    ),
  });
  const { handleSubmit, control } = methods;
  const onSubmit = (data) => {
    const listParams = {
      email,
      token,
      password: data.password,
      re_password: data.re_password,
    };
    dispatch(putToResetPassword(listParams, () => navigate('/sign-in')));
  };
  return (
    isTrueToken && (
      <FormSignIn
        onSubmit={handleSubmit(onSubmit)}
        bgi={BACKGROUND_LOGIN}
        xs={11}
        sm={9}
        md={5}
        lg={4}
        xl={3}
        title={tAuth("resetPassword.passwordChangeScreen")}
        item>
        <WrapperContent display='block'>
          <Content marginBottom='0.3rem' paddingTop='4px' minWidth='185px'>
          {tAuth("resetPassword.newPassword")}
          </Content>
          <FormInputText
            inputProps={{
              type: !showPassword ? 'password' : 'text',
            }}
            name='password'
            label={tAuth("resetPassword.pleaseEnterANewPassword")}
            control={control}
          />
          <IconShowPassword top='36px' onClick={() => setShowPassword(!showPassword)}>
            {!showPassword ? ICON_HIDE_PASSWORD : ICON_SHOW_PASSWORD}
          </IconShowPassword>
        </WrapperContent>
        <WrapperContent display='block'>
          <Content marginBottom='0.3rem' paddingTop='4px' minWidth='185px'>
          {tAuth("resetPassword.newPasswordRetype")}
          </Content>
          <FormInputText
            inputProps={{
              type: !showRepassword ? 'password' : 'text',
            }}
            label={tAuth("resetPassword.reEnterNewPassword")}
            name='re_password'
            control={control}
          />
          <IconShowPassword top='36px' onClick={() => setShowRepassword(!showRepassword)}>
            {!showRepassword ? ICON_HIDE_PASSWORD : ICON_SHOW_PASSWORD}
          </IconShowPassword>
        </WrapperContent>
        <ButtonSubmit
          type='submit'
          variant='contained'
          bgtheme='primary'
          size='medium'
          fullWidth>
          {tAuth("resetPassword.reEnterNewPassword")}
        </ButtonSubmit>
        <TextForm>
        {tAuth('loginIs')}<Link to='/sign-in'>{tAuth('here')}</Link>
        </TextForm>
      </FormSignIn>
    )
  );
};

export default ResetPasswordPage;
