import React, { useContext, useEffect } from 'react';
import {
  FormInputDate,
  FormInputDropdown,
  FormInputText,
} from 'components/FromFeature/form-components';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Content,
  WrapperContent,
  WrapperForm,
  WrapperButtonForm,
} from 'components/FromFeature/form-element/styled';
import { ButtonSubmit } from 'components/FromFeature/form-element/styledElement';
import { GENDER } from 'constants';
import { paramsCreate, paramsEdit } from './dataForm';
import { schemaCreate } from './schema';
import {
  createCSAdmin,
  createOperationAdmin,
  deleteCSAdmin,
  deleteOpeationAdmin,
  getDetailAdminCS,
  getDetailOperationAdmin,
  updateCSAdmin,
  updateOperationAdmin,
} from 'store/actions/admin.action';
import { STATUS_USER } from 'constants';
import { AlertDialog } from 'components/Dialog';
import { LanguageContext } from 'Context/translation.context';

export const FormEdit = ({
  isEdit = false,
  isAdmin = false,
  isCsEdit = false,
  isCsCreate,
}) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: paramsCreate,
    resolver: yupResolver(schemaCreate),
  });
  const { handleSubmit, reset, control, setValue, watch } = methods;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailCSAdmin, detailOperationAdmin } = useSelector((state) => state.admin);
  const { tAdmincs } = useContext(LanguageContext);

  const handleRedirectBack = (url) => {
    if (location?.state?.prevPath) {
      return navigate(`${url}${location?.state?.prevPath}`);
    }
    return navigate(`${url}`);
  };

  const onSubmit = (data) => {
    isCsCreate &&
      dispatch(createCSAdmin(data, () => handleRedirectBack('/manage-account-operation/cs-account')));
    isCsEdit &&
      dispatch(
        updateCSAdmin(data, id, () => handleRedirectBack('/manage-account-operation/cs-account'))
      );
    isAdmin &&
      dispatch(
        createOperationAdmin(data, () =>
          handleRedirectBack('/manage-account-operation/administrator')
        )
      );
    isEdit &&
      dispatch(
        updateOperationAdmin(data, id, () =>
          handleRedirectBack('/manage-account-operation/administrator')
        )
      );
  };

  const handleDelete = () => {
    isEdit &&
      dispatch(
        deleteOpeationAdmin(id, () => handleRedirectBack('/manage-account-operation/administrator'))
      );

    isCsEdit &&
      dispatch(deleteCSAdmin(id, () => handleRedirectBack('/manage-account-operation/cs-account')));
  };

  useEffect(() => {
    isCsEdit &&
      Object.keys(detailCSAdmin).length === 0 &&
      dispatch(
        getDetailAdminCS(
          id,
          () => {},
          () => handleRedirectBack('/manage-account-operation/cs-account')
        )
      );

    isEdit &&
      Object.keys(detailOperationAdmin).length === 0 &&
      dispatch(
        getDetailOperationAdmin(
          id,
          () => {},
          () => handleRedirectBack('/manage-account-operation/administrator')
        )
      );
  }, []);

  useEffect(() => {
    if (isCsEdit && Object.keys(detailCSAdmin).length > 0) {
      for (let key in paramsEdit) {
        setValue(key, detailCSAdmin[key] || (key === 'gender' ? 0 : ''));
      }
    }
    if (isEdit && Object.keys(detailOperationAdmin).length > 0) {
      for (let key in paramsEdit) {
        setValue(key, detailOperationAdmin[key] || (key === 'gender' ? 0 : ''));
      }
    }
  }, [detailCSAdmin, detailOperationAdmin]);

  return (
    <WrapperForm>
      <WrapperContent>
        <Content>{tAdmincs('table_admin.full_name')}</Content>
        <FormInputText
          name='full_name'
          control={control}
          label={tAdmincs('table_admin.full_name')}
        />
      </WrapperContent>
      <WrapperContent>
        <Content>{tAdmincs('table_admin.name_romaji')}</Content>
        <FormInputText
          name='name_romaji'
          control={control}
          label={tAdmincs('table_admin.name_romaji')}
        />
      </WrapperContent>
      <WrapperContent>
        <Content>{tAdmincs('table_admin.birthday')}</Content>
        <FormInputDate name='birthday' control={control} />
      </WrapperContent>
      <WrapperContent>
        <Content>{tAdmincs('table_admin.gender')}</Content>
        <FormInputDropdown
          name='gender'
          control={control}
          label={tAdmincs('table_admin.gender')}
          option={GENDER}
          noDefault={true}
        />
      </WrapperContent>
      <WrapperContent>
        <Content>{tAdmincs('table_admin.email')}</Content>
        <FormInputText name='email' control={control} label={tAdmincs('table_admin.email')} />
      </WrapperContent>
      {(isEdit || isCsEdit) && (
        <WrapperContent>
          <Content>{tAdmincs('table_admin.status')}</Content>
          <p style={{ marginTop: 5 }}>
            {STATUS_USER.find((item) => item.value === watch('status'))?.label}
          </p>
          {/* <FormInputDropdown
            name='status'
            control={control}
            isDisabled
            label={tAdmincs('table_admin.gender')}
            option={STATUS_USER}
          /> */}
        </WrapperContent>
      )}
      <WrapperButtonForm width='400px'>
        <ButtonSubmit
          onClick={() => {
            handleRedirectBack(
              `/manage-account-operation/${isAdmin || isEdit ? 'administrator' : 'cs-account'}`
            );
            reset();
          }}
          variant='outlined'>
          {tAdmincs('form.submit')}
        </ButtonSubmit>
        <AlertDialog
          handleAccpet={handleSubmit(onSubmit)}
          updateStatus={`完了`}
          title={`${
            isAdmin || isCsCreate ? tAdmincs('form.title') : tAdmincs('form.title_no_admin')
          }`}
          content={` ${
            isAdmin || isCsCreate ? tAdmincs('form.admin_create') : tAdmincs('form.admin_edit')
          } `}
        />
        {(isEdit || isCsEdit) && (
          <AlertDialog
            handleAccpet={handleDelete}
            addDelete
            title={tAdmincs('form.delete_title')}
            content={tAdmincs('form.delete_content')}
          />
        )}
      </WrapperButtonForm>
    </WrapperForm>
  );
};

export default FormEdit;
