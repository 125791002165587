import { ButtonCCF } from 'styled';

import styled, { css } from 'styled-components';
const ButtonSubmit = styled(ButtonCCF)`
  height: ${({ height }) => height || '100%'};
  min-width: ${({ min }) => min || '120px'} !important;
  margin-left: ${({ mgl }) => mgl || '10px'} !important;
  margin-right: ${({ margin }) => margin || '10px'} !important;
`;
const WrapperButton = styled.div`
  display: ${({ display }) => display || 'flex'};
  ${({ wrap }) => wrap && 'flex-wrap: wrap'};
  width: ${({ width }) => width || '100%'};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  ${({ mawidth }) => mawidth && `max-width: ${mawidth}`};
  align-items: ${({ align }) => align || 'center'};
  margin: 10px auto;
  ${({ margin }) => margin && `margin: ${margin} !important`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ justifyContent }) => justifyContent && 'justify-content: center'};
  gap: ${({ gap }) => gap || '10px'};
  ${({ marginBot }) => marginBot && `margin-bottom: ${marginBot} !important`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ position }) => position && `position: ${position}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ overText }) =>
    overText &&
    `
    flex: 1;
    overflow: hidden;
  `};
  ${({ isMessLeft }) =>
    isMessLeft &&
    css`
      position: absolute;
      top: 0;
    `}
  ${({ background }) => background && `background: ${background}`};
  ${({ multInput }) =>
    multInput &&
    css`
      .MuiOutlinedInput-inputMultiline {
        min-height: 36px;
        max-height: 36px;
      }
    `}/* fieldset {
    top: 0;
  } */
`;
const WrapperDropdown = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
`;
const WrapperTitleSetting = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  font-weight: 600;
  font-size: 1.25rem;
  padding: 8px;
  margin-bottom: 2.625rem;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '20px'};
`;
const ContentMin = styled.p`
  min-width: ${({ min }) => min || '100px'};
  margin: ${({ margin }) => margin || '0'};
`;
const WrapperCard = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || '600px'};
  width: 100%;
  margin: 30px auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
`;
const WrapperDetail = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || '400px'};
  margin-left: auto;
`;
const WrapperContentDetail = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin: 10px;
`;
const ContentDetail = styled.div`
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
  &:nth-child(1) {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export {
  ButtonSubmit,
  WrapperButton,
  WrapperDropdown,
  WrapperTitleSetting,
  WrapperTitle,
  WrapperCard,
  WrapperDetail,
  WrapperContentDetail,
  ContentDetail,
  ContentMin,
};
