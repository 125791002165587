import { TextLink, TextShow } from 'components/Text';
import { translate } from 'Context/translation.context';
import { postAdminLoginCreator } from 'store/actions/creator.action';
const hMain = (key) => translate(`form.${key}`, 'report');

export const renderForm = ({
  control,
  isCreator,
  detailReport,
  watch,
  dispatch,
  isFan,
  type,
}) => [
  {
    content: hMain('created_at'),
    label: hMain('created_at'),
    name: 'created_at',
    control,
    type: 'text',
    props: {
      disabled: true,
    },
  },
  {
    content: hMain('name_report'),
    label: hMain('name_report'),
    name: 'user_from_account_name',
    control,
    type: 'text',
    show: isCreator,
    props: {
      disabled: true,
    },
  },
  detailReport?.post_type === null
    ? {
        content: hMain('user_to_account_id'),
        label: hMain('user_to_account_id'),
        name: 'user_to_account_id',
        control,
        type: '',
        show: isCreator,
        props: {
          disabled: true,
        },
        render: (
          <TextShow width='100%' pding='15px'>
            <TextLink
              onClick={() => dispatch(postAdminLoginCreator(watch('user_to_account_id_origin'), ''))}
              tdecor='underline'>
              {watch('user_to_account_id')}
            </TextLink>
          </TextShow>
        ),
      }
    : {
        content: hMain('post_id'),
        label: hMain('post_id'),
        name: 'post_id',
        control,
        type: '',
        show: isCreator,
        props: {
          disabled: true,
        },
        render: (
          <TextShow width='100%' pding='15px'>
            <TextLink
              onClick={() => dispatch(postAdminLoginCreator(watch('post_id'), type))}
              tdecor='underline'>
              {watch('post_id')}
            </TextLink>
          </TextShow>
        ),
      },
  {
    content: hMain('account_id_2'),
    label: hMain('account_id_2'),
    name: 'account_id',
    control,
    type: 'text',
    show: isFan,
    props: {
      disabled: true,
    },
  },
  {
    content: hMain('name_report_2'),
    label: hMain('name_report_2'),
    name: 'user_from_account_name',
    control,
    type: 'text',
    show: isFan,
    props: {
      disabled: true,
    },
  },
  {
    content: hMain('name'),
    label: hMain('name'),
    name: 'name',
    control,
    type: 'text',
    props: {
      disabled: true,
    },
  },
  {
    content: hMain('content'),
    label: hMain('content'),
    name: 'content',
    control,
    type: 'text',
    props: {
      disabled: true,
      multiline: true,
      minRows: 4,
      mb: '15px',
    },
  },
  {
    content: hMain('status'),
    label: hMain('status'),
    name: 'status',
    control,
    type: 'text',
    props: {
      disabled: true,
    },
  },
];
