import { API_URL } from 'constants';
import { stringify } from 'qs';
const affiliatorAPI = {
  getListAffiliator: (...params) => `${API_URL}/affiliates?${stringify(...params)}`,
  getListBannerAffiliator: (...params) => `${API_URL}/affiliate/banner_history?${stringify(...params)}`,
  getDetailAffiliator: (id) => `${API_URL}/affiliate/${id}`,
  updateDetailAffiliator: (id) => `${API_URL}/affiliate/${id}`,
  deleteAffiliator: (id) => `${API_URL}/affiliate/${id}`,
  getListHistorySaleAffiliator: (...params) =>
    `${API_URL}/affiliate/sale_history?${stringify(...params)}`,
  getListHistoryPaymentAffiliator: (...params) =>
    `${API_URL}/affiliate/payment_history?${stringify(...params)}`,
  settingPaymentAffiliator: `${API_URL}/setting/limit_payment/affiliate`,
  updateStatusAffiliator: (id) => `${API_URL}/update-status/affiliate/${id}`,
};

export { affiliatorAPI };
