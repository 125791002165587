import { yupResolver } from '@hookform/resolvers/yup';
import { linkS3 } from 'apis/s3';
import { AlertDialog } from 'components/Dialog';
import {
  ButtonSubmit,
  Content,
  WrapperButton,
  WrapperButtonForm,
  WrapperContent,
  WrapperForm,
} from 'components/FromFeature/form-components';
import { MessError, MessRequired } from 'components/FromFeature/form-components/styled';
import { STATUS_BANNER } from 'constants';
import { getRenderInput } from 'constants';
import { LanguageContext } from 'Context/translation.context';
import { parseISO } from 'date-fns';
import { useUploadImage } from 'hooks';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createBanner,
  getDetailBanner,
  updateDetailBanner,
} from 'store/actions/banner.action';
import { checkTypeCell } from 'utils/utils';
import { paramsCreate, paramsDetail } from './dataForm';

export const FormEdit = ({ isCreate = false, isEdit = false, isDetail = false }) => {
  const { tBanner } = useContext(LanguageContext);
  const { UploadInputDragDrop, handleGetPathFile, images } = useUploadImage();
  const { detailBanner } = useSelector((state) => state.banner);
  const { listAffiliate } = useSelector((state) => state.common);
  const { id } = useParams();
  const navigate = useNavigate();
  const methods = useForm({
    mode: 'all',
    defaultValues: isCreate ? paramsCreate : isDetail || isEdit ? paramsDetail : {},
    // resolver: yupResolver(schemaEdit),
  });
  const { handleSubmit, control, setValue, watch } = methods;
  const dispatch = useDispatch();
  const [nameUrl, setNameUrl] = useState('');
  useEffect(() => {
    if (!isCreate && Object.keys(detailBanner).length === 0) {
      dispatch(
        getDetailBanner(
          id,
          () =>
            navigate(
              isDetail ? `/banners-management/detail/${id}` : `/banners-management/edit/${id}`
            ),
          () => navigate('/banners-management')
        )
      );
    }
  }, []);
  useEffect(() => {
    if (!isCreate && Object.keys(detailBanner).length > 0) {
      for (let key in paramsDetail) {
        setValue(key, detailBanner[key]);
        key === 'public_flag' && setValue(key, `${detailBanner['public_flag'] + 1}`);
        key === 'list_affiliator' && setValue(key, detailBanner['user_id'] || 0);
        key === 'start_time' && setValue(key, parseISO(detailBanner['start_time']));
        key === 'end_time' && setValue(key, parseISO(detailBanner['end_time']));
      }
    }
  }, []);
  const hanldeChooseDate = (dt) => {
    if (dt === '1') {
      setValue('start_time', parseISO(moment().format('YYYY-MM-DD')));
      setValue('end_time', parseISO(moment().add(1, 'days').format('YYYY-MM-DD')));
    } else if (dt === '2') {
      setValue('end_time', null);
    }
  };
  useEffect(() => {
    isCreate && hanldeChooseDate('1');
  }, []);
  const renderForm = [
    {
      content: tBanner('form.title'),
      label: tBanner('form.title'),
      name: 'title',
      control,
      type: 'text',
      props: {
        disabled: isDetail,
      },
    },
    {
      content: tBanner('form.flag'),
      name: 'public_flag',
      control,
      show: !isDetail,
      type: 'radio',
      props: {
        isDisabled: isDetail,
        options: [
          {
            value: '1',
            label: tBanner('form.postNow'),
          },
          {
            value: '2',
            label: tBanner('form.scheduledDelivery'),
          },
        ],
        onHandleDate: (dt) => hanldeChooseDate(dt),
      },
    },
    {
      content: isDetail && tBanner('form.title'),
      // show: watch('public_flag') === '2',
      upSize: !isDetail,
      render: (
        <WrapperButton>
          {getRenderInput({
            name: 'start_time',
            control,
            type: 'date',
            props: {
              clearData: isCreate && watch('public_flag') !== '1' && setValue,
              maxDate: watch('end_time')
                ? parseISO(moment(watch('end_time')).subtract(1, 'days').format('YYYY-MM-DD'))
                : null,
              minDate: !isDetail && new Date(),
              disabled: isDetail || watch('public_flag') === '1',
            },
          })}
          {getRenderInput({
            name: 'end_time',
            control,
            type: 'date',
            props: {
              clearData: !isDetail && setValue,
              maxDate: !isDetail && null,
              minDate: parseISO(
                moment(watch('start_time')).add(1, 'days').format('YYYY-MM-DD')
              ),
              disabled: isDetail,
            },
          })}
        </WrapperButton>
      ),
    },
    {
      content: tBanner('form.show'),
      label: '',
      name: 'show_affiliator',
      control,
      type: 'select',
      props: {
        option: STATUS_BANNER,
        noDefault: true,
        isDisabled: isDetail,
      },
    },
    {
      content: tBanner('form.select'),
      name: 'list_affiliator',
      control,
      type: 'asyncSelect',
      props: {
        type: 'affiliate',
        isDisabled: !isCreate,
        bannerAllOption: true,
        getURL: setNameUrl,
        isClearable: false,
        placeholder: tBanner('form.everyone'),
        // defaultSelect: !isCreate && watch('affiliator_name'),
      },
    },
  ];
  const handleCreate = (data) => {
    const { title, list_affiliator, start_time, end_time, show_affiliator, public_flag } =
      data;
    handleGetPathFile(
      (dt) =>
        dispatch(
          createBanner(
            {
              user_id: list_affiliator,
              title,
              start_time: checkTypeCell('date_api', start_time),
              end_time: checkTypeCell('date_api', end_time),
              show_affiliator,
              public_flag: +public_flag - 1,
              banner_image: dt,
            },
            () => navigate('/banners-management')
          )
        ),
      '/banner'
    );
  };
  const handleUpdate = (data) => {
    const { title, list_affiliator, start_time, end_time, show_affiliator, public_flag } =
      data;

    const dataPayload = (dt) => ({
      user_id: list_affiliator,
      title,
      start_time: checkTypeCell('date_api', start_time),
      end_time: checkTypeCell('date_api', end_time),
      show_affiliator,
      public_flag: +public_flag - 1,
      banner_image: dt,
    });
    images.length > 0
      ? handleGetPathFile(
          (dt) =>
            dispatch(
              updateDetailBanner(dataPayload(dt), id, () => navigate('/banners-management'))
            ),
          '/banner'
        )
      : dispatch(
          updateDetailBanner(dataPayload(watch('banner_image')), id, () =>
            navigate('/banners-management')
          )
        );
  };
  const validateSubmit = () => {
    const title = watch('title');
    const start_time = watch('start_time');
    const end_time = watch('end_time');
    const list_affiliator = watch('list_affiliator');
    // const show_affiliator = watch('show_affiliator');
    const banner_image = watch('banner_image');
    if(listAffiliate?.length === 0) return true
    if (!title || !start_time || !end_time) return true;
    if (isCreate && images.length < 1) return true;
    if (isEdit && images.length < 1 && !banner_image) return true;
    if (list_affiliator === undefined || list_affiliator === null || list_affiliator === '')
      return true;
    if (title.length > 100) return true;
  };
  return (
    <WrapperForm>
      <UploadInputDragDrop
        haveInput={!isDetail}
        haveDrag={isCreate}
        editMode={!isCreate}
        onlyView={isDetail}
        srcOriginal={watch('banner_image') && `${linkS3}${watch('banner_image')}`}
        contentLeft={<MessRequired marginTop='10px'>{tBanner('form.required')}</MessRequired>}
      />
      {renderForm.map((item, index) => {
        let { content, show = true, upSize = false } = item;
        return (
          <WrapperContent key={index}>
            {show && (
              <>
                {content && !isDetail && (
                  <MessRequired width='70px'>{tBanner('form.required')}</MessRequired>
                )}
                <Content
                  minWidth={upSize && '204px'}
                  paddingTop={content === tBanner('form.postPeriod') && '1rem'}>
                  {content}
                </Content>
                {content === tBanner('form.bannerTitle') &&
                  !isDetail &&
                  watch('title').length > 99 && (
                    <MessError isMessAbs='true'>
                      {tBanner('form.100CharactersOrLess')}
                    </MessError>
                  )}
              </>
            )}
            {getRenderInput(item)}
          </WrapperContent>
        );
      })}

      <WrapperButtonForm>
        {isCreate && (
          <AlertDialog
            propsButton={{
              disabled: validateSubmit(),
            }}
            handleAccpet={listAffiliate?.length > 0 ? handleSubmit(handleCreate) : () => {}}
            updateStatus={tBanner('form.create')}
            title={tBanner('form.wantToCreateABanner')}
            content={tBanner('form.areYouSureYouWantToCreateABanner')}></AlertDialog>
        )}
        {isEdit && (
          <AlertDialog
            propsButton={{
              disabled: validateSubmit(),
            }}
            handleAccpet={handleSubmit(handleUpdate)}
            updateStatus={tBanner('form.change')}
            title={tBanner('form.wantToChangeYourBanner')}
            content={tBanner('form.areYouSureYouWantToChangeTheBanner')}></AlertDialog>
        )}
        {isDetail && (
          <ButtonSubmit
            variant='contained'
            bgtheme='primary'
            onClick={() =>
              navigate(`/banners-management/edit/${id}`, {
                replace: true,
              })
            }>
            {tBanner('form.toEdit')}
          </ButtonSubmit>
        )}
      </WrapperButtonForm>
    </WrapperForm>
  );
};

export default FormEdit;
