const paramsDetail = {
  created_at: '',
  user_from_account_name: '',
  post_id: '',
  post_type: '',
  name: '',
  content: '',
  status: '',
  account_id: '',
  user_id: '',
  user_to_account_id: '',
  user_to_account_id_origin: ''
};
export { paramsDetail };
