import { PATH_URL } from 'constants/routes/settingUrl';
import { BannerPage, FormEdit } from 'pages/banner';
import { Navigate } from 'react-router-dom';

export const listRouteBanner = [
  {
    path: 'banners-management/list',
    element: <BannerPage />,
    title: 'バナー作成',
    currentPath: '/banners-management/list',
    backPath: `/${PATH_URL.REVENUE}`,
  },
  {
    path: 'banners-management/create',
    element: <FormEdit isCreate />,
    title: '新規バナー作成',
    currentPath: '/banners-management/create',
    backPath: '/banners-management/list',
  },
  {
    path: 'banners-management/detail/:id',
    element: <FormEdit isDetail />,
    title: 'バナー詳細',
    currentPath: '/banners-management/detail',
    backPath: '/banners-management/list',
  },
  {
    path: 'banners-management/edit/:id',
    element: <FormEdit isEdit />,
    title: 'バナー編集',
    currentPath: '/banners-management/edit',
    backPath: '/banners-management/list',
  },
  {
    path: `banners-management`,
    currentPath: `/banners-management/list`,
    element: <Navigate to='list' replace />,
  },
];
