import { useLocation, matchPath } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { TabsWrapper, TabWrapper } from './styled';

export default function TabMenu(tabs = []) {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const activeItem = tabs.tabs.find((item) => {
    if (Array.isArray(item.path)) {
      return item.path.includes(pathname);
    } else {
      return matchPath({ path: item.path }, pathname);
    }
  });

  const handleOnChange = (e, newValue) => {
    const url = Array.isArray(newValue) ? newValue[0] : newValue;
    return navigate(url, {
      state,
    });
  };
  return (
    <TabsWrapper value={activeItem?.path} onChange={handleOnChange}>
      {tabs.tabs.length > 0 &&
        tabs.tabs.map((item) => (
          <TabWrapper
            key={item.id}
            value={item.path}
            label={item.text}
            havecontent={item.havecontent}
            to={Array.isArray(item.path) ? item.path[0] : item.path}
            right={item.id === 'contact-users' ? '5px' : ''}
          />
        ))}
    </TabsWrapper>
  );
}
