import { API_URL } from 'constants';
import { stringify } from 'qs';
const bannerAPI = {
  getListBanner: (...params) => `${API_URL}/banners?${stringify( ...params )}`,
  getDetailBanner: (id) => `${API_URL}/banner/${id}`,
  updateDetailBanner: (id) => `${API_URL}/banner/${id}`,
  deleteBanner: (id) => `${API_URL}/banner/${id}`,
  createBanner: `${API_URL}/banner`,
};

export { bannerAPI };
