import { AlertDialog } from 'components/Dialog';
import { Content, WrapperButton } from 'components/FromFeature/form-components';
import { TooltipHover } from 'components/Text';
import { LINK_VIDEO } from 'constants';
import { LanguageContext } from 'Context/translation.context';

import React, { useContext, useEffect, useRef } from 'react';
import ReactHlsPlayer from 'react-hls-player/dist';

import { decryptPath, renderFormatDate } from 'utils/utils';
import { CardInfo, ContentCard } from './styled';

const RenderTitle = ({ title, content, showTooltip }) => (
  <WrapperButton overText display='block'>
    <Content removeMargin minWidth='140px'>
      {title}
    </Content>
    {showTooltip ? (
      <TooltipHover title={content || ''}>
        <Content overText fontWeight='500' removeMargin minWidth='100px'>
          {content}
        </Content>
      </TooltipHover>
    ) : (
      <Content overText fontWeight='500' removeMargin minWidth='100px'>
        {content}
      </Content>
    )}
  </WrapperButton>
);

export const CardRecordLivestreams = ({ data, handleDeleteRecordLiveStream }) => {
  const ref = useRef();
  const { period_time_1, period_time_2, live_title, views, recording } = data;

  useEffect(() => {
    ref.current?.setAttribute('playsinline', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { tCreator } = useContext(LanguageContext);
  return (
    <CardInfo>
      <ContentCard>
        <WrapperButton>
          <RenderTitle title={'タイトル'} content={live_title} />
          <RenderTitle title={'視聴人数'} content={views ?? 0} />
        </WrapperButton>
        <WrapperButton>
          <RenderTitle
            title={tCreator('card_content.live_distribution_start_date_and_time')}
            content={renderFormatDate(period_time_1, 1)}
          />
          <RenderTitle
            title={tCreator('card_content.end_date_and_time_of_live_distribution')}
            content={renderFormatDate(period_time_2, 1)}
          />
        </WrapperButton>
      </ContentCard>
      <ContentCard bg='#000' style={{ width: '100%', height: '400px' }}>
        <ReactHlsPlayer
          playerRef={ref}
          src={`${LINK_VIDEO}/${decryptPath(recording)}`}
          autoPlay={false}
          controls={true}
          width='100%'
          height='100%'
          playsInline={true}
        />
      </ContentCard>
      <WrapperButton gap='0'>
        <AlertDialog
          updateStatus={'削除する'}
          title={'動画を削除'}
          content={`この動画を削除しますか？`}
          handleAccpet={handleDeleteRecordLiveStream}
        />
      </WrapperButton>
    </CardInfo>
  );
};
