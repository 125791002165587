import { useEffect, useState } from 'react';

// infiniteScroll Window
const useInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback();
  }, [isFetching, callback]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    setIsFetching(true);
  };

  return [isFetching, setIsFetching];
};
const useInfiniteScrollRef = (ref, callback) => {
  const [isFetching, setIsFetching] = useInfiniteScroll(callback);
  useEffect(() => {
    ref?.current.addEventListener('scroll', handleScroll);
    return () => ref?.current?.removeEventListener('scroll', handleScroll);
  }, []); 
  const handleScroll = () => {
    const heightScrollRef = ref?.current?.scrollTop + ref?.current?.clientHeight
    if (heightScrollRef < ref?.current?.scrollHeight)
      return;
    setIsFetching(true);
  };

  return [isFetching, setIsFetching];
};

export { useInfiniteScroll, useInfiniteScrollRef };
