import TabMenu from 'components/TabMenu';
import { PATH_URL } from 'constants/routes/settingUrl';

export default function TabsAccount() {

  const navItems = [
    {
      id: 'admin',
      path: `/${PATH_URL.OPERATION_ACCOUNT}/administrator`,
      text: 'Administratorアカウント情報',
    },
    {
      id: 'cs',
      path: `/${PATH_URL.OPERATION_ACCOUNT}/cs-account`,
      text: 'CSアカウント情報',
    },
    {
      id: 'log',
      path: `/${PATH_URL.OPERATION_ACCOUNT}/log-management`,
      text: '運営アカウント管理機能操作時のログ管理',
    },
  ];

  return (
    <TabMenu tabs={navItems}/>
  );
}
