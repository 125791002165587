import { ICON_CLEAR_INPUT } from 'constants';
import { useErrorForm } from 'hooks';
import React from 'react';
import { Controller } from 'react-hook-form';
import { formatNumberPhone } from 'utils/utils';
import { ButtonClear, InputText, MessError, WrapperInput } from './styled';
export const FormInputText = ({
  name,
  control,
  label,
  type = 'text',
  isSearch = false,
  isErrForm = false,
  dfTrim = true,
  isCommission,
  ...props
}) => {
  const { watchError, handleChangeError } = useErrorForm();
  const formatText = (typeName, e) => {
    switch (typeName) {
      case 'phone':
        return formatNumberPhone(e.target.value?.trimStart());
      case 'min_price':
        return e.target.value?.trim().replace(/[^0-9.]/g, '');
      case 'commission_percent':
      case 'commission_sales':
      case 'commission_subscription':
      case 'commission_message':
      case 'commission_livestream':
      case 'value_affiliate':
        return (
          e.target.value
            ?.trim()
            .replace(/[^0-9.-]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/(?!^)-/g, '')
            .replace(/^0+(\d)/gm, '$1') + '%'
        );
      case 'point_from':
      case 'point_to':
      case 'exp_time':
      case 'agency_id_1':
      case 'agency_id_2':
      case 'creator_id':
      case 'package_id':
      case 'post_id':
        return e.target.value
          .replace(/[^0-9]/g, '')
          .trim()
          .replace(/\s/g, '')
          .replace(/^0+/, '');
      case 'point':
      case 'value':
      case 'price':
        return e.target.value?.trim().replace(/[^\w\s]/gi, '');
      case 'email':
      case 'password':
      case 're_password':
        return e.target.value?.trim();
      case 'messageAll':
      case 'message':
        return e.target.value?.slice(0, 500);
      case 'remark':
        return e.target.value?.slice(0, 100);
      case 'bonus':
        return e.target.value;
      default:
        return dfTrim ? e.target.value?.trimStart() : e.target.value;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <WrapperInput>
          <InputText
            helperText={error ? error.message : ''}
            size='small'
            type={type}
            error={!!error}
            onChange={(e) => {
              onChange(formatText(name, e));
              isErrForm && handleChangeError(name);
            }}
            value={value}
            fullWidth
            placeholder={label}
            inputProps={{
              onKeyDown: (e) => {
                if (name === 'commission_percent' && e.code === 'Backspace') {
                  // delete text before %
                  e.target.value = e.target.value
                    .replace(/[^0-9.]/g, '')
                    .replace(/(\..*)\./g, '$1')
                    .replace(/(?!^)-/g, '')
                    .replace(/^0+(\d)/gm, '$1');
                }
                if (name === 'bonus' || name === 'instock') {
                  e.target.value = e.target.value
                    .replace(/[^0-9.]/g, '')
                }
              },
            }}
            variant='outlined'
            {...props}
          />
          {isErrForm && formState?.isValid && watchError(name) && (
            <MessError bottom='0' marginTop='5px'>
              {watchError(name)}
            </MessError>
          )}
          {isCommission && !formState?.isValid && watchError(name) && (
            <MessError bottom='0' marginTop='5px'>
              {watchError(name)}
            </MessError>
          )}
          {isSearch && String(value).length > 0 && (
            <ButtonClear
              onClick={() => {
                onChange('');
              }}>
              {ICON_CLEAR_INPUT}
            </ButtonClear>
          )}
        </WrapperInput>
      )}
    />
  );
};
