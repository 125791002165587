/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  error: null,
  listTag: [],
  panigation: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_TAG_LIST_REQUEST:
    case TYPES.CREATE_TAG_REQUEST:
    case TYPES.UPDATE_TAG_REQUEST:
    case TYPES.DELETE_TAG_REQUEST:
      return {
        ...state,
        status: action.type,
        panigation: {}
      };
    case TYPES.GET_TAG_LIST_SUCCESS:
      return {
        ...state,
        listTag: action.data.data,
        panigation: action.data.panigation,
      };

    case TYPES.UPDATE_TAG_SUCCESS:
    case TYPES.DELETE_TAG_SUCCESS:
    case TYPES.CREATE_TAG_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.UPDATE_TAG_FAILED:
    case TYPES.GET_TAG_LIST_FAILED:
    case TYPES.CREATE_TAG_FAILED:
    case TYPES.DELETE_TAG_FAILED:
      return {
        ...state,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};
