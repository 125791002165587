export const ADMIN = {
  GET_LIST_CS_ADMIN: 'GET_LIST_CS_ADMIN',
  CREATE_CS_ADMIN: 'CREATE_CS_ADMIN',
  UPDATE_CS_ADMIN: 'UPDATE_CS_ADMIN',
  DELETE_CS_ADMIN: 'DELETE_CS_ADMIN',
  GET_DETAIL_CS_ADMIN: 'GET_DETAIL_CS_ADMIN',
  GET_PERMISSIONS_CS_ADMIN: 'GET_PERMISSIONS_CS_ADMIN',
  UPDATE_PERMISSIONS_CS_ADMIN: 'UPDATE_PERMISSIONS_CS_ADMIN',
  GET_LIST_OPERATION_ADMIN: 'GET_LIST_OPERATION_ADMIN',
  GET_DETAIL_OPERATION_ADMIN: 'GET_DETAIL_OPERATION_ADMIN',
  CREATE_OPERATION_ADMIN: 'CREATE_OPERATION_ADMIN',
  UPDATE_OPERATION_ADMIN: 'UPDATE_OPERATION_ADMIN',
  DELETE_OPERATION_ADMIN: 'DELETE_OPERATION_ADMIN',
  GET_LIST_ACCOUNT_LOG_MANAGEMENT: 'GET_LIST_ACCOUNT_LOG_MANAGEMENT',
};
