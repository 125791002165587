import { yupResolver } from '@hookform/resolvers/yup';
import { AlertDialog } from 'components/Dialog';
import {
  Content,
  WrapperButton,
  WrapperButtonForm,
  WrapperContent,
  WrapperForm,
} from 'components/FromFeature/form-components';
import { MessRequired } from 'components/FromFeature/form-components/styled';
import { getRenderInput, STATUS_ROLE_TYPE } from 'constants';
import { PATH_URL } from 'constants/routes/settingUrl';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {} from 'store/actions/banner.action';
import {
  createNotification,
  getDetailNotification,
  updateNotification,
} from 'store/actions/notification.action';
import { checkTypeCell } from 'utils/utils';
import { paramsCreate, paramsDetail } from './dataForm';
import { schemaCreate, schemaEdit } from './schema';
import { LanguageContext } from '../../../Context/translation.context';

const FormEdit = ({
  isCreate = false,
  isEdit = false,
  isDetail = false,
  isDuplicate = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tNotification } = useContext(LanguageContext);

  const methods = useForm({
    mode: 'all',
    defaultValues: isCreate ? paramsCreate : paramsDetail,
    resolver: yupResolver(isCreate ? schemaCreate : schemaEdit),
  });
  const { detailNotification } = useSelector((state) => state.notification);
  const { handleSubmit, control, setValue, watch } = methods;
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleRedirectBack = (url) => {
    if (location?.state?.prevPath) {
      return navigate(`${url}${location?.state?.prevPath}`);
    }
    return navigate(`${url}`);
  };

  useEffect(() => {
    if (!isCreate && Object.keys(detailNotification).length === 0) {
      dispatch(
        getDetailNotification(
          id,
          () =>
            handleRedirectBack(
              isDetail
                ? `/${PATH_URL.NOTIFICATION}/detail/${id}`
                : isDuplicate
                ? `/${PATH_URL.NOTIFICATION}/duplicate/${id}`
                : `/${PATH_URL.NOTIFICATION}/edit/${id}`
            ),
          () => handleRedirectBack(`/${PATH_URL.NOTIFICATION}/manage`)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isCreate && Object.keys(detailNotification).length > 0) {
      for (let key in paramsDetail) {
        setValue(key, detailNotification[key]);
        key === 'delivery_type' && setValue(key, detailNotification[key].toString());
        key === 'start_time' && setValue(key, parseISO(detailNotification['start_time']));
        key === 'end_time' && setValue(key, parseISO(detailNotification['end_time']));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watch('delivery_type') === '1') {
      setValue('start_time', parseISO(moment().format('YYYY-MM-DD')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('delivery_type')]);

  const validateSubmit = () => {
    const { title, content, start_time, end_time } = watch();
    if (title.length < 1) return true;
    if (content.length < 1) return true;
    if (!start_time) return true;
    if (!end_time) return true;
    if (title.length > 500) return true;
    if (
      (isEdit || isDetail) &&
      (detailNotification?.status === 2 || detailNotification?.status === 3)
    )
      return true;
    if (isEdit && start_time < parseISO(moment().format('YYYY-MM-DD'))) return true;
    return false;
  };
  const renderForm = [
    {
      content: tNotification('main.stt_role'),
      label: tNotification('main.bannerTitle'),
      name: 'role_type',
      control,
      type: 'select',
      props: {
        option: STATUS_ROLE_TYPE,
        noDefault: true,
        isDisabled: isDetail,
      },
    },
    {
      content: tNotification('main.input'),
      label: tNotification('main.label'),
      name: 'title',
      control,
      type: 'text',
      props: {
        disabled: isDetail,
      },
    },
    {
      content: tNotification('noticeText'),
      label: tNotification('enterNotificationText'),
      name: 'content',
      control,
      type: 'text',
      props: {
        multiline: true,
        minRows: 4,
        disabled: isDetail,
      },
    },
    {
      content: tNotification('postPeriod'),
      name: 'delivery_type',
      control,
      show: !isDetail,
      type: 'radio',
      props: {
        isDisabled: isDetail,
        options: [
          {
            value: '1',
            label: tNotification('postNow'),
          },
          {
            value: '2',
            label: tNotification('scheduledDelivery'),
          },
        ],
      },
    },
    {
      content: isDetail && tNotification('postPeriod'),
      // show: watch('delivery_type') === '2',
      upSize: !isDetail,
      render: (
        <WrapperButton margin={isDetail ? '0' : '0px 0 40px 0'}>
          {getRenderInput({
            name: 'start_time',
            control,
            type: 'date',
            props: {
              clearData: isCreate && watch('delivery_type') !== '1' && setValue,
              maxDate: parseISO(moment(watch('end_time')).format('YYYY-MM-DD')),
              minDate: (isCreate || isEdit) && new Date(),
              disabled: isDetail || watch('delivery_type') === '1',
              propsErr: {
                errInput: true,
              },
            },
          })}
          {getRenderInput({
            name: 'end_time',
            control,
            type: 'date',
            props: {
              clearData: !isDetail && setValue,
              maxDate: !isDetail && null,
              minDate: Math.max(
                parseISO(moment(watch('start_time')).add('day', 1).format('YYYY-MM-DD')),
                new Date()
              ),
              // parseISO(moment(watch('start_time')).add(1, 'days').format('YYYY-MM-DD'))
              disabled: isDetail,
              propsErr: {
                errInput: true,
              },
            },
          })}
        </WrapperButton>
      ),
    },
  ];
  const handleCreate = (data, status_save, mode = 'create') => {
    const params = {
      ...data,
      start_time: checkTypeCell('date_api', data?.start_time),
      end_time: checkTypeCell('date_api', data?.end_time),
      is_save: status_save,
    };
    // is_save: 0-draf, 1-save
    mode === 'create'
      ? dispatch(
          createNotification(params, () => handleRedirectBack(`/${PATH_URL.NOTIFICATION}/manage`))
        )
      : dispatch(
          updateNotification(id, params, () => handleRedirectBack(`/${PATH_URL.NOTIFICATION}/manage`))
        );
  };

  return (
    <WrapperForm>
      {renderForm.map((item, index) => {
        let { content, show = true, upSize = false } = item;
        return (
          <WrapperContent key={index}>
            {show && (
              <>
                {content && !isDetail && (
                  <MessRequired width='70px'>{tNotification('required')}</MessRequired>
                )}
                <Content
                  minWidth={upSize && '208px'}
                  paddingTop={content === '掲載期間' && '1rem'}>
                  {content}
                </Content>
              </>
            )}
            {getRenderInput(item)}
          </WrapperContent>
        );
      })}

      <WrapperButtonForm>
        {!isDetail && (
          <AlertDialog
            propsButton={{
              disabled: !isDuplicate && validateSubmit(),
            }}
            handleAccpet={handleSubmit((data) =>
              handleCreate(data, 0, isCreate || isDuplicate ? 'create' : 'edit')
            )}
            updateStatus={tNotification('temporarilySaved')}
            title={tNotification('newNotification')}
            content={tNotification('wantToCreate')}></AlertDialog>
        )}
        {(isCreate || isDuplicate) && (
          <AlertDialog
            propsButton={{
              disabled: !isDuplicate && validateSubmit(),
            }}
            handleAccpet={handleSubmit((data) => handleCreate(data, 1))}
            updateStatus={tNotification('create')}
            title={tNotification('newAnnouncement')}
            content={tNotification('wantToCreate2')}></AlertDialog>
        )}

        {isEdit && (
          <AlertDialog
            propsButton={{
              disabled: !isDuplicate && validateSubmit(),
            }}
            handleAccpet={handleSubmit((data) => handleCreate(data, 1, 'edit'))}
            updateStatus={tNotification('change')}
            title={tNotification('editAnnouncement')}
            content={tNotification('wantToEdit')}></AlertDialog>
        )}
      </WrapperButtonForm>
    </WrapperForm>
  );
};

export { FormEdit };
