import { ButtonCCF } from 'styled';
import styled from 'styled-components';

const HeaderTopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  position: fixed;
  width: calc(100% - 250px);
  background: #fff;
  z-index: 1300;
  height: 70px;
  top: 0;
  right: 0;
  padding: 0 10px;
  .header-back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 120px;
    height: 30px;
    color: #7b809a;
    border-radius: 50%;
    * {
      font-size: 24px !important;
    }
    button {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
  .header-top-title {
    font-size: 18px;
    padding: 0 10px;
    margin: 0;
    font-weight: 600;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0px;
    height: 2px;
    left: -10px;
    background: linear-gradient(30deg, transparent, #a4a4a4, transparent);
    width: calc(100% + 20px);
  }
`;
const ButtonHeader = styled(ButtonCCF)`
  color: #fff !important;
  padding: 6px 15px !important;
  border-radius: 5px !important;
  font-size: 12px;
  height: 27px;
`;
const WrapperButtonLogout = styled.div`
  text-align: end;
`;
export { HeaderTopWrapper, ButtonHeader, WrapperButtonLogout };
