
import { Paper, Box, List, ListItemText } from '@mui/material';
import { ButtonCCF } from 'styled';
import styled from 'styled-components';

const WrapperSidebar = styled(Paper)`
  position: fixed;
  z-index: 1234;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  /* height: calc(100vh - 2rem); */
  height: calc(100% - 40px);
  width: 250px;
  border-radius: 0 !important;
  /* margin: 1rem; */
  /* border-radius: 0.75rem !important; */
  box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
`;

const Logo = styled(Box)`
  display: block;
  gap: 12px;
  padding-top: 16px;
  text-align: center;
  background: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  & > img {
    width: 120px;
  }
  & > div {
    letter-spacing: 2px;
  }
`;
const Lightline = styled.hr`
  flex-shrink: 0;
  border-top: 0px solid #00000014;
  border-right: 0px solid #00000014;
  border-left: 0px solid #00000014;
  height: 0.0625rem;
  margin: 10px 0px;
  border-bottom: none;
  opacity: 0.25;
  background-color: transparent;
  background-image: linear-gradient(to right, #ffffff00, #fff, #ffffff00);
`;
const ListItem = styled(List)``;
const ItemButton = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 0 !important;
`;
const ItemContent = styled.div`
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  background: transparent;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.625rem;
  margin: 0.09375rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.active {
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  }
  &.child-item {
    padding-left: 30px;
  }
`;
const ItemIcon = styled.div`
  flex-shrink: 0;
  min-width: 2rem;
  min-height: 2rem;
  color: rgb(255, 255, 255);
  border-radius: 0.375rem;
  display: grid;
  place-items: center;
  transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  svg {
    color: #fff;
  }
`;
const ItemText = styled(ListItemText)`
  opacity: 1;
  max-width: 100%;
  margin-left: 0.625rem;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  span {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
  }
`;
const WrapperEnd = styled.div`
  padding: 16px;
  margin-top: auto;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
`;
const ButtonLogout = styled(ButtonCCF)`
  padding: 0.625rem 1.5rem;
  border-radius: 0.5rem !important;
  background-image: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
  span {
    transition: all 150ms ease-in 0s;
  }
  &:hover {
    span:nth-child(2) {
      box-shadow: rgb(26 115 232 / 40%) 0rem 0.875rem 1.625rem -0.75rem,
        rgb(26 115 232 / 15%) 0rem 0.25rem 1.4375rem 0rem,
        rgb(26 115 232 / 20%) 0rem 0.5rem 0.625rem -0.3125rem !important;
    }
  }
`;
const ProfileEndMenu = styled.div`
  width: 250px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: linear-gradient(195deg,rgb(73,163,241),rgb(26,115,232));
  height: 40px;
  & > p {
    color: #fff;
    font-size: 18px;
  }
`;
export {
  WrapperSidebar,
  Logo,
  Lightline,
  ListItem,
  ItemButton,
  ItemIcon,
  ItemText,
  ItemContent,
  WrapperEnd,
  ButtonLogout,
  ProfileEndMenu,
};
