/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  revenueSummary: {},
  revenueList: [],
  revenueDetail: {},
  dataCsvRevenue:[],
  pagination: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REVENUE_SUMARY_REQUEST:
      return {
        ...state,
        revenueSummary: {},
      };
    case TYPES.REVENUE_CSV_REQUEST:
      return {
        ...state,
        dataCsvRevenue: [],
      };
    case TYPES.REVENUE_LIST_REQUEST:
      return {
        ...state,
        revenueList: [],
        pagination: {},
      };
    case TYPES.REVENUE_DETAIL_REQUEST:
      return {
        ...state,
        revenueDetail: {},
      };
    case TYPES.REVENUE_SUMARY_SUCCESS:
      return {
        ...state,
        revenueSummary: action.data.data,
      };
    case TYPES.REVENUE_LIST_SUCCESS:
      return {
        ...state,
        revenueList: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.REVENUE_DETAIL_SUCCESS:
      return {
        ...state,
        revenueDetail: action.data.data,
      };
    case TYPES.REVENUE_CSV_SUCCESS:
      return {
        ...state,
        dataCsvRevenue: action.data.data,
      };
    case TYPES.REVENUE_SUMARY_FAILED:
    case TYPES.REVENUE_LIST_FAILED:
    case TYPES.REVENUE_DETAIL_FAILED:
    case TYPES.REVENUE_CSV_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
