import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'translate';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const { t: tCreator } = useTranslation('creator');
  const { t: tAgency } = useTranslation('agency');
  const { t: tAdmincs } = useTranslation('admincs');
  const { t: tBanner } = useTranslation('banner');
  const { t: tEmail } = useTranslation('email');
  const { t: tFan } = useTranslation('fan');
  const { t: tHome } = useTranslation('home');
  const { t: tNotification } = useTranslation('notification');
  const { t: tPayment } = useTranslation('payment');
  const { t: tPoint } = useTranslation('point');
  const { t: tReport } = useTranslation('report');
  const { t: tRevenue } = useTranslation('revenue');
  const { t: tSettingsite } = useTranslation('settingsite');
  const { t: tContact } = useTranslation('contact');
  const { t: tAuth } = useTranslation('auth');
  const { t: tTags } = useTranslation('tags');
  const { t: tSettingDisplaySite } = useTranslation('settingDisplaySite');

  return (
    <LanguageContext.Provider
      value={{
        tCreator,
        tAgency,
        tFan,
        tAdmincs,
        tBanner,
        tEmail,
        tHome,
        tPayment,
        tRevenue,
        tReport,
        tSettingsite,
        tPoint,
        tNotification,
        tContact,
        tAuth,
        tTags,
        tSettingDisplaySite,
      }}
      children={children}
    />
  );
};
const translate = (key, ns) => i18n.t(key, { ns });
const translateSchema = (key) => i18n.t(key, { ns: 'schema' });
export { LanguageProvider, LanguageContext, translate, translateSchema };
