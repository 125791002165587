const textToStatusNotification = {
  0: '一時保存',
  1: '予約配信',
  2: '掲載中',
  3: '掲載終了',
};
const textToStatusPayment = {
  1: '未払い',
  2: '未払い「CSV出力済み」',
  3: 'ペンディング',
  4: '支払い済み',
};
const textToStatus = {
  0: '作成した',
  1: '利用中',
  2: '停止中',
  3: '承認待ち',
  4: '拒否',
  5: '退会済み',
  7: '未承認',
};
const textToGender = {
  0: '女性',
  1: '男性',
};
const textToTransaction = {
  1: '投げ銭（メッセージ）',
  2: '投げ銭（ライブ配信）',
  3: 'サブスクリプション',
  4: '単体販売',
};
const textToRevenue = {
  1: '見込み',
  2: '確定',
};
const textToBanner = {
  0: 'OFF',
  1: 'ON',
};
const textToStatusMoneyPackage = {
  1: '普通預金',
  2: '当座預金',
};

const textToStatusRoleType = {
  0: '全体',
  1: 'Admin',
  2: 'クリエイター',
  3: 'ファン',
  4: '代理店',
  5: 'ユーザ',
};

const textToStatusAdminCS = {
  1: 'ログイン',
  2: '新規作成',
  3: '編集',
};
const textToPaymentMethod = {
  1: 'クレジットカード',
  2: 'TigerPay',
};
const textToStatusPoint = {
  1: '付与済み',
  2: '未付与',
  3: '失敗',
  4: '返金',
  5: '確認中',
};
const textToStatusReport = {
  1: '未解決',
  2: '解決',
};
const textToDisPlayPosition = {
  1: '広告枠1 864px x 200px',
  2: '広告枠2 344px x 122px',
  3: '広告枠3 344px x 122px',
  4: '広告枠4 344px x 122px',
};
const chooseTypeUser = {
  0: 'SUPER_ADMIN',
  1: 'ADMIN',
  2: 'CREATOR',
  3: 'FAN',
  4: 'AGENCY',
  6: 'CS',
};
const typePost = {
  1: '投稿',
  2: '販売コンテンツ',
  4: 'ショート動画',
};
const statusPost = {
  NOT_APPROVAL: '未承認',
  PUBLIC: '公開',
  REMOVE: '削除',
  REJECT: '拒否',
  REMOVE_BY_CREATOR: '投稿者が削除',
  APPROVED_AGAIN: '再承認'
};
export {
  textToStatusNotification,
  textToStatusPayment,
  textToStatus,
  textToGender,
  textToTransaction,
  textToBanner,
  textToStatusMoneyPackage,
  textToStatusRoleType,
  textToStatusAdminCS,
  textToPaymentMethod,
  textToStatusPoint,
  textToStatusReport,
  textToRevenue,
  textToDisPlayPosition,
  chooseTypeUser,
  typePost,
  statusPost,
};
