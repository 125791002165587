import * as yup from 'yup';
const schemaEdit = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required('名は入力してください。')
    //.matches(/^[ぁ-んァ-ン一-龥ー\s]+$/, '有効な形式で名を入力してください。')
    .max(20, '名は２０文字以内で入力してください。'),
  last_name: yup
    .string()
    .trim()
    .required('性は入力してください。')
    //.matches(/^[ぁ-んァ-ン一-龥ー\s]+$/, '有効な形式で姓を入力してください。')
    .max(20, '性は２０文字以内で入力してください。'),
  first_name_romaji: yup
    .string()
    .trim()
    .required('名（ローマ字）は入力してください。')
    .matches(/^([A-z\s])+$/, '有効な形式で名（ローマ字）を入力してください。')
    .max(20, '氏名（ローマ字）を20文字まで入力してください。'),
  last_name_romaji: yup
    .string()
    .trim()
    .required('性（ローマ字）は入力してください。')
    .matches(/^([A-z\s])+$/, '有効な形式で姓（ローマ字）を入力してください。')
    .max(20, '氏名（ローマ字）を20文字まで入力してください。'),
});
export { schemaEdit };
