import { ReportContext, ReportPropTypes, ReportReducer } from 'Context';
import React, { useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { TabsReports } from './settings/tabs';
import { FilterBox } from './views/FilterBox';
import { Table } from './views/Table';
import { useSearchParams } from 'react-router-dom';
import { useGetParamsPage } from 'hooks/useGetParamsPage';

export const ReportsPage = ({ isCreator, isFan }) => {
  const [searchParams] = useSearchParams();
  const { dataParams, pushStateParams } = useGetParamsPage();
  const defaultValues = {
    keyword: searchParams.get('keyword') || '',
    status: searchParams.get('status') ? Number(searchParams.get('status')) : '',
    category: searchParams.get('category') ? Number(searchParams.get('category')) : '',
  };
  const methods = useForm({
    mode: 'all', 
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = methods;
  const [state, dispatchState] = useReducer(
    ReportReducer.reducer,
    ReportReducer.initialState({
      ...defaultValues,
      role_type: isCreator ? 3 : isFan ? 2 : '',
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
      start_day: dataParams?.start_day,
      end_day: dataParams?.end_day,
    })
  );
  const { query, count } = state;
  return (
    <>
      <TabsReports />
      <ReportContext.Provider
        value={{
          isCreator,
          isFan,
          handleSubmit,
          control,
          query,
          count,
          dispatchState,
          dataParams,
          pushStateParams
        }}>
        <FilterBox />
        <Table />
      </ReportContext.Provider>
    </>
  );
};
ReportsPage.propTypes = ReportPropTypes.typesMain;
ReportsPage.defaultProps = ReportPropTypes.defaultMain;
export * from './EditForm';
