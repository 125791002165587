import { format, parse } from 'date-fns';
const year = new Date().getFullYear();
const listYear = [];
for (let index = 0; index < year - 1979; index++) {
  listYear.push(year - index);
}
const listMonth = [];
for (let index = 1; index < 13; index++) {
  listMonth.push(13 - index);
}

const FNS_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';
const FNS_DATE_FORMAT = 'yyyy-MM-dd';
const FNS_MONTH_FORMAT = 'MM/yyyy';

function formatDate(dateObj) {
  return format(dateObj, FNS_DATE_TIME_FORMAT);
}

function formatDateOnly(dateObj) {
  return format(dateObj, FNS_DATE_FORMAT);
}

function parseDateOnly(dateString) {
  return parse(dateString, FNS_DATE_FORMAT);
}

function formatMonth(month, year) {
  return format(new Date(year, month - 1, 1, 0, 0, 0, 0), FNS_MONTH_FORMAT);
}

export { listYear, listMonth, formatDate, formatDateOnly, parseDateOnly, formatMonth };
