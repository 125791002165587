import { createContext } from 'react';
import PropTypes from 'prop-types';
const ReportContext = createContext();
// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_QUERY':
      return { ...state, query: action.payload };
    case 'SET_COUNT':
      return { ...state, count: action.payload };
    default:
      return state;
  }
};
const initialState = (dataInitQuery) => {
  return {
    query: dataInitQuery || {},
    count: 0,
  }

};
const ReportReducer = { reducer, initialState };
// PropTypes
const ReportPropTypes = {
  typesMain: {
    isCreator: PropTypes.bool,
    isFan: PropTypes.bool,
  },
  defaultMain: {
    isCreator: false,
    isFan: false,
  },
};
export { ReportContext, ReportReducer, ReportPropTypes };
