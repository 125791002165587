import { useEffect, useCallback, useRef, useState } from 'react';

const useChannelEventListener = (channelRef, event, handler) => {
  useEffect(() => {
    const channel = channelRef.current;
    if (channel) {
      channel.addEventListener(event, handler);
      return () => channel.removeEventListener(event, handler);
    }
  }, [channelRef, event, handler]);
};
const useBroadcastChannel = (channelName, handleMessage, handleMessageError) => {
  const channelRef = useRef(
    typeof window !== 'undefined' && 'BroadcastChannel' in window
      ? new BroadcastChannel(channelName + '-channel')
      : null
  );
  useChannelEventListener(channelRef, 'message', handleMessage);
  useChannelEventListener(channelRef, 'messageerror', handleMessageError);
  return useCallback(
    (data) => {
      channelRef?.current?.postMessage(data);
    },
    [channelRef]
  );
};

const useBroadcastState = (channelName, initialState) => {
  const [state, setState] = useState(initialState);
  const setter = useBroadcastChannel(channelName, (ev) => setState(ev.data));
  useEffect(() => setter(state), [setter, state]);
  return [state, setState];
};

export { useBroadcastChannel, useBroadcastState };
