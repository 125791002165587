/* eslint-disable no-unused-expressions */
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WrapperTable from 'components/Table';
import {
  ButtonSubmit,
  ContentDetail,
  WrapperButton,
  WrapperDetail,
  WrapperTitle,
  WrapperContentDetail,
} from '../../components/FromFeature/form-element/styledElement';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { FormInputDropdown, FormInputText } from 'components/FromFeature/form-components';
import DateSelectGroup from 'components/WrapperSelect/select.date';
import {
  columnListBanner,
  columns,
  columnsHistoryPayment,
  columnsHistorySale,
} from './settings';
import { TabsAffiliate } from './settings/tabs';
import { STATUS_USER, checkParams } from 'constants';
import {
  getDetailAffiliator,
  getListAffiliator,
  getListBannerAffiliator,
  getListHistoryPaymentAffiliator,
  getListHistorySaleAffiliator,
} from 'store/actions/affiliate.action';
import { checkTypeCell, enterToSearch } from 'utils/utils';
import { STATUS_PAYMENT } from 'constants';
import { LanguageContext } from 'Context/translation.context';
export const AffiliatorPage = ({
  isMain = false,
  isSaleHistory = false,
  isPaymentHistory = false,
  isBanner = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataList, dataSaleHistory, dataPaymentHistory, pagination, listBanner } =
    useSelector((state) => state.affiliator);

  const defaultValues = {
    keyword: '',
    status: '',
    full_name: '',
    payment_status: '',
  };

  const { tAdmincs, tAgency } = useContext(LanguageContext);

  const methods = useForm({ mode: 'all', defaultValues: defaultValues });
  const { handleSubmit, control } = methods;
  const [handleSearch, setSearch] = useState(0);
  const [dateValue, setDateValue] = useState({});
  const [query, setQuery] = useState({});
  const getDetail = (id) => {
    dispatch(
      getDetailAffiliator(
        id,
        () => navigate(`/affiliate-management/manage-affiliate/edit/${id}`),
        () => navigate('/affiliate-management/manage-affiliate')
      )
    );
  };
  const LIST_STATUS_PAYMENT_AFFILIATE = [
    ...STATUS_USER.filter((item) => item.value === 5 || item.value === 2 || item.value === 1),
    {
      value: 'first_login',
      label: tAdmincs('affiliator.notLoggedIn'),
    },
  ];
  const onSubmit = (data) => {
    const paramsDatePayment = () => {
      const splitDate = (date, year, month, day) => {
        const arr = date.split('-');
        return {
          [year]: arr[0],
          [month]: arr[1],
          [day]: arr[2],
        };
      };
      return {
        ...splitDate(dateValue.from_date, 'from_year', 'from_month', 'from_date'),
        ...splitDate(dateValue.to_date, 'to_year', 'to_month', 'to_date'),
      };
    };
    const handleDataStatus = (stt) => {
      switch (stt) {
        case 'first_login':
          return {
            status: 1,
            first_login: 0,
          };
        case 1:
          return {
            status: 1,
            first_login: 1,
          };
        case 2:
          return {
            status: 2,
          };
        case 5:
          return {
            status: 5,
          };
        default:
          break;
      }
    };
    setQuery({
      ...checkParams(
        isSaleHistory || isBanner || isPaymentHistory ? paramsDatePayment() : dateValue
      ),
      ...checkParams(data),
      ...checkParams(handleDataStatus(data.status)),
    });
    setSearch(handleSearch + 1);
  };
  return (
    <>
      <TabsAffiliate />
      <WrapperButton justify='start' wrap='true'>
        {!isMain && (
          <WrapperTitle>
            <span style={{ width: '50px' }}>{tAdmincs('log.date')}</span>
            <DateSelectGroup
              isTypeFromDate
              handleSelect={(key, value) => {
                setDateValue((prev) => ({ ...prev, [`${key}`]: value }));
              }}
            />
          </WrapperTitle>
        )}
        {isPaymentHistory && (
          <WrapperTitle>
            {tAgency('main.status')}
            <FormInputDropdown
              name='payment_status'
              control={control}
              option={STATUS_PAYMENT.filter((e) => e.value === 4 || e.value === 3)}
            />
          </WrapperTitle>
        )}
        <WrapperButton margin='0' width='300px'>
          <FormInputText
            name={
              isPaymentHistory || isSaleHistory
                ? 'full_name'
                : isBanner
                ? 'keyword'
                : 'keyword'
            }
            inputProps={{
              onKeyDown: (e) => {
                enterToSearch(e, handleSubmit(onSubmit));
              },
            }}
            isSearch
            control={control}
            label={
              isMain
                ? 'キーワード入力で検索'
                : isSaleHistory
                ? 'アフィリエイターの氏名を入力'
                : isPaymentHistory
                ? 'アフィリエイターの氏名を入力'
                : '代理店の担当者名を入力'
            }
          />
        </WrapperButton>

        {isMain && (
          <WrapperTitle>
            {tAdmincs('table_admin.status')}
            <FormInputDropdown
              name='status'
              control={control}
              option={LIST_STATUS_PAYMENT_AFFILIATE}
            />
          </WrapperTitle>
        )}
        <ButtonSubmit onClick={handleSubmit(onSubmit)} variant='contained' bgtheme='primary'>
          {tAdmincs('admin.search')}
        </ButtonSubmit>
      </WrapperButton>
      {isSaleHistory && (
        <WrapperDetail>
          <WrapperContentDetail maxWidth='600px'>
            <ContentDetail>{tAdmincs('affiliator.totalAffiliateProfit')}</ContentDetail>
            <ContentDetail>
              {checkTypeCell('amount_pt', dataSaleHistory[0]?.sumProfit || '0pt')}
            </ContentDetail>
          </WrapperContentDetail>
        </WrapperDetail>
      )}
      <WrapperTable
        countSearch={handleSearch}
        columns={
          isMain
            ? columns(getDetail)
            : isSaleHistory
            ? columnsHistorySale
            : isPaymentHistory
            ? columnsHistoryPayment
            : isBanner
            ? columnListBanner
            : []
        }
        getRowId={(row) =>
          isMain ? row['id'] : isSaleHistory ? row['payment_id'] : row['id']
        }
        total={pagination?.total}
        listRows={
          isMain
            ? dataList
            : isSaleHistory
            ? dataSaleHistory
            : isPaymentHistory
            ? dataPaymentHistory
            : isBanner
            ? listBanner
            : []
        }
        query={query}
        action={(prev) =>
          isMain
            ? getListAffiliator({ ...prev })
            : isSaleHistory
            ? getListHistorySaleAffiliator({ ...prev })
            : isPaymentHistory
            ? getListHistoryPaymentAffiliator({ ...prev })
            : isBanner
            ? getListBannerAffiliator({ ...prev })
            : () => {}
        }
      />
    </>
  );
};
export * from './EditForm';
export * from './setting';
