import { API_URL_V2 } from 'constants';
import { stringify } from 'qs';

const voucherApi = {
  getListVoucher: (...data) => `${API_URL_V2}/vouchers?${stringify(...data)}`,
  createVoucher: (data) => `${API_URL_V2}/vouchers`,
  createVoucherPublic: (data) => `${API_URL_V2}/vouchers/send-voucher`,
  deleteVoucher: (id) => `${API_URL_V2}/vouchers/${id}`,
  editVoucher: (data) => `${API_URL_V2}/vouchers/${data?.id}`,
  editVoucherPublic: (data) => `${API_URL_V2}/vouchers/${data?.id}/send-voucher`,
};

export { voucherApi };
