const paramsEdit = {
  first_name: '',
  first_name_romaji: '',
  last_name: '',
  last_name_romaji: '',
  avatar: '',
  avatar_identify: '',
  identify_img_front: '',
  identify_img_back: '',
  contract_file: '',
};
export { paramsEdit };
