/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  listChart: [],
  pagination: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_CHART_REQUEST:
      return {
        ...state,
        listChart: [],
        pagination: {},
      };
    case TYPES.GET_LIST_CHART_SUCCESS:
      return {
        ...state,
        listChart: action.data.data,
        pagination: action.data.pagination,
      };
    case TYPES.GET_LIST_CHART_FAILED:
      return state;

    case TYPES.CREATE_CHART_REQUEST:
      return {
        ...state,
        listChart: [],
        pagination: {},
      };
    default:
      return state;
  }
};
