import {
  ButtonSubmit,
  WrapperTitle,
  WrapperButton,
  WrapperDetail,
  WrapperContentDetail,
  ContentDetail,
  FormInputDropdown,
  FormInputText,
  WrapperContent,
  Content,
} from 'components/FromFeature/form-components';
import WrapperTable from 'components/Table';
import DateSelectGroup from 'components/WrapperSelect/select.date';
import { STATUS_PAYMENT } from 'constants';
import { checkParams } from 'constants';
import { PATH_URL } from 'constants/routes/settingUrl';
import { LanguageContext } from 'Context/translation.context';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deletePostCreator,
  getDetailContract,
  getDetailCreator,
  getListContract,
  getListHistoryPayment,
  getListHistoryPost,
  getListHistorySale,
  postAdminLoginCreator,
} from 'store/actions/creator.action';
import { checkTypeCell, enterToSearch } from 'utils/utils';
import {
  columnPaymentHistory,
  columnSaleHistory,
  columnPostHistory,
  columnContract,
} from './settings';
import { TabsSecond } from './settings/tabs';

export const TableDetail = ({
  isPaymentHistory = false,
  isSaleHistory = false,
  isPostHistory = false,
  isContract = false,
}) => {
  const defaultValues = {
    keyword: '',
  };

  const methods = useForm({ mode: 'all', defaultValues });
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    pagination,
    listSaleHistory,
    listPaymentHistory,
    listPostHistory,
    totalProfit,
    detailCreator,
    listContract,
  } = useSelector((state) => state.creator);
  const [search, setSearch] = useState(0);
  const [query, setQuery] = useState({});
  const { handleSubmit, control } = methods;
  const [dateValue, setDateValue] = useState({});
  const { profile } = useSelector((state) => state.auth);

  useEffect(() => {
    Object.keys(detailCreator).length === 0 &&
      dispatch(
        getDetailCreator(
          id,
          () => {},
          () => navigate(`/${PATH_URL.CREATOR}`)
        )
      );
  }, []);
  const handleSearch = (data) => {
    setQuery({ ...checkParams(dateValue), ...checkParams(data) });
    setSearch(search + 1);
  };
  const detailPost = (postId, type) => {
    const checkType = Boolean(type === 2);
    dispatch(postAdminLoginCreator(postId, 'post', profile?.id));
  };
  const deletePost = (dt) => {
    dispatch(
      deletePostCreator(dt, (prev) =>
        dispatch(
          getListHistoryPost(
            id,
            () => {},
            () => navigate(`/${PATH_URL.CREATOR}/account`),
            { page: 1, limit: 20 }
          )
        )
      )
    );
  };
  const detailContract = (idContract) => {
    dispatch(
      getDetailContract(
        id,
        idContract,
        () => navigate(`/${PATH_URL.CREATOR}/contract/${id}/detail/${idContract}`),
        () => navigate(`/${PATH_URL.CREATOR}`)
      )
    );
  };

  const { tCreator } = useContext(LanguageContext);

  return (
    <>
      <TabsSecond />
      <WrapperButton justifyContent wrap='true'>
        {!isContract && (
          <DateSelectGroup
            handleSelect={(key, value) => {
              setDateValue((prev) => ({ ...prev, [`${key}`]: value }));
            }}
          />
        )}
        {isPaymentHistory && (
          <WrapperTitle>
            {tCreator('table_payment.status')}
            <FormInputDropdown
              name='status'
              control={control}
              option={STATUS_PAYMENT.filter((e) => e.value === 4 || e.value === 3)}
            />
          </WrapperTitle>
        )}
        {isPostHistory && (
          <WrapperButton width='400px' margin='0'>
            <FormInputText
              width='400px'
              name='keyword'
              isSearch
              inputProps={{
                onKeyDown: (e) => {
                  enterToSearch(e, handleSubmit(handleSearch));
                },
              }}
              control={control}
              label={tCreator('enter_post_ID_and_post_title')}
            />
          </WrapperButton>
        )}
        {!isContract && (
          <ButtonSubmit
            onClick={handleSubmit(handleSearch)}
            variant='contained'
            bgtheme='primary'>
            {tCreator('button_search')}
          </ButtonSubmit>
        )}
      </WrapperButton>
      {isSaleHistory && (
        <WrapperDetail>
          <WrapperContentDetail>
            <ContentDetail marginRight='10px'></ContentDetail>
            <ContentDetail style={{ display: 'flex'}}>
            <div style={{ fontWeight: "bold", marginRight: "3px"}}>{tCreator('total_profit')} :</div>
              {`${checkTypeCell(
                'amount_pt',
                Number(totalProfit) || `0pt`
              )}`}
            </ContentDetail>
          </WrapperContentDetail>
        </WrapperDetail>
      )}
      <WrapperContent justifyContentEnd marginBotTop={'10px'} paddingRight={'10px'}>
        <Content marginTop={'0'} marginBottom={'0'} textAlign={'end'}>
          検索結果：
        </Content>
        <div>{pagination?.total}件</div>
      </WrapperContent>
      <WrapperTable
        columns={
          isSaleHistory
            ? columnSaleHistory
            : isPaymentHistory
            ? columnPaymentHistory
            : isPostHistory
            ? columnPostHistory(detailPost, deletePost)
            : isContract
            ? columnContract(detailContract)
            : []
        }
        getRowId={(row) => row['id']}
        total={pagination?.total}
        countSearch={search}
        listRows={
          isSaleHistory
            ? listSaleHistory
            : isPaymentHistory
            ? listPaymentHistory
            : isPostHistory
            ? listPostHistory
            : isContract
            ? listContract
            : []
        }
        query={query}
        action={(prev) =>
          isSaleHistory
            ? getListHistorySale(
                id,
                () => {},
                () => navigate(`/${PATH_URL.CREATOR}/account`),
                { ...prev }
              )
            : isPaymentHistory
            ? getListHistoryPayment(
                id,
                () => {},
                () => navigate(`/${PATH_URL.CREATOR}/account`),
                { ...prev }
              )
            : isPostHistory
            ? getListHistoryPost(
                id,
                () => {},
                () => navigate(`/${PATH_URL.CREATOR}/account`),
                { ...prev }
              )
            : isContract
            ? getListContract(
                id,
                () => {},
                () => navigate(`/${PATH_URL.CREATOR}/account`),
                { ...prev }
              )
            : () => {}
        }
      />
    </>
  );
};
