import { API_URL_V2, API_URL_GLOBAL_V2 } from 'constants';
import { stringify } from 'qs';

const newHost = `${API_URL_V2}/users`;

const creatorAPI = {
  getListCreator: (...params) => `${newHost}?${stringify(...params)}`,
  getListLiveStreamCreator: (...params) => `${API_URL_V2}/livestreams?${stringify(...params)}`,
  getDetailCreator: (id) => `${newHost}/${id}`,
  updateCreator: (id) => `${newHost}/${id}`,
  updateContract: (id, contract_id) => `${newHost}/${id}/contracts/${contract_id}`,
  deleteCreator: (id) => `${newHost}/${id}`,
  deleteContract: (id, contract_id) => `${newHost}/${id}/contracts/${contract_id}`,
  approveCreator: (id) => `${newHost}/${id}/approval`,
  getSaleHistoryCreator: (id, ...params) =>
    `${newHost}/${id}/sale-histories?${stringify(...params)}`,
  getListContract: (id, ...params) => `${newHost}/${id}/contracts?${stringify(...params)}`,
  getContractDetail: (id, contract_id) => `${newHost}/${id}/contracts/${contract_id}`,
  getPaymentHistoryCreator: (id, ...params) =>
    `${newHost}/${id}/payment-histories?${stringify(...params)}`,
  getPostHistoryCreator: (id, ...params) =>
    `${newHost}/${id}/post-histories?${stringify(...params)}`,
  settingLimitPayment: `${API_URL_V2}/settings/limit-payment/users`,
  deletePostCreator: (id) => `${API_URL_V2}/posts/${id}`,
  deleteSubscriptionCreator: (id) => `${API_URL_V2}/subscriptions/${id}`,
  creatorStopLivestream: (key) => `${API_URL_V2}/livestreams/${key}/end`,
  adminLoginCreator: `${API_URL_V2}/user/admin-login`,
  adminLoginCreatorSecond: `${API_URL_V2}/user/admin-login-second`,
  getSubscriptionCreator: (id) => `${API_URL_V2}/subscriptions/${id}`,
  createTokenAgoraGuest: (name) =>
    `${API_URL_GLOBAL_V2}/livestreams/agora/token?channel_name=${name}`,
  getListRecordLiveStream: (id, ...params) =>
    `${API_URL_V2}/users/${id}/live-archives?${stringify(...params)}`,
  deleteRecordLiveStream: (id) => `${API_URL_V2}/live-archives/${id}`,
};

export { creatorAPI };
