import { linkS3 } from 'apis/s3';
import { AlertDialog } from 'components/Dialog';
import {
  ButtonSubmit,
  Content,
  FormInputDate,
  FormInputDropdown,
  FormInputText,
  WrapperButton,
} from 'components/FromFeature/form-components';
import { WrapperShowListCard } from 'components/ListCard';
import { TooltipHover } from 'components/Text';
import { textToBanner } from 'constants';
import { checkParams } from 'constants';
import { STATUS_BANNER } from 'constants';
import { LanguageContext } from 'Context/translation.context';
import { CardInfo, ImageCard } from 'pages/Creator/components/styled';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteBanner, getDetailBanner, getListBanner } from 'store/actions/banner.action';
import { checkTypeCell, enterToSearch } from 'utils/utils';

const BannerPage = () => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      start_time_from: '',
      start_time_to: '',
      end_time_from: '',
      end_time_to: '',
      show_affiliator: '',
      banner_title: '',
    },
  });
  const { dataList, pagination } = useSelector((state) => state.banner);
  const { tBanner } = useContext(LanguageContext);
  const distpatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, control, setValue, watch } = methods;
  const [query, setQuery] = useState({});
  const [countSearch, setCountSearch] = useState(0);
  const handleDelete = (id) => {
    distpatch(
      deleteBanner(id, () => {
        setCountSearch(countSearch + 1);
      })
    );

    // setResetTable(resetTable + 1);
  };
  const handleGetDetail = (id, type = 'detail') => {
    distpatch(
      getDetailBanner(
        id,
        () =>
          navigate(
            type === 'detail'
              ? `/banners-management/detail/${id}`
              : `/banners-management/edit/${id}`
          ),
        () => navigate('/banners-management')
      )
    );
  };
  const handleSearch = (data) => {
    setQuery({ ...checkParams(data) });
    setCountSearch(countSearch + 1);
  };
  const CardDetail = (props) => (
    <CardInfo height='450px'>
      <ImageCard
        height='230px'
        loading='lazy'
        src={`${linkS3}${props?.banner_image}`}
        alt=''
      />
      <WrapperButton justify='end' gap='0'>
        <ButtonSubmit
          min='auto'
          variant='contained'
          bgtheme='primary'
          onClick={() => handleGetDetail(props?.id)}>
          {tBanner('main.detail')}
        </ButtonSubmit>
        <ButtonSubmit
          min='auto'
          variant='contained'
          bgtheme='primary'
          onClick={() => handleGetDetail(props?.id, 'edit')}>
          {tBanner('main.edit')}
        </ButtonSubmit>
        <AlertDialog
          propsButton={{
            min: 'auto',
          }}
          title={tBanner('main.delete_title')}
          content={tBanner('main.delete_content')}
          handleAccpet={() => handleDelete(props?.id)}
          addDelete
        />
      </WrapperButton>
      <TooltipHover title={props?.title}>
        <Content marginBottom='10px' overText='true'>
          {props?.title}
        </Content>
      </TooltipHover>
      {/* <Content marginBottom='10px'>{props?.title}</Content> */}
      <Content marginBottom='10px'>{`掲載：${checkTypeCell(
        'date',
        props?.start_time
      )} - ${checkTypeCell('date', props?.end_time)}`}</Content>
      <Content marginBottom='10px'>{`${tBanner('main.text_banner')}: ${
        textToBanner[props?.show_affiliator]
      }`}</Content>
      <TooltipHover title={props?.affiliator_name}>
        <Content marginBottom='10px' overText='true'>{`${tBanner('main.text_aff')}: ${
          props?.affiliator_name || tBanner('form.everyone')
        }`}</Content>
      </TooltipHover>
    </CardInfo>
  );
  return (
    <>
      <WrapperButton gap='5px' wrap='true' justify='start'>
        <WrapperButton justify='start' margin='0' width='540px'>
          <Content marginBottom='1.25rem' minWidth='70px'>
            {tBanner('main.date')}
          </Content>
          <FormInputDate
            clearData={setValue}
            control={control}
            name='start_time_from'
            maxDate={
              watch('start_time_to') || watch('end_time_from') || watch('end_time_to') || null
            }
          />
          <FormInputDate
            clearData={setValue}
            control={control}
            name='start_time_to'
            minDate={watch('start_time_from') || null}
            maxDate={watch('end_time_to') || null}
          />
        </WrapperButton>
        <WrapperButton justify='start' margin='0' width='450px'>
          <Content marginBottom='1.25rem' minWidth='70px'>
            {tBanner('main.date_2')}
          </Content>
          <FormInputDate
            clearData={setValue}
            control={control}
            name='end_time_from'
            minDate={
              (!watch('start_time_from') && watch('start_time_to')) ||
              (!watch('start_time_to') && watch('start_time_from')) ||
              (watch('start_time_to') &&
                watch('start_time_from') &&
                watch('start_time_from')) ||
              null
            }
            maxDate={watch('end_time_to') || null}
          />
          <FormInputDate
            clearData={setValue}
            control={control}
            name='end_time_to'
            // minDate={watch('end_time_from') || watch('start_time_from') || watch('start_time_to') || null}
            minDate={
              Math.max(
                watch('end_time_from'),
                watch('start_time_from'),
                watch('start_time_to')
              ) || null
            }
            maxDate={null}
          />
        </WrapperButton>
      </WrapperButton>
      <WrapperButton wrap='true' justify='start'>
        <WrapperButton margin='0' width='250px'>
          <Content marginBottom='0.8rem' minWidth='140px'>
             {tBanner('main.drop')}
          </Content>
          <FormInputDropdown
            control={control}
            name='show_affiliator'
            option={[
              {
                value: '',
                label: tBanner('form.everyone'),
              },
              ...STATUS_BANNER,
            ]}
            noDefault
          />
        </WrapperButton>
        <WrapperButton margin='0' width='400px'>
          <Content marginBottom='0.8rem' minWidth='100px'>
            {tBanner('main.input')}
          </Content>
          <FormInputText
            label={tBanner('main.label_input')}
            control={control}
            name='banner_title'
            inputProps={{
              onKeyDown: (e) => {
                enterToSearch(e, handleSubmit(handleSearch));
              },
            }}
          />
        </WrapperButton>
        <ButtonSubmit
          onClick={handleSubmit(handleSearch)}
          variant='contained'
          bgtheme='primary'>
          {tBanner('main.search')}
        </ButtonSubmit>
      </WrapperButton>
      <WrapperButton justify='space-between'>
        <ButtonSubmit
          variant='outlined'
          bgtheme='primary'
          onClick={() => navigate('/banners-management/create')}>
          {tBanner('main.add')}
        </ButtonSubmit>
        {tBanner('main.total')}{pagination?.total}{tBanner("form.passage")}
      </WrapperButton>
      <WrapperShowListCard
        // resetPage={resetTable}
        action={getListBanner}
        listCard={dataList}
        countSearch={countSearch}
        query={query}
        itemCard={(el, i) => <CardDetail key={i} {...el} />}
        count={pagination?.total_pages}
      />
    </>
  );
};
export { BannerPage };
export * from './EditForm';
