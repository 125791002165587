import { listRouteAgentPage } from './agent.route';
import { listRouteFanAccountPage } from './fan.route';
import { listRoutePaymentPage } from './payment.route';
import { listRouteSettingPage } from './settingPage.route';
import { listRouteManageAccountOperationPage } from './manageAccountOperation.route';
import { listRouteAffiliatorPage } from './affiliator.route';
import { listRouteCreatorManagementPage } from './creatorManagement.route';
import { listRouteRevenuePage } from './revenue.route';
import { listRouteAuth } from './auth.route';
import { listRouteReportPage } from './reports.route';
import { listRouteBanner } from './banner.route';
import { listRouteContactPage } from './contact.route';
import { listRouteNotification } from './notification';
import { listRoutePointPage } from './point.route';
import { listRouteEmailPage } from './email.route';
import { listRouteSettingSite } from './settingSite.route';
import { TestObs } from 'pages/Obs';
import { listRoutePostPage } from './post.route';
import { listRouteSubscription } from './subscription.route';
import { listRouteVoucher } from './voucher.route';
import { listRouteDetailSalePage } from './detailSale.route';

const listRouterRejectAuth = [...listRouteAuth];
const listRouterAcceptAuth = (array = []) => {
  const convertArr = () => {
    let newObj = {};
    array.map((item) => (newObj = { ...newObj, ...item }));
    return newObj;
  };
  const {
    A004 = 1,
    A005 = 1,
    A006 = 1,
    A008 = 1,
    A015 = 1,
    A012 = 1,
    A010 = 1,
    A013 = 1,
    A014 = 1,
    A009 = 1,
    A016 = 1,
    A017 = 1,
    A018 = 1,
    A019 = 1,
    A020 = 1,
    A021 = 1,
    A022 = 1,
    A023 = 1,
    A024 = 1,
  } = convertArr();
  const permissionRoute = [
    {
      route: [...listRouteCreatorManagementPage],
      permission: A015,
    },
    {
      route: [...listRouteSettingSite],
      permission: A004,
    },
    {
      route: [...listRouteSettingPage],
      permission: A005,
    },
    {
      route: [...listRouteAffiliatorPage],
      permission: A014,
    },
    {
      route: [...listRouteFanAccountPage],
      permission: A016,
    },
    {
      route: [...listRouteAgentPage],
      permission: A013,
    },
    {
      route: [...listRoutePaymentPage],
      permission: A008,
    },
    {
      route: [...listRouteManageAccountOperationPage],
      permission: A012,
    },
    {
      route: [...listRouteRevenuePage],
      permission: A006,
    },
    {
      route: [...listRouteReportPage],
      permission: A017,
    },
    {
      route: [...listRouteBanner],
      permission: A009,
    },
    {
      route: [...listRouteContactPage],
      permission: A019,
    },
    {
      route: [...listRouteNotification],
      permission: A010,
    },
    {
      route: [...listRoutePointPage],
      permission: A018,
    },
    {
      route: [...listRouteEmailPage],
      permission: A020,
    },
    {
      route: [...listRoutePostPage],
      permission: A021,
    },
    // {
    //   route: [...listRouteSubscription],
    //   permission: A022,
    // },
    {
      route: [...listRouteVoucher],
      permission: A023,
    },
    {
      route: [...listRouteDetailSalePage],
      permission: A024,
    },
    {
      route: [
        {
          path: `obs`,
          element: <TestObs />,
          title: 'Join Live OBS - Agora',
          currentPath: `/obs`,
          backPath: `/obs`,
        },
      ],
      permission: 1,
    },
  ];
  const combineRoute = permissionRoute.reduce((acc, cur) => {
    if (cur.permission === 1) {
      acc = [...acc, ...cur.route];
    }
    return acc;
  }, []);

  return [...combineRoute];
};
export { listRouterRejectAuth, listRouterAcceptAuth };
