import styled from 'styled-components';
import { TextField } from '@mui/material';

const InputSearch = styled(TextField)`
  margin-right: 10px !important;
  width: ${({ width }) => width};
  label {
    line-height: 1;
  }
  input {
    border: 0px;
    background: none transparent;
    height: 1.4375em;
    margin: 0px !important;
    display: block;
    width: 100%;
    color: rgb(73, 80, 87);
    padding: 0.75rem;
  }
`;

export { InputSearch };
