import { createGlobalStyle } from 'styled-components';
import { Reset } from './reset';
import { ResetMUI } from './resetMUI';

const GlobalStyle = createGlobalStyle`
/* Reset CSS */
  ${Reset}
/* Reset MUI */
  ${ResetMUI}
`;

export { GlobalStyle };
export * from './components/button'